import React, {useState, useContext, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Input, Button, Select} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import {xaMessage, errorHandler} from "../../common";
import {ApiDispatch} from "./Api";

const {Option} = Select;

const ApiDetail = (props) => {
  const {onCancel} = props;
  const [apiMngSq, setApiMngSq] = useState(null);
  const [mode, setMode] = useState("new");
  const [form] = Form.useForm();
  const axios = Axios.create();
  const {gState} = useContext(ApiDispatch);

  /** i18next */
  const {t} = useTranslation();

  const handleLoadApiData = async (apiMngSq) => {
    if (apiMngSq) {
      const {data} = await axios.get(`/api/${apiMngSq}`);
      form.setFieldsValue(data);
      setMode("update");
    } else {
      form.resetFields();
      setMode("new");
    }
  };

  const onBtnCancel = async () => {
    form.resetFields();
    setApiMngSq(null);
    setMode("new");
    onCancel();
  };

  const onFinish = async (paramValue) => {
    if (mode === "new") {
      await Axios.post("/api", {params: paramValue})
        .then(({data, status}) => {
          if (status === 200 || status === "200") {
            xaMessage("success", t("message_save_complete"));
            onCancel();
          } else if (status !== 200 || status !== "200") {
            xaMessage("warn", t("message_please_check"));
          }
        })
        .catch((err) => {
          errorHandler(t("message_error_beginning"), err, null);
        });
    } else {
      await Axios.put(`/api/${paramValue.api_mng_sq}`, {params: paramValue})
        .then(({data, status}) => {
          xaMessage("success", t("message_save_complete"));
          onCancel();
        })
        .catch((err) => {
          errorHandler(t("message_error_beginning"), err, null);
        });
    }
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  useEffect(() => {
    if (apiMngSq !== props.apiMngSq) {
      setApiMngSq(props.apiMngSq);
      handleLoadApiData(props.apiMngSq);
    }
    if (!apiMngSq && props.apiMngSq === null) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.apiMngSq]);

  /* Renderer */
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        api_mng_sq: null,
        c_system_type: null,
        f_api_nm: "",
        f_api_url: "",
        f_method_type: null,
        f_crud_type: null,
        f_description: null,
        f_delete_yn: "N",
      }}
      size="small"
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="api_mng_sq" label={t("api_modal_api_mng_sq")}>
            <Input disabled={true} readOnly={true} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="c_system_type"
            label={t("api_modal_system_type")}
            rules={[{required: true}]}
          >
            <Select>
              <Option>{t("selector_select")}</Option>
              {gState.systemTypeList.map((item) => (
                <Option key={item.code2} value={item.code2}>
                  {item.code2} ({item.code_txt})
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="f_api_nm"
            label={t("api_modal_api_name")}
            rules={[{required: true, message: "Require Name"}]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="f_api_url"
            label={t("api_modal_url")}
            rules={[{required: true, message: "Require Url path"}]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="f_method_type"
            label={t("api_modal_method_type")}
            rules={[{required: true}]}
          >
            <Select>
              <Option>{t("selector_select")}</Option>
              {gState.methodTypeList.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="f_crud_type" label={t("api_modal_crud")} rules={[{required: true}]}>
            <Select>
              <Option>{t("selector_select")}</Option>
              {gState.crudList.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="f_delete_yn" label={t("api_modal_delete")} rules={[{required: true}]}>
            <Select>
              <Option>{t("selector_select")}</Option>
              <Option key="Y" value="Y">
                Y
              </Option>
              <Option key="N" value="N">
                N
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="f_description"
            label={t("api_modal_description")}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={2} placeholder={t("api_modal_description_message")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Button size="default" onClick={onBtnCancel} style={{marginRight: 8}}>
            {t("button_cancel")}
          </Button>
          <Button type="primary" size="default" htmlType="submit">
            {t("button_save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

/* Exports */
export default ApiDetail;
