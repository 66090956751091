import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Input, Button, Radio, Select, Modal} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import {errorHandler, TableRender, XaTooltipButton} from "../../common";
import {convertDatetimeString, replaceIpAddressString} from "../../utils/stringHelper";
import AuthMng from "./AuthMng";
import CompanyMng from "./CompanyMng";
import AdminMng from "./AdminMng";
import UserDetail from "./UserDetail";

const {Option} = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      state = {...state, companyList: action.payload.slice()};
      break;
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    case "SET_USERTYPE":
      state = {...state, userTypeList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const UserDispatch = React.createContext(null);

/* Main Component */
const Users = (props) => {
  const [isAddableAdminAcc, setIsAddableAdminAcc] = useState(false);
  const [authModalvisible, setAuthModalvisible] = useState(false);
  const [adminMngModalVisible, setAdminMngModalVisible] = useState(false);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [radioOptionValue, setRadioOptionValue] = useState(0);

  const [gState, setGState] = useReducer(reducer, {
    companyList: [],
    projectList: [],
    userTypeList: [],
  });
  const {companyList, projectList, userTypeList} = gState;

  const [userMngSq, setUserMngSq] = useState(null);
  const [form] = Form.useForm();

  /** i18next */
  const {t} = useTranslation();

  // 정렬 옵션 라디오 버튼 요소
  const optionsRadioGroup = [
    {label: t("user_menu_order_option_last"), value: 0},
    {label: t("user_menu_order_option_id"), value: 1},
    {label: t("user_menu_order_option_name"), value: 2},
  ];

  /* 사용자 목록 열 */
  const columns = [
    {
      title: t("user_menu_table_user_email"),
      dataIndex: "user_email_id",
      key: "tblUser01",
      width: 250,
    },
    {
      title: t("user_menu_table_name"),
      dataIndex: "f_user_nm",
      key: "tblUser02",
      width: 250,
    },
    {
      title: t("user_menu_table_user_type"),
      key: "tblUser03",
      dataIndex: "c_user_type",
      width: 100,
    },
    {
      title: t("user_menu_table_user_code"),
      key: "tblUser04",
      dataIndex: "user_type_text",
      width: 100,
    },
    {
      title: t("user_menu_table_last_login"),
      key: "tblUser05",
      dataIndex: "last_access_tm",
      width: 200,
      render: (text) => convertDatetimeString(text),
    },
    {
      title: t("user_menu_table_last_ip"),
      key: "tblUser06",
      dataIndex: "ip",
      render: (ip) => replaceIpAddressString(ip),
      width: 100,
    },
    {
      title: t("user_menu_table_description"),
      key: "tblUser07",
      dataIndex: "f_description",
      width: 250,
    },
  ];

  const onClickAuthMng = () => {
    setAuthModalvisible(true);
  };

  const onClickAdminMng = () => {
    setAdminMngModalVisible(true);
  };

  const onClickCompanyMng = () => {
    setCompanyModalVisible(true);
  };

  const onClickAddUser = () => {
    setUserModalVisible(true);
    setUserMngSq(null);
  };

  const handleSetUserForm = (userMngSq) => {
    setUserModalVisible(true);
    setUserMngSq(userMngSq);
  };

  const initForm = async () => {
    try {
      {
        const {data} = await Axios.get("/company");
        setGState({type: "SET_COMPANY", payload: data});
      }
      {
        const {data} = await Axios.get("/project/all");
        setGState({type: "SET_PROJECT", payload: data});
      }
      {
        const {data} = await Axios.get("/code", {params: {code1: "USR"}});
        setGState({type: "SET_USERTYPE", payload: data});
      }
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const handleSearch = async (paramValue) => {
    setIsShowLoading(true);
    paramValue.order = radioOptionValue === null ? 0 : radioOptionValue;
    const {data} = await Axios.get("/user", {params: paramValue});
    setUserList(data);
    setIsShowLoading(false);
  };

  const handleChangeCompany = (target) => {};
  const handleChangeProject = (target) => {};
  const handleChangeUserType = (target) => {};

  const handleRadioOption = (obj) => {
    setRadioOptionValue(obj.target.value);
  };

  const onFinish = (paramValue) => {
    handleSearch(paramValue);
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const handleAuthMngCancel = () => {
    setAuthModalvisible(false);
  };

  const handleCancelAdminMng = () => {
    setAdminMngModalVisible(false);
    initForm();
  };

  const handleCancelCompanyMng = () => {
    setCompanyModalVisible(false);
    initForm();
  };

  const handleOk = () => {};

  const handleCancel = () => {
    setUserMngSq(null);
    setUserModalVisible(false);
  };

  // 사용자 목록 행(Row) 관련 Action (onRow)
  const handleUserListRow = (record, _) => {
    return {
      // Row 클릭
      onClick: () => {
        handleSetUserForm(record.user_mng_sq);
      },
    };
  };

  useEffect(() => {
    setIsAddableAdminAcc(JSON.parse(sessionStorage.userInfo).admin_auth_yn);
    initForm();
    handleSearch({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <UserDispatch.Provider value={{gState, setGState}}>
      <Form
        form={form}
        name="advanced_search"
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        // className="ant-advanced-search-form"
        className="common-search-area-form "
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{company_mng_sq: null, project_mng_sq: null, f_user_type: null}}
      >
        <Row gutter={24}>
          <Col span={8} key={0}>
            <Form.Item name="company_mng_sq" label={t("user_company")} rules={[{required: false}]}>
              <Select onChange={handleChangeCompany}>
                <Option>{t("selector_all")}</Option>
                {companyList.map((company) => (
                  <Option key={company.company_mng_sq} value={company.company_mng_sq}>
                    {company.f_comp_nm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} key={1}>
            <Form.Item
              name="project_mng_sq"
              label={t("general_project")}
              rules={[{required: false}]}
            >
              <Select onChange={handleChangeProject} showSearch optionFilterProp="children">
                <Option>{t("selector_all")}</Option>
                {projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    [{project.project_mng_sq}] {project.f_proj_nm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} key={11}>
            <Form.Item name="f_user_type" label={t("user_type")} rules={[{required: false}]}>
              <Select onChange={handleChangeUserType}>
                <Option>{t("selector_all")}</Option>
                {userTypeList.map((code) => (
                  <Option key={code.code1 + code.code2} value={code.code1 + code.code2}>
                    {code.code_txt} ({code.code1 + code.code2})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8} key={2}>
            <Form.Item name="user_email_id" label={t("general_id")} rules={[{required: false}]}>
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8} key={3}>
            <Form.Item
              name="f_user_nm"
              label={t("user_detail_user_name")}
              rules={[{required: false}]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} key={4}>
            <div className="ant-form-item-label">{t("user_menu_order")}</div>
            <Radio.Group
              className="col-radio-buttton-right"
              onChange={handleRadioOption}
              value={radioOptionValue}
            >
              {optionsRadioGroup.map((el) => (
                <Radio value={el.value}>{el.label}</Radio>
              ))}
            </Radio.Group>
          </Col>
          <Col className="col-align-right" span={16} key={5}>
            <XaTooltipButton
              title={t("user_menu_button_authmng_description")}
              placement="bottom"
              btnName={t("user_menu_button_authmng")}
              btnType="dashed"
              onClick={onClickAuthMng}
            />

            <XaTooltipButton
              title={t("user_menu_button_admin_account_description")}
              placement="bottom"
              btnName={t("user_menu_button_admin_account")}
              btnType="dashed"
              onClick={onClickAdminMng}
              disabled={!isAddableAdminAcc}
            />

            <XaTooltipButton
              title={t("user_menu_button_company_description")}
              placement="bottom"
              btnName={t("user_menu_button_company")}
              btnType="dashed"
              onClick={onClickCompanyMng}
            />

            <Button type="dashed" onClick={onClickAddUser}>
              {t("user_menu_button_add_user")}
            </Button>
            <Button type="primary" htmlType="submit">
              {t("button_search")}
            </Button>
            <Button style={{margin: "0 8px"}} onClick={() => form.resetFields()}>
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </Form>

      <TableRender
        rowClassName="table-row-action"
        columns={columns}
        dataSource={userList}
        onRow={handleUserListRow}
        isLoading={isShowLoading}
        rowKey={"user_email_id"}
        size="small"
      />

      <Modal
        title={t("user_authmng_title")}
        open={authModalvisible}
        onOk={handleOk}
        onCancel={handleAuthMngCancel}
        footer={null}
        width={800}
        getContainer={false}
        maskClosable={false}
      >
        <AuthMng onCancel={handleAuthMngCancel} />
      </Modal>

      <Modal
        title={t("user_admin_mng_title")}
        open={adminMngModalVisible}
        onOk={handleOk}
        onCancel={handleCancelAdminMng}
        footer={null}
        width={450}
        getContainer={false}
        maskClosable={false}
      >
        <AdminMng onCancel={handleCancelAdminMng} />
      </Modal>

      <Modal
        title={t("user_company_modal_title")}
        open={companyModalVisible}
        onOk={handleOk}
        onCancel={handleCancelCompanyMng}
        footer={null}
        width={500}
        getContainer={false}
        maskClosable={false}
      >
        <CompanyMng onCancel={handleCancelCompanyMng} />
      </Modal>

      <Modal
        title={userMngSq ? t("user_detail_title_already") : t("user_detail_title_new")}
        open={userModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={950}
        getContainer={false}
        maskClosable={false}
      >
        <UserDetail onCancel={handleCancel} userMngSq={userMngSq} />
      </Modal>
    </UserDispatch.Provider>
  );
};

/* Exports */
export default Users;
