import React, {useState, useEffect} from "react";
import {Row, Col, Button, Upload, Modal} from "antd";
import "antd/dist/antd.css";
import {UploadOutlined} from "@ant-design/icons";
import Axios from "axios";
import {saveAs} from "file-saver";
import {useTranslation} from "react-i18next";
import "./index.css";
import {xaMessage, errorHandler, XaConfirmButton, LoadingAnimationWholePage} from "../../common";
import {convertDateString, convertDatetimeString} from "../../utils/stringHelper";

// 다운로드 버튼(JSON, Excel) 요소 정의
const BtnDownload = (props) => {
  const {name, onClick, disabled, className = ""} = props;
  return (
    <Button className={className} type="primary" onClick={onClick} disabled={!disabled}>
      {name}
    </Button>
  );
};

/* Main Component */
const SectionDataMngDetail = (props) => {
  const {onCancel, sectionInfo} = props;

  const [params, setParams] = useState({
    category: "",
    projectId: "",
    commitDate: "",
  });
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [cutValue, setCutValue] = useState(0);
  const [fillValue, setFillValue] = useState(0);
  const [uploadFile, setUploadFile] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isThumbnailImage, setIsThumbnailImage] = useState(false);

  const [thumbnailImageModal, setThumbnailImageModal] = useState(false);
  const [thumbnailImageLink, setThumbnailImageLink] = useState("");

  /** i18next */
  const {t} = useTranslation();

  // let params = {
  //   category: "",
  //   projectId: sectionInfo.projectId,
  //   commitDate: sectionInfo.commitDate,
  // };

  /** 섬네일 보기 버튼 클릭 */
  const onClickViewThumbnail = () => {
    setThumbnailImageModal(true);
  };

  /** 섬네일 Modal 닫기 */
  const onCancelImageModal = () => {
    setThumbnailImageModal(false);
  };

  /** 섬네일 이미지 삭제 (삭제 버튼 클릭) */
  const onClickDeleteThumbnail = async () => {
    handleDeleteThumbnailImage();
    onCancel();
  };

  /** 다운로드 버튼(Excel/JSON) 클릭 */
  const onClickDownload = async (cat) => {
    params.category = cat === "json" ? "json" : "xlsx";
    handleDownloadFile(params);
  };

  /** 파일 업로드 시 이벤트 */
  const onChangeUpload = ({fileList}) => {
    setUploadFile(fileList);
  };

  /** 저장(Save) 버튼 클릭 */
  const onClickSave = async () => {
    if (uploadFile.map((i) => i.status === "done").length !== 1) {
      setIsUploading(true);
      return;
    }

    handleSaveThumbnailImage();
    onCancel();
  };

  /** 닫기(Close) 버튼 클릭 */
  const onClickBtnClose = () => {
    onCancel();
  };

  /** 다운로드 파일 (Excel, JSON) */
  const handleDownloadFile = async (params) => {
    if (!sessionStorage.userInfo) {
      errorHandler(t("message_error_beginning"), t("message_please_check_login"));
      return;
    } else {
      const downloadFileName = `section_commit_${params.projectId}_${params.commitDate}.${params.category}`;
      const url = "/sectionData/downloads";
      const sendParams = {
        params: {
          projectId: params.projectId,
          commitDate: params.commitDate,
          category: params.category,
        },
        responseType: "blob",
      };
      setIsShowLoading(true);

      await Axios.get(url, sendParams)
        .then((response) => {
          saveAs(new Blob([response.data]), downloadFileName);
          setIsShowLoading(false);
          xaMessage("info", t("message_download_complete"));
        })
        .catch((err) => {
          setIsShowLoading(false);
          if (err.toString().includes("status code 404")) {
            xaMessage("warn", t("message_warn_file_not_found"));
          } else {
            errorHandler(t("message_error_beginning"), err.toString());
          }
        });
    }
  };

  /** 기성 섬네일 이미지 삭제 */
  const handleDeleteThumbnailImage = async () => {
    setIsShowLoading(true);
    await Axios.post("/sectionData/remove/thumbnail", {
      params: {
        projectId: params.projectId,
        commitDate: params.commitDate,
      },
    })
      .then(() => {
        setIsShowLoading(false);
      })
      .catch((err) => {
        setIsShowLoading(false);
        errorHandler(t("message_error_beginning"), err);
      });
  };

  /** 섬네일 이미지 저장 */
  const handleSaveThumbnailImage = async () => {
    if (!sessionStorage.userInfo) {
      errorHandler(t("message_error_beginning"), t("message_please_check_login"));
      return;
    }

    if (!uploadFile[0].type.includes("image") && !uploadFile[0].type.includes("png")) {
      xaMessage("error", t("message_please_check_upload_image"));
      return;
    }

    let formData = new FormData();
    formData.append("thumbnailImage", uploadFile[0].originFileObj);
    formData.append("projectId", params.projectId);
    formData.append("commitDate", params.commitDate);

    setUploadFile([]);
    await Axios.post("/sectionData/upload/thumbnail", formData, {
      headers: {"Content-Type": "multipart/form-data"},
    })
      .then(() => {
        setIsShowLoading(false);
      })
      .catch((err) => {
        setIsShowLoading(false);
        errorHandler(t("message_error_beginning"), err);
      });
  };

  /** 절성토량 정보 등 획득 */
  const handleGetCutfillData = async (params) => {
    setIsShowLoading(true);
    await Axios.get("/sectionData/cutFillInfo", {
      params: {
        projectId: params.projectId,
        commitDate: params.commitDate,
      },
    })
      .then(({data}) => {
        setCutValue(data.info.cut);
        setFillValue(data.info.fill);
        setIsThumbnailImage(data.isExistThumbnail);

        setThumbnailImageLink(
          data.isExistThumbnail
            ? `/Storage/data_pcd/${params.projectId}/${params.commitDate}/pcd_${params.projectId}_${params.commitDate}_cutfill_thumbnail.png`
            : null
        );
      })
      .catch((err) => {
        errorHandler(t("message_error_beginning"), err.toString());
      });
    setIsShowLoading(false);
  };

  /** Effect */
  useEffect(() => {
    setParams({
      category: "",
      projectId: props.sectionInfo.projectId,
      commitDate: props.sectionInfo.commitDate,
    });
    handleGetCutfillData(props.sectionInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sectionInfo]);

  /* Renderer */
  return (
    <>
      <LoadingAnimationWholePage
        isLoading={isShowLoading}
        component={
          <>
            <div className="detail-info-area">
              {/* 기성 정보 표시 Table 영역 시작 */}
              <table>
                <tbody>
                  {/* 프로젝트명 */}
                  <tr>
                    <th>{t("section_data_mng_modal_project_name")}</th>
                    <td>
                      ({sectionInfo.projectId}) {sectionInfo.projectName}
                    </td>
                  </tr>
                  {/* 기성일자 */}
                  <tr>
                    <th>{t("section_data_mng_modal_commit_date")}</th>
                    <td>{convertDateString(sectionInfo.commitDate)}</td>
                  </tr>
                  {/* 처리일시 */}
                  <tr>
                    <th>{t("section_data_mng_modal_process_date")}</th>
                    <td>{convertDatetimeString(sectionInfo.processingDate)}</td>
                  </tr>
                  {/* 절성토량 표시 영역 */}
                  <tr>
                    <th>{`${t("section_data_mng_modal_cutfill_volume")}(m³)`}</th>
                    <td>
                      <span>
                        {/* 절토 */}
                        {`${t("section_data_mng_modal_cutfill_volume_cut")}: ${cutValue}`}
                        <br />
                        {/* 성토 */}
                        {`${t("section_data_mng_modal_cutfill_volume_fill")}: ${fillValue}`}
                      </span>
                    </td>
                  </tr>
                  {/* PLY Thumbnail 버튼 표시 영역
                   <tr>
                    <th>{t("section_data_mng_modal_ply_thumbnail")}</th>
                    <td>
                      <Button
                        type="primary"
                        disabled={!isThumbnailImage}
                        onClick={onClickViewThumbnail}
                      >
                        {t("button_view")}
                      </Button>

                      {JSON.parse(sessionStorage.getItem("userInfo")).c_user_type === "USR901" && (
                        <XaConfirmButton
                          contents={t("delete_confirm")}
                          onConfirm={onClickDeleteThumbnail}
                          btnName={t("button_delete")}
                          buttonClassName="td-right-button"
                          placement="bottom"
                          size="middle"
                          disabled={!isThumbnailImage}
                        />
                      )}
                    </td>
                  </tr> */}
                  {/* 기성 자료 다운로드 */}
                  <tr>
                    <th>{t("section_data_mng_modal_download")}</th>
                    <td>
                      <BtnDownload
                        name={t("section_data_mng_modal_excel")}
                        onClick={() => onClickDownload("excel")}
                        disabled={sectionInfo.isExistExcel}
                      />
                      <BtnDownload
                        className="td-right-button"
                        name={t("section_data_mng_modal_json")}
                        onClick={() => onClickDownload("json")}
                        disabled={sectionInfo.isExistJson}
                      />
                    </td>
                  </tr>
                  {/* PLY Thumbnail 업로드 - 관리자권한(USR901)만 접근 */}
                  {/* {JSON.parse(sessionStorage.getItem("userInfo")).c_user_type === "USR901" && (
                    <tr>
                      <th>{t("section_data_mng_modal_upload_ply_thumbnail")}</th>
                      <td>
                        <span className="upload-cell">
                          <Upload
                            className="upload-object"
                            maxCount={1}
                            onChange={onChangeUpload}
                            fileList={uploadFile}
                            // showUploadList={isShowUploadList}
                          >
                            <Button size="small" icon={<UploadOutlined />}>
                              {t("button_upload")}
                            </Button>
                          </Upload>
                        </span>
                      </td>
                    </tr>
                  )}*/}
                </tbody>
              </table>
              {/* 기성 정보 표시 Table 영역 끝 */}
            </div>
            <Row gutter={[32, 24]}>
              <Col span={24} className="details-close-btn">
                {/*JSON.parse(sessionStorage.getItem("userInfo")).c_user_type === "USR901" && (
                  <Button
                    type="primary"
                    onClick={onClickSave}
                    size="middle"
                    disabled={uploadFile.length === 0 || isUploading}
                  >
                    {t("button_save")}
                  </Button>
                )*/}
                <Button className="button-margin-left" onClick={onClickBtnClose} size="middle">
                  {t("button_close")}
                </Button>
              </Col>
            </Row>
          </>
        }
      />

      <Modal
        open={thumbnailImageModal}
        title={t("section_data_mng_modal_ply_thumbnail_modal_title")}
        footer={null}
        onCancel={onCancelImageModal}
        width={800}
        centered={true}
        maskClosable={false}
        closable={true}
        keyboard={true}
      >
        <img
          alt={t("section_data_mng_modal_ply_thumbnail_modal_alt")}
          style={{width: "100%"}}
          src={thumbnailImageLink}
        />
      </Modal>
    </>
  );
};

/* Exports */
export default SectionDataMngDetail;
