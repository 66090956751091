/* React */
import React, {useState} from "react";
import moment from "moment";
import {Layout, Row, Col} from "antd";
import "./index.css";
import Axios from "axios";
import {useTranslation} from "react-i18next";
import LogoSymbol from "../../../src/assets/logo/logo-symbol.svg";
import {xcHealthCheck} from "../../utils/utils";
import {shortenString} from "../../utils/stringHelper";
import {useInterval} from "../../utils/useInterval";
import {HEALTH_CHECK_INTERVAL_SECONDS} from "../../config/miscellaneousInfo";
import {XaTooltip, XaConfirmButton} from "../../common";

/* Main Component */
const Mobile = (props) => {
  /* i18next */
  const {t} = useTranslation();

  const {Header, Footer, Content} = Layout;
  const [initUserInfo, setInitUserInfo] = useState(
    sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : null
  );
  const [initUserCompanyInfo, setInitUserCompanyInfo] = useState(
    sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo).company_info[0] : null
  );
  const [xcSolStatus, setXcSolStatus] = useState(null);
  const [xcAdminStatus, setAdminXcStatus] = useState(null);

  /**
   * 서버 상태 컴포넌트
   * @param {*} props
   *    type: 서버 구분 (sol: XiteCloud Solution(Fleet, Analyst), admin: XiteAdmin)
   *    state: 서버 상태 (T/F)
   * @returns AntD Tooltip을 포함한 컴포넌트
   */
  const StatusInfoComponent = (props) => {
    const {type, state} = props;
    const indicatorContents = state ? "OK" : "Check";
    const typeContents = type === "sol" ? "XiteCloud Server" : "XiteAdmin Server";
    const indicatorMain = state ? <span className="green-dot" /> : <span className="red-dot" />;
    const typeStringMain = type === "sol" ? "XC" : "XA";

    return (
      <>
        <XaTooltip
          content={`${typeContents}: ${indicatorContents}`}
          placement="bottom"
          mainComponent={
            <span>
              {indicatorMain}
              {typeStringMain}
            </span>
          }
          color={state ? "#29c93e" : "#ec281a"}
        />
      </>
    );
  };

  const AccountInfoComponent = () => {
    const userInfoObject = JSON.parse(sessionStorage.userInfo);

    return (
      <div className="account-tooltip-content">
        <table>
          <tbody>
            <tr>
              <th>User Seq. </th>
              <td>{userInfoObject.user_mng_sq}</td>
            </tr>
            <tr>
              <th>ID</th>
              <td>{shortenString(13, userInfoObject.user_email_id)}</td>
            </tr>
            <tr>
              <th>User Type</th>
              <td>{userInfoObject.t_user_type}</td>
            </tr>
            <tr>
              <th>Company</th>
              <td>{userInfoObject.company_info[0].f_comp_nm}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getHealthInfo = async () => {
    const result = await xcHealthCheck();
    setAdminXcStatus(result.admin);
    setXcSolStatus(result.sol);
  };

  const handleLogout = () => {
    Axios.get("/authenticate")
      .then(() => {
        sessionStorage.removeItem("userInfo");
        window.location.href = "/";
      })
      .catch((err) => console.log(err));
  };

  useInterval(() => {
    getHealthInfo();
  }, HEALTH_CHECK_INTERVAL_SECONDS);

  /* Renderer */
  return (
    <Layout>
      <Header>
        {/* Header */}
        <Row className="header-mobile">
          <Col>
            <img
              className="logo-img"
              src={LogoSymbol}
              alt="XiteCloud Logo"
              onClick={() => window.open("https://xitecloud.io/")}
            />
          </Col>
          <Col className="user-info-space">
            <Col className="server-status-space">
              <span className="server-status-each">
                <StatusInfoComponent type="sol" state={xcSolStatus} />
              </span>
              <span className="server-status-each">
                <StatusInfoComponent type="admin" state={xcAdminStatus} />
              </span>
            </Col>
            <Col>
              <XaConfirmButton
                contents="Are you sure to logout?"
                onConfirm={handleLogout}
                btnName="Logout"
                size="small"
                buttonClassName="button-logout"
                placement="bottomRight"
              />
            </Col>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Content>
          <p>Mobile Page</p>
        </Content>
      </Layout>
      <Footer>
        {/* Footer */}
        ©️ {moment().format("YYYY")} {t("footer_copyright")}
      </Footer>
    </Layout>
  );
};

/* Exports */
export default Mobile;
