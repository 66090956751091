import Axios from "axios";
import {relocateNotFoundUrl, relocateMain} from "./utils";

const currentPath = window.location.pathname;
const permittedMenus = sessionStorage.getItem("userInfo")
  ? JSON.parse(sessionStorage.getItem("userInfo")).permission_menu_info.map((item) => item.href)
  : [];

// HTTP client(Axios) 오류 처리 함수
const errorHandlerInternal = (error) => {
  console.log(error);
};

// 다운로드 링크 접근 회피
if (currentPath.includes("/downloads")) {
  relocateNotFoundUrl();
}

if (currentPath !== "/" && permittedMenus.includes(currentPath)) {
  relocateMain();
}

// Request Intercepted
Axios.interceptors.request.use((requestData) => {
  if (requestData.url.includes("/downloads") && !sessionStorage.userInfo) {
    return;
  }
  return requestData;
}, errorHandlerInternal);

// Response Intercepted
Axios.interceptors.response.use(
  (responseData) => {
    return responseData;
  },
  (error) => {
    // 오류 발생 시에도 Response 처리
    return Promise.resolve({
      data: null,
      status: error.response ? error.response.status : 500,
      statusText: error.response ? error.response.statusText : "Internal Server Error",
      headers: error.response ? error.response.headers : {},
      config: error.config,
      request: error.request,
    });
  }
);
