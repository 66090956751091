import moment from "moment";
import {saveAs} from "file-saver";
const Excel = await import("exceljs");

/**
 * 헤더 생성
 * @param {*} originalData  실제 데이터의 첫 행
 * @param {*} category  저장 파일형식 구분
 * @returns
 */
export const generateColumn = (originalData, category) => {
  const columns =
    category === "xlsx"
      ? Object.keys(originalData).map((item) => {
          return {
            header: item,
            key: item,
            width: item.length,
          };
        })
      : Object.keys(originalData)
          .map((item) => item)
          .toString()
          .replaceAll(",", ";");
  return columns;
};

/**
 * JSON 형식 데이터 Excel 파일 저장
 * @param {*} data  JSON 데이터
 * @param {*} category  구분("anno": 어노테이션, "geofence": 지오펜스)
 * @param {*} fileName  파일명
 */
export const createExcel = async (data, category, fileName) => {
  const prefix = category === "anno" ? "Annotation" : "GeoFence";
  const workbook = new Excel.Workbook();

  workbook.creator = "XiteAdmin";
  workbook.lastModifiedBy = "XiteAdmin";
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  // 문서 내부 레이아웃 설정
  const worksheet = workbook.addWorksheet(`${prefix}_${moment().format("YYYYMMDDHHmmss")}`); // 시트 추가
  worksheet.columns = generateColumn(data[0], "xlsx");

  worksheet.eachRow({includeEmpty: false}, (row, _rowNumber) => {
    row.eachCell({includeEmpty: true}, (cell) => {
      if (_rowNumber === 1) {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.font = {
          bold: true,
        };
      } else {
        cell.alignment = {
          vertical: "middle",
        };
        cell.font = {
          name: "Malgun",
        };
      }
    });
  });

  for (let idx in data) {
    worksheet.addRow(data[idx]);
  }
  const buffer = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), `${fileName}.xlsx`);
};

/**
 * JSON 형식 데이터의 CSV 파일 변환 및 저장
 * @param {*} data  JSON 데이터
 * @param {*} fileName  파일명
 */
export const createCsv = async (data, fileName) => {
  fileName += `${moment().format("YYYYMMDDHHmmss")}.csv`;
  let csvString = generateColumn(data[0], "csv");

  for (let idx in data) {
    const _idxData = Object.values(data[idx]).map((item) => {
      return typeof item === "object" ? JSON.stringify(item) : !item ? " " : item;
    });

    csvString += `\n${_idxData.join(";")}`; // 세미콜론으로 열(Column) 구분
  }

  saveAs(
    new Blob([csvString], {
      type: "text/csv;charset=utf-8",
    }),
    fileName
  );
};

/**
 * 업로드 Excel 파일의 JSON 형식 반환
 *
 * @param {*} file  File 객체
 * @returns JSON 형식 데이터
 */
export const convertExcelToJson = async (file) => {
  const workbook = new Excel.Workbook();
  let _columns = [];
  let _jsonData = [];

  try {
    await workbook.xlsx.load(file).then((workbook) => {
      workbook.eachSheet((sheet, _) => {
        sheet.eachRow((row, rowNumber) => {
          if (rowNumber === 1) {
            _columns = row.values.map((item) => item);
            _jsonData.push(_columns);
          } else if (rowNumber > 1) {
            let _json = {};
            for (let i = 1; i < _columns.length; i++) {
              _json[_columns[i]] = row.values[i];
            }
            _jsonData.push(_json);
          }
        });
      });
    });

    return _jsonData;
  } catch (err) {
    console.log(err);
  }
};
