import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Button, Modal, Input, Select} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import {TableRender, errorHandler} from "../../common";
import {convertDateString, sortByOrder, filterEmptyValues} from "../../utils/stringHelper";
import XcApiKeyDetail from "./XcApiKeyDetail";

const {Option} = Select;

// 선택(Select) 요소 정의
const SlctXcApiKey = (props) => {
  const {translate, nameSpace, onChange, value, selectList, code} = props;
  return (
    <>
      <Row>{nameSpace}:</Row>
      <Row>
        <Select
          showSearch
          optionFilterProp="children"
          className="select-general"
          onChange={onChange}
          value={value}
        >
          <Option value={""}>{translate("selector_select")}</Option>
          {selectList.map((el) => (
            <Option key={el.value} value={el.value}>
              {code === "status" ? el.label : `[${el.value}] ${el.label}`}
            </Option>
          ))}
        </Select>
      </Row>
    </>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      state = {...state, companyList: action.payload.slice()};
      break;
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    default:
      break;
  }
  return state;
};

/* Main Component */
const XcApiKey = () => {
  /* States */
  const [keyInfoModalVisible, setKeyInfoModalVisible] = useState(false); // Modal Popup (상세)
  const [isShowLoading, setIsShowLoading] = useState(false); // 로딩 애니메이션 State

  const [initKeyInfoList, setInitKeyInfoList] = useState([]); // API Key 발급 내역 목록
  const [keyInfoList, setKeyInfoList] = useState([]); // API Key 발급 내역 목록

  const [keyInfoDetail, setKeyInfoDetail] = useState(null);

  // 검색 영역
  const [selectedCompany, setSelectedCompany] = useState(""); // 선택 소속(회사)
  const [selectedProject, setSelectedProject] = useState(""); // 선택 프로젝트
  const [selectedStatus, setSelectedStatus] = useState(null); // 선택 상태
  const [searchUserId, setSearchUserId] = useState(""); // 검색 대상 사용자 ID
  const [searchUserName, setSearchUserName] = useState(null); // 검색 대상 사용자명

  const [gState, setGState] = useReducer(reducer, {
    companyList: [],
    projectList: [],
  });

  const {companyList, projectList} = gState;

  // i18next
  const {t} = useTranslation();

  /* 테이블 열 */
  const column = [
    {
      title: t("xc_api_key_table_column_company"),
      key: "tblXcApiKey01",
      dataIndex: "f_comp_nm",
    },
    {
      title: t("xc_api_key_table_column_project_id"),
      key: "tblXcApiKey02",
      dataIndex: "project_mng_sq_t_project_mng",
    },
    {
      title: t("xc_api_key_table_column_project_name"),
      key: "tblXcApiKey03",
      dataIndex: "f_proj_nm",
      width: 350,
    },
    {
      title: t("xc_api_key_table_column_create_date"),
      key: "tblXcApiKey04",
      dataIndex: "create_dt",
      render: (dateTime) => convertDateString(dateTime),
    },
    {
      title: t("xc_api_key_table_column_status"),
      key: "tblXcApiKey05",
      dataIndex: "f_status",
      render: (status) =>
        status === 1 ? t("xc_api_key_status_use") : t("xc_api_key_status_no_use"),
    },
    {
      title: t("xc_api_key_table_column_creater"),
      key: "tblXcApiKey06",
      dataIndex: "user_email_id",
    },
    {
      title: t("xc_api_key_table_column_creater_name"),
      key: "tblXcApiKey07",
      dataIndex: "f_user_nm",
    },
  ];

  const onChangeProject = (value) => {
    setSelectedProject(value);
  };

  const onChangeCompany = (value) => {
    setSelectedCompany(value);
  };

  const onChangeStatus = (value) => {
    setSelectedStatus(value);
  };

  const onChangeUserId = (e) => {
    setSearchUserId(e.target.value);
  };
  const onChangeUserName = (e) => {
    setSearchUserName(e.target.value);
  };

  // Key Info 상세정보 Modal 열기
  const onClickKeyInfoModal = () => {
    setKeyInfoDetail(null);
    setKeyInfoModalVisible(true);
  };

  // Clear 버튼 클릭
  const onClickClear = () => {
    setSelectedCompany(null);
    setSelectedProject(null);
    setSelectedStatus(null);
    setSearchUserId(null);
    setSearchUserName(null);
    setKeyInfoList(initKeyInfoList);
  };

  // Key Info 상세정보 Modal 닫기
  const onCancelInfoModal = () => {
    setKeyInfoDetail(null);
    setKeyInfoModalVisible(false);
    handleInquiry();
  };

  // 초기화 함수 (소속(회사), 프로젝트 정보 조회)
  const handleInit = async () => {
    try {
      {
        const {data} = await Axios.get("/company");
        setGState({
          type: "SET_COMPANY",
          payload: data.map((company) => {
            return {label: company.f_comp_nm, value: company.company_mng_sq};
          }),
        });
      }
      {
        const {data} = await Axios.get("/project/all");
        setGState({
          type: "SET_PROJECT",
          payload: data.map((project) => {
            return {label: project.f_proj_nm, value: project.project_mng_sq};
          }),
        });
      }
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // Table 행 클릭
  const handleTableRow = (keyInfo) => {
    setKeyInfoDetail(keyInfo);
    setKeyInfoModalVisible(true);
  };

  // Key 관리 내역 조회
  const handleInquiry = async () => {
    setIsShowLoading(true);

    try {
      const {data} = await Axios.get("/xcapikey/list");
      const sortedData = sortByOrder(data, "seq", "asc");

      setInitKeyInfoList(sortedData);
      setKeyInfoList(sortedData);

      setIsShowLoading(false);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
      setIsShowLoading(false);
    }
  };

  // 검색 버튼 또는 검색 기능
  const handleSearch = () => {
    const validSearchValues = filterEmptyValues({
      company_mng_sq_t_company_mng: selectedCompany,
      project_mng_sq_t_project_mng: selectedProject,
      f_status: selectedStatus,
      user_email_id: searchUserId,
      f_user_nm: searchUserName,
    });

    setKeyInfoList(
      initKeyInfoList.filter((item) =>
        Object.keys(validSearchValues).every((key) =>
          key === "user_email_id" || key === "f_user_nm"
            ? item[key].toLowerCase().includes(validSearchValues[key].toLowerCase()) ||
              item[key].toLowerCase() === validSearchValues[key].toLowerCase()
            : item[key] === validSearchValues[key]
        )
      )
    );
  };

  useEffect(() => {
    handleInit();
    handleInquiry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <>
      {/* 조회 조건 영역 */}
      <div className="search-area common-search-area-form">
        <Row className="search-area-row-1">
          <Col span={8} key={0} className="search-area-column">
            <SlctXcApiKey
              translate={t}
              nameSpace={t("general_company")}
              onChange={onChangeCompany}
              value={selectedCompany}
              selectList={companyList}
              code={"company"}
            />
          </Col>
          <Col span={8} key={1} className="search-area-column">
            <SlctXcApiKey
              translate={t}
              nameSpace={t("general_project")}
              onChange={onChangeProject}
              value={selectedProject}
              selectList={projectList}
              code={"project"}
            />
          </Col>
          <Col span={8} key={2} className="search-area-column">
            <SlctXcApiKey
              translate={t}
              nameSpace={t("general_status")}
              onChange={onChangeStatus}
              value={selectedStatus}
              selectList={[
                {label: t("xc_api_key_status_use"), value: 1},
                {label: t("xc_api_key_status_no_use"), value: 0},
              ]}
              code={"status"}
            />
          </Col>
        </Row>
        <Row className="search-area-row-2 ">
          <Col span={8} className="search-area-column">
            <Row>
              <div className="ant-form-item-label search-area-row-2-col-1">
                {t("xc_api_key_user_email_id")}:
              </div>
            </Row>
            <Row>
              <Input
                className="search-user"
                onChange={onChangeUserId}
                onPressEnter={handleSearch}
                value={searchUserId}
              />
            </Row>
          </Col>
          <Col span={8} className="search-area-column">
            <Row>
              <div className="ant-form-item-label search-area-row-2-col-1">
                {t("xc_api_key_user_email_user_name")}:
              </div>
            </Row>
            <Row>
              <Input
                className="search-user"
                onChange={onChangeUserName}
                onPressEnter={handleSearch}
                value={searchUserName}
              />
            </Row>
          </Col>
          <Col span={8} className="search-button-area">
            <Row>
              {/* API 관리 정보 상세 Modal 버튼 */}
              <Button type="dashed" onClick={onClickKeyInfoModal}>
                {t("button_generate_api_key")}
              </Button>
              <Button type="primary" onClick={handleSearch}>
                {t("button_search")}
              </Button>
              <Button className="button-margin-left" onClick={onClickClear}>
                {t("button_clear")}
              </Button>
            </Row>
          </Col>
        </Row>
      </div>

      {/* Key 발급이력 목록 영역 */}
      <TableRender
        columns={column}
        dataSource={keyInfoList}
        rowKey="seq"
        isLoading={isShowLoading}
        size="small"
        rowClassName="table-row-action"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleTableRow(record),
          };
        }}
      />

      {/* Key 내역 상세 Modal */}
      <Modal
        title={
          !keyInfoDetail ? t("xc_api_key_info_detail_title_new") : t("xc_api_key_info_detail_title")
        }
        open={keyInfoModalVisible}
        getContainer={false}
        onCancel={onCancelInfoModal}
        footer={null}
        width={550}
        centered={true}
        maskClosable={false}
        closable={true}
        keyboard={true}
      >
        <XcApiKeyDetail
          onCancel={onCancelInfoModal}
          keyInfo={keyInfoDetail}
          companyList={companyList}
          projectList={projectList}
          existKeyProjectList={projectList.filter((project) =>
            keyInfoList
              .filter((info) => info.f_status === 1)
              .map((info) => info.project_mng_sq_t_project_mng)
              .includes(project.value)
          )}
        />
      </Modal>
    </>
  );
};

export default XcApiKey;
