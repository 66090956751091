import {Popconfirm, Button} from "antd";
import "./common.css";
import {useTranslation} from "react-i18next";

/**
 * 공통  Popconfirm + Button 구성 요소
 * @param {*} props
 *   - contents  메시지 내용
 *   - onConfirm  OK 클릭 시 실행할 함수 내용
 *   - onCancel  Cancel 클릭 시 실행할 함수 내용
 *   - icon 버튼명 대신 표시할 아이콘 요소
 *   - buttonClassName 버튼에 적용할 CSS Class명
 *   - okText OK 버튼에 적용할 문자열
 *   - cancelText Cancel 버튼에 적용할 문자열
 *   - placement Popconfirm 위치
 *   - size 버튼 크기
 *   - disabled  Popconfirm 및 버튼 비활성 여부
 * @returns Popconfirm + Button
 */
export const XaConfirmIconButton = (props) => {
  // i18next
  const {t} = useTranslation();

  const {
    contents,
    onConfirm,
    onCancel,
    icon,
    buttonClassName = "",
    okText = t("button_ok"),
    cancelText = t("button_cancel"),
    placement = "bottom",
    size = "middle",
    disabled = false,
  } = props;

  return (
    <>
      <Popconfirm
        disabled={disabled}
        title={contents}
        onConfirm={onConfirm}
        onCancel={onCancel}
        okText={okText}
        cancelText={cancelText}
        placement={placement}
      >
        <Button className={buttonClassName} type="link" disabled={disabled} size={size}>
          {<div style={{border: "none"}}>{icon}</div>}
        </Button>
      </Popconfirm>
    </>
  );
};
