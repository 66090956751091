import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Button, Select} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import {convertAnnotationResultString} from "../../utils/utils";
import {convertDateString} from "../../utils/stringHelper";
import {TableRender, XaConfirmButton} from "../../common";

const {Option} = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

// Transaction
export const executeTransaction = async (code, requestUrl, param) => {
  // code: 0 - get, 1 - post
  const {data} =
    code === 0
      ? await Axios.get(requestUrl, {params: param})
      : await Axios.post(requestUrl, {params: param});
  return data;
};

/* Main Component */
const AnnotationCopy = (props) => {
  /** states */
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [searchButtonStatus, setSearchButtonStatus] = useState(true); // Search 버튼 활성화 여부
  const [copyAllButtonStatus, setCopyAllButtonStatus] = useState(true); // Copy All 버튼 활성화 여부
  const [copySelectButtonStatus, setCopySelectButtonStatus] = useState(true); // Copy Selected 버튼 활성화 여부
  const [createDateList, setCreateDateList] = useState([]); // Input Date 셀렉트박스 일자목록
  const [annotationList, setAnnotationList] = useState([]); // Annotation 목록 데이터
  const [projectId, setProjectId] = useState(null); // 선택 Project ID
  const [inputDate, setInputDate] = useState(null); // 선택 Input Date
  const [param, setParam] = useState({
    projectId: null,
    createDate: null,
  }); // Search 버튼 클릭 시 전송할 Parameter
  const [copyTargetProjectId, setCopyTargetProjectId] = useState(""); // 선택 Copy Project ID
  const [copyTargetDate, setCopyTargetDate] = useState(""); // 선택 Target Date
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 선택 체크박스의 Key
  const [selectedRowData, setSelectedRowData] = useState([]); // 선택 체크박스의 데이터 Row
  const [logStrings, setLogStrings] = useState([]); // 실시간 로그 문자열 저장 배열

  const [gState, setGState] = useReducer(reducer, {
    projectList: [],
  });
  const {projectList} = gState; // Project 셀렉트박스 목록

  const [copyableProjectList, setCopyableProjectList] = useState([]); // Copy Project 셀렉트박스 목록
  const [copyableCreateDateList, setCopyableCreateDateList] = useState([]); // Target Date 셀렉트박스 목록
  const [isShowResult, setIsShowResult] = useState(false); // 최하단 복사 Procedure 로그메시지 출력부 노출 여부

  /** i18next */
  const {t} = useTranslation();

  /** 상수 */
  // Annotation 목록(Copy) 컬럼 정의
  const columns = [
    {
      title: t("annotation_copy_table_column_seq"),
      dataIndex: "annotation_mng_sq",
      width: 110,
    },
    {
      title: t("annotation_copy_table_column_project_seq"),
      dataIndex: "project_mng_sq_t_project_mng",
      width: 100,
    },
    {
      title: t("annotation_copy_table_column_project"),
      dataIndex: "f_proj_nm",
      width: 300,
    },
    {
      title: t("annotation_copy_table_column_create"),
      render: (record) => convertDateString(record.f_create_date),
      width: 120,
    },
    {
      title: t("annotation_copy_table_column_fence_name"),
      dataIndex: "f_fence_nm",
      width: 250,
    },
    {
      title: t("annotation_copy_table_column_fence_type"),
      dataIndex: "c_fence_type",
    },
    {
      title: t("annotation_copy_table_column_anno_type"),
      dataIndex: "c_annotation_type",
    },
    {
      title: t("annotation_copy_table_column_status"),
      render: (record) => convertAnnotationResultString(record.f_status), // 0: 대기, 1: 작업 중, 2: 완료, 3(-1): 오류
    },
  ];

  const initialState = async () => {
    try {
      initResultArea();
      {
        const {data} = await Axios.get("/project");
        setGState({type: "SET_PROJECT", payload: data});
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  // Table Checkbox Key, Data 저장 Controller
  const handleChangeCheckboxes = (key, data) => {
    setSelectedRowKeys(key);
    setSelectedRowData(data);
  };

  // Table Checkbox 제어 조건 설정
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows, _) => {
      if (selectedRowKeys.length === 0) {
        handleChangeCheckboxes([], []);
      } else {
        handleChangeCheckboxes(selectedRowKeys, selectedRows);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.f_fence_annotation_flag === "l2",
      name: record.f_fence_annotation_flag,
    }),
  };

  // 프로젝트 선택 이벤트
  const handleChangeProject = async (targetId) => {
    setProjectId(targetId);
    const createDates = await executeTransaction(0, "/getInputDates", {
      projectId: targetId,
    });
    setCreateDateList(createDates);
    setInputDate(null);
  };

  // 일자 선택 이벤트
  const handleChangeCreateDate = (selectedTargetDate) => {
    setSearchButtonStatus(false);
    setInputDate(selectedTargetDate);
    setParam({projectId: projectId, createDate: selectedTargetDate});
  };

  // Search 버튼 이벤트
  const handleSearch = async () => {
    handleChangeCheckboxes([], []);

    const annotationData = await executeTransaction(0, "/annotations/copy/list", param);

    if (annotationData.length === 0) {
    }

    annotationData.length === 1
      ? setCopySelectButtonStatus(true)
      : setCopySelectButtonStatus(copySelectButtonStatus);
    setAnnotationList(annotationData);

    // 현재 선택 프로젝트 EPSG
    let tempArr = annotationData.map((item) => parseInt(item.f_epsg));
    const epsgArr = tempArr.filter((item, index) => tempArr.indexOf(item) === index);

    setIsShowLoading(true);
    const copyableProjectIds = await executeTransaction(0, "/annotations/checkProject", {
      epsg: epsgArr,
    });
    setCopyableProjectList(copyableProjectIds);
    setIsShowLoading(false);

    setCopyTargetProjectId(null);
    setCopyTargetDate("");
    setCopyAllButtonStatus(true);
  };

  // Clear 버튼 이벤트
  const handleClear = () => {
    initResultArea();
    setParam({projectId: null, createDate: null});
    setCreateDateList([]);
    setAnnotationList([]);
    setCopyableProjectList([]);
    setCopyableCreateDateList([]);
  };

  // 하단 복사 대상 프로젝트 선택 이벤트
  const handleChangeCopyProject = async (targetId) => {
    setCopyTargetProjectId(targetId);
    const copyableCreateDates = await executeTransaction(0, "/getInputDates", {
      projectId: targetId,
    });
    setCopyableCreateDateList(copyableCreateDates);
    setCopyTargetDate("");
  };

  // 하단 복사 대상 일자 선택 이벤트
  const handleChangeCopyCreateDate = (targetDate) => {
    setCopyTargetDate(targetDate);
    setCopyAllButtonStatus(false);
  };

  // 하단 Copy 버튼 이벤트
  const handleCopyAnnotations = async (code) => {
    handleChangeCheckboxes([], []);
    setCopySelectButtonStatus(true);
    document.getElementById("result-string-area").innerHTML = "";

    // code - 0: Copy All, 1: Copy Selected
    const annotationMngSqList =
      code === 0
        ? annotationList
            .filter((x) => x.f_fence_annotation_flag !== "l2")
            .map((x) => `${x.annotation_mng_sq}`)
        : selectedRowKeys;

    setIsShowResult(true);
    for (let item of annotationMngSqList) {
      let resultExecuteCopy = await executeTransaction(1, "/annotations/runCopy", {
        projectId: copyTargetProjectId,
        createDate: copyTargetDate,
        annotationMngSq: item,
      });
      let msg = "";
      if (resultExecuteCopy.length > 1) {
        msg = resultExecuteCopy[resultExecuteCopy.length - 1];
      } else {
        msg = resultExecuteCopy;
      }
      setLogStrings((logStrings) => [...logStrings, msg]);
    }
  };

  const initResultArea = () => {
    setCopyAllButtonStatus(true);
    setCopySelectButtonStatus(true);

    setProjectId(null);
    setInputDate("");
    setCopyTargetProjectId(null);
    setCopyTargetDate("");

    document.getElementById("result-string-area").innerHTML = "";
  };

  const cancelCopy = (e) => {};

  // 스크롤 이동(아래)
  const moveScrollToReceiveMessage = () => {
    let objDiv2 = document.getElementById("result-string-area");
    objDiv2.scrollTop = objDiv2.scrollHeight;
  };

  /** Effect */
  useEffect(() => {
    initialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedRowKeys.length === 0) {
      setCopySelectButtonStatus(true);
    } else if (selectedRowKeys.length > 0) {
      copyTargetDate ? setCopySelectButtonStatus(false) : setCopySelectButtonStatus(true);
    }
  }, [selectedRowKeys, selectedRowData, copyTargetDate]);

  useEffect(() => {
    moveScrollToReceiveMessage();
  }, [logStrings]);

  /* Renderer */
  return (
    <>
      {/* 조회 조건 영역 */}
      <Row className="search-area ant-advanced-search-form">
        <Col span={8} key={0} className="search-area-part">
          <Row className="ant-col ant-col-5 ant-form-item-label">{t("general_project")}:</Row>
          <Row>
            {/* 프로젝트 선택 */}
            <Select
              className="search-area-select"
              onChange={handleChangeProject}
              value={projectId}
              showSearch
              optionFilterProp="children"
            >
              <Option>{t("selector_select")}</Option>
              {projectList &&
                projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    [{project.project_mng_sq}] {project.f_proj_nm}
                  </Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col span={8} key={1} className="search-area-part">
          <Row className="ant-col ant-col-5 ant-form-item-label">{t("annotation_create")}:</Row>
          <Row>
            {/* 생성일 선택 */}
            <Select
              id="select-box-input-date"
              showSearch
              className="search-area-select"
              onChange={handleChangeCreateDate}
              value={inputDate}
              placeholder={t("annotation_copy_create_date_select_placeholder")}
            >
              {createDateList &&
                createDateList.map((item) => (
                  <Option key={item.f_input_date} value={item.f_input_date}>
                    {convertDateString(item.f_input_date)}
                  </Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col span={24} className="search-area-button">
          <Button
            className="button-margin-right"
            type="primary"
            onClick={handleSearch}
            disabled={searchButtonStatus}
          >
            {t("button_search")}
          </Button>
          <Button onClick={handleClear}>{t("button_clear")}</Button>
        </Col>
      </Row>
      {/* Annotation 목록 영역 */}
      <TableRender
        dataSource={annotationList}
        columns={columns}
        rowSelection={rowSelection}
        rowKey={"annotation_mng_sq"} // Antd 4.x 이상부터 반드시 정의
        bordered={false}
        size="small"
        isLoading={isShowLoading}
      />
      {/* 복사 대상 프로젝트 조회 및 복사 버튼 영역 */}
      <Row className="copy-area ant-advanced-search-form">
        <Col span={8} key={0} className="copy-area-part">
          <Row className="ant-col ant-col-5 ant-form-item-label">
            {t("annotation_copy_copy_project")}:
          </Row>
          <Row>
            {/* 복사 대상 프로젝트 조회 */}
            <Select
              className="search-area-select"
              onChange={handleChangeCopyProject}
              showSearch
              optionFilterProp="children"
              placeholder={t("annotation_copy_copy_project_select_placeholder")}
            >
              {copyableProjectList &&
                copyableProjectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    ({project.project_mng_sq}) {project.f_proj_nm}
                  </Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col style={{marginRight: "3vw"}} span={8} key={1} className="copy-area-part">
          <Row className="ant-col ant-col-5 ant-form-item-label">
            {t("annotation_copy_target_date")}:
          </Row>
          <Row>
            {/* 복사 대상 프로젝트의 생성일 조회 */}
            <Select
              id="target-project-date"
              showSearch
              className="search-area-select"
              onChange={handleChangeCopyCreateDate}
              placeholder={t("annotation_copy_target_date_select_placeholder")}
            >
              {copyableCreateDateList &&
                copyableCreateDateList.map((item) => (
                  <Option key={item.f_input_date} value={item.f_input_date}>
                    {convertDateString(item.f_input_date)}
                  </Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col className="copy-area-button-part" span={24}>
          <XaConfirmButton
            contents={t("annotation_copy_copy_all_confirm")}
            onConfirm={() => handleCopyAnnotations(0)}
            btnName={t("button_copy_all")}
            buttonClassName="copy-button"
            onCancel={cancelCopy}
            okText={t("button_yes")}
            cancelText={t("button_no")}
            disabled={copyAllButtonStatus}
          />
          <XaConfirmButton
            contents={t("annotation_copy_copy_selected_confirm")}
            onConfirm={() => handleCopyAnnotations(1)}
            btnName={t("button_copy_selected")}
            buttonClassName="copy-button"
            onCancel={cancelCopy}
            okText={t("button_yes")}
            cancelText={t("button_no")}
            disabled={copySelectButtonStatus}
          />
        </Col>
      </Row>
      <Row style={{marginTop: "0.5vh"}}>
        <div
          id="result-string-area"
          style={{display: isShowResult ? "inline" : "none"}}
          className="result-area ant-advanced-search-form"
        >
          {logStrings.map((msg, index) => {
            return <p style={{margin: 0}}>{msg}</p>;
          })}
        </div>
      </Row>
    </>
  );
};

/* Exports */
export default AnnotationCopy;
