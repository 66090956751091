import React, {useState} from "react";
import Axios from "axios";
import {Form, Row, Col, Button, Input} from "antd";
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import "./index.css";
import {errorHandler} from "../../common";

const LogJobDetail = (props) => {
  const {onCancel} = props;
  const [form] = Form.useForm();
  const axios = Axios.create();
  const [jobStatus, setJobStatus] = useState(""); // 상태
  const [selectedJobId, setSelectedJobId] = useState(null);

  // i18next
  const {t} = useTranslation();

  let convertData = {};

  const handleLoadJobLogData = async (job_id) => {
    if (job_id) {
      axios.get(`/logjob/${job_id}`).then(async ({data}) => {
        if (props.jobId !== data.job_id) {
          onClickBtnClose();
        }

        convertData = {
          job_id: data.job_id,
          job_status:
            data.job_status === "-1"
              ? "오류"
              : data.job_status === "0"
              ? "진행중"
              : data.job_status === "1"
              ? "정상"
              : "",
          f_description:
            data.f_description === "" || data.f_description === null ? "-" : data.f_description,
          job_type: `${data.job_type}`,
          payload: data.payload,
        };
        setJobStatus(data.job_status);
        form.setFieldsValue(convertData);
      });
    }
  };

  const onFinish = async () => {};

  const onFinishFailed = async (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const onClickBtnClose = async () => {
    onCancel();
    setSelectedJobId(null);
  };

  if (selectedJobId !== props.jobId) {
    setSelectedJobId(props.jobId);
    handleLoadJobLogData(props.jobId);
  } else {
    handleLoadJobLogData(selectedJobId);
  }

  /* Renderer */
  return (
    <>
      <Form
        layout="horizontal"
        size="small"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          job_type: "",
          job_status: "",
          f_description: "",
          payload: "",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            {/* Job 유형 */}
            <Form.Item name="job_type" label={t("logjob_job_type")}>
              <Input readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* Job 상태 */}
            <Form.Item name="job_status" label={t("logjob_job_state")}>
              <Input readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {/* Job 결과 메시지 */}
            <Form.Item name="f_description" label={t("logjob_message")}>
              <Input.TextArea rows={2} readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {/* Job 입력 데이터 */}
            <Form.Item name="payload" label={t("logjob_payload")}>
              <Input.TextArea rows={5} readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className="text-align-right">
            <Button className="button-margin-right" onClick={onClickBtnClose} size="default">
              {t("button_close")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

/* Exports */
export default LogJobDetail;
