import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Select, Button, Input, DatePicker} from "antd";
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import "./index.css";
import {xaMessage, XaConfirmButton, errorHandler, LoadingAnimationWholePage} from "../../common";
import {defaultDateFormat, convertDateStringToMoment, sortByOrder} from "../../utils/stringHelper";

const {Option} = Select;

const XcApiKeyDetail = (props) => {
  const {onCancel} = props;
  const [form] = Form.useForm();

  const [isShowLoad, setIsShowLoad] = useState(false);
  const [mode, setMode] = useState("");
  const [statusUse, setStatusUse] = useState(null);
  const [currentSeq, setCurrentSeq] = useState(null);
  const [isSelectedProject, setIsSelectedProject] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [userList, setUserList] = useState([]);
  const [createDt, setCreateDt] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [generateButtonTitle, setGenerateButtonTitle] = useState("");
  const [issueButtonTitle, setIssueButtonTitle] = useState("");

  // i18next
  const {t} = useTranslation();

  let _info = {
    seq: "",
    companyId: "",
    projectId: "",
    userId: "",
    userEmailId: "",
    userName: "",
    createDt: "",
    createDtMoment: "",
    status: "",
  };

  // 생성일 일자란 선택
  const onChangeCreateDate = (date) => {
    handleAssemblyData("createDtMoment", date);
    setCreateDt(date.format(defaultDateFormat));
  };

  // 생성 버튼 클릭
  const onClickGenerate = () => {
    const formData = form.getFieldsValue();
    handleGetApiKey(formData.projectId);
  };

  // 복사 버튼 클릭
  const onClickCopy = () => {
    if (!apiKey) xaMessage("warn", t("message_copy_empty"));

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(apiKey)
        .then(() => {
          xaMessage("success", t("message_copy_complete"));
        })
        .catch((err) => {
          errorHandler(t("message_error_beginning"), t("message_copy_fail"), err);
        });
    } else {
      // Fallback: 텍스트 영역을 사용하여 클립보드에 복사
      const textArea = document.createElement("textarea");
      textArea.value = apiKey;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        xaMessage("success", t("message_copy_complete"));
      } catch (err) {
        errorHandler(t("message_error_beginning"), t("message_copy_fail"), err);
      }
      document.body.removeChild(textArea);
    }
  };

  // 초기화 버튼 클릭 (신규일 때만 버튼 노출)
  const onClickInit = () => {
    setMode("new");
    setIsSelectedProject(false);
    form.resetFields();

    setApiKey("");
  };

  // 닫기 버튼 클릭
  const onClickCancel = () => {
    onCancel();
  };

  // 사용중지 버튼
  const onClickDeactivate = () => {
    setMode("reissue");
    setGenerateButtonTitle(t("button_regenerate"));
    setIssueButtonTitle(t("button_reissue"));
    setApiKey("");
  };

  // 저장 버튼 클릭
  const onClickIssue = () => {
    // 입력 항목 확인
    const hasEmptyFields = Object.values(form.getFieldsValue()).some(
      (value) => value === undefined || value === null || value === ""
    );

    if (hasEmptyFields && !apiKey) {
      xaMessage("error", t("xc_api_key_info_detail_message_empty_item"));
      return;
    }

    // 발급(저장)
    handleIssue();
  };

  /**
   * 초기화 함수
   * @param {*} info  API Key 정보 상세
   * @param {*} companyList 소속(회사)명 정보 목록
   * @param {*} projectList 프로젝트 정보 목록
   */
  const handleInit = async (info, companyList, projectList, existKeyProjectList) => {
    setCompanies(companyList);

    setIsShowLoad(true);
    try {
      const {data} = await Axios.get("/user", {params: {order: 0}});
      setUserList(
        sortByOrder(
          data.map((user) => {
            return {
              value: user.user_mng_sq,
              label: user.user_email_id,
              name: user.f_user_nm,
            };
          }),
          "label",
          "asc"
        )
      );
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }

    // 사용자 목록 조회
    if (info) {
      setProjects(projectList);
      setMode("update");
      setIsSelectedProject(true);

      setStatusUse(info.f_status);
      setCurrentSeq(info.seq);

      _info.companyId = info.company_mng_sq_t_company_mng;
      _info.projectId = info.project_mng_sq_t_project_mng;
      _info.userId = info.user_mng_sq_t_user_mng;
      _info.userEmailId = info.user_email_id;
      _info.userName = info.f_user_nm;
      _info.createDt = info.create_dt;
      _info.createDtMoment = convertDateStringToMoment(info.create_dt, defaultDateFormat);
      _info.status = info.f_status;

      setApiKey(info.f_xc_api_key);
    } else {
      setMode("new");
      setProjects(
        projectList.filter(
          (project) => !existKeyProjectList.map((exist) => exist.value).includes(project.value)
        )
      );
      form.resetFields();
      setIsSelectedProject(false);
      setApiKey("");
    }

    form.setFieldsValue(_info);
    setGenerateButtonTitle(t("button_generate"));
    setIssueButtonTitle(t("button_issue"));
    setIsShowLoad(false);
  };

  // Form 데이터 변경
  const handleAssemblyData = (filedName, param) => {
    const formData = form.getFieldsValue();
    form.setFieldsValue({
      ...formData,
      [filedName]: param,
    });
  };

  /**
   * 상세 정보 데이터 변경
   * @param {*} category  변경 내용 종류
   * @param {*} value 해당 값
   */
  const handleChanges = (category, value) => {
    switch (category) {
      case "company":
        handleAssemblyData("companyId", value);
        break;
      case "project":
        handleAssemblyData("projectId", value);
        value === 0 || value === "0"
          ? setIsSelectedProject(true)
          : !value
          ? setIsSelectedProject(false)
          : setIsSelectedProject(true);
        break;
      case "user":
        handleAssemblyData("userId", value);
        handleAssemblyData("userName", userList.filter((user) => user.value === value)[0].name);
        break;
      default:
        break;
    }
  };

  /**
   * API Key 생성(조회)
   * @param {*} projectId
   * @returns
   */
  const handleGetApiKey = async (projectId) => {
    if (mode === "update") return;

    try {
      const {data} = await Axios.post("/xcapikey/getApiKey", {
        params: {
          project_id: projectId,
        },
      });

      setApiKey(data);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // API Key발급(저장)
  const handleIssue = async () => {
    const formData = form.getFieldsValue();

    setIsShowLoad(true);
    if (mode === "new") {
      try {
        const {data} = await Axios.post("/xcapikey/create", {
          params: {
            company_id: formData.companyId,
            project_id: formData.projectId,
            user_id: formData.userId,
            xc_api_key: apiKey,
            create_dt: createDt,
          },
        });

        setCurrentSeq(data[0].seq);
        setStatusUse(data[0].f_status);
        setMode("update");
      } catch (err) {
        errorHandler(t("message_error_beginning"), err);
      }
      setIsShowLoad(false);
    } else if (mode === "reissue") {
      try {
        await Axios.post("/xcapikey/update", {
          params: {
            seq: currentSeq,
            xc_api_key: apiKey,
          },
        });
      } catch (err) {
        errorHandler(t("message_error_beginning"), err);
      }
      setIsShowLoad(false);
      onCancel();
    }
  };

  useEffect(() => {
    handleInit(props.keyInfo, props.companyList, props.projectList, props.existKeyProjectList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  /* Renderer */
  return (
    <LoadingAnimationWholePage
      isLoading={isShowLoad}
      component={
        <>
          <Form layout="horizontal" size="small" form={form} initialValues={_info}>
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item name="companyId" label={t("general_company_abbreviate")}>
                  <Select
                    className="select-general-detail"
                    showSearch
                    optionFilterProp="children"
                    onChange={(value) => handleChanges("company", value)}
                    disabled={mode === "update"}
                  >
                    {companies.map((el) => (
                      <Option key={el.value} value={el.value}>
                        [{el.value}] {el.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item name="projectId" label={t("general_project")}>
                  <Select
                    className="select-general-detail"
                    showSearch
                    optionFilterProp="children"
                    onChange={(value) => handleChanges("project", value)}
                    disabled={mode === "update" || mode === "reissue"}
                  >
                    {projects.map((el) => (
                      <Option key={el.value} value={el.value}>
                        [{el.value}] {el.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={14}>
                <Form.Item name="createDtMoment" label={t("general_create")}>
                  <DatePicker
                    className="date-picker"
                    onChange={onChangeCreateDate}
                    format={defaultDateFormat}
                    disabled={mode === "update" || mode === "reissue"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={14}>
                <Form.Item name="userId" label={t("general_id")}>
                  <Select
                    className="select-general-detail"
                    showSearch
                    optionFilterProp="children"
                    onChange={(value) => handleChanges("user", value)}
                    disabled={mode === "update" || mode === "reissue"}
                  >
                    {userList.map((el) => (
                      <Option key={el.value} value={el.value}>
                        [{el.name}] {el.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name="userName" label={t("general_user_name")}>
                  <Input disabled={mode === "update" || mode === "reissue"} readOnly={true} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row gutter={24}>
            <Col span={24} className="row-bottom-margin">
              {"XiteCore API Key"}
              <Button
                className="button-margin-left button-margin-right"
                type="primary"
                size="small"
                onClick={onClickGenerate}
                disabled={mode === "update" || !isSelectedProject || (apiKey && apiKey !== "")}
              >
                {generateButtonTitle}
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={onClickCopy}
                disabled={!apiKey || statusUse === 0 || mode === "new"}
              >
                {t("button_copy")}
              </Button>
            </Col>
            <Col span={24}>
              <Input.TextArea rows={5} readOnly={true} value={apiKey} />
            </Col>
          </Row>

          {/* 하단 버튼 */}
          <Row gutter={24} className="row-top-margin text-align-right">
            <Col span={24}>
              <Button onClick={onClickCancel} size="default">
                {t("button_close")}
              </Button>
              {mode === "update" && statusUse === 1 && (
                <XaConfirmButton
                  buttonClassName="button-margin-left"
                  contents={t("message_deactive_key")}
                  onConfirm={onClickDeactivate}
                  placement={"bottomLeft"}
                  btnName={t("button_deactivate")}
                  disabled={mode === "reissue"}
                  btnType="primary"
                  size="default"
                />
              )}
              {mode !== "update" && (
                <>
                  <Button
                    type="primary"
                    className="button-margin-left"
                    onClick={onClickIssue}
                    size="default"
                    disabled={mode === "update" || !apiKey}
                  >
                    {issueButtonTitle}
                  </Button>
                  {mode !== "reissue" && (
                    <Button
                      className="button-margin-left"
                      onClick={onClickInit}
                      size="default"
                      disabled={mode === "update"}
                    >
                      {t("button_init")}
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>
        </>
      }
    />
  );
};

/* Exports */
export default XcApiKeyDetail;
