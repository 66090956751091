import React, {useState, useEffect, useContext} from "react";
import Axios from "axios";
import {Form, Input, Row, Col, Button, Select, Modal} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import {TableRenderNoPage, xaMessage, errorHandler} from "../../common";
import DeviceAssignForm from "./DeviceAssignForm";
import DeviceAssignInfo from "./DeviceAssignInfo";
import {DeviceDispatch} from "./Device";

const {Option} = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FLT20":
      state = {...state, deviceKeyCategoryList: action.payload.slice()};
      break;
    case "SET_FLT21":
      state = {...state, subStmList: action.payload.slice()};
      break;
    case "SET_FLT22":
      state = {...state, workCategoryList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

const DeviceDetail = (props) => {
  // const [gState, setGState] = useReducer(reducer, {
  //   subStmList: [],
  //   workCategoryList: [],
  //   ynList: [
  //     { key: "Y", text: "Y" },
  //     { key: "N", text: "N" },
  //   ],
  //   deviceKeyCategoryList : []
  // });

  const {gState} = useContext(DeviceDispatch);
  const {subStmList, workCategoryList, ynList} = gState;

  const [deviceKey, setDeviceKey] = useState();
  const [mode, setMode] = useState("new");
  const [deviceKeyGbn, setDeviceKeyGbn] = useState("FLT201");
  const [subjectList, setSubjectList] = useState([]);
  const [subjectSelectedRowKeys, setSubjectSelectedRowKeys] = useState([]);
  const [assignInfoModalVisible, setAssignInfoModalVisible] = useState(false);
  const [deviceKeyAssignInfo, setDeviceKeyAssignInfo] = useState("");
  const {onCancel} = props;

  const [selDevice, setSelDevice] = useState();
  const [selSubject, setSelSubject] = useState();

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  /** i18next */
  const {t} = useTranslation();

  const axios = Axios.create();

  const colSubject = [
    {
      title: t("device_details_device_sub"), // "SubSystem",
      dataIndex: "sub_stm",
      key: "tblSubject_1",
      render: (_, record) => {
        let rtnObj = subStmList.find((item) => `${item.code1}${item.code2}` === record.sub_stm);
        return rtnObj ? rtnObj.code_txt : "";
      },
    },
    {
      title: t("device_details_device_category"), //"Cat.",
      dataIndex: "gbn_work",
      key: "tblSubject_2",
      render: (_, record) => {
        let rtnObj = workCategoryList.find(
          (item) => `${item.code1}${item.code2}` === record.gbn_work
        );
        return rtnObj ? rtnObj.code_txt : "";
      },
    },
    {
      title: t("device_details_subject_name"), // "Name",
      dataIndex: "subject_name",
      key: "tblSubject_3",
    },
    {
      title: t("device_details_subject_assign"), // "Assign",
      key: "tblSubject_4",
      dataIndex: "assign_yn",
    },
  ];
  const colSubjectNew = [
    {
      title: t("device_details_device_sub"), // "SubSystem",
      dataIndex: "sub_stm",
      key: "tblSubjectNew_1",
      render: (_, record) => {
        let rtnObj = subStmList.find((item) => `${item.code1}${item.code2}` === record.sub_stm);
        return rtnObj ? rtnObj.code_txt : "";
      },
    },
    {
      title: t("device_details_subject_name"), // "Name",
      dataIndex: "subject_name",
      key: "tblSubjectNew_2",
    },
  ];

  const handleLoadDeviceData = async (deviceKey) => {
    if (deviceKey) {
      const {data} = await axios.get(`/device/${deviceKey}`);
      form.setFieldsValue(data);
      setMode("update");
      setDeviceKey(props.deviceKey);
      setDeviceKeyGbn(data.device_key_gbn);
      setSelDevice(data);
      handleLoadSubjectsData(deviceKey);
      setSelSubject(null);
      setSubjectSelectedRowKeys([]);
    } else {
      form.resetFields();
      setMode("new");
      setDeviceKeyGbn("FLT201");
      setSelDevice(null);
      setSelSubject(null);
      setSubjectSelectedRowKeys([]);
      handleLoadSubjectsData(null);
    }
  };

  const handleLoadSubjectsData = async (deviceKey) => {
    try {
      const {data} = await axios.get(`/subjects`, {params: {device_key: deviceKey}});
      setSubjectList(data);
      setSubjectSelectedRowKeys([null]);
      // let _set = data.map((v) => v.subject_id);
      // await setSubjectSelectedRowKeys(_set);
    } catch (err) {
      errorHandler("", err);
    }
  };

  const onClickAssignInfo = async () => {
    setDeviceKeyAssignInfo(form.getFieldValue("device_key"));
    setAssignInfoModalVisible(true);
  };

  // Cancel 버튼
  const onBtnCancel = async () => {
    form.resetFields();
    setDeviceKey(null);
    setMode("new");
    setSelDevice(null);
    setSelSubject(null);
    onCancel();
  };

  // Save 버튼
  const onClickSave = async () => {
    try {
      const param = await form.validateFields();
      const param2 = await form2.validateFields();

      let assignSubject = {};

      if (subjectSelectedRowKeys.length > 0 && subjectSelectedRowKeys[0] != null) {
        let subjectKeys = [];
        assignSubject["subject_id"] = subjectSelectedRowKeys[0];
        const map_info_temple = selSubject.map_info_temple;
        for (const idx in map_info_temple.keys) {
          const _val = param2[map_info_temple.keys[idx]];
          subjectKeys.push(_val);
        }
        assignSubject["keys"] = subjectKeys;
      }

      const data = {...param, ...param2, assign_subject: assignSubject};

      try {
        if (mode === "new") {
          const resData = await Axios.post(`/device`, {
            params: data,
          });

          if (resData.data.device_key) {
            setDeviceKey(resData.data.device_key);
            xaMessage("success", t("message_save_complete"), null, true);
          } else {
            errorHandler(t("message_error_beginning"), resData.data, null);
          }
        } else {
          // Update
          const resData = await Axios.put(`/device/${deviceKey}`, {
            params: data,
          });
          setDeviceKey(resData.data.device_key);
          xaMessage("success", t("message_save_complete"), null, true);
        }

        // Modal 닫기
        onCancel();
      } catch (err) {
        if (err && err.response) {
          errorHandler(t("message_error_beginning"), err.response.data, null);
        } else {
          errorHandler(`${t("message_error_occured")}: `, t("message_please_check"), null);
        }
      }
    } catch (err) {
      errorHandler("", t("device_details_validation"));
      return;
    }
  };

  const onFinish = async (paramValue) => {
    if (mode === "new") {
      await Axios.post("/device", {params: paramValue})
        .then(({data, status}) => {
          if (status !== "200") {
            xaMessage("warn", t("message_please_check"));
          }
          xaMessage("success", t("message_save_success"));
          onCancel();
        })
        .catch((e) => {
          errorHandler(t("message_error_beginning"), e);
        });
    } else {
      await Axios.put(`/device/${paramValue.device_key}`, {params: paramValue})
        .then((data) => {
          xaMessage("success", t("message_save_success"));
          onCancel();
        })
        .catch(function (e) {
          errorHandler(t("message_error_beginning"), e);
        });
    }
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const onFinish2 = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const onFinishFailed2 = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  // Subject List 라디오 버튼 클릭 시
  const onTblSubjectRowSelection = async (selectedRowKeys) => {
    setSubjectSelectedRowKeys(selectedRowKeys);
    const _selSubject = subjectList.find((item) => item.subject_id === selectedRowKeys[0]);
    setSelSubject(_selSubject);

    if (selDevice && _selSubject && selDevice.subjects) {
      // let device_selSubject;
      // for (let i = 0; i < selDevice.subjects.length; i++) {
      //   if (
      //     selDevice.subjects[i].en_use_at === "99991231" &&
      //     selDevice.subjects[i].subject_id === _selSubject.subject_id
      //   ) {
      //     device_selSubject = selDevice.subjects[i];
      //   } else if (selDevice.subjects[i].subject_id === _selSubject.subject_id) {
      //     device_selSubject = selDevice.subjects[i];
      //   }
      // }
      const device_selSubject = selDevice.subjects.find(
        (item) => item.subject_id === _selSubject.subject_id
      );
      form2.setFieldsValue(device_selSubject);
    }
  };

  const subjectRowSelection = {
    selectedRowKeys: subjectSelectedRowKeys,
    onChange: (_selectedRowKeys, selectedRows) => {
      onTblSubjectRowSelection(_selectedRowKeys);
    },
  };

  const onChangeDeviceKeyGbn = (value) => {
    setDeviceKeyGbn(value);
  };

  const onChangeSubSystem = async (value) => {
    if (mode === "new") {
      const {data} = await axios.get(`/subjects`, {params: {device_key: null}});
      !value
        ? setSubjectList(data)
        : setSubjectList(data.filter((subject) => subject.sub_stm === value));
    }
  };

  // Assign Info Modal OK
  const handleAssignInfoModalOk = () => {};

  // Assign Info Modal Cancel
  const handleAssignInfoModalCancel = () => {
    setAssignInfoModalVisible(false);
  };

  useEffect(() => {
    setDeviceKeyGbn("FLT201");
    handleLoadSubjectsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // setDeviceKey(props.deviceKey);
    handleLoadDeviceData(props.deviceKey);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deviceKey]);

  /* Renderer */
  return (
    <DeviceDispatch.Provider value={{gState}}>
      <Form
        layout="horizontal"
        size="small"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          device_key: null,
          device_name: "",
          device_key_gbn: "FLT201" /* STATIC */,
          sub_stm: null,
          gbn_work: null,
          seq: "",
          description: "",
          use_yn: "Y",
          connect_yn: "N",
        }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="device_key"
              label={t("device_details_key")}
              rules={[
                {
                  required: deviceKeyGbn !== "FLT201" ? false : true,
                  message: t("device_details_key_message"),
                },
              ]}
            >
              <Input
                placeholder={mode === "new" ? t("device_details_key_placeholder") : ""}
                readOnly={mode === "new" ? false : true}
                disabled={deviceKeyGbn !== "FLT201" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="device_name"
              label={t("device_details_device_name")}
              rules={[{required: true, message: t("device_details_device_name_placeholder")}]}
            >
              <Input placeholder={t("device_details_device_name_placeholder")} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="device_key_gbn"
              label={t("device_details_device_gen")}
              rules={[{required: true, message: "Require Name"}]}
            >
              <Select onChange={onChangeDeviceKeyGbn}>
                {gState.deviceKeyCategoryList.map((item) => (
                  <Option key={item.code2} value={`${item.code1}${item.code2}`}>
                    {/* {item.code_txt} */}
                    {`${item.code1}${item.code2}` === "FLT201"
                      ? t("device_details_key_category_static")
                      : t("device_details_key_category_generative")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="sub_stm"
              label={t("device_details_device_sub")}
              rules={[{required: true, message: t("device_details_device_sub_placehold")}]}
            >
              <Select
                onChange={onChangeSubSystem}
                // disabled={mode === "update"}
              >
                <Option>{t("selector_select")}</Option>
                {gState.subStmList.map((item) => (
                  <Option key={item.code2} value={`${item.code1}${item.code2}`}>
                    {item.code_txt}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="gbn_work"
              label={t("device_details_device_category")}
              rules={[{required: true, message: t("device_details_device_category_placehold")}]}
            >
              <Select>
                <Option>{t("selector_select")}</Option>
                {gState.workCategoryList.map((item) => (
                  <Option key={item.code2} value={`${item.code1}${item.code2}`}>
                    {item.code_txt}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="seq" label={t("device_details_device_seq")}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="use_yn"
              label={t("device_details_device_use")}
              rules={[{required: true}]}
            >
              <Select>
                <Option>{t("selector_select")}</Option>
                <Option key="Y" value="Y">
                  Y
                </Option>
                <Option key="N" value="N">
                  N
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t("device_details_device_description")}
              rules={[{required: false}]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={16}>
        <Col span={12}>
          <TableRenderNoPage
            title={() => t("device_details_subject_list_title")}
            dataSource={subjectList}
            bordered={false}
            footer={null}
            columns={mode === "update" ? colSubject : colSubjectNew}
            rowSelection={{type: "radio", ...subjectRowSelection}}
            rowKey={"subject_id"}
            pagination={false}
            size="small"
          />
        </Col>
        <Col span={12}>
          {mode === "update" ? (
            <DeviceAssignForm
              form={form2}
              onFinish={onFinish2}
              onFinishFailed={onFinishFailed2}
              device={selDevice}
              subject={selSubject}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          {mode !== "new" && <Button onClick={onClickAssignInfo}>{t("button_assign_info")}</Button>}
          <Button className="button-margin-left" onClick={onBtnCancel}>
            {t("button_cancel")}
          </Button>
          <Button
            type="primary"
            className="button-margin-left"
            onClick={onClickSave}
            htmlType="submit"
          >
            {t("button_save")}
          </Button>
        </Col>
      </Row>

      <Modal
        title={t("device_details_assign_info_title")}
        open={assignInfoModalVisible}
        footer={null}
        width={"40vw"}
        onOk={handleAssignInfoModalOk}
        onCancel={handleAssignInfoModalCancel}
        centered={true}
        maskClosable={false}
        getContainer={false}
        closable={true}
        keyboard={true}
      >
        <DeviceAssignInfo
          deviceKeyModal={deviceKeyAssignInfo}
          onClickModalOk={handleAssignInfoModalCancel}
        />
      </Modal>
    </DeviceDispatch.Provider>
  );
};
/* Exports */
export default DeviceDetail;
