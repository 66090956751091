/* src/layouts/Main/Footer/Footer.js */
/* React */
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import "./index.css";
import {useTranslation} from "react-i18next";

/* Main Compoent */
const Footer = (props) => {
  /* i18next */
  const {t} = useTranslation();

  /* Renderer */
  return (
    <>
      <div className="main-footer">
        ©️ {moment().format("YYYY")} {t("footer_copyright")}
      </div>
    </>
  );
};

/* Main Component Settings */
Footer.propTypes = {
  className: PropTypes.string,
};

/* Exports */
export default Footer;
