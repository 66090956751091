import {xaMessage} from "./xaMessage";

/**
 * 오류 메시지 처리 Handler
 * @param {*} preString   메시지 수식 문자열
 * @param {*} errorMessage  본 메시지
 * @param {*} duration  지연 설정(초)
 * @returns xaMessage
 */
export const errorHandler = (preString, errorMessage, duration = 10) => {
  if (!errorMessage) return;

  const messageString = `${preString || ""}${errorMessage}`;
  console.log(messageString);
  xaMessage("error", messageString, duration, true);
};
