import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Input, Button, Select, Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {TableRender, errorHandler} from "../../common";
import {convertDatetimeString} from "../../utils/stringHelper";
import "./index.css";
import DeviceDetail from "./DeviceDetail";
import DeviceReturn from "./DeviceReturn";

const {Option} = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FLT20":
      state = {...state, deviceKeyCategoryList: action.payload.slice()};
      break;
    case "SET_FLT21":
      state = {...state, subStmList: action.payload.slice()};
      break;
    case "SET_FLT22":
      state = {...state, workCategoryList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const DeviceDispatch = React.createContext(null);

/* Main Component */
const Device = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 선택 체크박스의 Key
  const [selectedRowData, setSelectedRowData] = useState([]); // 선택 체크박스의 데이터 Row
  const [deviceModalVisible, setDeviceModalVisible] = useState(false);
  const [deviceReturnModalvisible, setDeviceReturnModalvisible] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  /** i18next */
  const {t} = useTranslation();

  const [gState, setGState] = useReducer(reducer, {
    subStmList: [],
    workCategoryList: [],
    ynList: [
      {key: "Y", text: "Y"},
      {key: "N", text: "N"},
    ],
    deviceKeyCategoryList: [],
  });
  const {subStmList, workCategoryList, ynList} = gState;

  const [deviceKey, setDeviceKey] = useState(null);
  const [form] = Form.useForm();

  // Table Checkbox Key, Data 저장 Controller
  const handleChangeRadio = (key, data) => {
    setSelectedRowKeys(key);
    setSelectedRowData(data[0]);
  };

  // Table Checkbox 제어 조건 설정
  const rowSelection = {
    type: "radio",
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRowKeys.length > 0
        ? handleChangeRadio(selectedRowKeys, selectedRows)
        : handleChangeRadio([], []);
    },
    getCheckboxProps: (record) => ({
      disabled: record.use_yn === "N",
      name: record.use_yn,
    }),
  };

  // Table 행 클릭 Action
  const onCellDeviceList = (record, index) => {
    return {
      // Row 클릭
      onClick: () => {
        handleOpenDeviceModal(record.device_key);
      },
    };
  };

  /* Devices List Column */
  const columns = [
    {
      title: t("device_table_column_device_key"), // 장비 고유키
      dataIndex: "device_key",
      key: "tblDevice01",
      className: "table-row-action",
      onCell: onCellDeviceList,
    },
    {
      title: t("device_table_column_device_name"), // 장비명
      dataIndex: "device_name",
      key: "tblDevice02",
      className: "table-row-action",
      onCell: onCellDeviceList,
    },
    {
      title: "Subjects",
      dataIndex: "subjects",
      key: "device99",
      render: (text) => text?.map((v) => v.description).join(","),
      className: "table-row-action",
      onCell: onCellDeviceList,
    },
    {
      title: t("device_table_column_sub"), // 시스템 구분
      dataIndex: "sub_stm",
      key: "tblDevice03",
      render: (text, record, _) =>
        subStmList.find((item) => `${item.code1}${item.code2}` === record.sub_stm)?.code_txt,
      className: "table-row-action",
      onCell: onCellDeviceList,
    },
    {
      title: t("device_table_column_category"), // 구분
      dataIndex: "gbn_work",
      key: "tblDevice04",
      render: (text, record, _) =>
        workCategoryList.find((item) => `${item.code1}${item.code2}` === record.gbn_work)?.code_txt,
      className: "table-row-action",
      onCell: onCellDeviceList,
    },
    {
      title: t("device_table_column_use"),
      key: "tblDevice05",
      dataIndex: "use_yn",
      className: "table-row-action",
      onCell: onCellDeviceList,
    },
    {
      title: t("device_table_column_create_date"),
      key: "tblDevice06",
      dataIndex: "create_at",
      className: "table-row-action",
      onCell: onCellDeviceList,
      render: (text) => convertDatetimeString(text),
      width: 180,
    },
    {
      title: t("device_table_column_update_date"),
      key: "tblDevice07",
      dataIndex: "update_at",
      className: "table-row-action",
      onCell: onCellDeviceList,
      render: (text) => convertDatetimeString(text),
      width: 180,
    },
    {
      title: t("device_table_column_last_connect"),
      key: "tblDevice08",
      dataIndex: "lst_connect_at",
      className: "table-row-action",
      render: (text) => convertDatetimeString(text),
      onCell: onCellDeviceList,
      width: 180,
    },
    // {
    //   title: "Disconnect",
    //   key: "device09",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Space size="middle">
    //           <Button type="link" size="small" onClick={() => handleDisconnect(record)}>
    //             Disconnect
    //           </Button>
    //         </Space>
    //       </>
    //     );
    //   },
    // },
  ];

  const handleAddDevice = () => {
    setDeviceModalVisible(true);
    setDeviceKey(null);
  };

  const handleOpenDeviceModal = (deviceKey) => {
    setDeviceKey(deviceKey);
    setDeviceModalVisible(true);
  };

  // 장비 일괄 반납 Modal 열기
  const onClickDeviceReturn = async () => {
    setDeviceReturnModalvisible(true);
  };

  const initForm = async () => {
    try {
      const {data} = await Axios.get("/code", {params: {code1: "FLT"}});
      const flt20 = data.filter((v) => {
        return v.code2.substring(0, 2) === "20";
      });
      const flt21 = data.filter((v) => {
        return v.code2.substring(0, 2) === "21";
      });
      const flt22 = data.filter((v) => {
        return v.code2.substring(0, 2) === "22";
      });

      setGState({type: "SET_FLT20", payload: flt20});
      setGState({type: "SET_FLT21", payload: flt21});
      setGState({type: "SET_FLT22", payload: flt22});

      form.resetFields();
      setSelectedRowKeys([]);
      setSelectedRowData([]);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const handleSearch = async (paramValue) => {
    setSelectedRowKeys([]);
    setSelectedRowData([]);
    setIsShowLoading(true);
    try {
      const {data} = await Axios.get("/devices", {params: paramValue});
      setDeviceList(data);
    } catch (e) {
      errorHandler(t("message_error_beginning"), t("message_search_failed"), null);
    }
    setIsShowLoading(false);
  };

  const onFinish = (paramValue) => {
    handleSearch(paramValue);
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const handleDisconnect = async (deviceInfo) => {
    setIsShowLoading(true);
    try {
      await Axios.put(`/device/${deviceInfo.device_key}`, {
        params: {...deviceInfo, use_yn: "N"},
      });
    } catch (err) {
      errorHandler(`${t("message_disconnected_fail")}: `, err);
    }
    setIsShowLoading(false);
  };

  const onClickDisconnect = () => {
    const deviceInfo = selectedRowData;

    Modal.confirm({
      title: t("device_disconnect_modal_title"),
      icon: <ExclamationCircleOutlined />,
      content: t("device_disconnect_modal_confirm", {name: deviceInfo.device_name}),
      okText: t("button_ok"),
      cancelText: t("button_cancel"),
      onOk: () => handleDisconnect(deviceInfo),
    });
  };

  const handleGetSocket = async (record) => {
    try {
      const {data} = await Axios.get("/device_getManagerSocketList", {params: record});
    } catch (e) {
      errorHandler("", "Disconnect Failed", null);
    }
  };

  const handleOk = () => {};

  const handleCancel = () => {
    setDeviceModalVisible(false);
  };

  const handleDeviceReturnCancel = () => {
    setDeviceReturnModalvisible(false);
  };

  useEffect(() => {
    initForm();
    handleSearch({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <DeviceDispatch.Provider value={{gState, setGState}}>
      <Form
        form={form}
        name="advanced_search"
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        className="common-search-area-form device-search-area"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          sub_stm: null,
          gbn_work: null,
          device_key: "",
          device_name: "",
          use_yn: null,
        }}
      >
        <Row gutter={24}>
          <Col span={8} key={0}>
            <Form.Item name="sub_stm" label={t("device_sub-system")} rules={[{required: false}]}>
              <Select>
                <Option>{t("selector_all")}</Option>
                {subStmList.map((sub_stm) => (
                  <Option key={sub_stm.code2} value={`${sub_stm.code1}${sub_stm.code2}`}>
                    {sub_stm.code_txt}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} key={1}>
            <Form.Item name="gbn_work" label={t("device_category")} rules={[{required: false}]}>
              <Select>
                <Option>{t("selector_all")}</Option>
                {workCategoryList.map((gbn_work) => (
                  <Option key={gbn_work.code2} value={`${gbn_work.code1}${gbn_work.code2}`}>
                    {gbn_work.code_txt}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8} key={2}>
            <Form.Item name="device_key" label={t("device_key")} rules={[{required: false}]}>
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8} key={3}>
            <Form.Item name="device_name" label={t("device_name")} rules={[{required: false}]}>
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8} key={4}>
            <Form.Item name="use_yn" label={t("device_use")} rules={[{required: false}]}>
              <Select>
                <Option>{t("selector_all")}</Option>
                <Option key="Y" value="Y">
                  Y
                </Option>
                <Option key="N" value="N">
                  N
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="button-area">
            <Row>
              <Button
                type="default"
                onClick={onClickDisconnect}
                disabled={selectedRowKeys.length === 0}
              >
                {t("button_device_disconnect")}
              </Button>
            </Row>
            <Row>
              <Button type="dashed" onClick={onClickDeviceReturn}>
                {t("button_device_return")}
              </Button>
              <Button type="dashed" onClick={handleAddDevice}>
                {t("button_add_device")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("button_search")}
              </Button>
              <Button className="button-margin-left" onClick={initForm}>
                {t("button_clear")}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
      <TableRender
        columns={columns}
        dataSource={deviceList}
        isLoading={isShowLoading}
        size="small"
        rowKey="device_key"
        rowSelection={rowSelection}
        // onRow={handleDevicesListRow}
        // onCell={handleDevicesListRow}
        // rowClassName={this.setRowClassName}
      />

      <Modal
        title={t("device_details_title")}
        open={deviceModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={900}
        maskClosable={false}
        getContainer={false}
        keyboard={true}
      >
        <DeviceDetail onCancel={handleCancel} deviceKey={deviceKey} />
      </Modal>

      <Modal
        title={t("device_return_title")}
        open={deviceReturnModalvisible}
        // onOk={handleOk}
        onCancel={handleDeviceReturnCancel}
        footer={null}
        width={400}
        maskClosable={false}
        getContainer={false}
        keyboard={true}
      >
        <DeviceReturn onClickCancel={handleDeviceReturnCancel} />
      </Modal>
    </DeviceDispatch.Provider>
  );
};

export default Device;
