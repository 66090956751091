import i18next from "i18next";
import Axios from "axios";
import {errorHandler} from "../common";

export const checkAllAuth = async (params) => {
  try {
    const {data} = await Axios.post("/authenticate", {
      username: JSON.parse(sessionStorage.userInfo).user_email_id,
      password: params,
    });
    return data.c_user_type === "USR901";
  } catch (err) {
    errorHandler(i18next.t("message_error_beginning"), err);
    return false;
  }
};
