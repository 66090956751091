import React, {useState, useRef, useEffect} from "react";
import config from "../../lib/env";
import "antd/dist/antd.css";
import "./index.css";
import {
  makeViewerWithOpenMap,
  getPolylineEntity,
  removeAllEntities,
  getMiddlePoints,
  addEntity,
  getRectangleCoordinates,
  getRectangleFromDegrees,
  setView,
  cameraTransform,
} from "../../utils/mappingUtils";

const Cesium = window.Cesium;
Cesium.Ion.defaultAccessToken = config.ion_defaultAccessToken;

/* Main Component */
const RouteViewer = (props) => {
  const [isView, setIsView] = useState("");

  const cesiumContainer = useRef();
  const cesiumViewer = useRef();
  const creditContainer = useRef();

  // 뷰어 로딩 및 펜스 매핑
  const loadViewer = () => {
    if (cesiumViewer.current === undefined) {
      // Cesium Viewer 로딩
      cesiumViewer.current = makeViewerWithOpenMap(
        cesiumContainer,
        false, // animation
        false, // baseLayerPicker
        false, // fullscreenButton
        false, // vrButton
        false, // geocoder
        false, // homeButton
        false, // infoBox
        false, // sceneModePicker
        false, // selectionIndicator
        false, // timeline
        false, // navigationHelpButton
        creditContainer, // creditContainer
        "https://tile.openstreetmap.org/" // imageryProvider: Open Steet Map
      );
    } else {
      removeAllEntities(cesiumViewer.current.entities);
    }

    if (props.data && props.data.length > 0) {
      mappingRoutes(props.data);
    }
  };

  // 선분 매핑
  const mappingRoutes = (coordArr) => {
    const viewer = cesiumViewer.current;
    removeAllEntities(viewer.entities);

    setIsView("inline");
    const _entity = getPolylineEntity(`route`, coordArr);
    const recCoord = getRectangleCoordinates(coordArr);

    // Entity 추가
    addEntity(viewer.entities, _entity);

    // 사각형 조감
    setView(
      viewer,
      getRectangleFromDegrees(recCoord.minX, recCoord.maxX, recCoord.minY, recCoord.maxY)
    );
  };

  useEffect(() => {
    loadViewer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <>
      <div style={{display: isView}} ref={cesiumContainer} />
    </>
  );
};

/* Exports */
export default RouteViewer;
