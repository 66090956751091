import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import ko_json from "./locales/ko-KR/translation.json";
import en_json from "./locales/en-EN/translation.json";
// import ja_json from "./locales/ja-JP/translation.json"; // test

// 언어별 번역 대상 문자열 상수
const resources = {
  ko: {translation: ko_json},
  en: {translation: en_json},
  // ja: {translation: ja_json}, // test
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "ko",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

/**
 * 언어 목록: 한국어, 영어
 * (일본어는 테스트용)
 */
export const languagesSelectOptionList = [
  {label: "한국어", value: "ko"},
  {label: "English", value: "en"},
  // {label: "日本語", value: "ja"}, // test
];

/**
 * i18next 언어 변경
 * @param {*} lang 언어 코드
 * @returns 언어 변경 함수
 */
export const changeLanguageOnScreen = (lang) => {
  return i18n.changeLanguage(lang);
};

export default i18n;
