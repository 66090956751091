import React, {useState, useEffect} from "react";
import {Form, Input, Button, InputNumber, Space} from "antd";
import Axios from "axios";
import {EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import "./index.css";
import {TableRender, xaMessage, XaConfirmIconButton, errorHandler} from "../../common";
import {isStringAllDigit, handleTelNumber} from "../../utils/stringHelper";

/* Main Component */
export const CompanyMng = (props) => {
  const {onCancel} = props;
  const [companyList, setCompanyList] = useState([]);
  const [editingKey, setEditingKey] = useState(null);
  const axios = Axios.create();
  const [form] = Form.useForm();

  /** i18next */
  const {t} = useTranslation();

  // 열 편집 여부 상수
  const isEditing = (record) => record.company_mng_sq === editingKey;

  // 편집 모드 열 Rendering Code
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{margin: 0}}
            rules={[
              {
                required: true,
                message: t("message_please_input", {target: title}),
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  // Table 열(Columns) 정의
  const columns = [
    // {
    //   title: t("user_company_modal_table_column_company_seq"),
    //   dataIndex: "company_mng_sq",
    //   key: "tblCompany1",
    //   width: 65,
    //   render: (text, record, _) => (record.category === "new" ? "New" : text),
    // },
    {
      title: t("user_company_modal_table_column_company_name"),
      dataIndex: "f_comp_nm",
      key: "tblCompany2",
      editable: true,
    },
    {
      title: t("user_company_modal_table_column_phone_number"),
      dataIndex: "f_comp_tel",
      key: "tblCompany3",
      render: (text) => handleTelNumber(text),
      editable: true,
    },
    {
      title: t("user_company_modal_table_column_action"),
      key: "tblCompany4",
      width: 80,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button
              type="link"
              size="small"
              icon={<CheckOutlined />}
              onClick={() => handleSaveCompany(record)}
            />
            <XaConfirmIconButton
              size="small"
              contents={t("general_cancel_confirm")}
              icon={<CloseOutlined />}
              onConfirm={handleCancelEdit}
            />
          </Space>
        ) : (
          <Space>
            <Button
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={() => handleEditRow(record)}
            />
            <XaConfirmIconButton
              contents={t("delete_confirm")}
              onConfirm={() => handleDeleteCompany(record)}
              icon={<DeleteOutlined />}
              size="small"
            />
          </Space>
        );
      },
    },
  ];

  // 편집 시 입력하기 위한 Table 열(Columns) 재정의
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  // 소속 정보 조회 및 할당
  const handleGetCompany = async () => {
    try {
      const {data} = await axios.get("/company");
      setCompanyList(data);
    } catch (err) {
      errorHandler(t("message_error_beginning", err));
    }
  };

  // 화면상 Table의 행(Row) 추가
  const handleAddCompany = () => {
    let _addList = [];
    _addList = _addList.concat(companyList);

    _addList.push({
      category: "new",
      company_mng_sq: "",
      f_comp_nm: "",
      f_comp_tel: "",
    });

    setCompanyList(_addList);
  };

  // 편집 취소
  const handleCancelEdit = () => {
    setEditingKey(null);
  };

  // 편집 시 화면에 표시될 Table의 행(Row) 데이터
  const handleEditRow = (rowData) => {
    form.setFieldsValue({
      category: rowData.company_mng_sq === null ? "New" : "Edit",
      company_mng_sq: "",
      f_comp_nm: "",
      f_comp_tel: "",
      ...rowData,
    });
    setEditingKey(rowData.company_mng_sq);
  };

  // 저장
  const handleSaveCompany = async (rowData) => {
    const row = await form.validateFields();
    const newData = [...companyList];
    const index = newData.findIndex((item) => rowData.company_mng_sq === item.company_mng_sq);
    let newRecord = {...newData[index], ...row};
    const _category = newRecord.category;

    if (!newRecord.f_comp_nm || !newRecord.f_comp_tel) {
      xaMessage("warn", t("user_company_modal_check_company_name_telno"));
      return;
    } else if (!isStringAllDigit(newRecord.f_comp_tel)) {
      xaMessage("warn", t("user_company_modal_check_company_telno_digit"));
      return;
    }

    try {
      if (_category === "new") {
        const {data} = await axios.post("/company", {
          params: {
            company_name: newRecord.f_comp_nm,
            company_tel_no: newRecord.f_comp_tel,
          },
        });
        setCompanyList(data);
      } else {
        const {data} = await axios.post("/company/update", {
          params: {
            company_mng_sq: newRecord.company_mng_sq,
            company_name: newRecord.f_comp_nm,
            company_tel_no: newRecord.f_comp_tel.replaceAll("-", ""),
          },
        });
        setCompanyList(data);
      }
      setEditingKey(null);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // 삭제
  const handleDeleteCompany = async (rowData) => {
    try {
      if (rowData.category === "new") {
        const newData = companyList.filter(
          (item) => item.company_mng_sq !== rowData.company_mng_sq
        );
        setCompanyList(newData);
      } else {
        const {data} = await axios.delete("/company/delete", {
          params: {
            company_mng_sq: rowData.company_mng_sq,
          },
        });
        setCompanyList(data);
        xaMessage("success", t("message_delete_success"));
      }
      setEditingKey(null);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // Table Footer 설정
  const handleTblFooterRender = () => {
    return (
      <Space>
        <Button type="default" size="small" onClick={handleInit}>
          {t("button_init")}
        </Button>
        <Button type="primary" size="small" onClick={handleAddCompany}>
          {t("user_company_modal_button_add")}
        </Button>
      </Space>
    );
  };

  // 초기화
  const handleInit = () => {
    handleGetCompany();
    setEditingKey(null);
  };

  useEffect(() => {
    handleGetCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <Form form={form} size="small">
      <TableRender
        dataSource={companyList}
        columns={mergedColumns}
        rowKey="company_mng_sq"
        rowClassName="editable-row"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        pagination={false}
        bordered={false}
        footer={handleTblFooterRender}
      />
    </Form>
  );
};

/* Exports */
export default CompanyMng;
