import React from "react";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import AnnotationMng from "./AnnotationMng";
import AnnotationCopy from "./AnnotationCopy";

/* Main Component */
const Annotation = (props) => {
  /** i18next */
  const {t} = useTranslation();

  const tabItems = [
    {
      // 어노테이션 관리
      key: "1",
      label: t("annotation_tab_manage"),
      children: <AnnotationMng />,
    },
    {
      // 어노테이션 복사
      key: "2",
      label: t("annotation_tab_copy"),
      children: <AnnotationCopy />,
    },
  ];

  /* Renderer */
  return (
    <>
      <Tabs defaultActiveKey="0" items={tabItems} />
    </>
  );
};

/* Exports */
export default Annotation;
