import {Space, message, Divider} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import "./common.css";

/**
 *  공통 메시지 요소 (XiteCore FrontEnd 참조)
 * @param {*} type  메시지 타입 (현재 common.css에 정의된 것은 info, success, warn(warning), error)
 * @param {*} text  메시지 내용
 * @param {*} duration  메시지 요소 유지 시간(초)
 * @param {*} closeable 닫기 버튼 작동 여부
 * @returns
 */
export const xaMessage = (type, text, duration = 5, closeable = true) => {
  return message[type](
    {
      className: `custom-message ${type}-message`,
      content: (
        <Space>
          <div>{text}</div>
          {closeable && (
            <>
              <Divider type="vertical" />
              <CloseOutlined onClick={() => message.destroy()} />
            </>
          )}
        </Space>
      ),
    },
    duration
  );
};
