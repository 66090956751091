/* src/App.js */
import React from "react";
import {BrowserRouter as Router, Switch, Redirect} from "react-router-dom";

import RouteWithLayout from "./layouts/RouteWithLayout";
import MainLayout from "./layouts/Main/Main";

import Main from "./routes/Main/Main";
import NotFoundPage from "./routes/NotFoundPage";

import Users from "./routes/Users";
import Jobs from "./routes/Jobs/Jobs";
import Crossection from "./routes/Crossection";

import Mobile from "./layouts/Mobile";
import LoginPage from "./routes/Login/LoginPage";
import LoginLayoutRoute from "./layouts/RouteWithLayout/LoginLayoutRoute";
import Codes from "./routes/Codes/";
import Api from "./routes/Auth";
import AuthApi from "./routes/Auth/AuthApi";
import CrossectionJobs from "./routes/CrossectionJobs";
import SectionCalcJobs from "./routes/SectionCalcJobs";
import Device from "./routes/Device";
import SocketManage from "./routes/SocketManage";
import FloorPlan from "./routes/FloorPlan";
import DeviceMng from "./routes/DeviceMng";
import LogJob from "./routes/LogJob";
import Annotation from "./routes/Annotation";
import SectionDataMng from "./routes/SectionDataMng";
import MenuMng from "./routes/MenuMng";
import XcApiKey from "./routes/XcApiKey";
import ParanormalAssets from "./routes/ParanormalAssets";
import {relocateNotFoundUrl} from "./utils/utils";
import "./utils/axiosUtil";
import "./lib/i18next/i18n";
import "./index.css";

const App = () => {
  const currentPath = window.location.pathname;

  // 다운로드 링크 접근 회피
  if (currentPath.includes("/downloads")) {
    relocateNotFoundUrl();
  }

  return (
    <Router>
      <Switch>
        <LoginLayoutRoute exact path="/" component={LoginPage} />
        <Mobile exact path="/mobile" component={Mobile} />
        <RouteWithLayout path="/main" layout={MainLayout} component={Main} />
        <RouteWithLayout path="/LogJob" layout={MainLayout} component={LogJob} />
        <RouteWithLayout path="/XcApiKey" layout={MainLayout} component={XcApiKey} />
        <RouteWithLayout path="/MenuMng" layout={MainLayout} component={MenuMng} />
        <RouteWithLayout path="/Codes" layout={MainLayout} component={Codes} />
        <RouteWithLayout path="/Api" layout={MainLayout} component={Api} />
        <RouteWithLayout path="/AuthApi" layout={MainLayout} component={AuthApi} />
        <RouteWithLayout path="/Users" layout={MainLayout} component={Users} />
        <RouteWithLayout path="/Jobs" layout={MainLayout} component={Jobs} />
        <RouteWithLayout path="/SectionDataMng" layout={MainLayout} component={SectionDataMng} />
        <RouteWithLayout path="/Crossection" layout={MainLayout} component={Crossection} />
        <RouteWithLayout path="/CrossectionJobs" layout={MainLayout} component={CrossectionJobs} />
        <RouteWithLayout path="/SectionCalcJobs" layout={MainLayout} component={SectionCalcJobs} />
        <RouteWithLayout path="/Device" layout={MainLayout} component={Device} />
        <RouteWithLayout path="/SocketManage" layout={MainLayout} component={SocketManage} />
        <RouteWithLayout path="/DeviceMng" layout={MainLayout} component={DeviceMng} />
        <RouteWithLayout path="/FloorPlan" layout={MainLayout} component={FloorPlan} />
        <RouteWithLayout path="/Annotation" layout={MainLayout} component={Annotation} />
        <RouteWithLayout
          path="/ParanormalAssets"
          layout={MainLayout}
          component={ParanormalAssets}
        />
        <RouteWithLayout path="/not-found" layout={MainLayout} component={NotFoundPage} />
        <Redirect to="/not-found" />
      </Switch>
    </Router>
  );
};

export default App;
