import {Tooltip} from "antd";
import "./common.css";

/**
 * 공통 ToolTip 요소
 * @param {*} props
 *   - className  CSS 적용 요소명
 *   - content  ToolTip 내부에 표시할 문자열
 *   - placement ToolTip 위치
 *   - overlayStyle ToolTip 위치
 *   - overlayInnerStyle ToolTip Style 요소
 *   - mainComponent ToolTip 태그 사이에 삽입할 요소
 * @returns Popconfirm
 */
export const XaTooltip = (props) => {
  const {
    className,
    overlayClassName,
    content,
    placement = "bottomRight",
    mainComponent = "",
    color = "#00cfb1",
    overlayInnerStyle,
  } = props;

  return (
    <>
      <Tooltip
        className={`specific-cursor ${className}`}
        overlayClassName={overlayClassName}
        title={content}
        placement={placement}
        color={color}
        overlayInnerStyle={overlayInnerStyle}
      >
        {mainComponent}
      </Tooltip>
    </>
  );
};
