import moment from "moment";
import _ from "lodash";

export const defaultDateFormat = "YYYY-MM-DD";
export const defaultDateTimeFormat = "YYYY-MM-DD HH:mm:ss";

/**
 * 문자열 자리 계수
 * @param {*} string 대상 문자열
 * @returns Integer
 */
export const countCharacters = (string) => String(string).length;

/**
 * 휴대전화 번호 여부
 * @param {*} telno 전화번호 문자열
 * @returns true/false
 */
export const isPhoneNum = (telNum) =>
  String(telNum)?.replace(/[- ]/g, "").substring(0, 3) === "010";

/**
 * 문자열 공백 및 하이픈 제거
 * @param {*} string 대상 문자열
 * @returns String
 */
export const removeDashAndSpace = (string) => String(string)?.replace(/[- ]/g, "");

/**
 * 문자열 공백 여부 확인
 * @param {string | null} str
 */
export const isNullOrWhiteSpace = (str) => {
  if (str === null) return true;

  if (typeof str !== "string") {
    throw new TypeError("The str is not String.");
  }

  return str === "" || str.match(/^ *$/) !== null;
};

/** 문자열의 숫자 여부 반환  */
export const isStringAllDigit = (digit) => {
  let numberRegex = /^\d+$/;
  return numberRegex.test(digit);
};

/**
 * string 파라미터 비교, 숫자 비교 포함
 * @param {string | undefined | null} a
 * @param {string | undefined | null} b
 */
export const compareTwoStrings = (a, b) => {
  if (a === undefined || b === undefined || isNullOrWhiteSpace(a) || isNullOrWhiteSpace(b))
    return 0;

  return a.localeCompare(b, undefined, {numeric: true, sensitivity: "base"});
};

/**
 * className merge용 함수
 * 고정 className을 유지하면서 합칠때 사용
 * @param  {string | string[]} className
 * @returns {string}
 */
export const combineClassName = (...classNames) => {
  return classNames.filter((name) => typeof name === "string").join(" ");
};

/**
 * 첫글자 대문자화
 * @param {string} str
 * @returns
 */
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * 숫자 천단위 콤마 추가
 * @param {*} value
 * @returns
 */
export const addComma = (value) => {
  return value
    .toString()
    .replace(/(\..*)$|(\d)(?=(\d{3})+(?!\d))/g, (digit, fract) => fract || digit + ",");
};

/** 숫자와 3자리 마다 반점이 포함된 문자열 */
export const numberWithCommas = (_number, _customDigit) => {
  if (!_number) return;
  else if (typeof _number !== "number" && typeof _number === "string") {
    _number = parseFloat(_number);
  }

  const _fractionDigits = _customDigit
    ? _customDigit
    : _number.toString().split(".")[1]
    ? _number.toString().split(".")[1].length
    : 0;

  return _number.toLocaleString(undefined, {maximumFractionDigits: _fractionDigits});
};

export const addCommaWithRound = (value, num) => {
  return addComma(_.round(value, num).toFixed(num));
};
/**
 * 일시 등 시간문자열의 Moment 객체 변환
 * @param {*} dateString 시간문자열
 * @param {*} _format 형식
 * @returns Moment 객체
 */
export const convertDateStringToMoment = (dateString, _format) => moment(dateString, _format);

/** 8자리 숫자 문자열 일자로 변환 */
export const convertDateString = (dateString) =>
  dateString === null || dateString === "" ? "-" : moment(dateString).format(defaultDateFormat);

/** Timestamp 형식의 연월일 시분초("YYYY-MM-DD HH:mm:ss") 형식 문자열로 변환 */
export const convertDatetimeString = (dateTimeString) => {
  return dateTimeString === null || dateTimeString === ""
    ? "-"
    : moment(dateTimeString).format(defaultDateTimeFormat);
};

export const replaceIpAddressString = (ip) => {
  return !ip ? "" : ip && ip.includes("::ffff:") ? ip.replace("::ffff:", "") : ip;
};

export const shortenString = (_length, _string) => {
  return _string.length > _length ? `${_string.slice(0, _length)} ...` : _string;
};

// Postgresql Timezone 형식 시간대 문자열 변환
export const convertTimeZoneToTimeString = (timeZone) => {
  return timeZone === null || timeZone === "" ? "-" : timeZone.split(".")[0];
};
/**
 * 전화번호 하이픈 추가
 * @param {*} _telno 하이픈 없는 숫자 문자열
 * @returns
 */
export const handleTelNumber = (telno) => {
  const _telno = String(telno).replace(/[ -]/g, "");
  let telnumLength = String(_telno).length;
  let telnumWithDash;

  if (telnumLength < 9 || telnumLength > 11) {
    telnumWithDash = _telno;
  } else if ((telnumLength === 10 && _telno.slice(0, 2) !== "01") || telnumLength === 10) {
    telnumWithDash = `${_telno.slice(0, 2)}-${_telno.slice(2, 6)}-${_telno.slice(6, 11)}`;
  } else if ((telnumLength === 11 && _telno.slice(0, 3) === "010") || telnumLength === 11) {
    telnumWithDash = `${_telno.slice(0, 3)}-${_telno.slice(3, 7)}-${_telno.slice(7, 12)}`;
  } else if (telnumLength === 10 && _telno.slice(0, 2) === "01") {
    telnumWithDash = `${_telno.slice(0, 3)}-${_telno.slice(3, 6)}-${_telno.slice(6, 11)}`;
  } else if (telnumLength === 11 && _telno.slice(0, 2) === "01") {
    telnumWithDash = `${_telno.slice(0, 3)}-${_telno.slice(3, 7)}-${_telno.slice(7, 12)}`;
  } else {
    telnumWithDash = _telno;
  }

  return telnumWithDash;
};

/**
 * 사업자번호 하이픈 추가
 * @param {*} _bizNo 입력받은 문자열
 * @returns
 */
export const bizNoWithDash = (bizNo) => {
  const _bizNo = String(bizNo).replace(/[ -]/g, "");
  return String(bizNo).length !== 10
    ? bizNo
    : `${_bizNo.slice(0, 3)}-${_bizNo.slice(3, 5)}-${_bizNo.slice(5, _bizNo.length)}`;
};

/**
 * 문자열의 JSON 여부 반환
 * @param {*} str 문자열
 * @returns true/false
 */
export const isJsonString = (str) => {
  try {
    let json = JSON.parse(str);
    return typeof json === "object" && json.constructor.name === "Object";
  } catch (e) {
    return false;
  }
};

/**
 * JSON 객체의 각 요소의 문자열 앞뒤 공백을 제거(Trim)
 * @param {*} _json JSON 객체
 * @returns 각 요소가 Trim 처리된 JSON 객체
 */
export const trimStringsInJson = (_json) => {
  // 객체가 null이거나 배열인 경우 그대로 반환
  if (_json === null || Array.isArray(_json)) return _json;

  // 객체 순회
  for (const _key in _json) {
    if (typeof _json[_key] === "string") {
      _json[_key] = _json[_key].trim();
    } else if (typeof _json[_key] === "object") {
      _json[_key] = trimStringsInJson(_json[_key]);
    }
  }

  return _json;
};

/**
 * 단일 JSON 객체 내 빈값이 1개 이상 존재 여부 반환
 * @param {*} _json JSON 객체
 * @param {*} _except 빈 값 확인을 제외할 key 이름 배열
 * @returns true/false
 */
export const isNullValues = (_json, _except) => {
  let isNull = false;
  for (let _key in _json) {
    if (
      !_except.includes(_key) &&
      (_json[_key] === "" ||
        _json[_key] === " " ||
        _json[_key] === " " ||
        String(_json[_key]).length === 0 ||
        _json[_key] === undefined ||
        _json[_key] === null)
    ) {
      isNull = true;
      break;
    }
  }

  return isNull;
};

/**
 * 단일 JSON 객체 내 빈값이 1개 이상 존재 여부 반환
 * @param {*} _json JSON 객체
 * @param {*} _include 빈 값 확인할 key 이름 배열
 * @returns true/false
 */
export const isNullValuesIncludes = (_json, _include) => {
  let isNull = false;
  for (let _key in _json) {
    if (
      _include.includes(_key) &&
      (_json[_key] === "" ||
        _json[_key] === " " ||
        _json[_key] === " " ||
        String(_json[_key]).length === 0 ||
        _json[_key] === undefined ||
        _json[_key] === null)
    ) {
      isNull = true;
      break;
    }
  }

  return isNull;
};

/**
 * 값이 없는 Key - Value 제거하여 JSON 객체 재구성
 * @param {*} _json JSON 객체
 * @returns JSON 객체
 */
export const filterEmptyValues = (_json) => {
  return Object.entries(_json).reduce((acc, [key, value]) => {
    if (value !== null && value !== "" && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

/**
 * Lodash orerBy 정렬
 * @param {*} jsonArray JSON 객체 포함 배열
 * @returns JSON 객체 포함 배열 (정렬 완료)
 */
export const sortByOrder = (jsonArray, _key, _order) =>
  _.orderBy(jsonArray, [(item) => item[_key].toString().toLowerCase()], [_order]);
