import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, Input, Modal, Popconfirm, Radio, Row, Table, Tree, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {convertDatetimeString} from "../../utils/stringHelper";
import {TableRender, xaMessage, errorHandler, XaConfirmButton} from "../../common";
import {DeleteOutlined} from "@ant-design/icons";
import Axios from "axios";

const {DirectoryTree} = Tree;

// Forms
// 폴더 생성 Form
const CreateFolderForm = ({formData, onFinish}) => {
  const {t} = useTranslation();
  let formRef = useRef();

  const handleCancel = () => {
    onFinish(false);
  };

  const handleFinish = async (parmas) => {
    let inputData = {
      folder_sq: null,
      folder_sq_parent: formData.recode.folder_sq,
      mg_device_sq: formData.recode.mg_device_sq,
      f_folder_name: parmas.folder_name,
    };

    try {
      const res = await Axios.get("/mgFolderChk", {
        params: {
          folder_sq_parent: formData.recode.folder_sq,
          mg_device_sq: formData.recode.mg_device_sq,
          f_folder_name: parmas.folder_name,
        },
      });

      if (res.data.length > 0) {
        xaMessage(
          "warning",
          t("floor_plan_manage_create_message_folder_duplicate", {folderName: parmas.folder_name})
        );
        return;
      }

      // 데이터 저장
      const {data} = await Axios.post("/mgFolder", {
        params: inputData,
      });

      xaMessage("success", t("message_save_complete"));
      onFinish(true);
    } catch (err) {
      onFinish(false);
      errorHandler(t("message_error_beginning"), err);
    }
  };
  return (
    <>
      <Form ref={formRef} onFinish={handleFinish}>
        <Form.Item label={t("floor_plan_manage_modal_create_folder_label")} name="folder_name">
          <Input placeholder={t("floor_plan_manage_modal_create_folder_input_placeholder")} />
        </Form.Item>
        <Button className="button-margin-right" type="primary" htmlType="submit">
          {t("button_save")}
        </Button>
        <Button onClick={handleCancel}>{t("button_cancel")}</Button>
      </Form>
    </>
  );
};

// 파일 업로드 Form
const UploadFileForm = ({formData, onFinish}) => {
  const {t} = useTranslation();
  let formRef = useRef();

  const [uploadFile, setUploadFile] = useState();

  const handleCancel = () => {
    onFinish(false);
  };
  const handleFinish = async (parmas) => {
    try {
      const fData = new FormData();
      fData.append("folder_sq", formData.recode.folder_sq);
      fData.append("mg_device_sq", formData.recode.mg_device_sq);
      fData.append("mg_device_key", formData.recode.mg_device_key);
      fData.append("file_type", parmas.file_type);
      fData.append("file_name", parmas.uploadfile[0].name);
      fData.append("uploadfile", uploadFile);

      // 데이터 저장
      const {data} = await Axios.post("/mgFile", fData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      xaMessage("success", t("message_save_complete"), null, true);
      onFinish(true);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
      onFinish(false);
    }
  };
  const handleChangeFileType = (e) => {
    let sltValue = e.target.value;

    if (sltValue === "EPSG") {
    } else {
    }
  };
  const handleGetFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Form ref={formRef} onFinish={handleFinish}>
        <Form.Item label={t("floor_plan_manage_modal_upload_folder_file_type")} name="file_type">
          <Radio.Group onChange={handleChangeFileType} buttonStyle="solid">
            <Radio.Button value={"EPSG"}>EPSG</Radio.Button>
            <Radio.Button value={"DXF"}>DXF</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t("floor_plan_manage_modal_upload_folder_file_select")}
          name="uploadfile"
          getValueFromEvent={handleGetFile}
        >
          <Upload
            beforeUpload={(file) => {
              setUploadFile(file);
              return false;
            }}
          >
            <Button>{t("button_select_file")}</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <span
            style={{
              color: "red",
            }}
          >
            {t("floor_plan_manage_modal_upload_folder_file_guide")}
          </span>
        </Form.Item>
        <Button className="button-margin-right" type="primary" htmlType="submit">
          {t("button_save")}
        </Button>
        <Button onClick={handleCancel}>{t("button_cancel")}</Button>
      </Form>
    </>
  );
};

// 상태 변경 Form
const ChangeStateForm = ({formData, onFinish}) => {
  const {t} = useTranslation();
  let formRef = useRef();

  const handleCancel = () => {
    onFinish(false);
  };
  const handleFinish = async (parmas) => {
    try {
      // 데이터 저장
      const {data} = await Axios.post("/mgFileChangeStatus", {
        params: {file_sq: formData.file_sq, f_status: parmas.f_status},
      });

      xaMessage("success", "Save Complete.", null, true);
      onFinish(true);
    } catch (err) {
      onFinish(false);
    }
  };

  return (
    <>
      <Form ref={formRef} onFinish={handleFinish}>
        <Form.Item label={t("floor_plan_manage_change_state_state")} name="f_status">
          <Radio.Group defaultValue={formData.f_status}>
            <Radio value={"1"}>{t("floor_plan_manage_change_state_radio_regist")}</Radio>
            <Radio value={"2"}>{t("floor_plan_manage_change_state_radio_not_sent")}</Radio>
            <Radio value={"3"}>{t("floor_plan_manage_change_state_radio_complete")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Button className="button-margin-right" type="primary" htmlType="submit">
          {t("button_save")}
        </Button>
        <Button onClick={handleCancel}>{t("button_cancel")}</Button>
      </Form>
    </>
  );
};

export default function FloorPlanMngPage() {
  /** i18next */
  const {t} = useTranslation();

  const FloorPlanListCols = [
    {
      title: t("floor_plan_manage_table_column_route"),
      width: 200,
      dataIndex: "f_path",
    },
    {
      title: t("floor_plan_manage_table_column_file_name"),
      width: 250,
      dataIndex: "f_file_name",
    },
    {
      title: t("floor_plan_manage_table_column_size"),
      width: 100,
      dataIndex: "f_file_size",
    },
    {
      title: t("floor_plan_manage_table_column_status"),
      width: 100,
      dataIndex: "f_status",
      render: (text, record, index) => {
        let arr = [
          "",
          t("floor_plan_manage_change_state_radio_regist"),
          t("floor_plan_manage_change_state_radio_not_sent"),
          t("floor_plan_manage_change_state_radio_complete"),
        ];
        return arr[Number(record.f_status)];
      },
    },
    {
      title: t("floor_plan_manage_table_column_type"),
      width: 100,
      dataIndex: "f_file_type",
    },
    {
      title: t("floor_plan_manage_table_column_upload_date"),
      width: 160,
      dataIndex: "f_create_dt",
      render: (text) => (text ? convertDatetimeString(text) : "-"),
      // (text, record, index) => {
      //   return text && moment(text).format("YYYY-MM-DD HH:mm:ss");
      // },
    },
    {
      title: t("floor_plan_manage_table_column_action"),
      render: (text, record, index) => {
        return (
          <span>
            <Button
              type="link"
              onClick={() => {
                setIsShowChangeStatusForm(true);
                setSelectedFile(record);
              }}
            >
              {t("button_change")}
            </Button>
            {/* <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => fnRemoveFile(record.file_sq)}
            >
              <Button type="link">
                <DeleteOutlined />
              </Button>
            </Popconfirm> */}
            <XaConfirmButton
              contents={t("delete_confirm")}
              btnType="link"
              onConfirm={() => fnRemoveFile(record.file_sq)}
              btnName={<DeleteOutlined />}
              placement="bottom"
            />
          </span>
        );
      },
    },
  ];

  // 상태정보
  // 폴더 생성 Modal 노출 여부
  const [isShowCreateFolderForm, setIsShowCreateFolderForm] = useState(false);
  const [isShowUploadFileForm, setIsShowUploadFileForm] = useState(false);
  const [isShowChangeStatusForm, setIsShowChangeStatusForm] = useState(false);

  const [disableAddFolder, setDisableAddFolder] = useState(true);
  const [disableRemoveFolder, setDisableRemoveFolder] = useState(true);
  const [disableFileUpload, setDisableFileUpload] = useState(true);

  const [fileData, setFileData] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    fnLoadTreeData();

    return () => {};
  }, []);

  const handleAddFolder = () => {
    // 폴더 생성 조건
    // 1. 트리에 선택 노드가 있을것
    if (!selectedNode) {
      xaMessage("warning", t("floor_plan_manage_remove_folder_screen_not_select_confirm"));
      return;
    }
    // 2. 선택된 노드의 깊이가 2일 때, 하위폴더 생성 불가
    if (selectedNode.recode.level === 3) {
      xaMessage("warning", t("floor_plan_manage_add_folder_screen_not_create_confirm"));
      return;
    }

    setIsShowCreateFolderForm(true);
  };
  const handleRemoveFolder = () => {
    // 삭제 조건: 트리에서 선택된 노드 존재
    if (!selectedNode) {
      xaMessage("warning", t("floor_plan_manage_remove_folder_screen_not_select_confirm"));
      return;
    }
    if (selectedNode.recode.type === "device") {
      xaMessage("warning", t("floor_plan_manage_remove_folder_screen_not_delete_confirm"));
      return;
    }
    // 선택된 노드의 자식이 있으면 삭제 불가
    if (selectedNode.children || fileData.length > 0) {
      xaMessage("warning", t("floor_plan_manage_remove_folder_screen_exist_folder"));
      return;
    }

    // 삭제 확인
    Modal.confirm({
      title: t("floor_plan_manage_remove_folder_confirm"),
      okText: t("button_ok"),
      cancelText: t("button_cancel"),
      onOk: () => fnRemoveFolder(),
    });
  };
  const handleUploadFile = () => {
    setIsShowUploadFileForm(true);
  };
  const handleDownloadFile = () => {};
  const handleTreeSelect = async (selectedKeys, e) => {
    handleGetFileList(e.node);
  };

  const handleOkCreateFolderForm = () => {
    fnLoadTreeData();
    setIsShowCreateFolderForm(false);
  };
  const handleCancelCreateFolderForm = () => {
    setIsShowCreateFolderForm(false);
  };
  const handleOkUploadFileForm = (result) => {
    if (result) {
      handleGetFileList(selectedNode);
    }
    setIsShowUploadFileForm(false);
  };
  const handleCancelUploadFileForm = () => {
    setIsShowUploadFileForm(false);
  };
  const handleCreateTransFile = () => {
    Axios.get("/mgCreateTransFile", {
      params: {
        mg_device_sq: selectedNode.recode.mg_device_sq,
        mg_device_key: selectedNode.recode.mg_device_key,
        folder_sq: selectedNode.recode.folder_sq,
      },
    });
    xaMessage("success", t("floor_plan_manage_message_file_success"));
    handleGetFileList(selectedNode);
  };

  const handleOkChangeStateForm = (result) => {
    if (result) {
      handleGetFileList(selectedNode);
    }
    setIsShowChangeStatusForm(false);
  };
  const handleCancelChangeStateForm = () => {
    setIsShowChangeStatusForm(false);
  };

  // 함수정의 - 선택된 트리 노드의 하위 키 모음
  const fnGetKeyList = (node, include = true) => {
    let arrKey = [];

    if (include) arrKey.push(node.key);

    if (node.children) {
      node.children.map((x) => {
        arrKey.push(...fnGetKeyList(x));
      });
    }

    return arrKey;
  };
  // 선택한 트리 노드의 단계(레벨) 정수
  const fnGetLevel = (pos) => {
    let arr = pos.split("-");
    return arr ? arr.length - 1 : 0;
  };
  const fnRemoveFolder = async () => {
    if (!selectedNode) return;

    let key = selectedNode.recode.folder_sq;

    try {
      await Axios.delete("/mgFolder/" + key);
      xaMessage("success", t("message_delete_success"));
      fnLoadTreeData();
    } catch (err) {
      errorHandler("", "Error Occured.");
    }
  };
  const fnLoadTreeData = async () => {
    const deviceData = await Axios.get("/mgDevices"); // 장비 목록 호출
    const folderData = await Axios.get("/mgFolders"); // 폴더 목록 호출

    let deviceList = deviceData.data;
    let folderList = folderData.data;

    if (!deviceList || !folderList) return;

    let treeDataTmp = deviceList.map((device) => {
      let tmp = {
        title: device.f_device_name,
        key: "device_" + device.mg_device_sq,
        recode: {type: "device", level: 1, ...device},
      };

      let arrFolder1 = folderList.filter(
        (f1) => f1.mg_device_sq === device.mg_device_sq && f1.folder_sq_parent === null
      );
      if (arrFolder1 && arrFolder1.length > 0) {
        let children = [];
        arrFolder1.map((f1) => {
          let obj = {
            title: f1.f_folder_name,
            key: f1.folder_sq,
            recode: {type: "folder", level: 2, mg_device_key: device.mg_device_key, ...f1},
          };

          let arrFolder2 = folderList.filter((f2) => f2.folder_sq_parent === f1.folder_sq);
          if (arrFolder2 && arrFolder2.length > 0) {
            let children2 = [];
            arrFolder2.map((f2) => {
              let obj2 = {
                title: f2.f_folder_name,
                key: f2.folder_sq,
                recode: {type: "folder", level: 3, mg_device_key: device.mg_device_key, ...f2},
              };
              children2.push(obj2);
            });

            obj = {...obj, children: children2};
          }
          children.push(obj);
        });

        tmp = {...tmp, children: children};
      }

      return tmp;
    });

    setTreeData(treeDataTmp);
    setSelectedNode(undefined);
  };

  const handleGetFileList = async (node) => {
    let nodelevel = fnGetLevel(node.pos);
    let arr = fnGetKeyList(node, nodelevel > 1);

    // 파일 조회
    const {data} = await Axios.get("/mgFiles", {
      params: {
        folderKeys: arr.length === 0 ? [-1] : arr,
      },
    });

    setFileData(data);
    setSelectedNode(node);
  };

  const fnRemoveFile = async (key) => {
    const res = await Axios.delete("/mgFile/" + key);
    xaMessage("success", "Delete Success.");
    handleGetFileList(selectedNode);
  };

  const btnStyle = {
    margin: "2px 2px 2px 0px",
  };
  return (
    <>
      <Row>
        <Col span={6} style={{paddingRight: "5px"}}>
          <Button
            type="primary"
            onClick={handleAddFolder}
            style={btnStyle}
            disabled={!selectedNode || selectedNode.recode.level === 3}
          >
            {/* Create Folder */}
            {t("button_create_folder")}
          </Button>
          <Button
            type="primary"
            onClick={handleRemoveFolder}
            style={btnStyle}
            disabled={!selectedNode}
          >
            {/* Delete Folder  */}
            {/* Remove Folder (Current) */}
            {t("button_remove_folder")}
          </Button>
          <Button
            type="primary"
            onClick={handleUploadFile}
            style={btnStyle}
            disabled={!selectedNode || selectedNode.recode.level === 1}
          >
            {/* Upload */}
            {t("button_upload")}
          </Button>
          {/* <Button onClick={handleDownloadFile}>Download</Button> */}
          <DirectoryTree
            defaultExpandAll
            onSelect={handleTreeSelect}
            treeData={treeData}
            expandAction={"doubleClick"}
          />
        </Col>
        <Col span={18}>
          <Row>
            <Col span={24}>
              {/* 전송파일 생성 버튼 */}
              {/* <Popconfirm title="Create a transfer file?" onConfirm={handleCreateTransFile}>
                <Button
                  type="primary"
                  disabled={!selectedNode || selectedNode.recode.level !== 1}
                  style={btnStyle}
                >
                  Create File
                </Button>
              </Popconfirm> */}
              <XaConfirmButton
                contents={t("floor_plan_manage_create_file_confirm")}
                onConfirm={handleCreateTransFile}
                btnType="primary"
                disabled={!selectedNode || selectedNode.recode.level !== 1}
                btnName={t("button_create_file")}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* <Table
                columns={FloorPlanListCols}
                dataSource={fileData}
                pagination={false}
                scroll={{y: "500px"}}
                size={"small"}
              /> */}
              <TableRender
                columns={FloorPlanListCols}
                dataSource={fileData}
                pagination={false}
                scroll={{y: "500px"}}
                size={"small"}
                rowKey="file_sq"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isShowCreateFolderForm && (
        <Modal
          title={t("floor_plan_manage_modal_create_folder_title")}
          open={isShowCreateFolderForm}
          onOk={handleOkCreateFolderForm}
          onCancel={handleCancelCreateFolderForm}
          footer={null}
          width={450}
          maskClosable={false}
        >
          <CreateFolderForm formData={selectedNode} onFinish={handleOkCreateFolderForm} />
        </Modal>
      )}
      {isShowUploadFileForm && (
        <Modal
          title={t("floor_plan_manage_modal_upload_folder_title")}
          open={isShowUploadFileForm}
          onOk={handleOkUploadFileForm}
          onCancel={handleCancelUploadFileForm}
          footer={null}
          width={450}
          maskClosable={false}
        >
          <UploadFileForm formData={selectedNode} onFinish={handleOkUploadFileForm} />
        </Modal>
      )}
      {isShowChangeStatusForm && (
        <Modal
          title={t("floor_plan_manage_change_state_title")}
          open={isShowChangeStatusForm}
          onOk={handleOkChangeStateForm}
          onCancel={handleCancelChangeStateForm}
          footer={null}
          width={450}
          maskClosable={false}
        >
          <ChangeStateForm formData={selectedFile} onFinish={handleOkChangeStateForm} />
        </Modal>
      )}
    </>
  );
}
