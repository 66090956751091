import React, {useState, useRef, useEffect} from "react";
import config from "../../lib/env";
import "antd/dist/antd.css";
import "./index.css";
import {
  makeViewerWithOpenMap,
  getPolygonEntity,
  getEllipseEntity,
  removeAllEntities,
  getMiddlePoints,
  addEntity,
  // fromDegrees,
  // loadGltfModel,
  // zoomTo,
  cameraTransform,
} from "../../utils/mappingUtils";

const Cesium = window.Cesium;
Cesium.Ion.defaultAccessToken = config.ion_defaultAccessToken;

/* Main Component */
const AnnotationViewer = (props) => {
  const [isView, setIsView] = useState("");
  const [annotationViewData, setAnnotationViewData] = useState([]);

  const cesiumContainer = useRef();
  const cesiumViewer = useRef();
  const creditContainer = useRef();

  const pointFlags = props.flags; // 점(Point) 플래그

  // 뷰어 로딩 및 펜스 매핑
  const loadViewer = () => {
    if (props.data.annotation_mng_sq) {
      setAnnotationViewData(props.data);

      if (cesiumViewer.current === undefined) {
        // Cesium Viewer 로딩
        cesiumViewer.current = makeViewerWithOpenMap(
          cesiumContainer,
          false, // animation
          false, // baseLayerPicker
          false, // fullscreenButton
          false, // vrButton
          false, // geocoder
          false, // homeButton
          false, // infoBox
          false, // sceneModePicker
          false, // selectionIndicator
          false, // timeline
          false, // navigationHelpButton
          creditContainer, // creditContainer
          "https://tile.openstreetmap.org/" // imageryProvider: Open Steet Map
        );
      } else {
        removeAllEntities(cesiumViewer.current.entities);
      }

      if (props.data.f_location) {
        mappingFence(props.data);
      }
    }
  };

  // 펜스 매핑
  const mappingFence = (data) => {
    const viewer = cesiumViewer.current;
    removeAllEntities(viewer.entities);

    setIsView("inline");
    const isPoint = data.is_point;
    const coordArr = data.wgs84_location_xy;
    const middlePoints = getMiddlePoints(coordArr);
    const identifierPrefix = `${data.project_mng_sq}_${data.annotation_mng_sq}_${data.f_fence_annotation_flag}`;

    const _entity = isPoint
      ? getEllipseEntity(`${identifierPrefix}_point`, coordArr)
      : getPolygonEntity(`${identifierPrefix}_geofence`, coordArr);

    addEntity(viewer.entities, _entity);

    /*
    if (
      (data.f_fence_annotation_flag === "a1" || data.f_fence_annotation_flag === "a2") &&
      data.f_result.fnbgcalc &&
      data.f_result.fnbgcalc.length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      data.f_result.fnbgcalc.map((item) => {
        const modelId = `${data.project_mng_sq}_${data.annotation_mng_sq}_${item.id}`;
        const imageUrl = `/Storage/data_pcd${item.f_ply_file_name.replace(".ply", ".glb")}`;
        loadGltfModel(viewer, modelId, imageUrl, getMiddlePoints(coordArr));
      });
    }
    */
    cameraTransform(viewer, middlePoints);
  };

  useEffect(() => {
    loadViewer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.annotation_mng_sq]);

  return (
    <>
      <div style={{display: isView}} ref={cesiumContainer} />
    </>
  );
};

/* Exports */
export default AnnotationViewer;
