import React, {useState, useRef, useReducer, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Button, Select} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import {TableRender, xaMessage, errorHandler, XaConfirmButton} from "../../common";
import axios from "axios";

const {Option} = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SYSTEMTYPE":
      state = {...state, systemTypeList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const CodeDispatch = React.createContext(null);

// const EditableCell = ({
//   editing,
//   dataIndex,
//   title,
//   inputType,
//   len,
//   record,
//   index,
//   children,
//   ...restProps
// }) => {
//   const inputNode = inputType === "number" ? <InputNumber /> : <Input {...{ maxLength: len }} />;

//   return (
//     <td {...restProps}>
//       {editing ? (
//         <Form.Item
//           name={dataIndex}
//           style={{
//             margin: 0,
//           }}
//           rules={[
//             {
//               required: true,
//               message: `Please Input ${title}!`,
//               len: { len },
//             },
//           ]}
//         >
//           {inputNode}
//         </Form.Item>
//       ) : (
//         children
//       )}
//     </td>
//   );
// };

/* Main Component */
const AuthApi = (props) => {
  const [gState, setGState] = useReducer(reducer, {
    systemTypeList: [],
    methodTypeList: [
      {key: "0", text: "GET"},
      {key: "1", text: "POST"},
    ],
    crudList: [
      {key: "1", text: "Read Only"},
      {key: "2", text: "C/U/D"},
    ],
  });

  const {systemTypeList, methodTypeList, crudList} = gState;

  const [authMngList, setAuthMngList] = useState([]);
  let authApiList = useRef([]);
  const [apiList, setApiList] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [apiSelectedRowKeys, setApiSelectedRowKeys] = useState([]);
  const [selAuth_mng_sq, setSelAuth_mng_sq] = useState(null);

  const [form] = Form.useForm(); //searchForm
  const [form2] = Form.useForm(); //code2
  const [form3] = Form.useForm(); //code1

  /** i18next */
  const {t} = useTranslation();

  const onTblSTMrowSelection = async (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onTblSTMrowSelection,
  };

  const onTblApirowSelection = async (selectedRowKeys) => {
    setApiSelectedRowKeys(selectedRowKeys);
  };

  const tblApirowSelection = {
    selectedRowKeys: apiSelectedRowKeys,
    onChange: onTblApirowSelection,
  };

  const colStmCode = [
    {
      title: t("authapi_table_left_column_name"),
      dataIndex: "code2",
    },
    {
      title: t("authapi_table_left_column_description"),
      dataIndex: "code_txt",
    },
  ];
  //systemTypeList, methodTypeList,  crudList
  const colApi = [
    {
      title: t("authapi_table_right_column_system_type"),
      dataIndex: "c_system_type",
      key: "tblApi_1",
      filters: systemTypeList.map((item) => ({text: item.code2, value: item.code2})),
      onFilter: (value, record) => {
        return record.c_system_type === value;
      },
    },
    {
      title: t("authapi_table_right_column_api_name"),
      dataIndex: "f_api_nm",
      key: "tblApi_2",
    },
    {
      title: t("authapi_table_right_column_method"),
      dataIndex: "f_method_type",
      key: "tblApi_3",
      render: (text, record, index) => {
        let rtnObj = methodTypeList.find((item) => item.key === text);
        return rtnObj ? rtnObj.text : "";
      },
      filters: methodTypeList.map((item) => ({text: item.text, value: item.key})),
      onFilter: (value, record) => {
        return record.f_method_type === value;
      },
    },
    {
      title: t("authapi_table_right_column_crud"),
      key: "tblApi_4",
      dataIndex: "f_crud_type",
      render: (text, record, index) => {
        let rtnObj = crudList.find((item) => item.key === text);
        return rtnObj ? rtnObj.text : "";
      },
      filters: crudList.map((item) => ({text: item.text, value: item.key})),
      onFilter: (value, record) => {
        return record.f_crud_type === value;
      },
    },
    {
      title: t("authapi_table_right_column_description"),
      key: "tblApi_5",
      dataIndex: "f_description",
    },
    {
      title: t("authapi_table_right_column_delete"),
      key: "tblApi_6",
      dataIndex: "f_delete_yn",
    },
  ];

  const InitForm = async () => {
    try {
      {
        const {data} = await Axios.get("/code", {params: {code1: "STM"}});

        const _data = data.map((item) => ({...item, key: item.code2}));
        setGState({type: "SET_SYSTEMTYPE", payload: _data});
      }
      {
        const {data} = await axios.get("/authmngs");
        setAuthMngList(data);
      }
      {
        const {data} = await axios.get("/authapis");
        authApiList.current = data;
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const onFinish = async (paramValue) => {};

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const handleTblSTMTitle = () => {
    const handleSaveStmApi = async () => {
      if (!selAuth_mng_sq || selAuth_mng_sq === "") return;
      try {
        const {data} = await Axios.put("/authapiSTM", {
          params: {auth_mng_sq: selAuth_mng_sq, c_system_type: selectedRowKeys},
        });
        setApiSelectedRowKeys(data.map((item) => item.api_mng_sq));
        xaMessage("success", t("message_save_complete"));
      } catch (err) {
        errorHandler(`${t("message_validate_failed")}: `, err.message);
      }
    };

    return (
      <Row gutter={24}>
        <Col span={12}>
          {" "}
          <h2>{t("authapi_table_left_title")}</h2>
        </Col>
        <Col span={12} style={{textAlign: "right"}}>
          <XaConfirmButton
            contents="Check Extend API (Method Type, CRUD)?"
            onConfirm={handleSaveStmApi}
            btnName={t("authapi_button_save_api")}
            size="small"
          />
        </Col>
      </Row>
    );
  };
  const handleTblApiTitle = () => {
    const handleSaveApi = async () => {
      if (!selAuth_mng_sq || selAuth_mng_sq === "") return;
      try {
        //저장
        const {data} = await Axios.put(`/authapiOwn`, {
          params: {auth_mng_sq: selAuth_mng_sq, api_mng_sq: apiSelectedRowKeys},
        });
        if (data.length > 0) {
          //화면 apiList - stm 코드 데이터셋 업데이트
          const res = await axios.get(`/authapis`);
          let _arr = res.data;

          authApiList.current = _arr;

          //선택 api 셋팅
          setApiSelectedRowKeys(data.map((item) => item.api_mng_sq));
        }

        //STM CODE selectrow setting
        const selAuthSystems = authApiList.current.find(
          (item) => item.auth_mng_sq === selAuth_mng_sq
        );
        let _sel =
          selAuthSystems && selAuthSystems.c_system_type && selAuthSystems.c_system_type.length > 0
            ? selAuthSystems.c_system_type
            : [];
        setSelectedRowKeys(_sel);

        xaMessage("success", t("message_save_complete"));
      } catch (err) {
        errorHandler(`${t("message_validate_failed")}: `, err.message);
      }
    };

    return (
      <Row gutter={24}>
        <Col span={12}>
          <h2>{t("authapi_table_right_title", {count: apiSelectedRowKeys.length})}</h2>
        </Col>
        <Col span={12} style={{textAlign: "right"}}>
          <XaConfirmButton
            contents={
              apiSelectedRowKeys.length === 0
                ? t("authapi_confirm_save_zero")
                : t("authapi_confirm_save_length")
            }
            onConfirm={handleSaveApi}
            btnName={t("authapi_button_save_selected_api")}
            size="small"
          />
        </Col>
      </Row>
    );
  };

  const renderTblApiFooter = () => {};

  const handleChangeAuthMng = async (key, record) => {
    setApiSelectedRowKeys([]);
    setSelAuth_mng_sq(key);

    const selAuthSystems = authApiList.current.find((item) => item.auth_mng_sq === key);

    let _set =
      selAuthSystems && selAuthSystems.c_system_type && selAuthSystems.c_system_type.length > 0
        ? selAuthSystems.c_system_type
        : [];

    setSelectedRowKeys(_set);

    const {data} = await axios.get(`/authapisOwn`, {params: {auth_mng_sq: key}});

    const _data = data.map((item) => ({...item, key: item.api_mng_sq}));

    setApiList(_data);
    const selApiKeys = data.filter((item) => item.gbn != null).map((item) => item.api_mng_sq);

    setApiSelectedRowKeys(selApiKeys);
  };

  useEffect(() => {
    InitForm();
    return () => {};
  }, []);

  /* Renderer */
  return (
    <CodeDispatch.Provider value={{gState, setGState}}>
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
            }}
          >
            <Button type="primary" htmlType="submit" onClick={InitForm}>
              {t("button_search")}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row gutter={24}>
        <Col span={8} key={1}>
          <Form form={form3} component={false} size="small" initialValues={{auth_mng_sq: null}}>
            <Form.Item name="auth_mng_sq" label={t("authapi_auth_mng")}>
              <Select onChange={handleChangeAuthMng}>
                <Option>{t("selector_select")}</Option>
                {authMngList.map((item) => (
                  <Option key={item.auth_mng_sq} value={item.auth_mng_sq}>
                    {`[${item.f_auth_nm}]-${item.f_description}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <TableRender
              dataSource={systemTypeList}
              size="small"
              title={handleTblSTMTitle}
              columns={colStmCode}
              rowSelection={rowSelection}
              pagination={false}
              scroll={{y: "500px"}}
            />
          </Form>
        </Col>
        <Col span={16} key={2}>
          <Form form={form2} component={false} size="small">
            <TableRender
              dataSource={apiList}
              size="small"
              title={handleTblApiTitle}
              footer={renderTblApiFooter}
              columns={colApi}
              rowSelection={tblApirowSelection}
              pagination={false}
              scroll={{y: "550px"}}
            />
          </Form>
        </Col>
      </Row>
    </CodeDispatch.Provider>
  );
};

export default AuthApi;
