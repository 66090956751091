import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Select, Button, Popconfirm} from "antd";
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import "./index.css";
import {xaMessage, errorHandler} from "../../common";

const {Option} = Select;

// 버튼(Popconfirm + Button) 요소 정의
const PcfReturnDeviceJob = (props) => {
  /** i18next */
  const {t} = useTranslation();
  const {title, onConfirm, disabled, btnName} = props;

  return (
    <Popconfirm
      disabled={disabled}
      title={title}
      onConfirm={onConfirm}
      okText={t("button_ok")}
      cancelText={t("button_no")}
      placement="bottomRight"
    >
      <Button type="primary" className="button-margin-left" disabled={disabled}>
        {btnName}
      </Button>
    </Popconfirm>
  );
};

/** Device Return Main*/
const DeviceAssignInfo = (props) => {
  const {onClickCancel} = props;
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [projectList, setProjectList] = useState([]);
  const axios = Axios.create();

  /** i18next */
  const {t} = useTranslation();

  // Assign Info 조회(Redis)
  const getProjectInfo = async () => {
    const {data} = await axios.get("/projects");
    setProjectList(data);
  };

  const deleteDevices = async (projectId) => {
    await axios
      .delete("/deleteAssetsByProject", {
        params: {
          project_id: parseInt(projectId),
        },
      })
      .then((res) => {
        if (res.data && res.status === 200) {
          xaMessage("success", t("message_delete_success"));
        } else if (res.status === 500) {
          xaMessage("warn", t("message_error_occured"));
        }
      })
      .catch((error) => {
        errorHandler(t("message_error_beginning"), error);
      });
  };

  const handleSelectProject = (e) => {
    setSelectedProjectId(e.toString());
  };

  const handleReturnAllDevices = async (projectId) => {
    if (!projectId) {
      xaMessage("warn", t("message_warn_select_project"));
      return;
    } else {
      await deleteDevices(projectId);
    }
  };

  useEffect(() => {
    getProjectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Select
            className="device-return-select"
            showSearch
            placeholder={t("device_return_select_placeholder")}
            onChange={handleSelectProject}
          >
            {projectList.map((item) => (
              <Option
                title={item.f_proj_nm}
                key={item.project_mng_sq}
                id={item.project_mng_sq}
                value={item.project_mng_sq}
              >
                ({item.project_mng_sq}) {item.f_proj_nm}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} className="assign-modal-button">
          <Button onClick={onClickCancel}>{t("button_close")}</Button>
          <PcfReturnDeviceJob
            title={t("device_return_confirm")}
            onConfirm={async () => handleReturnAllDevices(selectedProjectId)}
            disabled={!selectedProjectId}
            btnName={t("button_return")}
          />
        </Col>
      </Row>
    </>
  );
};

/* Exports */
export default DeviceAssignInfo;
