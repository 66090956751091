import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Button, Select, Modal} from "antd";
import "antd/dist/antd.css";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {saveAs} from "file-saver";
import {sortBy} from "lodash";
import WMSViewerOnCesium from "./WMSViewerOnCesium";
import PotreeViewer from "../../component/PotreeViewer";
import TargetViewer from "./TargetViewer";
import "./index.css";
import {convertDateString, convertDatetimeString} from "../../utils/stringHelper";
import {
  TableRender,
  xaMessage,
  errorHandler,
  XaConfirmButton,
  XaConfirmTooltipButton,
} from "../../common";
import JobDetail from "./JobDetail";

const {Option} = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      state = {...state, companyList: action.payload.slice()};
      break;
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    case "SET_RUNFLAG":
      state = {...state, runFlagList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const JobDispatch = React.createContext(null);

const BtnLayerDataLoad = (props) => {
  const {name, onClickFunc, record} = props;
  return (
    <Button type="link" onClick={() => onClickFunc(record)} size="small">
      {name}
    </Button>
  );
};

/* Main Component */
const Jobs = (props) => {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [jobModalvisible, setJobModalvisible] = useState(false);
  const [threeModalvisible, setThreeModalvisible] = useState(false);
  const [targetModalvisible, setTargetModalvisible] = useState(false);
  const [wmsModalvisible, setWMSModalvisible] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [jobSq, setJobSq] = useState(null);

  /** i18next */
  const {t} = useTranslation();

  const [gState, setGState] = useReducer(reducer, {
    companyList: [],
    projectList: [],
    runFlagList: [
      {key: 0, text: t("jobs_status_ready"), order: 3},
      {key: 1, text: t("jobs_status_error1"), order: 1},
      {key: 2, text: t("jobs_status_error2"), order: 2},
      {key: 3, text: t("jobs_status_complete"), order: 0},
    ],
  });
  const {projectList, runFlagList} = gState;
  const [selDataset, setSelDataset] = useState(null);
  const [selDatasetTarget, setSelDatasetTarget] = useState({
    jobSq: null,
    project_mng_sq_t_project_mng: null,
    rock_date: null,
    target_input_date: null,
    target_data_sq: null,
  });
  const [form] = Form.useForm();

  // 프로젝트 작업 테이블 열 정의
  const columns = [
    {
      title: t("jobs_status_table_column_job_seq"),
      dataIndex: "job_sq",
      key: "tblUser00",
      render: (_, record) => {
        return (
          <>
            <Button type="link" onClick={() => handleJobDetail(record.job_sq)} size="small">
              {record.job_sq}
            </Button>
          </>
        );
      },
    },
    {
      title: t("jobs_status_table_column_project_seq"),
      width: "3vw",
      dataIndex: "project_mng_sq_t_project_mng",
      key: "tblUser01",
    },
    {
      title: t("jobs_status_table_column_project_name"),
      dataIndex: "f_proj_nm",
      key: "tblUser02",
    },
    {
      title: t("jobs_status_table_column_input"),
      width: "6vw",
      key: "tblUser03",
      render: (record) => convertDateString(record.f_input_date1),
    },
    {
      title: t("jobs_status_table_column_status"),
      width: "3vw",
      key: "tblUser04",
      render: (record) => runFlagList.find((item) => item.key === record.run_flag).text,
    },
    {
      title: t("jobs_status_table_column_update"),
      key: "tblUser05",
      render: (record) => convertDatetimeString(record.update_at),
    },
    {
      title: t("jobs_status_table_column_last"),
      key: "tblUser06",
      render: (record) => convertDatetimeString(record.last_complete_at),
    },
    {
      title: t("jobs_status_table_column_operator"),
      width: "5.5vw",
      key: "tblUser07",
      render: (record) => `${record.f_user_nm} (${record.user_mng_sq})`,
    },
    {
      title: t("jobs_status_table_column_topo"),
      width: "7vw",
      key: "tblUser08",
      render: (record) => {
        return (
          <>
            <span>
              <BtnLayerDataLoad name="PCD" onClickFunc={handleOpenPCDPop} record={record} />
              <BtnLayerDataLoad name="WMS" onClickFunc={handleOpenWMSPop} record={record} />
            </span>
          </>
        );
      },
    },
    {
      title: t("jobs_status_table_column_ply"),
      width: "3vw",
      key: "tblUser_9",
      render: (record) => (
        <>
          <XaConfirmButton
            contents={t("down_ply_confirm")}
            onConfirm={() => onConfirmPlydownload(record)}
            btnName="PLY"
            btnType="link"
            okText={t("button_down")}
            cancelText={t("button_cancel")}
            size="small"
          />
        </>
      ),
    },
    {
      title: t("jobs_status_table_column_rock"),
      width: "7.5vw",
      key: "tblUser_10",
      render: (record) => `${convertDateString(record.rock_date)} (${record.rock_count})`,
    },
    {
      title: t("jobs_status_table_column_target"),
      key: "tblUser_11",
      render: (record) => {
        return record.is_exist_target ? (
          <>
            <Button type="link" onClick={() => handleOpenTargetPop(record)} size="small">
              {`[${record.topo_data_sq}] ${record.topo_layer_name}`}
            </Button>
          </>
        ) : (
          <div className="column-align-center">-</div>
        );
      },
    },
    {
      title: t("jobs_status_table_column_remove"),
      key: "tblUser_12",
      render: (_, record) => (
        <>
          <XaConfirmTooltipButton
            buttonClassName={"table-delete-button"}
            toolTipContent={t("jobs_status_table_column_remove_explain_tooltip", {
              project_id: record.project_mng_sq_t_project_mng,
              project_name: record.f_proj_nm,
            })}
            toolTipPlacement="bottomRight"
            zIndexTooltip={record.is_latest_date ? 1000 : -1}
            btnName={t("button_delete")}
            btnType="primary"
            size="small"
            popPlacement="topRight"
            popContent={t("jobs_status_table_column_check_remove_tooltip", {
              project_id: record.project_mng_sq_t_project_mng,
              project_name: record.f_proj_nm,
              input_date: convertDateString(record.f_input_date1),
            })}
            disabled={!record.is_latest_date}
            onConfirm={() => onClickRemoveAllData(record)}
          />
        </>
      ),
    },
  ];

  // Topo(지형모델) Layer열 PCD 클릭 (PotreeViewer)
  const handleOpenPCDPop = (record) => {
    setThreeModalvisible(true);
    setSelDataset(record);
  };

  // Topo(지형모델) Layer열 WMS 클릭 (WMSViewerOnCesium)
  const handleOpenWMSPop = (record) => {
    setWMSModalvisible(true);
    setSelDataset(record);
  };

  // PLY 클릭 (해당 프로젝트 일자의 PLY 파일 전부를 zip 형식으로 압축하여 다운로드)
  const onConfirmPlydownload = async (record) => {
    if (!sessionStorage.userInfo) {
      return;
    } else {
      const downloadFileName = `plys_${record.project_mng_sq_t_project_mng}_${record.f_input_date1}.zip`;
      const url = "/downloads/plyDown";
      const params = {
        params: {
          project_mng_sq: record.project_mng_sq_t_project_mng,
          input_date: record.f_input_date1,
        },
        responseType: "blob",
      };
      setIsShowLoading(true);

      await Axios.get(url, params)
        .then((response) => {
          saveAs(response.data, downloadFileName);
          setIsShowLoading(false);
          xaMessage("success", `${t("message_download_complete")}: ${downloadFileName}`);
        })
        .catch((e) => {
          setIsShowLoading(false);
          if (e.toString().includes("status code 404")) {
            errorHandler(t("message_error_beginning"), t("message_warn_file_not_found"));
          } else {
            errorHandler(t("message_error_beginning"), e.toString());
          }
        });
    }
  };

  // "삭제" 버튼 클릭
  const onClickRemoveAllData = (record) => {
    Modal.confirm({
      title: t("jobs_status_table_column_check_remove_again_title"),
      icon: <ExclamationCircleOutlined />,
      content: t("jobs_status_table_column_check_remove_again", {
        project_id: record.project_mng_sq_t_project_mng,
        project_name: record.f_proj_nm,
        input_date: convertDateString(record.f_input_date1),
      }),
      okText: t("button_ok"),
      cancelText: t("button_cancel"),
      onOk: () => handleRemoveAllData(record.job_sq),
    });
  };

  // 삭제 버튼 클릭
  const handleRemoveAllData = async (jobSq) => {
    try {
      const result = await Axios.delete(`/jobs/remove/${jobSq}`);

      if (result.status === 200) {
        result.data.message === "success"
          ? xaMessage("success", t("message_delete_success"))
          : result.data.message === "targetless"
          ? xaMessage("warn", t("message_delete_fail_targetless"))
          : xaMessage("warn", t("message_delete_fail"));
      } else if (result.status === 403) {
        errorHandler(t("message_error_beginning"), t("message_error_no_permission"));
        return;
      }
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const handleOpenTargetPop = (record) => {
    setTargetModalvisible(true);
    setSelDatasetTarget({...record});
  };

  // Action열 클릭 (Job Detail)
  const handleJobDetail = async (jobSq) => {
    setJobModalvisible(true);
    setJobSq(jobSq);
  };

  const handleInitForm = async () => {
    try {
      {
        const {data} = await Axios.get("/project");
        setGState({type: "SET_PROJECT", payload: data});
      }
      {
        const {data} = await Axios.get("/code", {params: {code1: "USR"}});
        setGState({type: "SET_USERTYPE", payload: data});
      }
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const handleSearch = async (paramValue) => {
    setIsShowLoading(true);
    try {
      const {data} = await Axios.get("/jobs/list", {params: paramValue});
      setJobList(data);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
    setIsShowLoading(false);
  };

  const handleChangeProject = (target) => {};

  const onFinish = (paramValue) => {
    handleSearch(paramValue);
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    setJobModalvisible(false);
    handleInitForm();
    handleSearch();
  };

  const threehandleOk = () => {};

  const threehandleCancel = () => {
    setThreeModalvisible(false);
  };

  const targethandleOk = () => {};

  const targethandleCancel = () => {
    setTargetModalvisible(false);
  };

  const wmshandleOk = () => {};

  const wmshandleCancel = () => {
    setWMSModalvisible(false);
  };

  useEffect(() => {
    form.resetFields();
    handleInitForm();
    handleSearch();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <JobDispatch.Provider value={{gState, setGState}}>
      <Form
        form={form}
        name="advanced_search"
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        className="common-search-area-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{company_mng_sq: null, f_project_mng_sq: null, run_flag: null}}
      >
        <Row gutter={24}>
          <Col span={8} key={1}>
            <Form.Item
              name={`f_project_mng_sq`}
              label={t("general_project")}
              rules={[
                {
                  required: false,
                  message: "All",
                },
              ]}
            >
              <Select onChange={handleChangeProject} showSearch optionFilterProp="children">
                <Option>{t("selector_all")}</Option>
                {projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    ({project.project_mng_sq}) {project.f_proj_nm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} key={11}>
            <Form.Item
              name="run_flag"
              label={t("jobs_status")}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select>
                <Option>{t("selector_all")}</Option>
                {sortBy(runFlagList, "order").map((item) => (
                  <Option key={item.key} value={item.key}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
            }}
          >
            <Button type="primary" htmlType="submit" size="middle">
              {t("button_search")}
            </Button>
            <Button size="middle" style={{margin: "0 8px"}} onClick={() => form.resetFields()}>
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </Form>

      {/* 프로젝트 작업 목록 */}
      <TableRender
        columns={columns}
        dataSource={jobList}
        size="small"
        rowKey={"job_sq"}
        isLoading={isShowLoading}
      />

      <Modal
        title={t("jobs_detail_title", {seq: jobSq})}
        open={jobModalvisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={750}
        getContainer={false}
        maskClosable={false}
      >
        <JobDetail onCancel={handleCancel} jobSq={jobSq}></JobDetail>
      </Modal>

      <Modal
        title={t("jobs_pcd_detail_potreeViewer_title")}
        open={threeModalvisible}
        onOk={threehandleOk}
        onCancel={threehandleCancel}
        footer={null}
        width={1000}
        getContainer={false}
        maskClosable={false}
      >
        <PotreeViewer
          style={{width: "600px", height: "400px"}}
          selDataset={selDataset}
          onOk={threehandleOk}
        />
      </Modal>

      <Modal
        title={t("jobs_pcd_detail_wmsViewer_title")}
        open={wmsModalvisible}
        onOk={wmshandleOk}
        onCancel={wmshandleCancel}
        footer={null}
        width={1000}
        getContainer={false}
        maskClosable={false}
      >
        <WMSViewerOnCesium
          style={{width: "600", height: "600"}}
          selDataset={selDataset}
          onOk={wmshandleOk}
        />
      </Modal>

      <Modal
        title={t("jobs_target_targetViewer_title")}
        open={targetModalvisible}
        onOk={targethandleOk}
        onCancel={targethandleCancel}
        footer={null}
        width={1048}
        height={700}
        getContainer={false}
        maskClosable={false}
      >
        <TargetViewer jobSq={jobSq} state={selDatasetTarget} />
      </Modal>
    </JobDispatch.Provider>
  );
};

export default Jobs;
