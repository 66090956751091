import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Button, Modal, Input, Radio, Space, Select} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import {useInterval} from "../../utils/useInterval";
import {TableRender, XaTooltipButton, xaMessage, XaConfirmButton, errorHandler} from "../../common";
import {socketAdmin, SOCKET_IO_LOG} from "../../lib/socketUtil";
import {convertDatetimeString} from "../../utils/stringHelper";
import LogJobDetails from "./LogJobDetails";
import DbActivity from "./DbActivity";
import {LOG_JOB_RELOAD_INTERVAL_SECONDS} from "../../config/miscellaneousInfo";

const {Option} = Select;

// 프로젝트 셀렉트 박스
const SlctProject = (props) => {
  const {t} = useTranslation();
  const {nameSpace, onChange, value, selectList} = props;
  return (
    <>
      <Row>{nameSpace}:</Row>
      <Row>
        <Select
          className="select-project"
          onChange={onChange}
          value={value}
          showSearch
          optionFilterProp="children"
        >
          <Option>{t("selector_select")}</Option>
          {selectList.map((el) => (
            <Option key={el.project_mng_sq} value={el.project_mng_sq}>
              [{el.project_mng_sq}] {el.f_proj_nm}
            </Option>
          ))}
        </Select>
      </Row>
    </>
  );
};

// 선택(Select) 요소 정의
const SlctLogJob = (props) => {
  const {t} = useTranslation();
  const {nameSpace, onChange, value, selectList} = props;
  return (
    <>
      <Row>{nameSpace}:</Row>
      <Row>
        <Select className="select-general" onChange={onChange} value={value}>
          <Option>{t("selector_select")}</Option>
          {selectList.map((el) => (
            <Option key={el.label} value={el.value}>
              {el.label}
              {/* {nameSpace === "Job Type" ? `(${el.koreanName})` : ``} */}
            </Option>
          ))}
        </Select>
      </Row>
    </>
  );
};

export const LogJobDispatch = React.createContext(null);

export const convertPidToText = (id) =>
  id === undefined || id === null || id === "" || id === "-" ? "-" : id;

export const convertLogString = (messageString) => {
  let infoStr = "";
  if (messageString.includes("진행률:")) {
    // 기성(Section xcad): '진행률' 포함 문자열
    let seqDiv = messageString.split("v_seqGbn: ")[1].substr(0, 2); //  기성: 자료구분(설계-현재-이전) 문자열
    let perDigit = parseFloat(messageString.split("진행률: ")[1]);
    infoStr = perDigit ? `PER_${seqDiv}_${perDigit.toString()}` : "";
  }
  return infoStr;
};

/* Main Component */
const LogJob = () => {
  /** Socket Client 관련 정의 */
  const connSocket = socketAdmin(SOCKET_IO_LOG);

  /**
   * 작업 데이터(현재 PID 및 Job 정보) 갱신 간격
   *
   * 20240117: 기존 30초에서 1분으로 간격 조정
   * 20240208: 간격 관리 파일에서 Import로 변경
   */
  const reloadInterval = LOG_JOB_RELOAD_INTERVAL_SECONDS;

  const [dbActivityModalVisible, setDbActivityModalVisible] = useState(false);
  const [logJobModalvisible, setLogJobModalvisible] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [projectInfo, setProjectInfo] = useState([]);
  const [logJobList, setLogJobList] = useState([]); // Job 목록
  const [jobIdJobDetail, setJobIdJobDetail] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState(null);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [searchJobId, setSearchJobId] = useState(null);
  const [logStrings, setLogStrings] = useState([]); // 실시간 로그 문자열 저장 배열
  const [currentPids, setCurrentPids] = useState([]);
  const [progressInfo, setProgressInfo] = useState({job_id: "", progress_info: ""});
  const [radioOptionValue, setRadioOptionValue] = useState(0);

  // i18next
  const {t} = useTranslation();

  // 작업 상태
  const jobStatusList = [
    {label: t("logjob_status_error"), value: 0},
    {label: t("logjob_status_processing"), value: 2},
    {label: t("logjob_status_complete"), value: 1},
  ];

  // 작업 유형
  const jobTypeList = [
    {label: "ADD_NOTICE", value: 0},
    {label: "System_Notice", value: 1},
    {label: "makeDxf", value: 2},
    {label: "makeWMSforDXF", value: 3},
    {label: "EXEC_ANALYSIS", value: 4},
    {label: "SECTION_ANALYSIS", value: 5},
    {label: "makeDxf_Xcad", value: 6},
    {label: "makeGLTFfromPLY", value: 7},
    {label: "makeWMSforOveray", value: 8},
    {label: "SECTION_XCAD", value: 9},
    {label: "trimble", value: 10},
    {label: "makeWMSforTif", value: 11},
  ];

  // 정렬 옵션 라디오 버튼 요소
  const optionsRadioGroup = [
    {label: t("logjob_sort_option_job_id"), value: 0},
    {label: t("logjob_sort_option_created"), value: 1},
    {label: t("logjob_sort_option_end"), value: 2},
  ];

  /* 테이블 열 */
  const columns = [
    {
      title: t("logjob_table_column_job_id"),
      key: "tblLogJob01",
      dataIndex: "job_id",
      render: (job_id) => {
        return (
          <>
            <Space size="middle">
              <Button type="link" size="small" onClick={() => onClickJobDetailModal(job_id)}>
                {job_id}
              </Button>
            </Space>
          </>
        );
      },
    },
    {
      title: t("logjob_table_column_project_id"),
      key: "tblLogJob02",
      dataIndex: "project_id",
    },
    {
      title: t("logjob_table_column_project"),
      key: "tblLogJob03",
      dataIndex: "f_proj_nm",
      width: 350,
    },
    {
      title: t("logjob_table_column_job_type"),
      key: "tblLogJob04",
      dataIndex: "job_type",
    },
    {
      title: t("logjob_table_column_job_ppid"),
      key: "tblLogJob05",
      dataIndex: "ppid",
      render: (ppid) => convertPidToText(ppid),
    },
    {
      title: t("logjob_table_column_job_pid"),
      key: "tblLogJob06",
      dataIndex: "pid",
      render: (pid) => convertPidToText(pid),
    },
    {
      title: t("logjob_table_column_job_state"),
      key: "tblLogJob07",
      dataIndex: "job_status",
      render: (code, record, index) => {
        return {
          props: {
            style: {
              background:
                currentPids.includes(parseInt(record.pid)) && code === "0" ? "#00cfb1" : "",
            },
          },
          children: convertJobStateString(code, record),
        };
      },
    },
    {
      title: t("logjob_table_column_job_status"),
      key: "tblLogJob08",
      dataIndex: "f_description",
      render: (description, record, index) => convertStateString(description, record, index),
    },
    {
      title: t("logjob_table_column_created"),
      key: "tblLogJob09",
      dataIndex: "create_time",
      render: (dateTime) => convertDatetimeString(dateTime),
    },
    {
      title: t("logjob_table_column_end"),
      key: "tblLogJob10",
      dataIndex: "end_time",
      render: (dateTime) => convertDatetimeString(dateTime),
    },
    {
      title: t("logjob_table_column_retry"),
      key: "tblLogJob11",
      dataIndex: "retry_cnt",
    },
    {
      title: t("logjob_table_column_forced_kill"),
      key: "tblLogJob12",
      dataIndex: "pid",
      render: (pid, record, index) => (
        <div style={{textAlign: "center"}}>
          <XaConfirmButton
            contents={t("logjob_confirm_forced_kill", {jobId: record.job_id, pid: pid})}
            onConfirm={() => onClickBtnKill(record.job_id, pid)}
            btnName={t("button_kill")}
            disabled={!currentPids.includes(parseInt(record.pid))}
            btnType="default"
            size="small"
          />
        </div>
      ),
    },
    {
      title: t("logjob_table_column_redo"),
      key: "tblLogJob13",
      dataIndex: "job_id",
      render: (job_id) => (
        <div style={{textAlign: "center"}}>
          <XaConfirmButton
            contents={t("logjob_confirm_redo", {jobId: job_id})}
            onConfirm={() => onClickBtnExecute(job_id)}
            btnName={t("button_redo_logjob")}
            btnType="primary"
            size="small"
          />
        </div>
      ),
    },
  ];

  const convertJobStateString = (code, record) => {
    const progressString = t("logjob_status_progress");
    if (record.job_type === "makeWMSforTif" && code === "0") {
      if (record.f_description === null) {
        return `${progressString}(1/3)`;
      } else if (record.f_description.includes("BT")) {
        return `${progressString}(2/3)`;
      } else if (record.f_description.includes("OT")) {
        return `${progressString}(3/3)`;
      }
    } else if (
      code === "0" &&
      record.f_description &&
      record.f_description.indexOf("exec error") > -1
    ) {
      return `${progressString}(1/3)`;
    } else {
      return code === "-1"
        ? t("logjob_status_error")
        : code === "0"
        ? progressString
        : code === "1"
        ? t("logjob_status_complete")
        : "";
    }
  };

  // 스크롤 아래로 이동
  const moveScrollToReceiveMessage = () => {
    let objDiv2 = document.getElementById("log-area");
    objDiv2.scrollTop = objDiv2.scrollHeight;
  };

  const onChangeProject = (value) => {
    setSelectedProject(value);
  };

  const onChangeJobStatus = (value) => {
    setSelectedJobStatus(value);
  };

  const onChangeJobType = (value) => {
    setSelectedJobType(value);
  };

  const onChangeJobId = (e) => {
    setSearchJobId(e.target.value);
  };

  const reconstructData = (data) => {
    return data.pgJobInfo.map((i) => {
      let searchProjectInfo = payloadToProjectInfo(
        data.projectInfo,
        data.commitInfo,
        data.jobInfo,
        i.job_type,
        i.payload
      );
      return {
        job_id: i.job_id,
        project_id: searchProjectInfo.id,
        f_proj_nm: searchProjectInfo.name,
        job_type: i.job_type,
        ppid: findString(i.payload, "ppid"),
        pid: findString(i.payload, "pid"),
        job_status: i.job_status,
        f_description: i.f_description,
        create_time: i.create_time,
        end_time: i.end_time,
        retry_cnt: i.retry_cnt,
      };
    });
  };

  const filteringBySelectedProject = (resultData) => {
    return selectedProject === 0 || selectedProject === "0"
      ? resultData.filter((i) => i.project_id === "0" || parseInt(i.project_id) === 0)
      : selectedProject
      ? resultData.filter((i) => parseInt(i.project_id) === selectedProject)
      : resultData;
  };

  const findString = (string, target) => {
    const splitedStringArray = string.split(",");
    const searchString = splitedStringArray.find((x) => x.includes(target));

    return searchString
      ? searchString.split(target).pop().split(":").pop().replace(/[{}]/g, "").trim()
      : "-";
  };

  const payloadToProjectInfo = (projectsInfo, commitInfo, jobInfo, jobType, payload) => {
    let projectId, projectName;
    const cat1Arr = [
      "ADD_NOTICE",
      "SECTION_ANALYSIS",
      "makeDxf_Xcad",
      "makeDxf",
      "makeWMSforOveray",
      "makeWMSforDXF",
      "trimble",
    ];
    const cat2Arr = ["EXEC_ANALYSIS", "makeGLTFfromPLY", "System_Notice"];

    if (!payload || payload === null) {
      projectId = "";
      projectName = "";
    } else {
      if (cat1Arr.includes(jobType)) {
        projectId = findString(payload, "project_mng_sq");
      } else if (cat2Arr.includes(jobType)) {
        projectId = findString(payload, "project_mng_sq_t_project_mng");
      } else if (jobType === "SECTION_XCAD") {
        projectId = commitInfo.find(
          (x) => x.seq === findString(payload, "t_section_commit_info_seq")
        )?.project_mng_sq_t_project_mng;
      } else if (jobType === "makeWMSforTif") {
        projectId = jobInfo.find(
          (x) =>
            x.job_sq === findString(payload, "job_sq") ||
            x.job_sq === parseInt(findString(payload, "job_sq"))
        )?.project_mng_sq_t_project_mng;
      } else {
        projectId = "-";
      }

      projectName =
        projectId !== "-"
          ? projectsInfo.find((x) => x.project_mng_sq === parseInt(projectId))?.f_proj_nm || "-"
          : "-";
    }

    return {
      id: projectId,
      name: projectName,
    };
  };

  // 진행상황 문자열 재구성
  const convertStateString = (description, record, index) => {
    const specJobArr = ["SECTION_XCAD", "makeWMSforDXF", "makeWMSforTif"];
    const completeString = t("logjob_status_complete");
    if (!description) {
      return "-";
    } else if (description === "Complete") {
      if (record.job_status === "1") {
        return completeString;
      } else if (record.job_status === "0") {
        return `${completeString}(Check)`;
      }
    } else if (record.job_status === "0" && specJobArr.includes(record.job_type)) {
      const descriptionInfo = (
        record.job_type === "SECTION_XCAD"
          ? progressInfo.job_id === record.job_id
            ? progressInfo?.progress_info
            : description || ""
          : description || ""
      ).split("_");

      if (descriptionInfo.length === 3) {
        if (record.job_type === "SECTION_XCAD") {
          return `(${descriptionInfo[1]}) ${descriptionInfo[2]}%`;
        } else if (record.job_type === "makeWMSforTif" || record.job_type === "makeWMSforDXF") {
          return `(${descriptionInfo[2]}) ${descriptionInfo[1]}%`;
        }
      } else if (descriptionInfo.length === 2) {
        if (descriptionInfo.filter((i) => i.indexOf("(exec error)")).length > -1) {
          return "-";
        } else {
          return `${descriptionInfo[1]}%`;
        }
      }
    } else if (record.job_status === "" && record.f_description.includes("ALRT")) {
      const stateString = record.f_description.split("_")[1];
      if (stateString === "process") {
        return t("logjob_status_abnormal_parents");
      } else if (stateString === "child") {
        return t("logjob_status_abnormal_child");
      }
    } else {
      return record.job_status === "1" ? completeString : "-";
    }

    return "-";
  };

  // Clear 버튼 클릭
  const onClickClear = () => {
    setSelectedProject(null);
    setSelectedJobStatus(null);
    setSelectedJobType(null);
  };

  // Job 내역 조회
  const handleSearch = async () => {
    setIsShowLoading(true);

    try {
      const {data} = await Axios.get("/logjob/list", {
        params: {
          jobType: selectedJobType,
          jobStatus: selectedJobStatus,
          jobId: searchJobId,
          order: parseInt(radioOptionValue === null ? 0 : radioOptionValue),
        },
      });
      const redefinedData = reconstructData(data);

      setProjectInfo(data.projectInfo);
      setLogJobList(filteringBySelectedProject(redefinedData));

      setIsShowLoading(false);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
      setIsShowLoading(false);
    }
  };

  // Session Mng 버튼 클릭
  const onClickSessionMng = async () => {
    setDbActivityModalVisible(true);
  };

  // Service Broker PID 조회
  const queryPids = async () => {
    try {
      Axios.put("/checkJobPids");
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // 강제종료 호출
  const killJob = async (jobId, pid) => {
    try {
      await Axios.put(`/logjob/kill/${jobId}`, {
        params: {
          pid: pid,
        },
      });
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // 재실행 호출
  const executeRedo = async (jobId) => {
    try {
      await Axios.put(`/logjob/${jobId}`);
      xaMessage("info", t("logjob_message_execute_redo_success"));
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // 강제종료
  const onClickBtnKill = (jobId, pid) => {
    killJob(jobId, pid);
  };

  // 재실행
  const onClickBtnExecute = (jobId) => {
    executeRedo(jobId);
  };

  // Job 상세정보 Modal 열기
  const onClickJobDetailModal = (jobId) => {
    setJobIdJobDetail(jobId);
    setLogJobModalvisible(true);
  };

  const handleRadioOption = (obj) => {
    setRadioOptionValue(obj.target.value);
    // handleSearch();
  };

  const handleDbActivityModalCancel = () => {
    setDbActivityModalVisible(false);
  };

  const handleOk = () => {};

  const handleJobDetailModalCancel = () => {
    setLogJobModalvisible(false);
    setJobIdJobDetail(null);
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect: 실시간 로그 문자열 갱신 시 스크롤 다운
  useEffect(() => {
    if (logStrings.length > 200) {
      setLogStrings([]);
    }
    moveScrollToReceiveMessage();
  }, [logStrings]);

  // Effect: Log Socket 연결 및 로그/PID 수신부
  useEffect(() => {
    connSocket.on("emitLogs", (msg) => {
      if (!msg.cat) {
        return;
      } else {
        if (parseInt(msg.cat) === 2) {
          setCurrentPids(msg.msg);
        } else {
          setProgressInfo({
            job_id: msg.jobId,
            progress_info: convertLogString(msg.msg),
          });
          setLogStrings((logStrings) => [...logStrings, msg.msg]);
          if (msg.msg.isClose === 1) {
            handleSearch();
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 일정 간격 실행을 위한 useInterval
   * reloadInterval: 갱신 간격 변수
   */
  useInterval(() => {
    queryPids();
    handleSearch();
  }, reloadInterval);

  /* Renderer */
  return (
    <LogJobDispatch.Provider>
      {/* Service Broker(Ch) 로그 표시 영역 */}
      <Row className="common-search-area-form">
        <div className="sub-area">
          <label className="log-area-title">Service Broker Log</label>
          <div id="log-area" className="log-output-area">
            {logStrings.map((msg, index) => {
              return <p style={{margin: 0}}>{msg}</p>;
            })}
          </div>
        </div>
      </Row>

      {/* 조회 조건 영역 */}
      <div className="search-area common-search-area-form">
        <Row className="search-area-row-1">
          <Col span={8} key={0} className="search-area-column">
            <SlctProject
              nameSpace={t("general_project")}
              onChange={onChangeProject}
              value={selectedProject}
              selectList={projectInfo}
            />
          </Col>
          <Col span={8} key={1} className="search-area-column">
            <SlctLogJob
              nameSpace={t("logjob_job_state")}
              onChange={onChangeJobStatus}
              value={selectedJobStatus}
              selectList={jobStatusList}
            />
          </Col>
          <Col span={8} key={2} className="search-area-column">
            <SlctLogJob
              nameSpace={t("logjob_job_type")}
              onChange={onChangeJobType}
              value={selectedJobType}
              selectList={jobTypeList}
            />
          </Col>
        </Row>
        <Row className="search-area-row-2 ">
          <Col span={8} className="search-area-column">
            <Row>
              <div className="ant-form-item-label search-area-row-2-col-1">
                {t("logjob_job_id")}:
              </div>
            </Row>
            <Row>
              <Input
                className="search-job-id"
                onChange={onChangeJobId}
                onPressEnter={handleSearch}
              />
            </Row>
          </Col>
          <Col span={8} className="search-area-column">
            <Row>
              <div className="ant-form-item-label search-area-row-2-col-1">{t("logjob_order")}</div>
            </Row>
            <Row>
              <Radio.Group
                className="col-radio-buttton-right"
                onChange={handleRadioOption}
                value={radioOptionValue}
              >
                {optionsRadioGroup.map((el) => (
                  <Radio value={el.value}>{el.label}</Radio>
                ))}
              </Radio.Group>
            </Row>
          </Col>
          <Col span={8} className="search-button-area">
            {/* DB Session 내역 및 관리 Modal 버튼 */}
            {/* <XaTooltipButton
              title={t("explain_button_db_session")}
              placement="bottom"
              btnName={t("button_session_mng")}
              btnType="dashed"
              onClick={onClickSessionMng}
            /> */}
            <Button type="primary" onClick={handleSearch}>
              {t("button_search")}
            </Button>
            <Button className="button-margin-left" onClick={onClickClear}>
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </div>

      {/* Job 목록 영역 */}
      <TableRender
        columns={columns}
        dataSource={logJobList}
        isLoading={isShowLoading}
        size="small"
      />

      {/* DB Activity List Modal */}
      <Modal
        bodyStyle={{height: 600}}
        title={t("logjob_db_activity_list")}
        open={dbActivityModalVisible}
        getContainer={false}
        onOk={handleOk}
        onCancel={handleDbActivityModalCancel}
        centered={true}
        width={1000}
        maskClosable={false}
        closable={true}
        keyboard={true}
        footer={null}
      >
        <DbActivity onCancel={handleDbActivityModalCancel} />
      </Modal>
      {/* Job 상세 Modal */}
      <Modal
        title={t("logjob_details_title", {id: jobIdJobDetail})}
        open={logJobModalvisible}
        getContainer={false}
        onOk={handleOk}
        onCancel={handleJobDetailModalCancel}
        footer={null}
        width={600}
        centered={true}
        maskClosable={false}
        closable={true}
        keyboard={true}
      >
        <LogJobDetails
          onCancel={handleJobDetailModalCancel}
          jobTypeList={jobTypeList}
          jobId={jobIdJobDetail}
        />
      </Modal>
    </LogJobDispatch.Provider>
  );
};

export default LogJob;
