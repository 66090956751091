import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";

//공유관련해서 window 객체를 써야함 아니면 threejs 에서 오류 발생 [2020.06.26 전종수]
//const THREE = window.THREE;

var THREEx = { Math: {} };

/**
 * Returns the angle in radians of the vector (p1,p2). In other words, imagine
 * putting the base of the vector at coordinates (0,0) and finding the angle
 * from vector (1,0) to (p1,p2).
 * @param  {Object} p1 start point of the vector
 * @param  {Object} p2 end point of the vector
 * @return {Number} the angle
 */
THREEx.Math.angle2 = function (p1, p2) {
  var v1 = new THREE.Vector2(p1.x, p1.y);
  var v2 = new THREE.Vector2(p2.x, p2.y);
  v2.sub(v1); // sets v2 to be our chord
  v2.normalize();
  if (v2.y < 0) return -Math.acos(v2.x);
  return Math.acos(v2.x);
};

THREEx.Math.polar = function (point, distance, angle) {
  var result = {};
  result.x = point.x + distance * Math.cos(angle);
  result.y = point.y + distance * Math.sin(angle);
  return result;
};

/**
 * Calculates points for a curve between two points
 * @param startPoint - the starting point of the curve
 * @param endPoint - the ending point of the curve
 * @param bulge - a value indicating how much to curve
 * @param segments - number of segments between the two given points
 */
THREEx.BulgeGeometry = function (startPoint, endPoint, bulge, segments) {
  var vertex, i, center, p0, p1, angle, radius, startAngle, thetaAngle;

  THREE.Geometry.call(this);

  this.startPoint = p0 = startPoint
    ? new THREE.Vector2(startPoint.x, startPoint.y)
    : new THREE.Vector2(0, 0);
  this.endPoint = p1 = endPoint
    ? new THREE.Vector2(endPoint.x, endPoint.y)
    : new THREE.Vector2(1, 0);
  this.bulge = bulge = bulge || 1;

  angle = 4 * Math.atan(bulge);
  radius = p0.distanceTo(p1) / 2 / Math.sin(angle / 2);
  center = THREEx.Math.polar(
    startPoint,
    radius,
    THREEx.Math.angle2(p0, p1) + (Math.PI / 2 - angle / 2)
  );

  this.segments = segments = segments || Math.max(Math.abs(Math.ceil(angle / (Math.PI / 18))), 6); // By default want a segment roughly every 10 degrees
  startAngle = THREEx.Math.angle2(center, p0);
  thetaAngle = angle / segments;

  this.vertices.push(new THREE.Vector3(p0.x, p0.y, 0));

  for (i = 1; i <= segments - 1; i++) {
    vertex = THREEx.Math.polar(center, Math.abs(radius), startAngle + thetaAngle * i);

    this.vertices.push(new THREE.Vector3(vertex.x, vertex.y, 0));
  }
};

THREEx.BulgeGeometry.prototype = Object.create(THREE.Geometry.prototype);

export class ConvertDXFToThree {
  constructor(data) {
    this.rtnGeometry = new THREE.Object3D();

    this.data = data;
    // this.arrBlock = Object.keys(this.data.blocks)
    this.layers = Object.keys(data.tables.layer.layers);

    this.maxLayers = 1000;
    this.maxEntities = 100000;

    this.entitiyGroup = {};
  }

  order() {
    for (let layers of this.layers) {
      this.entitiyGroup[layers] = [];
    }

    for (let entity of this.data.entities) {
      let _3dObj = this.drawEntity(entity, this.data);
      this.entitiyGroup[entity.layer].push(_3dObj);
    }
  }

  convert_org() {
    for (let entity of this.data.entities) {
      let _3dObj = this.drawEntity(entity, this.data);
      this.rtnGeometry.add(_3dObj);
    }
  }

  //from entities
  // convertAll() {
  convert(filtter) {
    if (Object.keys(this.layers).length > 0) {
      this.convertLayers(filtter);
    } else {
      if (this.data.entities && this.data.entities.length > 0) {
        this.convertAll();
      }
    }
  }

  //from blocks
  convertLayers(arrFiltterLayers) {
    for (let _layerName of this.layers.slice(0, this.maxLayers)) {
      if (arrFiltterLayers === undefined || arrFiltterLayers.length === 0) {
        this.convertLayer(_layerName);
      } else {
        if (arrFiltterLayers.filter((v) => v === _layerName).length > 0) {
          this.convertLayer(_layerName);
        }
      }
    }
  }

  //from entities
  convertLayer(layerName) {
    let _entities = this.data.entities.filter((v) => v.layer === layerName);

    let maxCount = this.maxEntities > _entities.length ? _entities.length : this.maxEntities;

    let _rtnGeometry = new THREE.Object3D();
    _rtnGeometry.name = layerName;

    for (let i = 0; i < maxCount; i++) {
      let entity = _entities[i];
      let _3dObj = this.drawEntity(entity, this.data);

      _rtnGeometry.add(_3dObj);
    }
    this.rtnGeometry.add(_rtnGeometry);
  }

  //from entities
  convertAll() {
    // let maxCount =
    //   this.maxEntities > this.data.entities.length ? this.data.entities.length : this.maxEntities;

    // console.log( ' DXF Convert (' + maxCount+'/' + this.data.entities.length+')');

    // for ( let i = 0; i < maxCount; i++ ) {
    for (let i = 0; i < this.data.entities.length; i++) {
      let entity = this.data.entities[i];
      let _3dObj = this.drawEntity(entity, this.data);
      this.rtnGeometry.add(_3dObj);
    }
  }

  drawEntity(entity, data) {
    var mesh;

    if (entity.type === "CIRCLE" || entity.type === "ARC") {
      mesh = this.drawArc(entity, data);
    } else if (
      entity.type === "LWPOLYLINE" ||
      entity.type === "LINE" ||
      entity.type === "POLYLINE"
    ) {
      mesh = this.drawLine(entity, data);
    } else if (entity.type === "TEXT") {
      //return new THREE.Object3D();
      mesh = this.drawText(entity, data);
    } else if (entity.type === "SOLID") {
      mesh = this.drawSolid(entity, data);
    } else if (entity.type === "3DFACE") {
      mesh = this.drawFace(entity, data);
    } else if (entity.type === "POINT") {
      mesh = this.drawPoint(entity, data);
    } else if (entity.type === "INSERT") {
      mesh = this.drawBlock(entity, data);
    } else if (entity.type === "SPLINE") {
      mesh = this.drawSpline(entity, data);
    } else if (entity.type === "MTEXT") {
      return new THREE.Object3D();
      //   mesh = this.drawMtext(entity, data);
    } else if (entity.type === "ELLIPSE") {
      mesh = this.drawEllipse(entity, data);
    } else if (entity.type === "DIMENSION") {
      var dimTypeEnum = entity.dimensionType & 7;
      if (dimTypeEnum === 0) {
        mesh = this.drawDimension(entity, data);
      } else {
        console.log("Unsupported Dimension type: " + dimTypeEnum);
      }
    } else {
      console.log("Unsupported Entity Type: " + entity.type);
    }

    // window.viewer.scene.scene.add(mesh);

    return mesh;
  }

  drawEllipse(entity, data) {
    var color = this.getColor(entity, data);

    var xrad = Math.sqrt(
      Math.pow(entity.majorAxisEndPoint.x, 2) + Math.pow(entity.majorAxisEndPoint.y, 2)
    );
    var yrad = xrad * entity.axisRatio;
    var rotation = Math.atan2(entity.majorAxisEndPoint.y, entity.majorAxisEndPoint.x);

    var curve = new THREE.EllipseCurve(
      entity.center.x,
      entity.center.y,
      xrad,
      yrad,
      entity.startAngle,
      entity.endAngle,
      false, // Always counterclockwise
      rotation
    );

    var points = curve.getPoints(50);
    var geometry = new THREE.BufferGeometry().setFromPoints(points);
    var material = new THREE.LineBasicMaterial({ linewidth: 1, color: color });

    // Create the final object to add to the scene
    var ellipse = new THREE.Line(geometry, material);
    return ellipse;
  }

  drawMtext(entity, data) {
    var color = this.getColor(entity, data);

    var geometry = new THREE.TextGeometry(entity.text, {
      font: this.font,
      size: entity.height * (4 / 5),
      height: 1,
    });
    var material = new THREE.MeshBasicMaterial({ color: color });
    var text = new THREE.Mesh(geometry, material);

    // Measure what we rendered.
    var measure = new THREE.Box3();
    measure.setFromObject(text);

    var textWidth = measure.max.x - measure.min.x;

    // If the text ends up being wider than the box, it's supposed
    // to be multiline. Doing that in threeJS is overkill.
    if (textWidth > entity.width) {
      console.log("Can't render this multipline MTEXT entity, sorry.", entity);
      return undefined;
    }

    text.position.z = 0;
    switch (entity.attachmentPoint) {
      case 1:
        // Top Left
        text.position.x = entity.position.x;
        text.position.y = entity.position.y - entity.height;
        break;
      case 2:
        // Top Center
        text.position.x = entity.position.x - textWidth / 2;
        text.position.y = entity.position.y - entity.height;
        break;
      case 3:
        // Top Right
        text.position.x = entity.position.x - textWidth;
        text.position.y = entity.position.y - entity.height;
        break;

      case 4:
        // Middle Left
        text.position.x = entity.position.x;
        text.position.y = entity.position.y - entity.height / 2;
        break;
      case 5:
        // Middle Center
        text.position.x = entity.position.x - textWidth / 2;
        text.position.y = entity.position.y - entity.height / 2;
        break;
      case 6:
        // Middle Right
        text.position.x = entity.position.x - textWidth;
        text.position.y = entity.position.y - entity.height / 2;
        break;

      case 7:
        // Bottom Left
        text.position.x = entity.position.x;
        text.position.y = entity.position.y;
        break;
      case 8:
        // Bottom Center
        text.position.x = entity.position.x - textWidth / 2;
        text.position.y = entity.position.y;
        break;
      case 9:
        // Bottom Right
        text.position.x = entity.position.x - textWidth;
        text.position.y = entity.position.y;
        break;

      default:
        return undefined;
    }

    return text;
  }

  drawSpline(entity, data) {
    // var color = this.getColor(entity, data);

    var points = entity.controlPoints.map(function (vec) {
      return new THREE.Vector2(vec.x, vec.y);
    });

    var interpolatedPoints = [];
    var curve;
    if (entity.degreeOfSplineCurve === 2 || entity.degreeOfSplineCurve === 3) {
      for (var i = 0; i + 2 < points.length; i = i + 2) {
        if (entity.degreeOfSplineCurve === 2) {
          curve = new THREE.QuadraticBezierCurve(points[i], points[i + 1], points[i + 2]);
        } else {
          curve = new THREE.QuadraticBezierCurve3(points[i], points[i + 1], points[i + 2]);
        }
        interpolatedPoints.push.apply(interpolatedPoints, curve.getPoints(50));
      }
    } else {
      curve = new THREE.SplineCurve(points);
      interpolatedPoints = curve.getPoints(100);
    }

    // var geometry = new THREE.BufferGeometry().setFromPoints( interpolatedPoints );
    // var material = new THREE.LineBasicMaterial( { color : color } );
    // var splineObject = new THREE.Line( geometry, material );

    let geometry2 = new LineGeometry();
    geometry2.setPositions(
      interpolatedPoints
        .map((vec2) => [vec2.x, vec2.y, 0])
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        })
    );

    var material = new LineMaterial({
      color: this.getColor(entity, data),
      linewidth: entity.width ? entity.width : 1,
      resolution: new THREE.Vector2(1000, 1000),
    });

    var splineObject = new Line2(geometry2, material);

    return splineObject;
  }

  drawLine(entity, data) {
    var geometry = new THREE.Geometry(),
      color = this.getColor(entity, data),
      material,
      lineType,
      vertex,
      startPoint,
      endPoint,
      bulgeGeometry,
      bulge,
      i,
      line;

    // create geometry
    for (i = 0; i < entity.vertices.length; i++) {
      if (entity.vertices[i].bulge) {
        bulge = entity.vertices[i].bulge;
        startPoint = entity.vertices[i];
        endPoint = i + 1 < entity.vertices.length ? entity.vertices[i + 1] : geometry.vertices[0];

        bulgeGeometry = new THREEx.BulgeGeometry(startPoint, endPoint, bulge);

        geometry.vertices.push.apply(geometry.vertices, bulgeGeometry.vertices);
      } else {
        vertex = entity.vertices[i];
        geometry.vertices.push(new THREE.Vector3(vertex.x, vertex.y, 0));
      }
    }
    if (entity.shape) geometry.vertices.push(geometry.vertices[0]);

    // set material
    if (entity.lineType) {
      lineType = data.tables.lineType.lineTypes[entity.lineType];
    }

    if (lineType && lineType.pattern && lineType.pattern.length !== 0) {
      console.log(
        JSON.stringify(lineType) + ":" + lineType.pattern + ":" + entity.width + ":" + color
      );
      // [0.25 -0.125]
      // lineType.patternLength 0.75
      const gapSize = lineType.patternLength / lineType.pattern[0];
      const dashSize = Math.abs(lineType.patternLength / lineType.pattern[1]);
      const lineWidth = entity.width ? entity.width : 1;

      // material = new THREE.LineDashedMaterial({ color: color, gapSize: 5 , dashSize: 10, linewidth : lineWidth });
      // line = new THREE.Line(geometry, material);

      material = new LineMaterial({
        color: color,
        // vertexColors: true,
        gapSize: gapSize,
        dashSize: dashSize,
        linewidth: lineWidth,
        resolution: new THREE.Vector2(1000, 1000),
        dashed: true,
        alphaToCoverage: true,
      });

      material.defines.USE_DASH = "";
      material.needsUpdate = true;
    } else {
      // material = new THREE.LineBasicMaterial({ linewidth: 1, color: color });

      // const gapSize = lineType.patternLength / lineType.pattern[0];
      // const dashSize = Math.abs(lineType.patternLength / lineType.pattern[1]);
      const lineWidth = entity.width ? entity.width : 1;

      material = new LineMaterial({
        color: color,
        // vertexColors: true,
        linewidth: lineWidth,
        resolution: new THREE.Vector2(1000, 1000),
        dashed: false,
        alphaToCoverage: true,
      });
    }

    let geometry2 = new LineGeometry();
    geometry2.setPositions(
      entity.vertices
        .map((vec2) => [vec2.x, vec2.y, 0])
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        })
    );

    line = new Line2(geometry2, material);
    line.computeLineDistances();

    return line;
  }

  drawArc(entity, data) {
    var startAngle, endAngle;
    if (entity.type === "CIRCLE") {
      startAngle = entity.startAngle || 0;
      endAngle = startAngle + 2 * Math.PI;
    } else {
      startAngle = entity.startAngle;
      endAngle = entity.endAngle;
    }

    var curve = new THREE.ArcCurve(0, 0, entity.radius, startAngle, endAngle);

    var points = curve.getPoints(32);
    // var geometry = new THREE.BufferGeometry().setFromPoints( points );
    // var material = new THREE.LineBasicMaterial({ color: this.getColor(entity, data)});
    // var arc = new THREE.Line(geometry, material);

    let geometry2 = new LineGeometry();
    geometry2.setPositions(
      points
        .map((vec2) => [vec2.x, vec2.y, 0])
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        })
    );

    var material = new LineMaterial({
      color: this.getColor(entity, data),
      //   , linewidth : (entity.lineTypeScale) ? entity.lineTypeScale : 1
      linewidth: 1,
      resolution: new THREE.Vector2(1000, 1000),
    });

    var arc = new Line2(geometry2, material);

    arc.position.x = entity.center.x;
    arc.position.y = entity.center.y;
    arc.position.z = entity.center.z;

    return arc;
  }

  drawSolid(entity, data) {
    var material,
      //   mesh,
      verts,
      geometry = new THREE.Geometry();

    verts = geometry.vertices;
    verts.push(new THREE.Vector3(entity.points[0].x, entity.points[0].y, entity.points[0].z));
    verts.push(new THREE.Vector3(entity.points[1].x, entity.points[1].y, entity.points[1].z));
    verts.push(new THREE.Vector3(entity.points[2].x, entity.points[2].y, entity.points[2].z));
    verts.push(new THREE.Vector3(entity.points[3].x, entity.points[3].y, entity.points[3].z));

    // Calculate which direction the points are facing (clockwise or counter-clockwise)
    var vector1 = new THREE.Vector3();
    var vector2 = new THREE.Vector3();
    vector1.subVectors(verts[1], verts[0]);
    vector2.subVectors(verts[2], verts[0]);
    vector1.cross(vector2);

    // If z < 0 then we must draw these in reverse order
    if (vector1.z < 0) {
      geometry.faces.push(new THREE.Face3(2, 1, 0));
      geometry.faces.push(new THREE.Face3(2, 3, 1));
    } else {
      geometry.faces.push(new THREE.Face3(0, 1, 2));
      geometry.faces.push(new THREE.Face3(1, 3, 2));
    }

    material = new THREE.MeshBasicMaterial({ color: this.getColor(entity, data) });

    return new THREE.Mesh(geometry, material);
  }

  drawText(entity, data) {
    var geometry, material, text;

    if (!this.font)
      return console.warn(
        "Text is not supported without a Three.js font loaded with THREE.FontLoader! Load a font of your choice and pass this into the constructor. See the sample for this repository or Three.js examples at http://threejs.org/examples/?q=text#webgl_geometry_text for more details."
      );

    geometry = new THREE.TextGeometry(entity.text, {
      font: this.font,
      height: 0,
      size: entity.textHeight || 12,
    });

    if (entity.rotation) {
      var zRotation = (entity.rotation * Math.PI) / 180;
      geometry.rotateZ(zRotation);
    }

    material = new THREE.MeshBasicMaterial({ color: this.getColor(entity, data) });

    text = new THREE.Mesh(geometry, material);
    text.position.x = entity.startPoint.x;
    text.position.y = entity.startPoint.y;
    text.position.z = entity.startPoint.z;

    return text;
  }

  drawPoint(entity, data) {
    var geometry, material, point;

    geometry = new THREE.Geometry();

    geometry.vertices.push(
      new THREE.Vector3(entity.position.x, entity.position.y, entity.position.z)
    );

    // TODO: could be more efficient. PointCloud per layer?

    var numPoints = 1;

    var color = this.getColor(entity, data);
    var colors = new Float32Array(numPoints * 3);
    colors[0] = color.r;
    colors[1] = color.g;
    colors[2] = color.b;

    geometry.colors = colors;
    geometry.computeBoundingBox();

    material = new THREE.PointsMaterial({ size: 0.05, vertexColors: THREE.VertexColors });
    point = new THREE.Points(geometry, material);

    //scene.add(point);
    return point;
  }

  drawDimension(entity, data) {
    var block = data.blocks[entity.block];

    if (!block || !block.entities) return null;

    var group = new THREE.Object3D();
    // if(entity.anchorPoint) {
    //     group.position.x = entity.anchorPoint.x;
    //     group.position.y = entity.anchorPoint.y;
    //     group.position.z = entity.anchorPoint.z;
    // }

    for (var i = 0; i < block.entities.length; i++) {
      var childEntity = this.drawEntity(block.entities[i], data, group);
      if (childEntity) group.add(childEntity);
    }

    return group;
  }

  drawBlock(entity, data) {
    var block = data.blocks[entity.name];

    if (!block.entities) return null;

    var group = new THREE.Object3D();

    if (entity.xScale) group.scale.x = entity.xScale;
    if (entity.yScale) group.scale.y = entity.yScale;

    if (entity.rotation) {
      group.rotation.z = (entity.rotation * Math.PI) / 180;
    }

    if (entity.position) {
      group.position.x = entity.position.x;
      group.position.y = entity.position.y;
      group.position.z = entity.position.z;
    }

    for (var i = 0; i < block.entities.length; i++) {
      var childEntity = this.drawEntity(block.entities[i], data, group);
      if (childEntity) group.add(childEntity);
    }

    return group;
  }

  drawFace(entity, data) {
    var vertices = entity.vertices;

    let _color = this.getColor(entity, data);
    // let _trans = [0, 0, 0];

    var vertex = [];
    var faces = [];
    let geometry = new THREE.BufferGeometry();

    // var idx = 0;

    vertex.push(
      ...vertices.map((v) => {
        return new THREE.Vector3(v.x, v.y, v.z);
      })
    );

    if (
      vertex[vertex.length - 1].x === 0 &&
      vertex[vertex.length - 1].y === 0 &&
      vertex[vertex.length - 1].z === 0
    )
      vertex.pop();

    if (
      vertex[vertex.length - 1].x === vertex[vertex.length - 2].x &&
      vertex[vertex.length - 1].y === vertex[vertex.length - 2].y &&
      vertex[vertex.length - 1].z === vertex[vertex.length - 2].z
    )
      vertex.pop();

    //폴리곤 4점
    if (vertex.length === 3) {
      //with close point (polygon)

      faces.push(new THREE.Face3(0, 1, 2));
      // faces.push(new THREE.Face3(0, 2, 3));
    }
    //폴리곤 5점
    else if (vertex.length === 4) {
      //with close point (polygon)
      faces.push(new THREE.Face3(0, 1, 2));
      faces.push(new THREE.Face3(0, 2, 3));
      // faces.push(new THREE.Face3(0, 3, 4));
    }

    geometry = new THREE.Geometry();
    geometry.vertices = vertex;
    geometry.faces = faces;

    //material option
    let _option = {
      //wireframe:false,
      //transparent: true,
      //opacity: 0.9,
      //vertexColors: 2,
      color: 0xffffff,
      side: THREE.DoubleSide,
      //blending: THREE.AdditiveBlending,
      flatShading: true,
    };
    _option.color = _color;

    let material = new THREE.MeshStandardMaterial(_option);

    // let material = new THREE.MeshBasicMaterial({ color: this.getColor(entity, data) });
    let rtnGeom = new THREE.Mesh(geometry, material);

    return rtnGeom;
  }

  getColor(entity, data) {
    var color = 0x000000; //default
    if (entity.color) color = entity.color;
    else if (data.tables && data.tables.layer && data.tables.layer.layers[entity.layer])
      color = data.tables.layer.layers[entity.layer].color;

    if (color == null || color === 0xffffff) {
      color = 0x000000;
    }
    return color;
  }

  createLineTypeShaders(data) {
    var ltype, type;
    if (!data.tables || !data.tables.lineType) return;
    var ltypes = data.tables.lineType.lineTypes;

    for (type in ltypes) {
      ltype = ltypes[type];
      if (!ltype.pattern) continue;
      ltype.material = this.createDashedLineShader(ltype.pattern);
    }
  }

  createDashedLineShader(pattern) {
    var i,
      dashedLineShader = {},
      totalLength = 0.0;

    for (i = 0; i < pattern.length; i++) {
      totalLength += Math.abs(pattern[i]);
    }

    dashedLineShader.uniforms = THREE.UniformsUtils.merge([
      THREE.UniformsLib["common"],
      THREE.UniformsLib["fog"],

      {
        pattern: { type: "fv1", value: pattern },
        patternLength: { type: "f", value: totalLength },
      },
    ]);

    dashedLineShader.vertexShader = [
      "attribute float lineDistance;",

      "varying float vLineDistance;",

      THREE.ShaderChunk["color_pars_vertex"],

      "void main() {",

      THREE.ShaderChunk["color_vertex"],

      "vLineDistance = lineDistance;",

      "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

      "}",
    ].join("\n");

    dashedLineShader.fragmentShader = [
      "uniform vec3 diffuse;",
      "uniform float opacity;",

      "uniform float pattern[" + pattern.length + "];",
      "uniform float patternLength;",

      "varying float vLineDistance;",

      THREE.ShaderChunk["color_pars_fragment"],
      THREE.ShaderChunk["fog_pars_fragment"],

      "void main() {",

      "float pos = mod(vLineDistance, patternLength);",

      "for ( int i = 0; i < " + pattern.length + "; i++ ) {",
      "pos = pos - abs(pattern[i]);",
      "if( pos < 0.0 ) {",
      "if( pattern[i] > 0.0 ) {",
      "gl_FragColor = vec4(1.0, 0.0, 0.0, opacity );",
      "break;",
      "}",
      "discard;",
      "}",

      "}",

      THREE.ShaderChunk["color_fragment"],
      THREE.ShaderChunk["fog_fragment"],

      "}",
    ].join("\n");

    return dashedLineShader;
  }

  findExtents(scene) {
    for (var child of scene.children) {
      var minX, maxX, minY, maxY;
      if (child.position) {
        minX = Math.min(child.position.x, minX);
        minY = Math.min(child.position.y, minY);
        maxX = Math.max(child.position.x, maxX);
        maxY = Math.max(child.position.y, maxY);
      }
    }

    return { min: { x: minX, y: minY }, max: { x: maxX, y: maxY } };
  }
}
