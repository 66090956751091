import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Button, Checkbox, Input, Row, Col} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import {orderBy} from "lodash";
import {convertDatetimeString, shortenString} from "../../utils/stringHelper";
import {checkAllAuth} from "../../utils/authUtil";
import {XaTooltip, TableRender, XaConfirmButton, xaMessage, errorHandler} from "../../common";

const AppQueryTooltip = (props) => {
  const {category, titleLength, className, overlayClassName, title, placement, mainComponent} =
    props;
  return (
    <XaTooltip
      className={className}
      overlayClassName={overlayClassName}
      content={title}
      placement={placement}
      mainComponent={mainComponent}
      color=""
      overlayInnerStyle={{
        width: titleLength > 50 ? "450px" : "300px",
        textAlign: category === "app" ? "center" : "left",
        borderRadius: "10px",
      }}
    />
  );
};

const DbActivity = (props) => {
  const {onCancel} = props;
  const [isShowLoadingAct, setIsShowLoadingAct] = useState(false);
  const [dbActivityList, setDbActivityList] = useState([]);
  const [selectedCheckValues, setSelectedCheckValues] = useState([]);
  const [selectedActivityListRowKeys, setSelectedActivityListRowKeys] = useState([]);
  const [authCheckParams, setAuthCheckParams] = useState(null);

  // i18next
  const {t} = useTranslation();

  const onChangeSelectPid = (selectedActivityListRowKeys) => {
    setSelectedActivityListRowKeys(selectedActivityListRowKeys);
  };

  const rowSelection = {
    // columnWidth: 25,
    selectedRowKeys: selectedActivityListRowKeys,
    onChange: onChangeSelectPid,
    getCheckboxProps: (record) => ({
      disabled: record.query.includes(`LISTEN "XiteCloud_PG_NOTIFY"`) || record.state === "active",
    }),
  };

  const checkboxOptions = [
    {label: t("logjob_search_option_24hrs"), value: 0},
    {label: t("logjob_search_option_noti"), value: 1},
    {label: t("logjob_search_option_listener"), value: 2},
  ];

  const onChangeCheckbox = (values) => {
    setSelectedCheckValues(values);
  };

  const onClickClear = () => {
    handleInit();
  };

  const columnActivityList = [
    {
      title: t("logjob_db_activity_pid"),
      key: "tbl01",
      dataIndex: "pid",
      width: 70,
      fixed: "left",
    },
    {
      title: t("logjob_db_activity_client_addr"),
      key: "tbl02",
      dataIndex: "client_addr",
      width: 120,
    },
    {
      title: t("logjob_db_activity_start"),
      key: "tbl03",
      dataIndex: "query_start",
      width: 165,
      render: (text) => convertDatetimeString(text),
    },
    {
      title: t("logjob_db_activity_state"),
      key: "tbl04",
      dataIndex: "state",
      width: 55,
      render: (text) => {
        return {
          props: {
            style: {
              background: text === "active" ? "#00cfb1" : "idle" ? "#ff4d4f" : "",
            },
          },
          children: (
            <>
              <div>{text}</div>
            </>
          ),
        };
      },
    },
    {
      title: t("logjob_db_activity_app"),
      key: "tbl05",
      dataIndex: "application_name",
      width: 150,
      render: (text) => (
        <AppQueryTooltip
          category="app"
          titleLength={text.length}
          title={text}
          placement="top"
          mainComponent={shortenString(15, text)}
        />
      ),
    },
    {
      title: t("logjob_db_activity_client_query"),
      key: "tbl06",
      dataIndex: "query",
      width: 250,
      render: (text) => (
        <AppQueryTooltip
          category="query"
          titleLength={text.length}
          title={text}
          placement="top"
          mainComponent={shortenString(30, text)}
        />
      ),
    },
  ];

  const handleInit = () => {
    setDbActivityList([]);
    setSelectedCheckValues([]);
    setSelectedActivityListRowKeys([]);
    handleGetActivityList();
  };

  const handleGetActivityList = async () => {
    setIsShowLoadingAct(true);
    try {
      const {data} = await Axios.get("/logjob/statAct", {
        params: {
          filterArray: selectedCheckValues,
        },
      });
      setDbActivityList(orderBy(data, "backend_start", "desc"));
      setIsShowLoadingAct(false);
    } catch (err) {
      errorHandler(t("sentence_error_semicolon"), err);
      setIsShowLoadingAct(false);
    }
  };

  const handleTerminateSession = async () => {
    if (!authCheckParams) {
      errorHandler(t("sentence_please"), t("sentence_input_account_passwords"));
      return;
    }

    const isAuth = await checkAllAuth(authCheckParams);
    if (!isAuth) {
      errorHandler(t("sentence_please"), t("sentence_check_account"));
    } else {
      setIsShowLoadingAct(true);
      try {
        const result = await Axios.put("/logjob/statAct", {
          params: {
            pids: selectedActivityListRowKeys,
          },
        });

        const countSuccess = result.data.map(
          (item) => item.pg_terminate_backend && item.pg_cancel_backend
        ).length;

        if (result.status === 200) {
          if (countSuccess > 0) {
            xaMessage(
              "info",
              t("logjob_db_activity_termininate_result_message", {count: countSuccess})
            );
          } else {
            xaMessage("warn", t("sentence_please_check"));
          }
        } else {
          xaMessage("warn", t("sentence_please_check"));
        }
        setIsShowLoadingAct(false);
      } catch (err) {
        errorHandler(t("sentence_error_semicolon"), err);
        setIsShowLoadingAct(false);
      }
    }
    setAuthCheckParams(null);
    handleInit();
  };

  const onChangeConfirmInput = (e) => {
    setAuthCheckParams(e.target.value);
  };

  const handleCancelConfirm = () => {
    setAuthCheckParams(null);
  };

  const titleString = (data) => {
    return `${t("logjob_db_activity_list_title")}: ${data.length}`;
  };

  useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* 조회 조건 영역 */}
      <div className="search-area ant-advanced-search-form">
        <Row>
          <Col span={4}>{t("logjob_search_option")}</Col>
          <Col span={20}>
            <Checkbox.Group
              options={checkboxOptions}
              defaultValue={selectedCheckValues}
              onChange={onChangeCheckbox}
            />
          </Col>
        </Row>
        <Row className="db-activity-search-row2">
          <Col style={{textAlign: "left"}} span={12}>
            <XaConfirmButton
              contents={
                <div>
                  <p>{t("logjob_db_activity_password_confirm")}</p>
                  <Input.Password
                    placeholder={t("general_password_capital")}
                    onChange={onChangeConfirmInput}
                  />
                </div>
              }
              onConfirm={handleTerminateSession}
              onCancel={handleCancelConfirm}
              btnName={t("button_terminate")}
              btnType="primary"
              okText={t("button_ok")}
              cancelText={t("button_cancel")}
              placement="bottomLeft"
              disabled={selectedActivityListRowKeys.length === 0}
            />
          </Col>
          <Col style={{textAlign: "right"}} span={12}>
            <Button type="primary" onClick={handleGetActivityList}>
              {t("button_search")}
            </Button>
            <Button className="button-margin-left" onClick={onClickClear}>
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </div>
      {/* 표(테이블) 영역 */}
      <div className="activity-table">
        <TableRender
          title={() => titleString(dbActivityList)}
          columns={columnActivityList}
          dataSource={dbActivityList}
          isLoading={isShowLoadingAct}
          rowSelection={rowSelection}
          size="small"
          rowKey="pid"
          bordered={false}
          scroll={{y: 300}}
          pagination={{position: ["topLeft"]}}
        />
      </div>
    </>
  );
};

/* Exports */
export default DbActivity;
