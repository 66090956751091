import React, {useState, useReducer, useEffect} from "react";
import {Form, Row, Col, Input, Button, Space, Typography, InputNumber} from "antd";
import {EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import Axios from "axios";
import {TableRender, xaMessage, errorHandler, XaConfirmIconButton} from "../../common";
import "./index.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      state = {...state, companyList: action.payload.slice()};
      break;
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    case "SET_USERTYPE":
      state = {...state, userTypeList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const CodeDispatch = React.createContext(null);

/* Main Component */
const Codes = (props) => {
  const [dsCodeList, setDsCodeList] = useState([]);
  const [initDsCode1List, setInitDsCode1List] = useState([]);
  const [dsCode1List, setDsCode1List] = useState([]);
  const [dsCode2List, setDsCode2List] = useState([]);
  const [selectedCode1, setSelectedCode1] = useState(null);
  const [gState, setGState] = useReducer(reducer, {
    companyList: [],
    projectList: [],
    userTypeList: [],
  });
  const [editingKey, setEditingKey] = useState(null);

  const [form2] = Form.useForm(); // code1
  const [form1] = Form.useForm(); // code2

  // i18next
  const {t} = useTranslation();

  const isEditing = (record) => record.code2 === editingKey;

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    len,
    record,
    index,
    children,
    ...restProps
  }) => {
    // i18next
    const inputNode = inputType === "number" ? <InputNumber /> : <Input {...{maxLength: len}} />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{margin: 0}}
            rules={[
              {
                required: true,
                message: t("message_please_input", {target: title}),
                len: {len},
              },
            ]}
            size="small"
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    form1.setFieldsValue({
      gbn: record.code2 === "" ? "new" : "edit",
      code1: selectedCode1 ? selectedCode1.code1 : "",
      code2: "",
      code_txt: "",
      description: "",
      ...record,
    });
    setEditingKey(record.code2);
  };

  const handleEditCancel = () => {
    setEditingKey(null);
  };

  const deleteCode = async (record, index) => {
    try {
      if (record.gbn !== "new") {
        await Axios.delete(`/code`, {params: record});
      } else {
      }

      let newData = dsCode2List.filter((item) => item.code2 !== record.code2);
      setDsCode2List(newData);
      setEditingKey(null);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const colCode1 = [
    {
      title: t("code_code1"),
      dataIndex: "code1",
      key: "tblCode_1",
    },
  ];

  const colCode2 = [
    {
      title: t("code_table_column_category"),
      dataIndex: "gbn",
      render: (text, record, index) => {
        return (
          <span>
            {record.gbn === "new"
              ? t("code_table_column_category_add")
              : t("code_table_column_category_db")}
          </span>
        );
      },
    },
    {
      title: t("code_table_column_code1"),
      dataIndex: "code1",
    },
    {
      title: t("code_table_column_code2"),
      dataIndex: "code2",
      editable: true,
      len: 3,
      maxLength: 3,
    },
    {
      title: t("code_table_column_text"),
      dataIndex: "code_txt",
      editable: true,
    },
    {
      title: t("code_table_column_descrition"),
      dataIndex: "description",
      editable: true,
    },
    {
      title: t("code_table_column_action"),
      render: (_, record, index) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              className="search-code1-button"
              type="link"
              onClick={() => handleSaveCode(record.code2)}
              size="small"
            >
              {<CheckOutlined />}
            </Button>
            <XaConfirmIconButton
              contents={t("general_cancel_confirm")}
              onConfirm={handleEditCancel}
              icon={<CloseOutlined />}
              btnType="link"
              size="small"
            />
          </span>
        ) : (
          <Space>
            <Typography.Link
              className="search-code1-button"
              disabled={editingKey != null}
              size="small"
              onClick={() => edit(record)}
            >
              {<EditOutlined />}
            </Typography.Link>
            <Typography.Link disabled={editingKey != null}>
              <XaConfirmIconButton
                contents={t("delete_confirm")}
                icon={<DeleteOutlined />}
                size="small"
                onConfirm={() => deleteCode(record, index)}
              />
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const handleInitCode1List = (code1Data) => {
    let uniqueValues = [...new Set(code1Data.map((item) => item.code1))];
    setDsCode1List(
      uniqueValues.map((item) => {
        return {code1: item};
      })
    );
  };

  // 코드 저장
  const handleSaveCode = async (key) => {
    try {
      const row = await form1.validateFields();
      const newData = [...dsCode2List];
      const index = newData.findIndex((item) => key === item.code2);
      let newRecord = {...newData[index], ...row};
      const _gbn = newRecord.gbn;

      if (_gbn === "new") await Axios.post("/code", {params: newRecord});
      else await Axios.put("/code", {params: newRecord});

      const item = newData[index];
      newData.splice(index, 1, {...item, ...row, gbn: ""});

      setDsCode2List(newData);
      setEditingKey(null);
    } catch (err) {
      errorHandler(`${t("message_validate_failed")}: `, err);
    }
  };

  const handleRenderCode2Tbl = async (pCode) => {
    try {
      const {data} = await Axios.get("/code", {params: {code1: pCode.code1}});
      setSelectedCode1(pCode);
      setDsCode2List(data);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const handleSearch = async (paramValue) => {
    try {
      const {data} = await Axios.get("/code", {params: paramValue});
      setInitDsCode1List(data);
      setDsCodeList(data);
      setSelectedCode1(null);

      handleInitCode1List(data);
      setDsCode2List([]);
      form2.setFieldsValue({gbn: "new", code1: "", code1Search: ""});
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };
  // const onFinish = async (paramValue) => {
  //   handleSearch(paramValue);
  // };

  // const onFinishFailed = (err) => {
  //   errorHandler("", err);
  // };

  const handleRenderTitleTblCode1 = () => {
    const handleAddCode1 = async () => {
      if (dsCodeList.length === 0) {
        xaMessage("info", t("message_please_search"));
        return;
      }

      try {
        const row = await form2.validateFields();
        let valKey = dsCodeList.filter((item) => item.code1 === row.code1);
        if (valKey.length > 0) {
          errorHandler(t("message_error_beginning"), t("message_error_code_aleady_exitst"), null);
          return;
        }
        const newData = [...dsCode1List];
        const index = newData.findIndex((item) => row.code1 === item.code1);

        if (index !== -1) {
          errorHandler(t("message_error_beginning"), t("message_error_code_duplicate"), null);
          return;
        }

        newData.unshift(row);
        setDsCode1List(newData);
      } catch (errInfo) {
        errorHandler(`${t("message_validate_failed")}: `, errInfo);
      }
    };

    /** Render */
    return (
      <>
        <Row style={{width: "100%"}}>
          <Form.Item
            name="gbn"
            noStyle
            rules={[
              {
                required: true,
                message: t("code_guide"),
                len: 3,
              },
            ]}
            size="small"
          >
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="code1"
            label={t("code_code1")}
            rules={[
              {
                required: true,
                message: t("code_guide"),
                len: 3,
              },
            ]}
            size="small"
          >
            <Input style={{width: "9vw"}} placeholder={t("code_guide")} maxLength={3} />
          </Form.Item>
          <Button className="code1-button" size="small" onClick={() => handleAddCode1()}>
            {t("button_add")}
          </Button>
        </Row>
        <Row>
          <Form.Item name="code1Search" label={t("code_code1_search")} size="small">
            <Input
              style={{width: "9vw", marginRight: "4px"}}
              onChange={onChangeSearchCode1}
              maxLength={3}
            />
          </Form.Item>
          <Button className="code1-button" onClick={onClickSearchCode1Init}>
            {t("button_clear")}
          </Button>
        </Row>
      </>
    );
  };

  const handleRenderTblCode2Footer = () => {
    const handleAddCode2 = () => {
      let _addList = [];
      _addList = _addList.concat(dsCode2List);

      _addList.push({
        gbn: "new",
        code1: selectedCode1 ? selectedCode1.code1 : "",
        code2: "",
        code_txt: "",
        description: "",
      });

      setDsCode2List(_addList);
    };

    return (
      <Button type="primary" onClick={handleAddCode2} disabled={!selectedCode1}>
        {t("button_add")}
      </Button>
    );
  };

  const mergedColumns = colCode2.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text", //col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        len: col.len,
        maxLength: col.maxLength,
      }),
    };
  });

  const handleRenderCode2TblTitle = (selectedCode1) => {
    const titleString = `${selectedCode1 ? selectedCode1.code1 : ""} ${t("code_code_list")}`;
    return <div style={{fontWeight: "600"}}>{titleString}</div>;
  };

  const onChangeSearchCode1 = (e) => {
    const inputCharactors = e.target.value;

    if (inputCharactors.length === 0) {
      handleInitCode1List(initDsCode1List);
    } else {
      const searchWords = inputCharactors.toUpperCase();
      setDsCode1List(dsCode1List.filter((item) => item.code1.includes(searchWords)));
    }
  };

  const onClickSearchCode1Init = () => {
    form2.resetFields();
    handleInitCode1List(initDsCode1List);
  };

  useEffect(() => {
    handleSearch({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <CodeDispatch.Provider value={{gState, setGState}}>
      {/* <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
            }}
          >
            <Button type="primary" htmlType="submit">
              {t("button_search")}
            </Button>
          </Col>
        </Row>
      </Form> */}
      <Row gutter={24}>
        <Col span={6} key={1}>
          <Form form={form2} component={false} size="small">
            <TableRender
              title={handleRenderTitleTblCode1}
              columns={colCode1}
              dataSource={dsCode1List}
              size="small"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleRenderCode2Tbl(record);
                  }, // click row
                };
              }}
              rowClassName="code1-table-row-action"
            />
          </Form>
        </Col>
        <Col span={18} key={2}>
          <Form form={form1} component={false} size="small">
            <TableRender
              title={() => handleRenderCode2TblTitle(selectedCode1)}
              dataSource={dsCode2List}
              columns={mergedColumns}
              size="small"
              rowClassName="editable-row"
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              footer={handleRenderTblCode2Footer}
            />
          </Form>
        </Col>
      </Row>
    </CodeDispatch.Provider>
  );
};

export default Codes;
