import React, {useState, useRef, useEffect} from "react";
import config from "../../lib/env";
import {Button, Row, Col, Divider, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {relocateNotFoundUrl} from "../../utils/utils";

const {Title} = Typography;
const Cesium = window.Cesium;
Cesium ? (Cesium.Ion.defaultAccessToken = config.ion_defaultAccessToken) : relocateNotFoundUrl();

const WMSViewerOnCesium = (props) => {
  const [wmsInfo, setWmsInfo] = useState({x: null, y: null, long: null, lat: null, url: null});

  /** i18next */
  const {t} = useTranslation();

  const cesiumContainer = useRef();
  const creditContainer = useRef();

  const cesiumViewer = useRef();
  const baseLayer = useRef();
  const wmsLayer = useRef();

  const [selBaseMapVworld, setSelBaseMapVworld] = useState(0); //layers.layer index

  const containerWidth = props.style.width;
  const containerHeight = props.style.height;

  const layers = [
    {layer: "Base", tileType: "png"},
    {layer: "gray", tileType: "png"},
    {layer: "midnight", tileType: "png"},
    {layer: "Hybrid", tileType: "png"},
    {layer: "Satellite", tileType: "jpeg"},
  ];

  const baseMapChangeHandler = (e) => {
    fnChangeBasemapVWorld(Number(e.currentTarget.value));
  };

  const fnChangeBasemapVWorld = (gbn) => {
    cesiumViewer.current.imageryLayers.removeAll();

    setSelBaseMapVworld(gbn);

    baseLayer.current = new Cesium.WebMapTileServiceImageryProvider({
      url: `http://api.vworld.kr/req/wmts/1.0.0/${config.vworld_key}/${layers[gbn].layer}/{TileMatrix}/{TileRow}/{TileCol}.${layers[gbn].tileType}`,
      minimumLevel: 6,
      maximumLevel: 19,
      credit: new Cesium.Credit("VWorld Korea"),
    });
    cesiumViewer.current.imageryLayers.addImageryProvider(baseLayer.current);

    baseLayer.current.readyPromise.then(() => {
      cesiumViewer.current.imageryLayers.addImageryProvider(wmsLayer.current);
    });
  };

  useEffect(() => {
    // console.log( 'userEffect no deps')

    baseLayer.current = new Cesium.WebMapTileServiceImageryProvider({
      url: `http://api.vworld.kr/req/wmts/1.0.0/${config.vworld_key}/${layers[selBaseMapVworld].layer}/{TileMatrix}/{TileRow}/{TileCol}.${layers[selBaseMapVworld].tileType}`,
      minimumLevel: 6,
      maximumLevel: 19,
      credit: new Cesium.Credit("VWorld Korea"),
    });

    if (cesiumViewer.current === undefined) {
      cesiumViewer.current = new Cesium.Viewer(cesiumContainer.current, {
        animation: false,
        baseLayerPicker: false,
        fullscreenButton: false,
        vrButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        creditContainer: creditContainer.current,
        imageryProvider: baseLayer.current,
        // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        //   url:
        //     "https://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/",
        // })
      });

      cesiumContainer.current.addEventListener("onResize", () => {
        cesiumViewer.current.canvas.width = cesiumContainer.current.offsetWidth;
        cesiumViewer.current.canvas.height = cesiumContainer.current.offsetHeight;
      });
    }

    cesiumViewer.current.canvas.width = containerWidth; //cesiumContainer.current.offsetWidth
    cesiumViewer.current.canvas.height = containerHeight; //cesiumContainer.current.offsetHeight

    // cesiumViewer.current.camera.setView({
    //   destination: new Cesium.Cartesian3(-3756512.992115552, 5003744.628566555, 4786760.616010258)
    //   });

    return () => {};
  });

  useEffect(
    (project_mng_sq_t_project_mng, f_input_date1) => {
      // console.log('data deps');
      if (cesiumViewer.current.imageryLayers.length > 1) {
        cesiumViewer.current.imageryLayers.remove(cesiumViewer.current.imageryLayers.get(1));
      }
      //cesiumViewer.current.entities.removeAll();
      if (cesiumViewer.current.dataSources.length > 0) {
        cesiumViewer.current.dataSources.removeAll();
      }

      let url = `/Storage/data_pcd/${props.selDataset.project_mng_sq_t_project_mng}/${props.selDataset.f_input_date1}/wms`;
      wmsLayer.current = new Cesium.TileMapServiceImageryProvider({
        url: url,
        // ,proxy: new Cesium.DefaultProxy('/proxy/')
      });

      let layer = cesiumViewer.current.imageryLayers.addImageryProvider(wmsLayer.current);

      layer.getViewableRectangle().then((rectangle) => {
        setWmsInfo({...wmsInfo, url: url});

        cesiumViewer.current.scene.camera.setView({
          destination: rectangle,
        });

        //SceneMode : Number
        // MORPHING 0
        // COLUMBUS_VIEW 1
        // SCENE2D 2
        // SCENE3D 3

        // SCENE3D 3
        const getMapCenter_SCENE3D = () => {
          let windowPosition = new Cesium.Cartesian2(
            cesiumViewer.current.container.clientWidth / 2,
            cesiumViewer.current.container.clientHeight / 2
          );
          let pickRay = cesiumViewer.current.scene.camera.getPickRay(windowPosition);
          let pickPosition = cesiumViewer.current.scene.globe.pick(
            pickRay,
            cesiumViewer.current.scene
          );
          let pickPositionCartographic =
            cesiumViewer.current.scene.globe.ellipsoid.cartesianToCartographic(pickPosition);
          console.log(pickPositionCartographic.longitude * (180 / Math.PI));
          console.log(pickPositionCartographic.latitude * (180 / Math.PI));

          return {
            long: pickPositionCartographic.longitude * (180 / Math.PI),
            lat: pickPositionCartographic.latitude * (180 / Math.PI),
            x: pickPositionCartographic.longitude * (180 / Math.PI),
            y: pickPositionCartographic.latitude * (180 / Math.PI),
          };
        };

        const getMapCenter_SCENE2D = () => {
          let camPos = cesiumViewer.current.camera.positionCartographic;
          console.log(camPos.longitude * (180 / Math.PI));
          console.log(camPos.latitude * (180 / Math.PI));

          return {
            long: camPos.longitude * (180 / Math.PI),
            lat: camPos.latitude * (180 / Math.PI),
            x: camPos.longitude * (180 / Math.PI),
            y: camPos.latitude * (180 / Math.PI),
          };
        };

        if (cesiumViewer.current.scene.mode === Cesium.SceneMode.SCENE3D) {
          setWmsInfo({...wmsInfo, ...getMapCenter_SCENE3D()});
        } else {
          setWmsInfo({...wmsInfo, ...getMapCenter_SCENE2D()});
        }
      });

      let urlGeoJson = `/jobs_getTargetGeoJSON?f_project_mng_sq=${props.selDataset.project_mng_sq_t_project_mng}&target_date=${props.selDataset.f_input_date1}`;
      let promise = Cesium.GeoJsonDataSource.load(urlGeoJson, {
        stroke: Cesium.Color.GRAY,
        fill: Cesium.Color.GRAY.withAlpha(0.5),
        strokeWidth: 1,
      });

      promise
        .then((dataSource) => {
          cesiumViewer.current.dataSources.add(dataSource);
        })
        .otherwise((error) => {
          //Display any errrors encountered while loading.
          console.log(error);
          // window.alert(error);
        });

      return () => {
        cesiumViewer.current.imageryLayers.remove(wmsLayer.current, true);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.selDataset.project_mng_sq_t_project_mng, props.selDataset.f_input_date1]
  );

  return (
    <>
      <Row>
        <Col span={16}>
          <div ref={cesiumContainer} {...props}></div>
          <div style={{display: "none"}} ref={creditContainer} />
        </Col>
        <Col span={8}>
          <Divider orientation="left">{t("jobs_pcd_detail_wmsViewer_center_position")}</Divider>
          <p>
            X: {wmsInfo.x}
            <br />
            Y: {wmsInfo.y}
          </p>
          <Divider orientation="left">{t("jobs_pcd_detail_wmsViewer_center_info")}</Divider>
          <p>{wmsInfo.url}</p>
          <Divider orientation="left">{t("jobs_pcd_detail_wmsViewer_center_coordinate")}</Divider>
          <p>{props.selDataset.f_coord_system}</p>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Button size={"small"} onClick={baseMapChangeHandler} value={0}>
            {t("button_base_map")}
          </Button>
          <Button size={"small"} onClick={baseMapChangeHandler} value={3}>
            {t("button_hybrid_map")}
          </Button>
          <Button size={"small"} onClick={baseMapChangeHandler} value={4}>
            {t("button_satellite_map")}
          </Button>
        </Col>
      </Row> */}
    </>
  );
};

export default WMSViewerOnCesium;
