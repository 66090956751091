/* src/routes/Home/Home.js */
/* React */
import React from "react";
import PropTypes from "prop-types";

/* Styled */
import styled from "styled-components";

/* Styled Components */
const Container = styled.div`
  background-color: white;
`;

/* Main Component */
const Home = (props) => {
  /* Props */
  const {className} = props;

  /* Renderer */
  return <Container className={className}>Main Page</Container>;
};

/* Main Component Settings */
Home.propTypes = {
  className: PropTypes.string,
};

/* Exports */
export default Home;
