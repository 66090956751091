/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import "antd/dist/antd.css";
import "./index.css";
import {Form, Input, Button} from "antd";
import Axios from "axios";
import {useTranslation} from "react-i18next";
import Logo from "../../../src/assets/logo/Logo190x34px-01.png";
import {LoadingAnimationWholePage, errorHandler} from "../../common";
import {xcHealthCheck} from "../../utils/utils";

const LoginPage = () => {
  let history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [xcSolStatus, setXcSolStatus] = useState(null);
  const [xcAdminStatus, setAdminXcStatus] = useState(null);
  const [isShowLoad, setIsShowLoad] = useState(false);

  /** i18next */
  const {t} = useTranslation();

  const getHealthInfo = async () => {
    const result = await xcHealthCheck();
    setAdminXcStatus(result.admin);
    setXcSolStatus(result.sol);
  };

  const handleFetchUser = async (param) => {
    try {
      if (!param.userId || !param.passWord) return;

      let res = await Axios.post("/authenticate", {
        username: param.userId,
        password: param.passWord,
      });

      if (res.data) {
        sessionStorage.setItem("userInfo", JSON.stringify(res.data));
        Axios.defaults.headers.common["Authorization"] = res.data.token;

        if (res.data.admin_auth_yn) {
          sessionStorage.setItem("userInfo", JSON.stringify(res.data));

          window.addEventListener("beforeunload", (event) => {
            Axios.get("/authenticate");
          });

          isMobile ? history.push("/mobile") : history.push("/main");
        } else {
          errorHandler(
            "",
            t("message_error_no_permission_to_{{target}}", {target: t("xiteadmin")})
          );
          return;
        }
      } else if (res.status === 422) {
        errorHandler(`${t("message_error_login")}: `, t("message_error_login_check_id_pw"));
        return;
      }
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const onFinish = (values) => {
    handleFetchUser(values);
  };

  const onFinishFailed = (errorInfo) => {
    // errorHandler(t("message_error_beginning"), t("message_error_login"));
  };

  useEffect(async () => {
    setIsShowLoad(true);
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    getHealthInfo();
    setIsShowLoad(false);
  }, []);

  return (
    <div className="centered">
      <h1 className="title">
        <img className="title-img" src={Logo} alt="logo" />
        XiteAdmin
      </h1>
      <div className="login-main-area">
        <LoadingAnimationWholePage
          isLoading={isShowLoad}
          component={
            <>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="userId"
                  rules={[
                    {
                      required: true,
                      message: t("message_error_login_check_id_empty"),
                    },
                  ]}
                >
                  <Input placeholder="User ID (E-mail Address)" />
                </Form.Item>

                <Form.Item
                  name="passWord"
                  rules={[
                    {
                      required: true,
                      message: t("message_error_login_check_pw_empty"),
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item>
                  <Button className="login-button" type="primary" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </>
          }
        />

        <div className="health-check-area">
          <div>{isMobile && <>(Mobile)</>}</div>
          <div className="title-string">XiteCloud Status</div>
          <div>
            {xcSolStatus ? <span className="green-dot" /> : <span className="red-dot" />}
            XiteCloud Server
          </div>
          <div style={{marginLeft: "0.2vw"}}>
            {xcAdminStatus ? <span className="green-dot" /> : <span className="red-dot" />}
            XiteAdmin Server
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
