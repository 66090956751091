import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Input, Button, Select, Modal} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {TableRender, errorHandler} from "../../common";
import {sortBy} from "lodash";
import {useTranslation} from "react-i18next";
import ApiDetail from "./ApiDetail";

const {Option} = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SYSTEMTYPE":
      state = {...state, systemTypeList: action.payload.slice()};
      break;
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    case "SET_USERTYPE":
      state = {...state, userTypeList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const ApiDispatch = React.createContext(null);

/* Main Component */
const Api = (props) => {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [apiDetailModalvisible, setApiDetailModalvisible] = useState(false);
  const [apiList, setApiList] = useState([]);

  const [gState, setGState] = useReducer(reducer, {
    systemTypeList: [],
    methodTypeList: [
      {key: "0", text: "GET"},
      {key: "1", text: "POST"},
    ],
    crudList: [
      {key: "1", text: "Read Only"},
      {key: "2", text: "C/U/D"},
    ],
  });
  const {systemTypeList, methodTypeList, crudList} = gState;

  const [selectedApiMngSq, setSelectedApiMngSq] = useState(null);
  const [form] = Form.useForm();

  /** i18next */
  const {t} = useTranslation();

  // 테이블 행
  const onRowApiList = (record, _) => {
    return {
      // Row 클릭
      onClick: () => {
        handleOpenApiModal(record.api_mng_sq);
      },
    };
  };

  /* API 테이블 열 정의 */
  const columns = [
    {
      title: t("api_table_system_type"),
      dataIndex: "c_system_type",
      key: "tblApi_1",
    },
    {
      title: t("api_table_api_name"),
      dataIndex: "f_api_nm",
      key: "tblApi_2",
    },
    {
      title: t("api_table_url"),
      dataIndex: "f_api_url",
      key: "tblApi_3",
    },
    {
      title: t("api_table_method_type"),
      dataIndex: "f_method_type",
      key: "tblApi_4",
      render: (text, record, index) => {
        let rtnObj = methodTypeList.find((item) => item.key === text);
        return rtnObj ? rtnObj.text : "";
      },
    },
    {
      title: t("api_table_crud"),
      key: "tblApi_5",
      dataIndex: "f_crud_type",
      render: (text, record, index) => {
        let rtnObj = crudList.find((item) => item.key === text);
        return rtnObj ? rtnObj.text : "";
      },
    },
    {
      title: t("api_table_description"),
      key: "tblApi_6",
      dataIndex: "f_description",
    },
    {
      title: t("api_table_delete"),
      key: "tblApi_7",
      dataIndex: "f_delete_yn",
    },
  ];

  const handleAddApi = () => {
    setApiDetailModalvisible(true);
    setSelectedApiMngSq(null);
  };

  const handleOpenApiModal = (apiMngSq) => {
    setApiDetailModalvisible(true);
    setSelectedApiMngSq(apiMngSq);
  };

  // 초기 함수
  const handleInitForm = async () => {
    try {
      const {data} = await Axios.get("/code", {params: {code1: "STM"}});
      setGState({type: "SET_SYSTEMTYPE", payload: data});
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // 검색
  const handleSearch = async (paramValue) => {
    setIsShowLoading(true);
    try {
      const {data} = await Axios.get("/apis", {
        params: {
          c_system_type: paramValue.c_system_type,
          f_api_nm: paramValue.f_api_nm?.toString().toLowerCase(),
          f_api_url: paramValue.f_api_url?.toString().toLowerCase(),
          f_delete_yn: paramValue.f_delete_yn?.toString().toUpperCase(),
        },
      });
      setApiList(sortBy(data, "api_mng_sq"));
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
    setIsShowLoading(false);
  };

  const onFinish = async (paramValue) => {
    handleSearch(paramValue);
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    setApiDetailModalvisible(false);
  };

  useEffect(() => {
    handleInitForm();
    handleSearch({});
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <ApiDispatch.Provider value={{gState, setGState}}>
      <Form
        form={form}
        name="advanced_search"
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        className="common-search-area-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{c_system_type: null, f_delete_yn: null}}
      >
        <Row gutter={24}>
          <Col span={8} key={0}>
            <Form.Item
              name="c_system_type"
              label={t("api_category")}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select>
                <Option>{t("selector_select")}</Option>
                {systemTypeList.map((system_type) => (
                  <Option key={system_type.code2} value={system_type.code2}>
                    {system_type.code2} ({system_type.code_txt})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8} key={2}>
            <Form.Item
              name="f_api_nm"
              label={t("api_name")}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8} key={3}>
            <Form.Item
              name="f_api_url"
              label={t("api_url")}
              rules={[
                {
                  required: false,
                  message: "Choose optional",
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8} key={0}>
            <Form.Item
              name="f_delete_yn"
              label={t("api_delete")}
              rules={[
                {
                  required: false,
                  message: "Choose optional",
                },
              ]}
            >
              <Select>
                <Option>{t("selector_select")}</Option>
                <Option key="Y" value="Y">
                  Y
                </Option>
                <Option key="N" value="N">
                  N
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
            }}
          >
            <Button type="dashed" onClick={handleAddApi}>
              {t("api_button_add_api")}
            </Button>
            <Button type="primary" htmlType="submit">
              {t("button_search")}
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => form.resetFields()}
            >
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </Form>
      <TableRender
        columns={columns}
        rowClassName="table-row-action"
        isLoading={isShowLoading}
        dataSource={apiList}
        onRow={onRowApiList}
        rowKey={"api_mng_sq"}
      />

      <Modal
        title={t("api_modal_title")}
        open={apiDetailModalvisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={600}
        getContainer={false}
        maskClosable={false}
      >
        <ApiDetail onCancel={handleCancel} apiMngSq={selectedApiMngSq} />
      </Modal>
    </ApiDispatch.Provider>
  );
};

/* Exports */
export default Api;
