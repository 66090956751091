import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Button} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import {convertDatetimeString} from "../../utils/stringHelper";
import {TableRender, xaMessage, XaConfirmTooltipButton, errorHandler} from "../../common";

const AnnotationJobDetail = (props) => {
  const {onCancel} = props;
  const [isShowLoadAnnoJob, setIsShowLoadAnnoJob] = useState(false);
  const [annotationJobData, setAnnotationJobData] = useState([]);

  /** i18next */
  const {t} = useTranslation();

  const axios = Axios.create();

  const annotationJobColumns = [
    {
      title: t("annotation_manage_job_info_table_column_seq"),
      dataIndex: "annotation_mng_sq",
    },
    {
      title: t("annotation_manage_job_info_table_column_job_id"),
      dataIndex: "job_id",
    },
    {
      title: t("annotation_manage_job_info_table_column_status"),
      dataIndex: "job_status",
      render: (text) => {
        return text === "-1"
          ? t("annotation_manage_job_info_table_column_status_error")
          : text === "0"
          ? t("annotation_manage_job_info_table_column_status_process")
          : text === "1"
          ? t("annotation_manage_job_info_table_column_status_complete")
          : "";
      },
    },
    {
      title: t("annotation_manage_job_info_table_column_start"),
      dataIndex: "create_time",
      render: (text) => convertDatetimeString(text),
    },
    {
      title: t("annotation_manage_job_info_table_column_end"),
      dataIndex: "end_time",
      render: (text) => convertDatetimeString(text),
    },
    {
      title: t("annotation_manage_job_info_table_column_redo"),
      dataIndex: "job_id",
      render: (text, record, _) => (
        <>
          <XaConfirmTooltipButton
            toolTipContent={t("annotation_manage_job_info_tooltip")}
            toolTipPlacement="bottom"
            btnName={t("button_manual_redo")}
            btnType="primary"
            size="small"
            popPlacement="top"
            popContent={t("annotation_manage_job_info_confirm", {seq: text})}
            onConfirm={() => handleRunAnalisys(text, record.annotation_mng_sq)}
          />
        </>
      ),
    },
  ];

  const handleLoadAnnotationJob = async (seq) => {
    setIsShowLoadAnnoJob(true);
    const {data} = await axios.get(`/annotations/jobInfo/${seq}`);
    setAnnotationJobData(data);
    setIsShowLoadAnnoJob(false);
  };

  //분석 재실행 (Service Broker 기존 Job 재실행 - Job Log 재실행 API 호출)
  const handleRunAnalisys = async (jobId, annotationMngSq) => {
    try {
      await axios.put(`/logjob/${jobId}`);
      onCancel();
      xaMessage(
        "success",
        t("annotation_manage_mamual_redo_result_message", {
          annotationMngSeq: annotationMngSq,
          jobId: jobId,
        })
      );
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const onClickBtnClose = async () => {
    onCancel();
  };

  useEffect(() => {
    handleLoadAnnotationJob(props.annotationMngSq);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.annotationMngSq]);

  return (
    <>
      {/* 테이블 활용하여 정보 표시 */}
      <TableRender
        dataSource={annotationJobData}
        columns={annotationJobColumns}
        rowKey={"job_id"} // Antd 4.x 이상부터 반드시 정의
        bordered={false}
        pagination={false}
        isLoadin={isShowLoadAnnoJob}
        size="small"
      />

      <Row gutter={16}>
        <Col span={24} className="annotation-job-detail-footer">
          <Button className="button-margin-left" onClick={onClickBtnClose}>
            {t("button_close")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

/* Exports */
export default AnnotationJobDetail;
