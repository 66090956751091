/* src/layouts/Main/Header/Header.js */
/* React */
import React from "react";

/* Styled */
import HeaderComponent from "../../routes/Login/HeaderMain";

const Header = () => {
  return (
    <>
      <HeaderComponent />
    </>
  );
};

/* Exports */
export default Header;
