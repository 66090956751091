import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal, Row, Col} from "antd";
import Axios from "axios";
import {useTranslation} from "react-i18next";
import {convertDatetimeString} from "../../utils/stringHelper";
import {TableRender, XaConfirmButton, errorHandler, xaMessage} from "../../common";
import DeviceMGDetails from "./DeviceMGDetails";

/** Main */
const DeviceMng = () => {
  const [searchform] = Form.useForm();
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isOpenDeviceMGDetailsModalForm, setIsOpenDeviceMGDetailsModalForm] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [initData, setInitData] = useState();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 선택 체크박스의 Key
  const [selectedDeviceData, setSelectedDeviceData] = useState([]); // 선택 체크박스의 데이터 Row

  // Table Checkbox Key, Data 저장 Controller
  const handleChangeRadio = (key, data) => {
    setSelectedRowKeys(key);
    setSelectedDeviceData(data[0]);
  };

  const onRowInitDataList = (record, _) => {
    return {
      // Row 클릭
      onClick: () => {
        handleEditData(record);
      },
    };
  };

  // Table Checkbox 제어 조건 설정
  const rowSelection = {
    type: "radio",
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRowKeys.length > 0
        ? handleChangeRadio(selectedRowKeys, selectedRows)
        : handleChangeRadio([], []);
    },
    getCheckboxProps: (record) => ({
      disabled: record.use_yn === "N",
      name: record.use_yn,
    }),
  };

  /** i18next */
  const {t} = useTranslation();

  /** Table Columns */
  const columns = [
    {
      title: t("device_manage_table_column_key"),
      width: 120,
      dataIndex: "mg_device_key",
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_name"),
      width: 120,
      dataIndex: "f_device_name",
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_model"),
      width: 120,
      dataIndex: "f_model",
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_company"),
      width: 80,
      dataIndex: "f_comp",
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_asset"),
      width: 100,
      dataIndex: "f_asset",
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_num"),
      width: 120,
      dataIndex: "f_car_number",
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_regist"),
      width: 100,
      dataIndex: "user_name",
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_memo"),
      width: 200,
      dataIndex: "f_memo",
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_start"),
      width: 100,
      dataIndex: "use_st",
      render: (text) => (text ? convertDatetimeString(text) : "-"),
      onCell: onRowInitDataList,
    },
    {
      title: t("device_manage_table_column_end"),
      width: 100,
      dataIndex: "use_en",
      render: (text) => (text ? convertDatetimeString(text) : "-"),
      onCell: onRowInitDataList,
    },
  ];

  // 장비등록
  const handleAddDevice = () => {
    setIsOpenDeviceMGDetailsModalForm(true);
    setSelectedData({});
  };

  const handleOk = (result) => {
    setIsOpenDeviceMGDetailsModalForm(false);
    if (result) handleSearch();
  };
  const handleCancel = () => {
    setIsOpenDeviceMGDetailsModalForm(false);
  };

  const handleFinish = (params) => {
    let p = {
      mg_device_key: params.mg_device_key || "",
      device_name: params.device_name || "",
      model: params.model || "",
      comp: params.comp || "",
      asset: params.asset || "",
      car_number: params.car_number || "",
    };
    handleSearch(p);
  };

  const handleFinishFailed = () => {};

  // 초기화
  const initForm = () => {
    setSelectedRowKeys([]);
    setSelectedDeviceData([]);
    searchform.resetFields();
  };

  // 데이터 조회
  const handleSearch = async (params) => {
    setIsShowLoading(true);
    try {
      const {data} = await Axios.get("/mgDevices", {
        params: params,
      });

      setInitData(data);
    } catch (err) {
      errorHandler("", err);
    }
    setIsShowLoading(false);
  };

  const handleEditData = (data) => {
    setSelectedData(data);
    setIsOpenDeviceMGDetailsModalForm(true);
  };

  // 삭제처리
  const handleRemoveData = async (key) => {
    try {
      const {data} = await Axios.post("/mgDevice", {
        params: {mg_device_sq: key, f_delete_yn: "Y"},
      });

      xaMessage("success", t("message_delete_success"));
      handleSearch();
    } catch (err) {
      errorHandler("", err);
    }
  };

  const onConfirmRemoveDevice = () => {
    handleRemoveData(selectedDeviceData.mg_device_sq);
  };

  useEffect(() => {
    initForm();
    handleSearch();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Render */
  return (
    <>
      <Form
        form={searchform}
        onFinish={handleFinish}
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        className="common-search-area-form"
      >
        <Row>
          <Col span={8}>
            <Form.Item name="mg_device_key" label={t("device_manage_key")} className="form-input">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={`device_name`} label={t("device_manage_name")} className="form-input">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={`model`} label={t("device_manage_model")} className="form-input">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item name={`comp`} label={t("device_manage_company")} className="form-input">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={`asset`} label={t("device_manage_asset")} className="form-input">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={`car_number`}
              label={t("device_manage_vehicle_number")}
              className="form-input"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{display: "flex", justifyContent: "space-between"}}>
            <Row>
              <XaConfirmButton
                contents={t("device_manage_remove_device_confirm")}
                onConfirm={onConfirmRemoveDevice}
                btnName={t("button_device_remove")}
                disabled={selectedRowKeys.length === 0}
                placement="top"
              />
            </Row>
            <Row>
              <Button type="dashed" onClick={handleAddDevice}>
                {t("button_add_device")}
              </Button>
              <Button className="button-margin-right" type="primary" htmlType="submit">
                {t("button_search")}
              </Button>
              <Button onClick={initForm}>{t("button_clear")}</Button>
            </Row>
          </Col>
        </Row>
      </Form>

      <TableRender
        rowClassName="table-row-action"
        rowKey="mg_device_key"
        columns={columns}
        dataSource={initData}
        size="small"
        onRow={onRowInitDataList}
        rowSelection={rowSelection}
        isLoading={isShowLoading}
      />

      {isOpenDeviceMGDetailsModalForm && (
        <Modal
          title={t("device_manage_device_form_title")}
          open={isOpenDeviceMGDetailsModalForm}
          onOk={handleOk}
          onCancel={handleCancel}
          width={500}
          footer={null}
          maskClosable={true}
          getContainer={false}
        >
          <DeviceMGDetails selectedDeviceMGData={selectedData} onFinish={handleOk} />
        </Modal>
      )}
    </>
  );
};

/* Exports */
export default DeviceMng;
