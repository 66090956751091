import React, {useState, useEffect, useRef} from "react";
import Axios from "axios";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {Row, Col, Checkbox, Divider, Spin} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import DxfParser from "dxf-parser";
import {ConvertDXFToThree} from "../../lib/ConvertDXFToThree";
import {xaMessage, errorHandler, LoadingAnimationWholePage} from "../../common";

export const DXFDispatch = React.createContext(null);

const TargetViewer = (props) => {
  const [dxfLayers, setDxfLayers] = useState([]);
  const [dxfPosition, setDxfPosition] = useState({
    size: {x: null, y: null, z: null},
    center: {x: null, y: null, z: null},
  });
  const [checkedList, setCheckedList] = useState([]);
  const [targetFileName, setTargetFileName] = useState("");
  const [isShowLoading, setIsShowLoading] = useState(true);

  /** i18next */
  const {t} = useTranslation();

  const axios = Axios.create();

  const RenderElement = useRef();
  const DXFData = useRef();
  const DXF3DObj = useRef();

  const scene = useRef();
  const camera = useRef();
  const renderer = useRef();
  const controls = useRef();

  const drawDXF = () => {
    DXF3DObj.current = new ConvertDXFToThree(DXFData.current);
    DXF3DObj.current.convert();
    scene.current.add(DXF3DObj.current.rtnGeometry);

    //camera, controls, selection,
    fitCameraToObject(camera.current, controls.current, DXF3DObj.current.rtnGeometry);
  };

  const initThree = () => {
    const _renderDOM = RenderElement.current; //div

    let width = _renderDOM.offsetWidth;
    let height = _renderDOM.offsetHeight;

    scene.current = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    renderer.current = new THREE.WebGLRenderer();
    renderer.current.setSize(width, height);

    // _renderDOM.appendChild( renderer.domElement );  // 객체 복사로서 render 가 두개 생김
    RenderElement.current.appendChild(renderer.current.domElement);

    const light = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
    scene.current.add(light);

    camera.current.position.z = 2;

    // 카메라 및 마우스의 상호 작용을 위해 OrbitControls 설정
    controls.current = new OrbitControls(camera.current, renderer.current.domElement);
    controls.current.rotateSpeed = 1.0; // 마우스로 카메라를 회전시킬 속도입니다. 기본값(Float)은 1입니다.
    controls.current.zoomSpeed = 1.2; // 마우스 휠로 카메라를 줌 시키는 속도 입니다. 기본값(Float)은 1입니다.
    controls.current.panSpeed = 0.8; // 패닝 속도 입니다. 기본값(Float)은 1입니다.
    // this.controls.minDistance = 5; // 마우스 휠로 카메라 거리 조작시 최소 값. 기본값(Float)은 0 입니다.
    // this.controls.maxDistance = 100; // 마우스 휠로 카메라 거리 조작시 최대 값. 기본값(Float)은 무제한 입니다.

    // const axesHelper = new THREE.AxesHelper( 5 );
    // scene.add( axesHelper );

    // const helper = new THREE.CameraHelper( camera );
    // scene.add( helper );

    let animate = () => {
      requestAnimationFrame(animate);
      renderer.current.render(scene.current, camera.current);

      controls.current.update();
    };
    animate();
  };

  //didmount
  useEffect(() => {
    initThree();
    return () => {
      // clean three
      if (DXF3DObj.current && DXF3DObj.current.rtnGeometry) {
        scene.current.remove(DXF3DObj.current.rtnGeometry);

        setDxfPosition({
          size: {x: null, y: null, z: null},
          center: {x: null, y: null, z: null},
        });
      }
    };
  }, []);

  const onCHKLayersChange = (checkedValues) => {
    setCheckedList(checkedValues);
    for (let value in DXF3DObj.current.rtnGeometry.children) {
      let obj = DXF3DObj.current.rtnGeometry.children[value];
      if (obj)
        obj.visible = checkedValues.filter((item) => item === obj.name).length > 0 ? true : false;
    }
  };

  const fitCameraToObject = (camera, controls, object, fitOffset = 1.2) => {
    const box = new THREE.Box3();
    box.expandByObject(object);

    const size = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());

    setDxfPosition({
      size: {x: size.x, y: size.y, z: size.z},
      center: {x: center.x, y: center.y, z: center.z},
    });

    const maxSize = Math.max(size.x, size.y, size.z);
    const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / camera.aspect;
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = controls.target
      .clone()
      .sub(camera.position)
      .normalize()
      .multiplyScalar(distance);

    controls.maxDistance = distance * 10;
    controls.target.copy(center);

    camera.near = distance / 100;
    camera.far = distance * 100;
    camera.updateProjectionMatrix();

    camera.position.copy(controls.target).sub(direction);

    controls.update();
  };

  useEffect(() => {
    /* jobSq */
    if (props.state.target_data_sq != null) {
      if (DXF3DObj.current && DXF3DObj.current.rtnGeometry) {
        scene.current.remove(DXF3DObj.current.rtnGeometry);

        setDxfPosition({
          size: {x: null, y: null, z: null},
          center: {x: null, y: null, z: null},
        });
        setDxfLayers([]);
      }

      const _targetFileName =
        props.state.f_input_date1 === props.state.target_input_date
          ? `target_${props.state.project_mng_sq_t_project_mng}_${props.state.target_input_date}_original.dxf`
          : `target_${props.state.project_mng_sq_t_project_mng}_${props.state.f_input_date1}_original.dxf`;
      setTargetFileName(_targetFileName);
      const url = `/Storage/data_pcd/${props.state.project_mng_sq_t_project_mng}/${props.state.f_input_date1}/${_targetFileName}`;

      setIsShowLoading(true);

      axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            if (res.data && res.data.status) {
              errorHandler(t("message_error_beginning"), res.data.message, null);
            } else {
              const fileContent = res.data;
              let parser = new DxfParser();

              DXFData.current = parser.parseSync(fileContent);
              setDxfLayers(Object.keys(DXFData.current.tables.layer.layers));
              setCheckedList(Object.keys(DXFData.current.tables.layer.layers));
              drawDXF();
            }
          } else {
            setIsShowLoading(false);
            errorHandler(t("message_error_beginning"), res.data.message);
          }
          setIsShowLoading(false);
        })
        .catch((e) => {
          setIsShowLoading(false);
          errorHandler(t("message_error_beginning"), e);
        });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.target_data_sq]);

  /* Renderer */
  return (
    <>
      <LoadingAnimationWholePage
        isLoading={isShowLoading}
        component={
          <>
            <div style={{position: "relative"}}>
              <Row>
                <Col span={18}>
                  <div ref={RenderElement} style={{width: "700px", height: "600px"}}></div>
                </Col>
                <Col span={6}>
                  <Divider orientation="left">{t("jobs_target_targetViewer_file_info")}</Divider>
                  <Row>
                    <p>
                      {/* target_{props.state.project_mng_sq_t_project_mng}_
                      {props.state.f_input_date1 === props.state.target_input_date
                        ? props.state.target_input_date
                        : props.state.f_input_date1}
                      _original.dxf */}
                      {targetFileName}
                    </p>
                  </Row>
                  <Divider orientation="left">{t("jobs_target_targetViewer_layer")}</Divider>
                  <Row className="target-info-list-area">
                    <Checkbox.Group
                      options={dxfLayers.map((item) => ({label: item, value: item}))}
                      value={checkedList}
                      onChange={onCHKLayersChange}
                    />
                  </Row>
                  <Divider orientation="left">{t("jobs_target_targetViewer_position")}</Divider>
                  <Row>
                    <p>
                      X: {dxfPosition.center.x}
                      <br />
                      Y: {dxfPosition.center.y}
                      <br />
                      Z: {dxfPosition.center.z}
                    </p>
                  </Row>{" "}
                  <Divider orientation="left">{t("jobs_target_targetViewer_size")}</Divider>
                  <Row>
                    <p>
                      X: {dxfPosition.size.x}
                      <br />
                      Y: {dxfPosition.size.y}
                      <br />
                      Z: {dxfPosition.size.z}
                    </p>
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        }
      />
    </>
  );
};

/* Exports */
export default TargetViewer;
