import React, {useState, useEffect} from "react";
import {Row, Col, Button} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import config from "../../lib/env";
import {convertLineStringToArray} from "../../utils/mappingUtils";
import {useTranslation} from "react-i18next";
import {convertDatetimeString} from "../../utils/stringHelper";
import {LoadingAnimationWholePage, TableRenderNoPage} from "../../common";
import RouteViewer from "./RouteViewer";

const Cesium = window.Cesium;
Cesium.Ion.defaultAccessToken = config.ion_defaultAccessToken;

/* Main Component */
const ParanormalAssetDetail = (props) => {
  const {onCancel} = props;
  const {t} = useTranslation();

  const [isShowModalLoading, setIsShowModalLoading] = useState(false); // 로딩 애니메이션 State
  const [assetDetailInfo, setAssetDetailInfo] = useState(null); // 해당 장비 상세 정보 State
  const [routeHistory, setRouteHistory] = useState(null); // 해당 시각대 경로 정보 State
  const [selectedRowKeys, setSelectedRowKeys] = useState([0]); // 선택 라디오 버튼의 Key
  const [routeLocData, setRouteLocData] = useState(null);

  const columnsRoute = [
    {
      title: t("paranormal_assets_modal_table_column_start_time"),
      dataIndex: "f_start_time",
      key: "col01",
      render: (start) => handleConvertTimeString(start),
    },
    {
      title: t("paranormal_assets_modal_table_column_end_time"),
      dataIndex: "f_end_time",
      key: "col02",
      render: (end) => handleConvertTimeString(end),
    },
  ];

  // Table Checkbox 제어 조건 설정
  const handleChangeRadio = (key, data) => {
    const _data = data[0];

    setSelectedRowKeys(key);
    handleMappingRoute(_data);
  };

  // Table Checkbox 제어 조건 설정
  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      handleChangeRadio(selectedRowKeys, selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: !record || !record.f_route_loc_txt || record.f_route_loc_txt === undefined,
      name: record.f_route_loc_txt,
    }),
  };

  // Table 행 클릭 시, 호출 함수
  const handleMappingRoute = (routeInfo) => {
    if (!routeInfo || !routeInfo.f_route_loc_txt || routeInfo.f_route_loc_txt === undefined) {
      return;
    } else {
      setRouteLocData(convertLineStringToArray(routeInfo.f_route_loc_txt));
    }
  };

  // 닫기 버튼 클릭
  const onClickCancel = () => {
    onCancel();
  };

  const handleConvertTimeString = (_str) => convertDatetimeString(_str).split(" ")[1];

  // 초기
  const handleInit = async (assetInfo) => {
    setIsShowModalLoading(true);

    setSelectedRowKeys([]);

    setAssetDetailInfo(assetInfo);

    const _history = props.routesInfo.map((item, index) => {
      return {
        index: index,
        ...item,
      };
    });

    setRouteHistory(_history);

    const foundIndex = _history.findIndex((item) => item.f_route_loc_txt);
    const validatedIndex = foundIndex !== -1 ? foundIndex : 0;

    // 경로 목록 중, 유효한 경로 정보가 있는 Index 중 첫번째 선택
    handleChangeRadio([validatedIndex], [_history[validatedIndex]]);

    setIsShowModalLoading(false);
  };

  useEffect(() => {
    if (props.assetInfo.c_equip_type === "AST002") {
      handleInit(props.assetInfo.seq);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.assetInfo.seq]);

  return (
    <>
      <LoadingAnimationWholePage
        isLoading={isShowModalLoading}
        component={
          <>
            {
              <>
                <Row>
                  <Col className="route-info-detail-col1">
                    <TableRenderNoPage
                      dataSource={routeHistory}
                      columns={columnsRoute}
                      rowSelection={rowSelection}
                      rowKey="index"
                      size="small"
                      bordered={false}
                      pagination={false}
                      scroll={{y: 400}}
                    />
                  </Col>
                  <Col className="route-info-detail-col2">
                    <RouteViewer data={routeLocData} />
                  </Col>
                </Row>
              </>
            }
            <Row className="row-top-margin">
              <Col span={24} className="col-align-right">
                <Button onClick={onClickCancel} size="default">
                  {t("button_close")}
                </Button>
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ParanormalAssetDetail;
