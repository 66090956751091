import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const SectionLineChartViewer = (props) => {
  const defaultColor = ["#ff6384", "#36a2eb", "#cc65fe", "#ffce56", "#444444", "#555555"];
  const initData = {
    labels: [0, 100, 200, 300, 400, 500],
    datasets: [],
  };
  const [data, setData] = useState(initData);

  const [options, setOption] = useState({
    plugin: {
      title: {
        display: true,
        text: "Section Chart",
      },
    },
    scales: {
      x: {
        ticks: { maxTicksLimit: 10 },
      },
    },
  });

  //effect station list
  useEffect(() => {
    setData(initData);
    if (props.stationChartList && props.stationChartList.length > 0) {
      let max = 100;
      let _dataset = props.stationChartList.map((item) => {
        let _data = item.f_rock_line.split(",").map((d) => {
          let tmp = d
            .split(" ")
            .filter((x) => x !== "")
            .map((x) => parseFloat(x).toFixed(0));
          let obj = { x: Number(tmp[0]), y: Number(tmp[1]) };
          return obj;
        });

        let itemArr = _data.map((mItem) => mItem.x).sort((a, b) => b - a);
        max = itemArr[0] > max ? itemArr[0] : max;

        return {
          label: item.c_rock_name,
          data: _data,
          fill: false,
          radius: 1,
          backgroundColor: defaultColor[item.f_rock_num],
          borderColor: defaultColor[item.f_rock_num],
        };
      });

      let _title = {
        display: true,
        text: props.stationChartList[0].c_station_name,
      };
      let labels = [];
      for (let i = 0; i <= max + 5; i++) {
        labels.push(Number(i));
      }
      setData({ labels: labels, datasets: _dataset });
      setOption({ ...options, plugins: { title: _title } });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stationChartList]);

  return (
    <>
      <Line data={data} options={options} />
    </>
  );
};

export default SectionLineChartViewer;
