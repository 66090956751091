import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Input, Button, Checkbox, Divider} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {xaMessage, errorHandler} from "../../common";
import "./index.css";
import {NOT_AVAILABLE_MENU_AUTH} from "../../config/miscellaneousInfo";

/* Main Component */
const MenuDetail = (props) => {
  /** Constants */
  const disabledMenu = NOT_AVAILABLE_MENU_AUTH;
  // i18next
  const {t} = useTranslation();

  /** State */
  const {onCancel, adminPermissinInfo, adminMenuSq} = props;
  const [mode, setMode] = useState("new");
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(null);
  const [adminPermissionList, setAdminPermissionList] = useState([]);
  const [form] = Form.useForm();
  const axios = Axios.create();

  const saveMenuInfo = async (paramValue) => {
    if (!paramValue.f_menu_url || !paramValue.f_admin_menu_nm || !paramValue.permissionMenu) {
      xaMessage("error", t("message_please_check"));
      return;
    } else {
      let params = {
        adminMenuSq: mode === "new" ? null : paramValue.admin_menu_sq,
        adminMenuNm: paramValue.f_admin_menu_nm,
        menuUrl: paramValue.f_menu_url,
        menuOrder: paramValue.f_menu_order,
        description: paramValue.f_description,
        permissionMenu: paramValue.permissionMenu,
      };

      if (mode === "new") {
        await Axios.post("/menu", {
          params: params,
        })
          .then((data) => {
            xaMessage("success", t("message_save_success"));
            onCancel();
          })
          .catch((err) => {
            errorHandler("", err);
          });
      } else if (mode === "update") {
        await Axios.put(`/menu/${params.adminMenuSq}`, {
          params: params,
        })
          .then((data) => {
            xaMessage("success", t("message_save_success"));
            onCancel();
          })
          .catch((err) => {
            errorHandler("", err);
          });
      }
    }
  };

  const onClickCancel = () => {
    form.resetFields();
    setMode("new");
    onCancel();
  };

  const handleGetMenuDetail = async (adminMenuSq) => {
    if (adminMenuSq) {
      const {data} = await axios.get(`/menu/${adminMenuSq}`);
      const objData = {
        ...data,
        permissionMenu: data.permissionInfo.map((item) => item.auth_mng_sq_t_auth_mng),
      };
      setIsCheckboxDisabled(disabledMenu.includes(data.f_menu_url));
      form.setFieldsValue(objData);
      setMode("update");
    } else {
      // 인증 로직
      setIsCheckboxDisabled(false);
      form.resetFields();
      setMode("new");
    }
  };

  useEffect(() => {
    form.resetFields();
    setAdminPermissionList(props.adminPermissinInfo);
    handleGetMenuDetail(props.adminMenuSq);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  /* Renderer */
  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{span: 7}}
        onFinish={saveMenuInfo}
        autoComplete="off"
        size="small"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="admin_menu_sq" label={t("menu_seq")} rules={[{required: false}]}>
              <Input disabled readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="f_menu_order" label={t("menu_sort")}>
              <Input disabled readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="f_admin_menu_nm"
              label={t("menu_name")}
              rules={[
                {
                  required: true,
                  message: t("message_please_input_target"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="f_menu_url"
              label={t("menu_url")}
              rules={[
                {
                  required: true,
                  message: t("message_please_input_target"),
                },
              ]}
            >
              <Input placeholder="/" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="f_description"
              label={t("menu_description")}
              rules={[{required: false}]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("menu_permission")}</Divider>

        <Row>
          <Col span={24}>
            <Form.Item
              name="permissionMenu"
              rules={[{required: true, message: t("message_please_check")}]}
            >
              <Checkbox.Group
                disabled={isCheckboxDisabled}
                style={{width: "100%"}}
                options={adminPermissionList.map((item) => ({
                  label: `(${item.f_auth_nm}) ${item.f_description}`,
                  value: item.auth_mng_sq,
                }))}
              ></Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="col-align-right" span={24}>
            <Button className="button-margin-right" size="default" onClick={onClickCancel}>
              {t("button_cancel")}
            </Button>
            <Button type="primary" size="default" htmlType="submit">
              {t("button_save")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

/* Exports */
export default MenuDetail;
