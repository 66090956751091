/* src/layouts/Main/SideBar/SideBar.js */
/* React */
import React from "react";
import {Menu} from "antd";
import {NavLink} from "react-router-dom";

/* Main Component */
const SideBar = () => {
  /* Constant Variables */
  /**
   * 메뉴 데이터 할당
   * 20230926: DB에서 읽어 로그인 시 회원 및 인증 정보와 같이 BackEnd에서 받는 것으로 변경
   * USR901: 관리자(모든 메뉴), USR101: 도면관리, 이외(USR000 - Operator)
   */
  const menuElements = JSON.parse(sessionStorage.userInfo).permission_menu_info.map(
    (items, index) => {
      return {
        key: index + 1,
        label: <NavLink to={items.href}>{items.label}</NavLink>,
      };
    }
  );

  /* Renderer */
  return <Menu items={menuElements} />;
};

/* Exports */
export default SideBar;
