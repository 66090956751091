/* src/layouts/Main/Main.js */
/* React */
import React from "react";
import PropTypes from "prop-types";

import "antd/dist/antd.css";
import "./index.css";
import "../../utils/axiosUtil";
import {Layout} from "antd";

/* Sub Components */
import HeaderContents from "../Header/Header";
import FooterContens from "../Footer/Footer";
import SideBar from "./SideBar";
import Section from "./Section";

/* Main Compoent */
const Main = (props) => {
  const {Header, Footer, Sider, Content} = Layout;

  /* Props */
  const {children} = props;

  /* Renderer */
  return (
    <Layout>
      <Header>
        <HeaderContents />
      </Header>
      <Layout>
        <Sider>
          <SideBar />
        </Sider>
        <Content>
          <Section>{children}</Section>
        </Content>
      </Layout>
      <Footer>
        <FooterContens />
      </Footer>
    </Layout>
  );
};

/* Main Component Settings */
Main.propTypes = {
  children: PropTypes.node,
};

/* Exports */
export default Main;
