import React, {useEffect, useState, useRef} from "react";
import {Form, Row, Col, Button, Table, message} from "antd";
import "antd/dist/antd.css";
import {xaMessage, errorHandler} from "../../common";
import axios from "axios";

const SectionLineEditor = (props) => {
  const [processStatus, setProcessStatus] = useState(0);

  const [project_mng_sq_t_project_mng, setProject_mng_sq_t_project_mng] = useState();
  const [f_input_date1, setF_input_date1] = useState();

  const [stationList, setStationList] = useState([]);

  const uploadFile = useRef(); //File Object
  const inputFile = useRef(); //file input tag

  const [form] = Form.useForm();

  /* user list table */
  const stationColumns = [
    {
      title: "Section ID",
      width: 100,
      dataIndex: "f_section_id",
    },
    {
      title: "Section Name",
      width: 150,
      dataIndex: "c_section_name",
    },
    {
      title: "Station ID",
      width: 100,
      dataIndex: "f_station_id",
    },
    {
      title: "Station Name",
      width: 200,
      dataIndex: "c_station_name",
    },
    {
      title: "Line Point",
      dataIndex: "f_section_line",
    },
  ];

  useEffect(() => {
    onClear();

    return () => {
      onClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //effect station list
  useEffect(() => {
    setProject_mng_sq_t_project_mng(props.selDataset.project_mng_sq_t_project_mng);
    setF_input_date1(props.selDataset.f_input_date1);
  }, [props.selDataset.project_mng_sq_t_project_mng, props.selDataset.f_input_date1]);

  const fnInit = () => {
    setProcessStatus(0);
    setStationList([]);
    if (inputFile.current !== null) inputFile.current.value = null;
  };
  const handleFiles = async (e) => {
    if (e.target.files.length === 0) return;

    let file = e.target.files[0];
    if (file.name.indexOf(".csv") === -1) {
      message.error("Only CSV File.");
      if (inputFile.current !== null) inputFile.current.value = null;
      return;
    }
    uploadFile.current = file;
  };

  const onFinish = () => {};

  const onClear = async () => {
    form.resetFields();
    if (processStatus === 1) {
      await axios.post("/delteSectionFile");
    }
    fnInit();
  };

  const onUpload = async () => {
    if (!uploadFile.current) return;

    var formData = new FormData();

    formData.append("uploadFile", uploadFile.current);
    formData.append("f_project_mng_sq", project_mng_sq_t_project_mng);
    formData.append("f_create_date", f_input_date1);

    axios
      .post("/loadStationListFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({data}) => {
        if (data) {
          setStationList(data.csvRow);
          if (data.cntData && Number(data.cntData) > 0) {
            xaMessage("info", "Already Data exist. Please, Upload.");
          } else {
            xaMessage("info", "Upload Complete.");
          }

          setProcessStatus(1);
        }
      })
      .catch((err) => {
        errorHandler("", err);
      });
  };

  const onSave = async () => {
    axios
      .post("/loadStationList", {
        f_project_mng_sq: project_mng_sq_t_project_mng,
        f_create_date: f_input_date1,
      })
      .then(({data}) => {
        xaMessage("info", "Upload Complete And Make Section Rock info created.");
        onClear();

        props.lineEditorHandleCancel();
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <>
      <Form name="from1" onFinish={onFinish} form={form}>
        <Row gutter={24}>
          <Col span={8}>
            <input ref={inputFile} type="file" onChange={handleFiles} accept=".csv" />
          </Col>
          <Col>
            <Button href="/section_line_sample.csv" target="_blank">
              Sample CSV
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={onClear}>Clear</Button>
            <Button type="primary" onClick={onUpload}>
              Upload
            </Button>
            {processStatus === 1 && (
              <Button type="primary" onClick={onSave}>
                Save
              </Button>
            )}
          </Col>
        </Row>
      </Form>
      <Table
        columns={stationColumns}
        dataSource={stationList}
        size="small"
        // scroll={{ x: 1500, y: 300 }}
        // rowSelection={rowSelection}
      />
    </>
  );
};

export default SectionLineEditor;
