import {Popconfirm, Tooltip, Button} from "antd";
import "./common.css";
import {useTranslation} from "react-i18next";

/**
 * 공통  Tooltip + Popconfirm + Button 구성 요소
 * @param {*} props
 *   - toolTipContent  Tooltip에 표시할 내용
 *   - btnName 버튼 표시명
 *   - btnType  버튼 유형 (default, primary, dashed, ghost, link, text)
 *   - buttonClassName 버튼에 적용할 CSS Class명
 *   - btnStyle  버튼에 적용할 CSS 코드
 *   - toolTipPlacement  Tooltip 표시 위치
 *   - popPlacement  Popconfirm 표시 위치
 *   - popContent  Popconfirm 확인 시 표시할 문자열
 *   - okText Popconfirm의 OK 버튼에 적용할 문자열
 *   - onConfirm  OK 클릭 시 실행할 함수 내용
 *   - cancelText Popconfirm의 Cancel 버튼에 적용할 문자열
 *   - size 버튼 크기
 *   - disabled  Popconfirm 및 버튼 비활성 여부
 *   - tooltipColor  Tooltip 내부에 적용할 색상 코드
 *   - zIndexTooltip  Tooltip의 zIndex
 * @returns Tooltip + Popconfirm + Button
 */
export const XaConfirmTooltipButton = (props) => {
  // i18next
  const {t} = useTranslation();

  const {
    toolTipContent,
    btnName,
    btnType = "default",
    buttonClassName = "",
    btnStyle,
    toolTipPlacement = "bottomRight",
    popPlacement,
    popContent,
    okText = t("button_ok"),
    onConfirm,
    cancelText = t("button_cancel"),
    size = "middle",
    disabled = false,
    tooltipColor = "#00cfb1",
    zIndexTooltip = 1000,
  } = props;

  return (
    <>
      <Popconfirm
        disabled={disabled}
        title={popContent}
        onConfirm={onConfirm}
        okText={okText}
        cancelText={cancelText}
        placement={popPlacement}
      >
        <Tooltip
          title={toolTipContent}
          placement={toolTipPlacement}
          color={tooltipColor}
          zIndex={zIndexTooltip}
        >
          <Button
            className={buttonClassName}
            style={btnStyle}
            type={btnType}
            disabled={disabled}
            size={size}
          >
            {btnName}
          </Button>
        </Tooltip>
      </Popconfirm>
    </>
  );
};
