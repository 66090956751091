import React, { useState, useRef, useEffect } from "react";
import config from "../../lib/env";
import { Button, message, Row, Col } from "antd";

const Cesium = window.Cesium;
Cesium.Ion.defaultAccessToken = config.ion_defaultAccessToken;

const SectionLineViewer = (props) => {
  const cesiumContainer = useRef();
  const creditContainer = useRef();

  const cesiumViewer = useRef();
  const baseLayer = useRef();

  const [selBaseMapVworld, setSelBaseMapVworld] = useState(0); //layers.layer index

  const containerWidth = props.style.width;
  const containerHeight = props.style.height;

  const layers = [
    { layer: "Base", tileType: "png" },
    { layer: "gray", tileType: "png" },
    { layer: "midnight", tileType: "png" },
    { layer: "Hybrid", tileType: "png" },
    { layer: "Satellite", tileType: "jpeg" },
  ];

  // const selLayer = layers[0];

  useEffect(() => {
    // console.log( 'userEffect no deps')

    baseLayer.current = new Cesium.WebMapTileServiceImageryProvider({
      url: `http://api.vworld.kr/req/wmts/1.0.0/${config.vworld_key}/${layers[selBaseMapVworld].layer}/{TileMatrix}/{TileRow}/{TileCol}.${layers[selBaseMapVworld].tileType}`,
      minimumLevel: 6,
      maximumLevel: 19,
      credit: new Cesium.Credit("VWorld Korea"),
    });

    if (cesiumViewer.current === undefined) {
      cesiumViewer.current = new Cesium.Viewer(cesiumContainer.current, {
        animation: false,
        baseLayerPicker: false,
        fullscreenButton: false,
        vrButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        creditContainer: creditContainer.current,
        imageryProvider: baseLayer.current,
        // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        //   url:
        //     "https://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/",
        // })
      });

      cesiumContainer.current.addEventListener("onResize", () => {
        cesiumViewer.current.canvas.width = cesiumContainer.current.offsetWidth;
        cesiumViewer.current.canvas.height = cesiumContainer.current.offsetHeight;
      });
    }

    cesiumViewer.current.canvas.width = containerWidth; //cesiumContainer.current.offsetWidth
    cesiumViewer.current.canvas.height = containerHeight; //cesiumContainer.current.offsetHeight

    return () => {};
  });

  //effect station list
  useEffect(() => {
    cesiumViewer.current.entities.removeAll();

    let entities = props.stationList.map((item) => {
      let codinate = JSON.parse(item.geometry).coordinates.flat(); //n -> 1

      let _entity = cesiumViewer.current.entities.add({
        name: item.c_station_name,
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray(codinate),
          width: 2,
          material: new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.RED,
          }),
        },
      });

      //station name label
      cesiumViewer.current.entities.add({
        name: "Label_" + item.c_station_name,
        position: Cesium.Cartesian3.fromDegrees(codinate[0], codinate[1]),
        point: {
          pixelSize: 1,
          color: Cesium.Color.BLACK,
          // outlineColor : Cesium.Color.WHITE,
          // outlineWidth : 2
        },
        label: {
          text: item.c_station_name,
          font: "10pt monospace",
          style: Cesium.LabelStyle.FILL,
          fillColor: Cesium.Color.BLACK,
          //scale : 0.5,
          // outlineWidth : 2,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -9),
        },
      });

      return _entity;
    });

    cesiumViewer.current.zoomTo(entities);
    console.log(entities);

    return () => {};
  }, [props.stationList]);

  useEffect(() => {
    // console.log('data deps');
    if (cesiumViewer.current.imageryLayers.length > 1) {
      cesiumViewer.current.imageryLayers.remove(cesiumViewer.current.imageryLayers.get(1));
    }
    //cesiumViewer.current.entities.removeAll();
    if (cesiumViewer.current.dataSources.length > 0) {
      cesiumViewer.current.dataSources.removeAll();
    }

    if (
      !props.selDataset ||
      !props.selDataset.project_mng_sq_t_project_mng ||
      !props.selDataset.f_input_date1
    )
      return;

    let urlGeoJson = `/jobs_getTargetGeoJSON?f_project_mng_sq=${props.selDataset.project_mng_sq_t_project_mng}&target_date=${props.selDataset.f_input_date1}`;
    let promise = Cesium.GeoJsonDataSource.load(urlGeoJson, {
      stroke: Cesium.Color.GRAY,
      fill: Cesium.Color.GRAY.withAlpha(0.5),
      strokeWidth: 0,
    });

    promise
      .then(function (dataSource) {
        cesiumViewer.current.dataSources.add(dataSource);

        cesiumViewer.current.zoomTo(dataSource);
      })
      .otherwise(function (error) {
        message.error("No Target, check data.");
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selDataset.project_mng_sq_t_project_mng, props.selDataset.f_input_date1]);

  const baseMapChangeHandler = (e) => {
    console.log(e);

    fnChangeBasemapVWorld(Number(e.currentTarget.value));
  };

  const fnChangeBasemapVWorld = (gbn) => {
    cesiumViewer.current.imageryLayers.removeAll();

    setSelBaseMapVworld(gbn);

    baseLayer.current = new Cesium.WebMapTileServiceImageryProvider({
      url: `http://api.vworld.kr/req/wmts/1.0.0/${config.vworld_key}/${layers[gbn].layer}/{TileMatrix}/{TileRow}/{TileCol}.${layers[gbn].tileType}`,
      minimumLevel: 6,
      maximumLevel: 19,
      credit: new Cesium.Credit("VWorld Korea"),
    });
    cesiumViewer.current.imageryLayers.addImageryProvider(baseLayer.current);

    baseLayer.current.readyPromise.then(function () {
      // cesiumViewer.current.imageryLayers.addImageryProvider(wmsLayer.current)
    });
  };

  return (
    <>
      <Row>
        <Col>
          <div ref={cesiumContainer} {...props}></div>
          <div style={{ display: "none" }} ref={creditContainer} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button size={"small"} onClick={baseMapChangeHandler} value={0}>
            BaseMap
          </Button>
          <Button size={"small"} onClick={baseMapChangeHandler} value={3}>
            Hybrid
          </Button>
          <Button size={"small"} onClick={baseMapChangeHandler} value={4}>
            Satellite
          </Button>
          <span>*VWorld BaseMAP</span>
        </Col>
      </Row>
    </>
  );
};

export default SectionLineViewer;
