import React, {useEffect, useState, useContext, useCallback} from "react";
import Axios from "axios";
import {Form, Row, Col, Button, Select, Table} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {xaMessage, errorHandler} from "../../common";
import {CrossectionDispatch} from "./CrossectionJobs";
import axios from "axios";

const {Option} = Select;

const SectionJobDetail = (props) => {
  const [processStatus, setProcessStatus] = useState(0);
  const [job, setJob] = useState();
  const [chkModify, setChkModify] = useState(false);

  const [sectionList, setSectionList] = useState([]);
  const {gState} = useContext(CrossectionDispatch);
  const {projectList, layerSourceList, runFlagList} = gState;

  const [form] = Form.useForm();

  /** i18next */
  const {t} = useTranslation();

  /* user list table */
  const sectionColumns = [
    {
      title: "Sec. Name",
      dataIndex: "c_section_name",
    },
    {
      title: "St Count",
      dataIndex: "station_cnt",
    },
  ];

  //component init
  useEffect(() => {
    if (props.selDataset.job_sq) {
      loadJob(props.selDataset.job_sq);
    } else {
      setChkModify(false);
      initFrom();
    }

    if (props.selDataset.project_mng_sq_t_project_mng && props.selDataset.f_input_date1) {
      gnGetSectionList({
        f_project_mng_sq: props.selDataset.project_mng_sq_t_project_mng,
        f_create_date: props.selDataset.f_input_date1,
      });
    } else {
      setSectionList([]);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selDataset]);

  const loadJob = (job_sq) => {
    const fields = form.getFieldsValue();
    axios
      .get("/getSectionJob", {params: {job_sq: props.selDataset.job_sq}})
      .then(async ({data}) => {
        await setJob(data[0]);
        await setProcessStatus(1);

        await form.setFieldsValue({
          ...fields,
          f_project_mng_sq: data[0].project_mng_sq,
          layersource_date: data[0].f_input_date,
          from_subsurface_date: data[0].run_params.from_subsurface_date,
          f_target_date: data[0].run_params.target_tbl_nm.split("_")[2],
          run_flag: data[0].run_flag,
        });
      });
  };

  const initFrom = () => {
    const fields = form.getFieldsValue();

    setJob(null);
    setProcessStatus(0);

    form.setFieldsValue({
      ...fields,
      f_project_mng_sq: props.selDataset.project_mng_sq_t_project_mng,
      layersource_date: props.selDataset.f_input_date1,
      from_subsurface_date: null,
      f_target_date: null,
      run_flag: 0,
    });
  };

  const onFinish = async (paramValue) => {
    onSave();
  };

  const onSave = async () => {
    const frmValues = form.getFieldsValue();
    await axios
      .post("/createSectionJob", {
        params: {
          ...frmValues,
          target_tbl_nm: `target_${frmValues.f_project_mng_sq}_${frmValues.f_target_date}_original`,
          gbn: processStatus,
          job_sq: job ? job.job_sq : null,
        },
      })
      .then(({data}) => {
        xaMessage("success", "Section Analysis Job Saved.");
        props.jobHandleOk();
      })
      .catch((err) => {
        errorHandler("", err.response.data);
      });
  };

  const gnGetSectionList = async (param) => {
    if (param.f_project_mng_sq && param.f_create_date) {
      const {data} = await Axios.get("/sectionList", {params: param});
      setSectionList(data);
    }
  };

  const makeList = (lst, key) => {
    let _rtn = lst.map((item) => {
      return item[key];
    });
    return [...new Set(_rtn)];
  };

  const handleChangeLayerSource = (target) => {
    let param = form.getFieldsValue();

    gnGetSectionList({
      f_project_mng_sq: param.f_project_mng_sq,
      f_create_date: param.layersource_date,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _setButton = useCallback(() => {
    if (processStatus === null || processStatus === 0 || (job && job.run_flag === 0))
      return (
        <Button type="primary" htmlType={"submit"}>
          Create
        </Button>
      );
    else if (processStatus === 1 && job && (job.run_flag === 2 || job.run_flag === 4)) return "";
    else if (processStatus === 1 && job && job.run_flag === 3) return "";
    else
      return (
        <Button type="primary" htmlType={"submit"}>
          Terminate
        </Button>
      );
  });

  const handleDXFDownload = () => {
    const fileName = `${job.project_mng_sq}_${job.f_input_date}.zip`;

    Axios({
      url: "/FileDown", //your url
      method: "GET",
      responseType: "blob", // important
      params: job,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        xaMessage("success", "Download Success");
      })
      .catch((e) => {
        errorHandler(t("message_error_section_job_download"), t("message_please_check"), null);
      });
  };

  const handleCompareSheetDownload = () => {
    const param = form.getFieldsValue();

    if (param.org_target_date == null) {
      xaMessage("warn", t("message_warn_section_job_details_target_date"));
      return;
    }

    const fileName = `${job.project_mng_sq}_${job.f_input_date}_with_target_${param.f_target_date}.xls`;

    Axios({
      url: "/excelCompareSheet", //your url
      method: "GET",
      responseType: "blob", // important
      params: {
        project_mng_sq: param.f_project_mng_sq,
        baseDate: param.org_target_date,
        preDate: param.org_target_date,
        curDate: param.layersource_date,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        xaMessage("success", "Download Success");
      })
      .catch((e) => {
        errorHandler(t("message_error_section_job_download"), t("message_please_check"));
      });
  };

  return (
    <>
      <Form name="from1" onFinish={onFinish} form={form} className="ant-advanced-search-form">
        <Row gutter={24}>
          <Col span={7}>
            <Form.Item
              name={`f_project_mng_sq`}
              label={`Project`}
              rules={[
                {
                  required: true,
                  message: "선택",
                },
              ]}
            >
              <Select disabled style={{width: 180}}>
                <Option>{t("selector_select")}</Option>
                {projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    ({project.project_mng_sq}){project.f_proj_nm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="layersource_date"
              label="Create Date"
              rules={[
                {
                  required: true,
                  message: "Choose require",
                },
              ]}
            >
              <Select disabled={chkModify} onChange={handleChangeLayerSource} style={{width: 220}}>
                <Option>{t("selector_select")}</Option>
                {layerSourceList.map((item) => (
                  <Option key={item.layer_source_sq} value={item.f_input_date}>
                    ({item.f_input_date}){item.f_title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item name="run_flag" label="Status">
              <Select style={{width: 100}} disabled>
                <Option></Option>
                {runFlagList.map((item) => (
                  <Option key={item.key} value={item.key}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="from_subsurface_date"
              label="Copy Section Line"
              rules={[
                {
                  required: false,
                  message: "Choose Optional",
                },
              ]}
            >
              <Select disabled={chkModify} style={{width: "80%"}}>
                <Option>{t("selector_select")}</Option>
                {makeList(layerSourceList, "f_input_date").map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="f_target_date"
              label="target"
              rules={[
                {
                  required: true,
                  message: "Choose require",
                },
              ]}
            >
              <Select disabled={chkModify} style={{width: "80%"}}>
                <Option>{t("selector_select")}</Option>
                {makeList(layerSourceList, "f_target_input_date").map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {processStatus === 1 ? (
          <Row>
            <Col span={12} style={{textAlign: "right"}}>
              {job && job.run_flag === 3 ? (
                <Form.Item
                  name="org_target_date"
                  label="Base target Date"
                  rules={[
                    {
                      required: false,
                      message: "Choose require",
                    },
                  ]}
                >
                  <Select style={{width: 250}}>
                    <Option>{t("selector_select")}</Option>
                    {makeList(layerSourceList, "f_target_input_date").map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
            <Col style={{textAlign: "right"}}>
              {job && job.run_flag === 3 ? (
                <>
                  <Button danger onClick={handleCompareSheetDownload}>
                    CompareSheet(Excel)
                  </Button>
                  <Button danger onClick={handleDXFDownload}>
                    DXF-Zip
                  </Button>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row style={{textAlign: "right"}}>
          <Col span={24}>{_setButton()}</Col>
        </Row>
      </Form>
      <Table
        columns={sectionColumns}
        dataSource={sectionList}
        size="small"
        title={() => "Section Info"}
        // scroll={{ x: 1500, y: 300 }}
        // rowSelection={rowSelection}
      />
    </>
  );
};

export default SectionJobDetail;
