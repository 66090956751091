import {Tooltip, Button} from "antd";
import "./common.css";

/**
 * 공통 XaTooltipButton(XaTooltip + Button) 요소
 * @param {*} props
 *   - title  ToolTip 내용
 *   - placement ToolTip 위치
 *   - btnName 버튼명
 *   - btnType  버튼 유형 (default, primary, dashed, ghost, link, text)
 *   - btnClassName  버튼 Class명
 *   - btnStyle  버튼에 적용할 CSS Style
 *   - onClick  버튼 클릭 시 실행될 함수
 *   - size  버튼 크기 (기본: middle / large, middle, small)
 *   - disabled  비활성 여부
 *   - color ToolTip 색상 (XiteCloud 기본색)
 *   - htmlType Form일 때 Submit 실행 관련
 * @returns Popconfirm
 */
export const XaTooltipButton = (props) => {
  const {
    title,
    placement = "bottomRight",
    btnName,
    btnType = "default",
    btnClassName,
    btnStyle,
    onClick,
    size = "middle",
    disabled = false,
    color = "#00cfb1",
    htmlType,
  } = props;

  return (
    <>
      <Tooltip title={title} placement={placement} color={color}>
        <Button
          className={btnClassName}
          style={btnStyle}
          type={btnType}
          onClick={onClick}
          htmlType={htmlType}
          size={size}
          disabled={disabled}
        >
          {btnName}
        </Button>
      </Tooltip>
    </>
  );
};
