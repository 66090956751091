import React, {useState, useEffect} from "react";
import {Form, Input, Button, Select, Typography, InputNumber, Space} from "antd";
import {EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import Axios from "axios";
import {TableRender, xaMessage, XaConfirmIconButton, errorHandler} from "../../common";

// eslint-disable-next-line no-undef
const AuthMng = (props) => {
  const {onCancel} = props;
  const [mode, setMode] = useState("new");
  const [dsAuthMngList, setDsAuthMngList] = useState([]);
  const [editingKey, setEditingKey] = useState(null);
  const axios = Axios.create();
  const [form] = Form.useForm();

  /** i18next */
  const {t} = useTranslation();

  const isEditing = (record) => record.auth_mng_sq === editingKey;

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    len,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    if (inputType === "number") {
      inputNode = <InputNumber />;
    }
    if (inputType === "select") {
      inputNode = (
        <Select>
          <option value="Y"> Y </option>
          <option value="N"> N </option>
        </Select>
      );
    } else {
      inputNode = <Input {...{maxLength: len}} />;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: t("message_please_input", {target: title}),
                len: {len},
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const colAuthMng = [
    // {
    //   title: "Cat.",
    //   dataIndex: "category",
    //   render: (text, record, index) => {
    //     return <span>{record.category === "new" ? "New" : "Saved"}</span>;
    //   },
    // },
    {
      title: t("user_authmng_table_column_seq"),
      dataIndex: "auth_mng_sq",
      render: (text, record, index) => (record.category === "new" ? "New" : text),
    },
    {
      title: t("user_authmng_table_column_permission"),
      dataIndex: "f_auth_nm",
      editable: true,
    },
    {
      title: t("user_authmng_table_column_description"),
      dataIndex: "f_description",
      editable: true,
    },
    {
      title: t("user_authmng_table_column_del"),
      dataIndex: "f_delete_yn",
      editable: true,
      inputType: "select",
    },
    {
      title: t("user_authmng_table_column_edit"),
      render: (_, record, index) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              size="small"
              style={{
                marginRight: 8,
              }}
              onClick={() => saveData(record.auth_mng_sq)}
            >
              {<CheckOutlined />}
            </Button>
            <XaConfirmIconButton
              size="small"
              contents={t("general_cancel_confirm")}
              icon={<CloseOutlined />}
              onConfirm={editCancel}
            />
          </span>
        ) : (
          <Space>
            <Typography.Link disabled={editingKey != null} onClick={() => editData(record)}>
              {<EditOutlined />}
            </Typography.Link>
            <Typography.Link disabled={editingKey != null}>
              <XaConfirmIconButton
                contents={t("delete_confirm")}
                size="small"
                icon={<DeleteOutlined />}
                onConfirm={() => deleteData(record)}
                disabled={editingKey != null}
              />
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const editCancel = () => {
    setEditingKey(null);
  };

  const editData = (record) => {
    form.setFieldsValue({
      category: record.auth_mng_sq === null ? "new" : "edit",
      auth_mng_sq: "",
      f_auth_nm: "",
      f_description: "",
      f_delete_yn: "N",
      ...record,
    });
    setEditingKey(record.auth_mng_sq);
  };

  const deleteData = async (record) => {
    try {
      if (record.category !== "new") {
        await Axios.delete("/authmng", {params: record});
      }
      const newData = dsAuthMngList.filter((item) => item.auth_mng_sq !== record.auth_mng_sq);
      setDsAuthMngList(newData);
      setEditingKey(null);
    } catch (e) {
      console.log(e);
    }
  };

  const saveData = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dsAuthMngList];
      const index = newData.findIndex((item) => key === item.auth_mng_sq);
      let newRecord = {...newData[index], ...row};
      const _category = newRecord.category;

      if (_category === "new") await Axios.post("/authmng", {params: newRecord});
      else await Axios.put("/authmng", {params: newRecord});

      const item = newData[index];
      newData.splice(index, 1, {...item, ...row, category: ""});

      setDsAuthMngList(newData);
      setEditingKey(null);
    } catch (err) {
      errorHandler(t("message_validate_failed"), err);
    }
  };

  const handleLoadAuthMngData = async () => {
    const {data} = await axios.get("/authmngs");
    setDsAuthMngList(data);
    setMode("update");
    setEditingKey(null);
  };

  const handleTblFooterRender = () => {
    const handleAddAuthMng = () => {
      let _addList = [];
      _addList = _addList.concat(dsAuthMngList);

      _addList.push({
        category: "new",
        auth_mng_sq: "",
        f_auth_nm: "",
        f_description: "",
        f_delete_yn: "N",
      });

      setDsAuthMngList(_addList);
    };

    return (
      <Space>
        <Button size="small" onClick={handleLoadAuthMngData}>
          {t("button_init")}
        </Button>
        <Button type="primary" size="small" onClick={handleAddAuthMng}>
          {t("button_add")}
        </Button>
      </Space>
    );
  };

  const onFinish = async (paramValue) => {
    if (mode === "new") {
      await Axios.post("/api", {params: paramValue})
        .then(({data, status}) => {
          if (status !== "200") {
            xaMessage("warn", t("message_please_check"));
          }
          onCancel();
        })
        .catch((e) => {
          xaMessage("error", e);
        });
    } else {
      await Axios.put(`/api/${paramValue.api_mng_sq}`, {params: paramValue})
        .then((data) => {
          onCancel();
        })
        .catch(function (e) {
          xaMessage("error", e);
        });
    }
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const mergedColumns = colAuthMng.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        len: col.len,
        maxLength: col.maxLength,
      }),
    };
  });

  useEffect(() => {
    handleLoadAuthMngData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      size="small"
    >
      <TableRender
        dataSource={dsAuthMngList}
        columns={mergedColumns}
        rowKey="auth_mng_sq"
        rowClassName="editable-row"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        pagination={false}
        bordered={false}
        footer={handleTblFooterRender}
      />
    </Form>
  );
};

/* Exports */
export default AuthMng;
