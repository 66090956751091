import React, {useState, useEffect} from "react";
import {Form, Input} from "antd";
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import "./index.css";

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 4},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 20},
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {span: 24, offset: 0},
    sm: {span: 20, offset: 4},
  },
};

const DeviceAssignForm = (props) => {
  const form = props.form;
  const [assignList, setAssignList] = useState([]);
  const [initValues, setInitValues] = useState({
    device_key: null,
    subject_id: null,
    st_use_at: null,
    en_use_at: null,
    map_info: null,
    use_yn: "Y",
  });

  /** i18next */
  const {t} = useTranslation();

  // mapping of our components
  const componentMapping = {
    input: Input,
  };

  const FormElement = ({component, label, required, name}) => {
    const Component = componentMapping[component];
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[{required, message: t("device_details_subject_list_form_message")}]}
      >
        <Component />
      </Form.Item>
    );
  };

  useEffect(() => {
    if (props.subject) {
      const map_info_temple = props.subject.map_info_temple;

      let device_selSubject = null;

      if (props.device && props.device.subjects) {
        device_selSubject = props.device.subjects.find(
          (v) => v.subject_id === props.subject.subject_id
        );
      }

      const tblList = [
        {
          component: "input",
          name: "st_use_at",
          label: t("device_details_subject_list_form_start"),
          required: true,
        },
        {
          component: "input",
          name: "en_use_at",
          label: t("device_details_subject_list_form_end"),
          required: true,
        },
      ];

      const formData = {...props.device};

      try {
        if (map_info_temple.type.toUpperCase() === "TABLE") {
          for (const idx in map_info_temple.keys) {
            const _val =
              device_selSubject && device_selSubject.map_info
                ? device_selSubject.map_info.keys[idx]
                : "";

            tblList.push({
              component: "input",
              label: `${map_info_temple.desc[idx]}`,
              name: `${map_info_temple.keys[idx]}`,
              required: true,
            });
            formData[map_info_temple.keys[idx]] = _val;
          }
        }
      } catch (e) {
        console.log(e);
      }
      setAssignList(tblList);
      form.setFieldsValue(formData);
    } else {
      form.resetFields();
      setAssignList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subject]);

  return (
    <>
      <Form layout="vertical" {...props} initialValues={initValues} size="small">
        {/* <Form.Item
        name="st_use_at"
        label="Use Start Day"
        rules={[{ required:true, message: "" }]}
      >
        <Input readOnly={false} placeholder="YYYYMMDD" />
      </Form.Item>
      <Form.Item
        name="en_use_at"
        label="Use End Day"
        rules={[{ required: true, message: "Require Name" }]}
      >
        <Input placeholder="99991231" />
      </Form.Item> */}
        {assignList.map((component) => (
          <FormElement {...component} />
        ))}
      </Form>
    </>
  );
};

/* Exports */
export default DeviceAssignForm;
