import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Button, Select, Modal, Space} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {EditOutlined, ReloadOutlined} from "@ant-design/icons";
import {convertDateString} from "../../utils/stringHelper";
import {TableRender, xaMessage, errorHandler} from "../../common";
import SectionCalcDetail from "./SectionCalcDetail";

const {Option} = Select;

//Global State Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    case "SET_LAYERSOURCE":
      state = {...state, layerSourceList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const CrossectionDispatch = React.createContext(null);

/* Main Component */
const Crossection = (props) => {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [calcList, setCalcList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedSeq, setSelectedSeq] = useState(null);

  /** i18next */
  const {t} = useTranslation();

  const frmInitVal = {
    f_project_mng_sq: null,
    f_create_date: null,
    run_flag: null,
  };

  const [gState, setGState] = useReducer(reducer, {
    projectList: [],
    layerSourceList: [],
    statusFlagList: [
      {key: 0, text: t("section_calc_jobs_status_list_regist")},
      {key: 1, text: t("section_calc_jobs_status_list_running")},
      {key: 2, text: t("section_calc_jobs_status_list_complete")},
      {key: 3, text: t("section_calc_jobs_status_list_error")},
    ],
  });

  const {projectList, layerSourceList, statusFlagList} = gState;
  const [form] = Form.useForm();

  const [selDataset, setSelDataset] = useState({
    project_mng_sq_t_project_mng: null,
    // f_input_date1: null,
    seq: null,
  });

  const [jobDetailModalvisible, setJobDetailModalvisible] = useState(false);

  // 횡단 분석 목록 행(Row) 관련 Action (onRow)
  const handleCalcListRow = (record, _) => {
    return {
      // Row 클릭
      onClick: () => {
        setSelectedProjectId(record.project_mng_sq_t_project_mng);
        setSelectedSeq(record.seq);
        handleGetCalcResult(record);
      },
    };
  };

  /* 횡단 분석 작업 테이블 컬럼 */
  const calcColumns = [
    {
      title: t("section_calc_jobs_table_column_seq"),
      dataIndex: "seq",
      key: "tblSectionList1",
      width: 80,
      render: (text, record) => (
        <Button type="link" size="small" onClick={() => handleOpenCalcDetail(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: t("section_calc_jobs_table_column_project"),
      key: "tblSectionList2",
      dataIndex: "f_proj_nm",
      width: 150,
    },
    {
      title: t("section_calc_jobs_table_column_calc_date"),
      key: "tblSectionList3",
      dataIndex: "f_calc_date",
      width: 100,
      render: (text) => convertDateString(text),
    },
    {
      title: t("section_calc_jobs_table_column_status"),
      key: "tblSectionList4",
      render: (record) => {
        return <>{`${statusFlagList.find((item) => item.key == record.f_calc_status).text}`}</>;
      },
      width: 90,
    },
    {
      title: t("section_calc_jobs_table_column_sector_count"),
      key: "tblSectionList5",
      dataIndex: "cnt_sector",
      width: 80,
    },
    {
      title: t("section_calc_jobs_table_column_station_count"),
      key: "tblSectionList6",
      dataIndex: "cnt_station",
      width: 80,
    },
    {
      title: t("section_calc_jobs_table_column_complete_count"),
      key: "tblSectionList7",
      dataIndex: "com_cnt",
      width: 80,
    },
    {
      title: t("section_calc_jobs_table_column_error_count"),
      key: "tblSectionList8",
      dataIndex: "err_cnt",
      width: 80,
    },
    {
      title: t("section_calc_jobs_table_column_commit_date"),
      key: "tblSectionList9",
      width: 120,
      render: (record) => {
        return (
          <>
            {record && record.f_commit_date
              ? `${convertDateString(record.f_commit_date)} (${record.commit_seq})`
              : ""}
          </>
        );
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: 60,
    //   render: (record) => {
    //     return (
    //       <>
    //         <Space>
    //           <Button
    //             type="link"
    //             size="small"
    //             onClick={() => handleOpenCalcDetail(record)}
    //             icon={<EditOutlined />}
    //           />
    //         </Space>
    //       </>
    //     );
    //   },
    // },
  ];

  const resultColumns = [
    {
      title: t("section_calc_jobs_section_table_column_section"),
      dataIndex: "c_section_name",
      width: 80,
    },
    {
      title: t("section_calc_jobs_section_table_column_station"),
      dataIndex: "c_station_name",
    },
    {
      title: t("section_calc_jobs_section_table_column_result"),
      dataIndex: "f_log_text",
      width: 80,
      render: (text) =>
        text.toLowerCase() === "complete" ? t("section_calc_jobs_status_list_complete") : text,
    },
  ];

  const initForm = async () => {
    form.resetFields();

    try {
      const {data} = await Axios.get("/project");
      setGState({type: "SET_PROJECT", payload: data});
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const handleSearch = async (paramValue) => {
    setIsShowLoading(true);
    const {data} =
      Object.keys(paramValue).length !== 0
        ? await Axios.get("/SectionCalcJobs/getCalcInfoList", {params: paramValue})
        : "";
    setCalcList(data);
    setIsShowLoading(false);

    // setsectionCalcResultList([]);
    setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: paramValue.f_project_mng_sq,
      // f_input_date1: paramValue.f_create_date,
      seq: paramValue.seq,
    });
  };

  const handleChangeProject = async (target) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({...fields, f_create_date: null});

    const {data} = await Axios.get("/SectionCalcJobs/layerSourceList", {
      params: {f_project_mng_sq: target},
    });
    setGState({type: "SET_LAYERSOURCE", payload: data});

    setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: target,
      // f_input_date1: null,
      seq: null,
    });
    setCalcList([]);
    setResultList([]);
  };

  const onFinish = async (paramValue) => {
    handleSearch(paramValue);
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const handleJobOk = () => {
    setJobDetailModalvisible(false);
    setCalcList([]);
    setResultList([]);
  };

  const handleJobCancel = async () => {
    setJobDetailModalvisible(false);
    // setCalcList([]);
    // setsectionCalcResultList([]);

    setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: null,
      // f_input_date1: null,
      seq: null,
    });
  };

  const handleAddJob = () => {
    handleOpenCalcDetail();
  };

  const handleRefreshLog = () => {
    handleGetCalcResult();
  };

  const handleOpenCalcDetail = (record) => {
    const frmValues = form.getFieldsValue();

    if (!frmValues.f_project_mng_sq) {
      xaMessage("warn", t("message_choose_data"));
      return;
    }

    // Edit
    if (record) {
      setSelDataset({
        ...selDataset,
        project_mng_sq_t_project_mng: record.project_mng_sq_t_project_mng,
        seq: record.seq,
      });
    } else {
      // Add job
      setSelDataset({
        ...selDataset,
        project_mng_sq_t_project_mng: frmValues.f_project_mng_sq,
        seq: null,
      });
    }
    setJobDetailModalvisible(true);
  };

  const handleGetCalcResult = async (record) => {
    setResultList([]);
    if (record) {
      setSelDataset({
        ...selDataset,
        project_mng_sq_t_project_mng: record.project_mng_sq,
        seq: record.seq,
      });
    } else {
      record = {
        project_mng_sq_t_project_mng: selectedProjectId,
        seq: selectedSeq,
      };
    }

    try {
      const {data} = await Axios.get("/SectionCalcJobs/sectionCalcResultList", {
        params: {f_project_mng_sq: record.project_mng_sq_t_project_mng, seq: record.seq},
      });
      setResultList(data);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const handleSetResultTitle = () => {
    return selDataset.seq ? (
      <h3>
        <span>
          {t("section_calc_jobs_section_table_title", {seq: selDataset.seq})}
          <Button
            type="link"
            size="small"
            onClick={() => handleRefreshLog()}
            icon={<ReloadOutlined />}
          />
        </span>
      </h3>
    ) : (
      ""
    );
  };

  useEffect(() => {
    initForm();
    handleSearch({});
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <CrossectionDispatch.Provider value={{gState, setGState}}>
      <Form
        form={form}
        name="advanced_search"
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        className="ant-advanced-search-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={frmInitVal}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="f_project_mng_sq"
              label={t("general_project")}
              rules={[
                {
                  required: true,
                  message: t("section_calc_jobs_project_select_placeholder"),
                },
              ]}
            >
              <Select onChange={handleChangeProject} showSearch optionFilterProp="children">
                <Option>{t("selector_select")}</Option>
                {projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    ({project.project_mng_sq}) {project.f_proj_nm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item
              name="f_create_date"
              label="DataSet Date"
              rules={[
                {
                  required: false,
                  message: "Choose optional",
                },
              ]}
            >
              <Select>
                <Option>전체</Option>
                {layerSourceList.map((item) => (
                  <Option key={item.layer_source_sq} value={item.f_input_date}>
                    ({item.f_input_date}){item.f_title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item
              name="run_flag"
              label={t("section_calc_jobs_status")}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select>
                <Option>{t("selector_all")}</Option>
                {statusFlagList.map((item) => (
                  <Option key={item.key} value={item.key}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
            }}
          >
            <Button type="primary" htmlType="submit">
              {t("button_search")}
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => form.resetFields()}
            >
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row gutter={24}>
        <Col span={18}>
          <TableRender
            columns={calcColumns}
            dataSource={calcList}
            rowKey={"seq"}
            isLoading={isShowLoading}
            size="small"
            onRow={handleCalcListRow}
            pagination={false}
            scroll={{y: "550px"}}
          />
        </Col>
        <Col span={6}>
          <TableRender
            columns={resultColumns}
            dataSource={resultList}
            rowKey={"seq"}
            size="small"
            title={handleSetResultTitle}
            pagination={false}
            scroll={{y: "500px"}}
          />
        </Col>
      </Row>

      <Modal
        title={t("section_calc_jobs_details_title")}
        open={jobDetailModalvisible}
        onOk={handleJobOk}
        onCancel={handleJobCancel}
        footer={null}
        width={1000}
        getContainer={false}
        maskClosable={false}
      >
        <SectionCalcDetail
          selDataset={selDataset}
          handleJobOk={handleJobOk}
          handleJobCancel={handleJobCancel}
        />
      </Modal>
    </CrossectionDispatch.Provider>
  );
};

export default Crossection;
