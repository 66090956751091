import React from "react";
import {message, Row, Col, Divider} from "antd";
import {withTranslation} from "react-i18next";
// import vanillaJS Potree libs, /!\ would be best with proper ES6 import
const Potree = window.Potree;
// const THREE = window.THREE;
// const TWEEN = window.TWEEN;

class PotreeViewer extends React.Component {
  viewer;

  constructor(props) {
    super(props);
    this.potreeContainerDiv = React.createRef();
    this.state = {
      boundingBox_Max: {x: null, y: null, z: null},
      position: {x: null, y: null, z: null},
      url: "",
    };
  }

  updatePointcloudState(pointcloudObj) {
    this.setState({...pointcloudObj});
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps");
    this.loadpointcloud(nextProps.selDataset);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate");
    //    this.loadpointcloud()
  }

  componentDidMount() {
    // initialize Potree viewer
    const viewerElem = this.potreeContainerDiv.current;
    this.viewer = new Potree.Viewer(viewerElem);

    this.viewer.setEDLEnabled(true);
    this.viewer.setFOV(60);
    this.viewer.setPointBudget(1 * 1000 * 1000);
    this.viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
    this.viewer.loadSettingsFromURL();

    this.viewer.setControls(this.viewer.earthControls);

    if (this.props != null) {
      console.log(this.props);
      this.loadpointcloud(this.props.selDataset);
    }
  }

  loadpointcloud = (obj) => {
    this.clearPointcloud();
    if (obj) {
      //http://localhost:3030/Storage/data_pcd/0/20200101/layer0.ply
      let url = `/Storage/data_pcd/${obj.project_mng_sq_t_project_mng}/${obj.topo_input_date}/Pointcloud/cloud.js`; // do not forget to put your pointcloud data in the public/pointcloud/ folder

      // await Axis.get(url)
      Potree.loadPointCloud(url)
        .then(
          (e) => {
            // let scene = this.viewer.scene;
            let pointcloud = e.pointcloud;
            let material = pointcloud.material;

            material.activeAttributeName = "rgba";
            material.minSize = 2;
            material.pointSizeType = Potree.PointSizeType.FIXED;

            this.viewer.scene.addPointCloud(pointcloud);

            this.viewer.fitToScreen();
            this.updatePointcloudState({
              boundingBox_Max: e.pointcloud.boundingBox.max,
              position: e.pointcloud.position,
              url: url,
            });
          },
          (e) => {
            this.updatePointcloudState({
              boundingBox_Max: {x: null, y: null, z: null},
              position: {x: null, y: null, z: null},
              url: url,
            });
            message.error(e.message);
            console.log("ERROR: ", e);
          }
        )
        .catch((e) => {
          console.log(e);
        });
    }
  };

  clearPointcloud = () => {
    return new Promise((resolve, reject) => {
      try {
        this.viewer.scene.pointclouds.forEach((layer) => {
          this.viewer.scene.scenePointCloud.remove(layer);
        });
        this.viewer.scene.pointclouds = [];
        return resolve();
      } catch (err) {
        return reject(err);
      }
    });
  };

  fnReset = async () => {
    if (this.props.selDataset) {
      await this.clearPointcloud();
      this.loadpointcloud();
    }
  };

  render() {
    const {t} = this.props;
    return (
      <>
        <Row>
          <Col span={16}>
            <div ref={this.potreeContainerDiv} className={"potree_container "} {...this.props}>
              <div id="potree_render_area"></div>
            </div>
          </Col>
          <Col span={8}>
            <Divider orientation="left">{t("jobs_pcd_detail_potreeViewer_boundingbox")}</Divider>
            <p>
              X: {this.state.boundingBox_Max.x}
              <br />
              Y: {this.state.boundingBox_Max.y}
              <br />
              Z: {this.state.boundingBox_Max.z}
            </p>

            <Divider orientation="left">{t("jobs_pcd_detail_potreeViewer_position")}</Divider>
            <p>
              X: {this.state.position.x}
              <br />
              Y: {this.state.position.y}
              <br />
              Z: {this.state.position.z}
            </p>
            <Divider orientation="left">{t("jobs_pcd_detail_potreeViewer_info")}</Divider>
            <p>{this.state.url}</p>
          </Col>
        </Row>
      </>
    );
  }
}

export default withTranslation()(PotreeViewer);
