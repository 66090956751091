import {Table, Empty} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

/**
 * 공통 테이블 구성 요소
 * @param {*} props
 *   - className  CSS 적용 요소명
 *   - title  테이블 제목 설정
 *   - columns  테이블 컬럼 정의 배열
 *   - dataSource   테이블에 표시할 데이터
 *   - isLoading   로딩 애니메이션 표시 여부
 *   - size   테이블 크기(large, middle, small)
 *   - rowKey   Row Key
 *   - bordered   테두리 여부
 *   - rowSelection   행 선택 시 적용할 이벤트
 *   - onRow   행에 적용할 이벤트
 *   - rowClassName 행에 적용할 CSS Class명
 *   - pagination  페이징 관련
 *   - components
 *   - scroll 테이블 내부 스크롤 여부
 * @returns
 */
export const TableRenderNoPage = (props) => {
  const {
    className,
    title,
    columns,
    dataSource,
    isLoading,
    size,
    rowKey,
    bordered,
    rowSelection,
    onRow,
    rowClassName,
    pagination,
    components,
    scroll,
    footer,
  } = props;

  /** i18next */
  const {t} = useTranslation();

  // Spin Props (로딩 애니메이션 정의)
  const loading = {
    indicator: <LoadingOutlined style={{fontSize: 36}} spin />,
    size: "large",
    tip: t("general_spin_tip"),
  };

  return (
    <Table
      className={className}
      title={!title ? "" : title}
      columns={columns}
      dataSource={dataSource}
      bordered={bordered ? bordered : false}
      rowKey={rowKey}
      loading={isLoading ? loading : false}
      rowSelection={rowSelection}
      onRow={onRow}
      rowClassName={!rowClassName ? "" : rowClassName}
      pagination={pagination}
      components={!components ? "" : components}
      scroll={scroll ? scroll : ""}
      size={size ? size : "small"}
      locale={{
        emptyText: !isLoading ? (
          <Empty description={t("table_empty_content")} />
        ) : (
          <>
            <div className="table-render-loading-tbody" />
          </>
        ),
      }}
      footer={footer ? footer : ""}
    />
  );
};
