import React from "react";
import {Table} from "antd";
import "antd/dist/antd.css";

const SectionLineTableViewer = ({stationChartList}) => {
  /* user list table */
  const stationColumns = [
    {
      title: "c_station_name",
      width: 150,
      dataIndex: "c_station_name",
    },
    {
      title: "Rock Name",
      width: 150,
      dataIndex: "c_rock_name",
    },
    {
      title: "Line point",
      // width: 800,
      dataIndex: "f_rock_line",
    },
  ];

  return (
    <>
      <h2>
        {stationChartList && stationChartList.length > 0 && stationChartList[0].c_station_name}
      </h2>
      <Table
        columns={stationColumns}
        dataSource={stationChartList}
        size="small"
        scroll={{y: 400}}
        pagination={false}
      />
    </>
  );
};

export default SectionLineTableViewer;
