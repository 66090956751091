import React, {useState, useContext, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Input, Button, Select, Checkbox, Divider} from "antd";
import {MailOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {
  replaceIpAddressString,
  shortenString,
  convertDatetimeString,
} from "../../utils/stringHelper";
import {xaMessage, errorHandler, LoadingAnimationWholePage} from "../../common";
import {handleCompareArrayLength} from "../../utils/utils";
import {UserDispatch} from "./Users";
import "./index.css";

const {Option} = Select;

const UserDetail = (props) => {
  const [mode, setMode] = useState("new");
  const [userMngSq, setUserMngSq] = useState(null);
  const [isRequiredPwd, setIsRequiredPwd] = useState(true);
  const [logData, setLogData] = useState([]);
  const [isShowLoad, setIsShowLoad] = useState(false);

  const [isAllAuthUser, setIsAllAuthUser] = useState(false);
  const [indeterminateProjects, setIndeterminateProjects] = useState(true);
  const [checkAllProjects, setCheckAllProjects] = useState(false);

  const {onCancel} = props;

  const [form] = Form.useForm();
  const axios = Axios.create();

  /** i18next */
  const {t} = useTranslation();
  const {gState} = useContext(UserDispatch);

  const columnLog = [
    {
      title: t("user_detail_table_column_datetime"),
      dataIndex: "access_tm",
    },
    {
      title: t("user_detail_table_column_ip"),
      dataIndex: "ip",
      render: (ip) => replaceIpAddressString(ip),
    },
  ];

  const handleLoadUserDetail = async (user_mng_sq) => {
    if (user_mng_sq) {
      setIsShowLoad(true);
      const {data} = await axios.get(`/user/${user_mng_sq}`);
      const objData = {
        ...data,
        userProject: data.userProject.map((item) => item.project_mng_sq_t_project_mng),
        userLoginLog: data.userLoginLog,
      };

      objData.access_tm = convertDatetimeString(objData.access_tm);
      form.setFieldsValue(objData);
      setMode("update");
      setLogData(objData.userLoginLog);
      setIndeterminateProjects(
        handleCompareArrayLength(
          data.userProject,
          gState.projectList.map((project) => project.project_mng_sq),
          "right"
        )
      );
      setCheckAllProjects(
        handleCompareArrayLength(
          data.userProject,
          gState.projectList.map((project) => project.project_mng_sq),
          "equal"
        )
      );
      setIsShowLoad(false);
    } else {
      form.resetFields();
      setMode("new");
    }
  };

  // 좌하단 프로젝트 접근 권한 부여 체크박스 중, "전체"
  const handleCheckboxProjectAll = (e) => {
    const _data = form.getFieldsValue();
    let selectedProjects = [];
    if (e.target.checked) {
      selectedProjects = gState.projectList.map((project) => project.project_mng_sq);
      setCheckAllProjects(true);
      setIndeterminateProjects(false);
    } else {
      setIndeterminateProjects(
        handleCompareArrayLength(
          _data.userProject,
          gState.projectList.map((project) => project.project_mng_sq),
          "right"
        )
      );

      setCheckAllProjects(false);
    }

    form.setFieldsValue({
      ..._data,
      userProject: selectedProjects,
    });
  };

  // const handleSendMail = async (userMngSq) => {
  //   await Axios.post(`/sendMail`, {
  //     params: {
  //       user_mng_sq: userMngSq,
  //     },
  //   });
  // };

  const onClickCancel = async () => {
    form.resetFields();
    setMode("new");
    onCancel();
  };

  const onFinish = async (paramValue) => {
    if (mode === "new") {
      setIsShowLoad(true);
      await Axios.post("/user", {params: paramValue})
        .then((data) => {
          xaMessage("success", t("message_save_success"));
          onCancel();
        })
        .catch((e) => {
          errorHandler(t("message_error_beginning"), e);
        });
      setIsShowLoad(false);
    } else {
      setIsShowLoad(true);
      await Axios.put(`/user/${paramValue.user_mng_sq}`, {params: paramValue})
        .then((data) => {
          xaMessage("success", t("message_save_success"));
          onCancel();
        })
        .catch((e) => {
          errorHandler(t("message_error_beginning"), e);
        });
      setIsShowLoad(false);
    }
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err, null);
  };

  useEffect(() => {
    let usr = JSON.parse(sessionStorage.userInfo);
    const _isAllAuthUser = ["USR901"].includes(usr.c_user_type);
    setIsAllAuthUser(_isAllAuthUser);

    if (mode === "update") {
      setIsRequiredPwd(!_isAllAuthUser);
    }

    form.resetFields();
    setUserMngSq(props.userMngSq);
    handleLoadUserDetail(props.userMngSq);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userMngSq]);

  /* Renderer */
  return (
    <>
      <LoadingAnimationWholePage
        isLoading={isShowLoad}
        component={
          <>
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{span: 6}}
              autoComplete="off"
              size="small"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="user_mng_sq"
                    label={t("user_detail_user_number")}
                    rules={[{required: false}]}
                  >
                    <Input disabled readOnly={true} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="company_mng_sq_t_company_mng"
                    label={t("user_detail_company")}
                    rules={[{required: true, message: t("user_detail_company_select_company")}]}
                  >
                    <Select placeholder={t("user_detail_company_select_company")}>
                      <Option>{t("selector_select")}</Option>
                      {gState.companyList.map((company) => (
                        <Option key={company.company_mng_sq} value={company.company_mng_sq}>
                          {company.f_comp_nm}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="user_email_id"
                    label={t("user_detail_user_email")}
                    rules={[{required: true, message: t("user_detail_user_email_message")}]}
                  >
                    <Input placeholder={t("user_detail_user_email_message")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="f_user_nm"
                    label={t("user_detail_user_name")}
                    rules={[{required: true, message: t("user_detail_user_name_message")}]}
                  >
                    <Input placeholder={t("user_detail_user_name_message")} />
                  </Form.Item>
                </Col>
              </Row>
              {mode === "new" ? (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="user_password"
                      label={t("user_detail_user_password_new")}
                      rules={[{required: true, message: t("user_detail_user_password_message")}]}
                    >
                      <Input.Password
                        placeholder={t("user_detail_user_password_message")}
                        htmlType="password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="user_password"
                      label={t("user_detail_user_password")}
                      rules={[{required: false, message: t("user_detail_user_password_message")}]}
                    >
                      <Input.Password
                        placeholder={t("user_detail_user_password_message")}
                        htmlType="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="new_user_password"
                      label={t("user_detail_user_password_new")}
                      rules={[
                        {required: false, message: t("user_detail_user_password_new_message")},
                      ]}
                    >
                      <Input.Password
                        placeholder={t("user_detail_user_password_new_message")}
                        htmlType="password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="f_user_tel"
                    label={t("user_detail_user_telno")}
                    rules={[{required: false, message: t("user_detail_user_telno_message")}]}
                  >
                    <Input placeholder={t("user_detail_user_telno_message")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="c_user_type"
                    label={t("user_detail_user_type")}
                    rules={[{required: true, message: t("user_detail_user_type_select_message")}]}
                  >
                    <Select placeholder={t("user_detail_user_type_select_message")}>
                      <Option>{t("selector_select")}</Option>
                      {gState.userTypeList.map((code) => (
                        <Option
                          key={`${code.code1}${code.code2}`}
                          value={`${code.code1}${code.code2}`}
                        >
                          {code.code_txt} ({`${code.code1}${code.code2}`})
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="f_description"
                    label={t("user_detail_description")}
                    rules={[
                      {
                        required: false,
                        message: t("user_detail_description_message"),
                      },
                    ]}
                  >
                    <Input.TextArea rows={1} placeholder={t("user_detail_description_message")} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={mode === "new" ? 24 : 14}>
                  <Divider orientation="left">{t("user_detail_divider_permissions")}</Divider>
                  {isAllAuthUser &&
                    mode === "update" &&
                    JSON.parse(sessionStorage.getItem("userInfo")).c_user_type === "USR901" && (
                      <Checkbox
                        indeterminate={indeterminateProjects}
                        onChange={handleCheckboxProjectAll}
                        checked={checkAllProjects}
                      >
                        {t("general_all")}
                      </Checkbox>
                    )}
                  <Form.Item name="userProject" rules={[{required: false}]}>
                    <Checkbox.Group
                      className="project-permission-list"
                      onChange={() => {
                        const _data = form.getFieldsValue();

                        setIndeterminateProjects(
                          handleCompareArrayLength(
                            _data.userProject,
                            gState.projectList.map((project) => project.project_mng_sq),
                            "right"
                          )
                        );
                        setCheckAllProjects(
                          handleCompareArrayLength(
                            _data.userProject,
                            gState.projectList.map((project) => project.project_mng_sq),
                            "equal"
                          )
                        );
                      }}
                      options={gState.projectList.map((project) => ({
                        label: `(${project.project_mng_sq}) ${
                          project.f_proj_nm.length < 30
                            ? project.f_proj_nm
                            : shortenString(30, project.f_proj_nm)
                        }`,
                        value: project.project_mng_sq,
                      }))}
                    />
                  </Form.Item>
                </Col>

                {mode !== "new" && (
                  <Col span={10}>
                    <Divider orientation="left">{t("user_detail_divider_recent")}</Divider>
                    <>
                      <div className="recent-access">
                        <table>
                          <thead>
                            <tr>
                              {columnLog.map((columnItem) => (
                                <th>{columnItem.title}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {logData.length > 0 ? (
                              logData.map((item) => (
                                <tr>
                                  <td>{convertDatetimeString(item.access_tm)}</td>
                                  <td>{replaceIpAddressString(item.ip)}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colspan={2}>{t("table_empty_content")}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  </Col>
                )}
              </Row>

              <Row gutter={16} className="modal-button-area">
                <Col className="col-align-right" span={24}>
                  {/* <Button
                    size="default"
                    className="button-margin-right"
                    type="primary"
                    icon={<MailOutlined />}
                    onClick={() => handleSendMail(userMngSq)}
                  /> */}
                  <Button className="button-margin-right" size="default" onClick={onClickCancel}>
                    {t("button_cancel")}
                  </Button>
                  <Button type="primary" size="default" htmlType="submit">
                    {t("button_save")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        }
      />
    </>
  );
};

/* Exports */
export default UserDetail;
