import io from "socket.io-client";
export const SOCKET_IO_LOG = "/log";

// XiteAdmin BackEnd Socket 연결
let connSocketAdmin;
export const socketAdmin = (path) => {
  if (!connSocketAdmin) {
    connSocketAdmin = io.connect(path, {
      reconnection: true, // default: true
      reconnectionDelay: 1000, // default: 1000
      reconnectionAttempts: 20, // default: Infinity
      forceNew: false, // default: false
      timeout: 2000, // default: 20000
    });
  }

  return connSocketAdmin;
};

// Channel 활용 시 사용
let connSocket;
export const socket = (path, token) => {
  if (!connSocket) {
    connSocket = io.connect(path, {
      auth: {
        token: token,
      },
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: 20,
      forceNew: false,
      query: {
        token: token,
      },
    });
  }

  return connSocket;
};
