/* React */
import React from "react";
import { Redirect, Route } from "react-router-dom";

const RouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => {
  if (sessionStorage.userInfo === undefined) {
    return <Redirect to={{ pathname: "/" }} />;
  } else {
    let data = JSON.parse(sessionStorage.userInfo);
    if (data.c_user_type !== "USR901"
      && ["/LogJob", "/Codes", "/Api", "/AuthApi", "/Users"].includes(rest.path)
    ) {
      return <Redirect to={{ pathname: "/" }} />;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

/* Exports */
export default RouteWithLayout;
