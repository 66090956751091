import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Button, Select, Modal, Table, Space, message} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import SectionJobDetail from "./SectionJobDetail";
import {EditOutlined, ReloadOutlined} from "@ant-design/icons";

const {Option} = Select;

//Global State Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    case "SET_LAYERSOURCE":
      state = {...state, layerSourceList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const CrossectionDispatch = React.createContext(null);

/* Main Component */
const Crossection = (props) => {
  const [sectionJobList, setSectionJobList] = useState([]);
  const [sectionJobLogList, setSectionJobLogList] = useState([]);
  const frmInitVal = {
    f_project_mng_sq: null,
    f_create_date: null,
    run_flag: null,
  };

  const [gState, setGState] = useReducer(reducer, {
    projectList: [],
    layerSourceList: [],
    runFlagList: [
      {key: 0, text: "Ready"},
      {key: 1, text: "Running"},
      {key: 2, text: "Error"},
      {key: 3, text: "Complete"},
      {key: 4, text: "Cancel"},
    ],
  });
  const {projectList, layerSourceList, runFlagList} = gState;

  const [form] = Form.useForm();

  const [selDataset, setSelDataset] = useState({
    project_mng_sq_t_project_mng: null,
    f_input_date1: null,
    job_sq: null,
  });

  const [jobDetailModalvisible, setJobDetailModalvisible] = useState(false);

  /** i18next */
  const {t} = useTranslation();

  /* user list table */
  const sectionJobColumns = [
    {
      title: "seq",
      dataIndex: "job_sq",
      key: "seq",
      width: 50,
    },
    {
      title: "Status",
      key: "run_flag",
      render: (record) => {
        return (
          <>
            <Space size="small">
              {" "}
              {`${runFlagList.find((item) => item.key === record.run_flag).text}`}
            </Space>{" "}
          </>
        );
      },
      width: 100,
    },
    {
      title: "PID",
      key: "pid",
      dataIndex: "pid",
    },
    {
      title: "User",
      key: "create_user_mng_sq",
      dataIndex: "create_user_mng_sq",
    },
    {
      title: "Create",
      key: "create_at",
      dataIndex: "create_at",
      width: 150,
    },
    {
      title: "Complete",
      key: "complete_at",
      dataIndex: "complete_at",
      width: 150,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        return (
          <>
            <Space>
              <Button type="link" onClick={() => fnOpenJobDetail(record)} icon={<EditOutlined />} />
            </Space>
          </>
        );
      },
    },
  ];

  const jobLogColumns = [
    {
      title: "TimeStamp",
      dataIndex: "create_at",
      width: 150,
    },
    {
      title: "Log",
      dataIndex: "log",
    },
  ];

  const fnInitForm = async () => {
    form.resetFields();

    try {
      const {data} = await Axios.get("/project");
      setGState({type: "SET_PROJECT", payload: data});
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleChangeProject = async (target) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({...fields, f_create_date: null});

    const {data} = await Axios.get("/layerSourceList", {params: {f_project_mng_sq: target}});
    setGState({type: "SET_LAYERSOURCE", payload: data});

    await setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: target,
      f_input_date1: null,
      job_sq: null,
    });
    setSectionJobList([]);
    setSectionJobLogList([]);
  };

  const onFinish = async (paramValue) => {
    // console.log( paramValue )
    const {data} = await Axios.get("/sectionJobList", {params: paramValue});
    setSectionJobList(data);

    setSectionJobLogList([]);
    setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: paramValue.f_project_mng_sq,
      f_input_date1: paramValue.f_create_date,
      job_sq: null,
    });
  };

  const onFinishFailed = (err) => {
    console.log(err);
  };

  const jobHandleOk = () => {
    setJobDetailModalvisible(false);
    setSectionJobList([]);
    setSectionJobLogList([]);
  };

  const jobHandleCancel = async () => {
    setJobDetailModalvisible(false);
    // setSectionJobList([]);
    // setSectionJobLogList([]);

    await setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: null,
      f_input_date1: null,
      job_sq: null,
    });
  };

  const handleAddJob = () => {
    fnOpenJobDetail();
  };

  const handleRefreshLog = () => {
    fnGetJobLogs();
  };

  const fnOpenJobDetail = async (record) => {
    const frmValues = form.getFieldsValue();

    if (!frmValues.f_project_mng_sq) {
      message.error("Choose data~");
      return;
    }

    //edit
    if (record) {
      await setSelDataset({
        ...selDataset,
        project_mng_sq_t_project_mng: record.project_mng_sq,
        f_input_date1: record.f_input_date,
        job_sq: record.job_sq,
      });
      //add job
    } else {
      await setSelDataset({
        ...selDataset,
        project_mng_sq_t_project_mng: frmValues.f_project_mng_sq,
        f_input_date1: frmValues.f_create_date,
        job_sq: null,
      });
    }
    setJobDetailModalvisible(true);
  };

  const fnGetJobLogs = async (record) => {
    if (record)
      await setSelDataset({
        ...selDataset,
        project_mng_sq_t_project_mng: record.project_mng_sq,
        f_input_date1: record.f_input_date,
        job_sq: record.job_sq,
      });
    else record = selDataset;

    try {
      const {data} = await Axios.get("/sectionJobLogList", {params: {job_sq: record.job_sq}});
      setSectionJobLogList(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  // const handleFileDown = (record) => {
  //   const fileName = `${record.project_mng_sq}_${record.f_input_date}.zip`;

  //   Axios({
  //     url: "/FileDown", //your url
  //     method: "GET",
  //     responseType: "blob", // important
  //     params: record,
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", fileName); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((e) => {
  //       //console.log(e)
  //       message.error("fileDownLoad Error. require checkout job status");
  //     });
  // };

  useEffect(() => {
    fnInitForm();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <CrossectionDispatch.Provider value={{gState, setGState}}>
      <Form
        form={form}
        name="advanced_search"
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        className="ant-advanced-search-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={frmInitVal}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name={`f_project_mng_sq`}
              label={`Project`}
              rules={[
                {
                  required: true,
                  message: "선택",
                },
              ]}
            >
              <Select onChange={handleChangeProject} showSearch>
                <Option>{t("selector_select")}</Option>
                {projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    ({project.project_mng_sq}) {project.f_proj_nm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="f_create_date"
              label="DataSet Date"
              rules={[
                {
                  required: false,
                  message: "Choose optional",
                },
              ]}
            >
              <Select>
                <Option>전체</Option>
                {layerSourceList.map((item) => (
                  <Option key={item.layer_source_sq} value={item.f_input_date}>
                    ({item.f_input_date}){item.f_title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="run_flag"
              label="Status"
              rules={[
                {
                  required: false,
                  message: "Choose optional",
                },
              ]}
            >
              <Select>
                <Option>전체</Option>
                {runFlagList.map((item) => (
                  <Option key={item.key} value={item.key}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
            }}
          >
            <Button type="dashed" onClick={handleAddJob}>
              Add Section Job
            </Button>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
      </Form>
      <Row gutter={24}>
        <Col span={12}>
          <Table
            columns={sectionJobColumns}
            dataSource={sectionJobList}
            rowKey={"job_sq"}
            size="small"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  fnGetJobLogs(record);
                }, // click row
                onDoubleClick: (event) => {}, // double click row
                onContextMenu: (event) => {}, // right button click row
                onMouseEnter: (event) => {}, // mouse enter row
                onMouseLeave: (event) => {}, // mouse leave row
              };
            }}
            pagination={false}
            scroll={{y: "550px"}}
          />
        </Col>
        <Col span={12}>
          <Table
            columns={jobLogColumns}
            dataSource={sectionJobLogList}
            size="small"
            title={() => {
              return selDataset.job_sq ? (
                <span>
                  {selDataset.job_sq}-{selDataset.f_input_date1}
                  <Button type="link" onClick={handleRefreshLog} icon={<ReloadOutlined />} />
                </span>
              ) : (
                ""
              );
            }}
            pagination={false}
            scroll={{y: "500px"}}
          />
        </Col>
      </Row>
      <Modal
        title="Section Job"
        open={jobDetailModalvisible}
        onOk={jobHandleOk}
        onCancel={jobHandleCancel}
        footer={null}
        width={1000}
        getContainer={false}
        maskClosable={false}
      >
        <SectionJobDetail
          selDataset={selDataset}
          jobHandleOk={jobHandleOk}
          jobHandleCancel={jobHandleCancel}
        ></SectionJobDetail>
      </Modal>
    </CrossectionDispatch.Provider>
  );
};

export default Crossection;
