import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Button, Select, Modal, Radio} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {convertAnnotationResultString} from "../../utils/utils";
import {convertDateString} from "../../utils/stringHelper";
import {
  TableRender,
  xaMessage,
  XaTooltipButton,
  XaConfirmTooltipButton,
  errorHandler,
} from "../../common";
import {createExcel, createCsv} from "../../utils/excelUtil";
import "./index.css";
import {executeTransaction} from "./AnnotationCopy";
import AnnotationDetail from "./AnnotationDetail";
import AnnotationJobDetail from "./AnnotationJobDetail";

const {Option} = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

/* Main Component */
const AnnotationMng = (props) => {
  /** states */
  const [annotationList, setAnnotationList] = useState([]); // 어노테이션 목록 데이터
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [createDateList, setCreateDateList] = useState([]); // 셀렉트박스 일자(Create Date)목록
  const [selectedProject, setSelectedProject] = useState(null); // 선택 프로젝트
  const [selectedCreateDate, setSelectedCreateDate] = useState(null); // 선택 일자
  const [selectedType, setSelectedType] = useState(null); // 선택 어노테이션 유형
  const [downloadModalVisible, setDownloadModalVisible] = useState(false); /// 데이터 다운로드 Modal Open 여부
  const [downloadDataCat, setDownloadDataCat] = useState({
    dataCat: "anno",
    fileCat: "csv",
  }); // 데이터 다운로드 초기 설정 (기본: 어노테이션 및 CSV 형식)
  const [selectedAnnotationMngSq, setSelectedAnnotationMngSq] = useState(null); // 목록에서 클릭한 어노테이션의 ID
  const [selectedAnnotationName, setSelectedAnnotationName] = useState(null); // 목록에서 클릭한 어노테이션의 이름
  const [selectedAnnotationState, setSelectedAnnotationState] = useState(null); // 목록에서 클릭한 어노테이션의 상태
  const [annotationDetailVisible, setAnnotationDetailVisible] = useState(false); // 어노테이션 상세 Modal Open 여부
  const [annotationJobDetailVisible, setAnnotationJobDetailVisible] = useState(false); // 어노테이션 Job 상세 Modal Open 여부
  const [gState, setGState] = useReducer(reducer, {
    projectList: [],
  });

  const {projectList} = gState; // Project 셀렉트박스 목록

  /** i18next */
  const {t} = useTranslation();

  /** 상수 */
  // 어노테이션 유형 셀렉트박스 내용 - Point, Annotation, Line, Area
  const annotationTypeList = [
    {label: "Annotation", value: "annotation"},
    {label: "Area", value: "area"},
    {label: "Line", value: "line"},
    {label: "Point", value: "point"},
  ];

  // Annotation 목록(Copy) 컬럼 정의
  const annotationColumns = [
    {
      title: t("annotation_manage_table_column_seq"),
      dataIndex: "annotation_mng_sq",
      width: 110,
      render: (text, record, _) => {
        return (
          <Button type="link" onClick={() => handleOpenAnnotationInfoModal(record)} size="small">
            {text}
          </Button>
        );
      },
    },
    {
      title: t("annotation_manage_table_column_project_seq"),
      dataIndex: "project_mng_sq",
      width: 100,
    },
    {
      title: t("annotation_manage_table_column_project"),
      dataIndex: "f_proj_nm",
    },
    {
      title: t("annotation_manage_table_column_create"),
      render: (record) => convertDateString(record.f_create_date),
    },
    {
      title: t("annotation_manage_table_column_fence_name"),
      dataIndex: "f_fence_nm",
    },
    {
      title: t("annotation_manage_table_column_fence_type"),
      dataIndex: "c_fence_type",
      width: 80,
    },
    {
      title: t("annotation_manage_table_column_anno_type"),
      dataIndex: "c_annotation_type",
      width: 80,
    },
    {
      title: t("annotation_manage_table_column_anno_tag"),
      dataIndex: "f_fence_annotation_flag",
    },
    {
      title: t("annotation_manage_table_column_status"),
      dataIndex: "f_status",
      render: (text) => convertAnnotationResultString(text),
      width: 150,
    },
    {
      title: t("annotation_manage_table_column_job"),
      dataIndex: "job_exec_count",
      render: (text, record, _) => {
        return (
          <>
            {text ? (
              <Button type="link" onClick={() => handleOpenAnnotationJobModal(record)} size="small">
                {text}
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
      width: 100,
    },
    {
      title: t("annotation_manage_table_column_run"),
      render: (_, record) => {
        return isRedo(record) ? (
          <XaConfirmTooltipButton
            toolTipContent={t("annotation_manage_mamual_run_tooltip")}
            toolTipPlacement="bottom"
            btnName={t("button_manual_run")}
            btnType="primary"
            size="small"
            popPlacement="top"
            popContent={t("annotation_manage_mamual_run_confirm", {seq: record.annotation_mng_sq})}
            onConfirm={() => onClickRunJob(record.annotation_mng_sq)}
          />
        ) : (
          "-"
        );
      },
    },
  ];

  // 프로젝트 선택 이벤트
  const handleChangeProject = async (targetId) => {
    setSelectedProject(targetId);
    const createDates = await executeTransaction(0, "/getInputDates", {
      projectId: targetId,
    });
    setCreateDateList(createDates);
    setSelectedCreateDate(null);
  };

  // 일자 선택 이벤트
  const handleChangeCreateDate = (selectedDate) => {
    setSelectedCreateDate(selectedDate);
  };

  // 상태 선택 이벤트
  const handleChangeType = (selectedType) => {
    setSelectedType(selectedType);
  };

  // 초기 함수
  const initialState = async () => {
    try {
      const {data} = await Axios.get("/project");
      setGState({type: "SET_PROJECT", payload: data});
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // 데이터 다운로드 버튼 클릭
  const onClickDownload = () => {
    // Modal 열기
    setDownloadModalVisible(true);
  };

  // 초기화 버튼 클릭
  const handleClear = () => {
    setAnnotationList([]);
    setSelectedProject(null);
    setSelectedCreateDate(null);
  };

  // 어노테이션 및 지오펜스 데이터 다운로드 시 조회하는 함수
  const handleDownloadData = async (category, fileCat) => {
    const prefix =
      category === "anno"
        ? t("annotation_data_download_data_radio_anno")
        : t("annotation_data_download_data_radio_geo");
    const handleFileName = (category, prefix) => {
      if (category === "geofence") {
        return !selectedProject && selectedProject !== 0
          ? `${prefix}_All`
          : `${prefix}_${selectedProject === 0 ? "0" : selectedProject}`;
      } else if (category === "anno") {
        return !selectedCreateDate && !selectedProject && selectedProject !== 0
          ? `${prefix}_All`
          : !selectedCreateDate
          ? `${prefix}_${selectedProject === 0 ? "0" : selectedProject}_All`
          : `${prefix}_${selectedProject === 0 ? "0" : selectedProject}_${selectedCreateDate}`;
      }
    };

    try {
      await Axios.get("/annotation/download", {
        params: {
          projectId: selectedProject,
          createDate: selectedCreateDate,
          category: category,
        },
      }).then(async (response) => {
        if (response.data.length > 0) {
          const fileName = handleFileName(category, prefix);

          // CSV OR Excel
          fileCat === "xlsx"
            ? createExcel(response.data, category, fileName)
            : createCsv(response.data, fileName);
        } else {
          xaMessage("warn", t("annotation_manage_download_warn", {prefix: prefix}));
        }
      });
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // 어노테이션 조회
  const handleSearch = async () => {
    setIsShowLoading(true);

    await Axios.get("/annotations/mng/list", {
      params: {
        projectId: selectedProject,
        createDate: selectedCreateDate,
        annotationType: selectedType,
      },
    })
      .then(({data}) => {
        setAnnotationList(data);
        setIsShowLoading(false);
      })
      .catch((err) => {
        errorHandler(t("message_error_beginning"), err);
        setIsShowLoading(false);
      });
  };

  // 어노테이션 정보 Modal
  const handleOpenAnnotationInfoModal = (record) => {
    setAnnotationDetailVisible(true);
    setSelectedAnnotationMngSq(record.annotation_mng_sq);
    setSelectedAnnotationName(record.f_fence_nm);
    setSelectedAnnotationState(record.f_status);
  };

  // 데이터 다운로드 Modal "Download" 클릭
  const handleOpenDownloadDataModal = () => {
    handleDownloadData(downloadDataCat.dataCat, downloadDataCat.fileCat);
  };

  // 어노테이션 Job 정보 Modal
  const handleOpenAnnotationJobModal = (record) => {
    setAnnotationJobDetailVisible(true);
    setSelectedAnnotationName(record.f_fence_nm);
    setSelectedAnnotationMngSq(record.annotation_mng_sq);
  };

  const handleAnnotationOk = () => {};

  // 데이터 다운로드 Modal 닫기
  const handleCanceDownloadDataModal = () => {
    setDownloadModalVisible(false);
  };

  // 어노테이션 정보 Modal 닫기
  const handleCancelAnnotation = () => {
    setAnnotationDetailVisible(false);
  };

  // 어노테이션 Job Modal 닫기
  const handleCancelAnnotationJob = () => {
    setAnnotationJobDetailVisible(false);
  };

  /**
   * 분석 재실행 (Service Broker 신규 실행)
   *
   * @param {*} annotationMngSq 어노테이션 일련번호(annotation_mng_sq)
   * @returns -
   */
  const onClickRunJob = async (annotationMngSq) => {
    try {
      await Axios.post("/annotations/runAnalysis", {
        params: {
          annotationMngSq: annotationMngSq,
        },
      });
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
    xaMessage(
      "success",
      t("annotation_manage_mamual_run_result_message", {annotationMngSeq: annotationMngSq})
    );
  };

  /**
   * 재실행 버튼 Disable 유무
   *  - 대상: 해당 Flag(a1, a2, l2)가 아니면서(비대상: l0, l1, p0, p2), Service Broker 미실행된 어노테이션
   *
   * @param {*} rowData 어노테이션 일련번호(annotation_mng_sq)별 데이터
   * @returns true/false
   */
  const isRedo = (rowData) => {
    const includesArr = ["a1", "a2", "l2"];
    return includesArr.includes(rowData.f_fence_annotation_flag) && !rowData.job_exec_count;
  };
  const handleChangeDownloadModalRadio = (e, cat) => {
    cat === "data"
      ? setDownloadDataCat({dataCat: e.target.value, fileCat: downloadDataCat.fileCat})
      : setDownloadDataCat({dataCat: downloadDataCat.dataCat, fileCat: e.target.value});
  };
  /** Effect */
  useEffect(() => {
    initialState();
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <>
      {/* 조회 조건 영역 */}
      <Row className="search-area ant-advanced-search-form">
        <Col span={8} key={0} className="search-area-part">
          {/* 프로젝트 선택 */}
          <Row className="ant-col ant-col-5 ant-form-item-label">{t("general_project")}:</Row>
          <Row>
            <Select
              className="search-area-select"
              onChange={handleChangeProject}
              value={selectedProject}
              showSearch
              optionFilterProp="children"
            >
              <Option>{t("selector_select")}</Option>
              {projectList &&
                projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    [{project.project_mng_sq}] {project.f_proj_nm}
                  </Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col span={8} key={1} className="search-area-part">
          {/* 일자 선택 */}
          <Row className="ant-col ant-col-5 ant-form-item-label">{t("annotation_create")}:</Row>
          <Row>
            <Select
              id="select-box-input-date"
              value={selectedCreateDate}
              showSearch
              className="search-area-select"
              onChange={handleChangeCreateDate}
            >
              <Option>{t("selector_select")}</Option>
              {createDateList &&
                createDateList.map((item) => (
                  <Option key={item.f_input_date} value={item.f_input_date}>
                    {convertDateString(item.f_input_date)}
                  </Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col span={8} key={2} className="search-area-part">
          {/* 어노테이션 유형 선택 */}
          <Row className="ant-col ant-col-5 ant-form-item-label">
            {t("annotation_manage_type")}:
          </Row>
          <Row>
            <Select className="search-area-select" onChange={handleChangeType} value={selectedType}>
              <Option>{t("selector_select")}</Option>
              {annotationTypeList.map((type) => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Row>
        </Col>
        {/* 버튼 영역 */}
        <Col span={24} className="search-area-button">
          {/* 어노테이션/지오펜스 데이터 다운로드 버튼 */}
          {/* <XaTooltipButton
            btnClassName="button-margin-right"
            title={t("annotation_manage_download_tooltip")}
            placement="bottomRight"
            btnName={t("button_download")}
            btnType="default"
            onClick={onClickDownload}
            disabled={!(selectedCreateDate && selectedProject)}
          /> */}
          {/* 검색 버튼 */}
          <Button className="button-margin-right" type="primary" onClick={handleSearch}>
            {t("button_search")}
          </Button>
          {/* 초기화 버튼 */}
          <Button onClick={handleClear}>{t("button_clear")}</Button>
        </Col>
      </Row>
      {/* Annotation 목록 영역 */}
      <TableRender
        dataSource={annotationList}
        columns={annotationColumns}
        rowKey={"annotation_mng_sq"}
        bordered={false}
        isLoading={isShowLoading}
        size="small"
      />

      {/* Modal Popup Windows */}
      {/* 데이터 다운로드 Modal */}
      <Modal
        title={t("annotation_data_download_title")}
        open={downloadModalVisible}
        okText={t("button_down")}
        cancelText={t("button_cancel")}
        onOk={handleOpenDownloadDataModal}
        onCancel={handleCanceDownloadDataModal}
        width={350}
        getContainer={false}
        maskClosable={false}
      >
        {/* 데이터 및 파일 종류 선택 */}
        <Row className="modal-download-row">
          <Col span={6}>{t("annotation_data_download_data")}</Col>
          <Col>
            <Radio.Group
              options={[
                {label: t("annotation_data_download_data_radio_anno"), value: "anno"},
                {label: t("annotation_data_download_data_radio_geo"), value: "geofence"},
              ]}
              onChange={(evt) => handleChangeDownloadModalRadio(evt, "data")}
              value={downloadDataCat.dataCat}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
        </Row>
        <Row className="modal-download-row">
          <Col span={6}>{t("annotation_data_download_file")} </Col>
          <Col>
            <Radio.Group
              options={[
                {label: "CSV", value: "csv"},
                {label: "XLSX", value: "xlsx"},
              ]}
              onChange={(evt) => handleChangeDownloadModalRadio(evt, "file")}
              value={downloadDataCat.fileCat}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
        </Row>
      </Modal>

      {/* 어노테이션 상세 */}
      <Modal
        title={t("annotation_manage_detail_title", {
          annotation_info: `${selectedAnnotationName} (${selectedAnnotationMngSq})`,
        })}
        open={annotationDetailVisible}
        onOk={handleAnnotationOk}
        onCancel={handleCancelAnnotation}
        width={1000}
        footer={null}
        getContainer={false}
        maskClosable={false}
      >
        <AnnotationDetail
          onCancel={handleCancelAnnotation}
          annotationMngSq={selectedAnnotationMngSq}
        />
      </Modal>

      {/* 어노테이션 Job 상세 */}
      <Modal
        title={t("annotation_manage_job_info_title", {
          job_info: `${selectedAnnotationName} (${selectedAnnotationMngSq})`,
        })}
        open={annotationJobDetailVisible}
        onOk={handleAnnotationOk}
        onCancel={handleCancelAnnotationJob}
        footer={null}
        width={650}
        getContainer={false}
        maskClosable={false}
      >
        <AnnotationJobDetail
          onCancel={handleCancelAnnotationJob}
          annotationMngSq={selectedAnnotationMngSq}
          annotationState={selectedAnnotationState}
        />
      </Modal>
    </>
  );
};

/* Exports */
export default AnnotationMng;
