import React, {useEffect, useState, useRef} from "react";
import {Button, Form, Input, Select} from "antd";
import Axios from "axios";
import {useTranslation} from "react-i18next";
import {errorHandler, xaMessage, LoadingAnimationWholePage} from "../../common";

const {Option} = Select;
const {TextArea} = Input;

/** 장착장비 상세 또는 추가 Modal Component */
const DeviceMGDetails = (props) => {
  const {selectedDeviceMGData, onFinish} = props;
  const [deviceKeyList, setDeviceKeyList] = useState();
  const [isShowLoad, setIsShowLoad] = useState(false);
  const formRef = useRef();

  /** i18next */
  const {t} = useTranslation();

  const handleCancel = () => {
    onFinish(false);
  };

  const handleFinish = async (parmas) => {
    let inputData = {
      mg_device_sq: selectedDeviceMGData && selectedDeviceMGData.mg_device_sq,
      mg_device_key: parmas.device_key,
      f_device_name: parmas.device_name,
      f_model: parmas.model,
      f_comp: parmas.comp,
      f_asset: parmas.asset,
      f_car_number: parmas.car_number,
      f_memo: parmas.memo,
    };

    try {
      // 데이터 저장
      await Axios.post("/mgDevice", {
        params: inputData,
      });

      // 저장 완료
      xaMessage("success", t("message_save_complete"));
      onFinish(true);
    } catch (err) {
      errorHandler(", err");
    }
  };

  const handleChangeDeviceKey = (e) => {};

  const handleSetDeviceKeyList = async () => {
    setIsShowLoad(true);
    try {
      const {data} = await Axios.get("/mgDevices/unregister");
      setDeviceKeyList(data);
    } catch (err) {
      errorHandler("", err);
    }
    setIsShowLoad(false);
  };

  useEffect(() => {
    if (selectedDeviceMGData.mg_device_key) {
      // 데이터 조회 및 설정
      formRef.current.setFieldsValue({
        device_key: selectedDeviceMGData.mg_device_key,
        device_name: selectedDeviceMGData.f_device_name,
        model: selectedDeviceMGData.f_model,
        comp: selectedDeviceMGData.f_comp,
        asset: selectedDeviceMGData.f_asset,
        car_number: selectedDeviceMGData.f_car_number,
        memo: selectedDeviceMGData.f_memo,
      });
    } else {
      // set device key list
      handleSetDeviceKeyList();

      formRef.current.setFieldsValue({
        device_key: "",
        device_name: "",
        model: "",
        comp: "",
        asset: "",
        car_number: "",
        memo: "",
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Render */
  return (
    <>
      <LoadingAnimationWholePage
        isLoading={isShowLoad}
        component={
          <>
            <Form
              ref={formRef}
              onFinish={handleFinish}
              labelCol={{span: 5}}
              wrapperCol={{span: 18}}
              layout="horizontal"
              size="small"
            >
              <Form.Item
                label={t("device_manage_device_form_key")}
                name="device_key"
                required={true}
              >
                <Select
                  placeholder={t("device_manage_device_form_key_placeholder")}
                  onChange={handleChangeDeviceKey}
                >
                  {deviceKeyList &&
                    deviceKeyList.map((deviceKey) => (
                      <Option key={deviceKey.device_key} value={deviceKey.device_key}>
                        {deviceKey.device_key}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("device_manage_device_form_name")}
                name="device_name"
                required={true}
              >
                <Input placeholder={t("device_manage_device_form_name_placeholder")} />
              </Form.Item>
              <Form.Item label={t("device_manage_device_form_model")} name="model">
                <Input placeholder={t("device_manage_device_form_model_placeholder")} />
              </Form.Item>
              <Form.Item label={t("device_manage_device_form_company")} name="comp">
                <Input placeholder={t("device_manage_device_form_company_placeholder")} />
              </Form.Item>
              <Form.Item label={t("device_manage_device_form_asset")} name="asset">
                <Input placeholder={t("device_manage_device_form_asset_placeholder")} />
              </Form.Item>
              <Form.Item label={t("device_manage_device_form_num")} name="car_number">
                <Input placeholder={t("device_manage_device_form_num_placeholder")} />
              </Form.Item>
              <Form.Item label={t("device_manage_device_form_memo")} name="memo">
                <TextArea rows={4} placeholder={t("device_manage_device_form_memo_placeholder")} />
              </Form.Item>
              <Form.Item wrapperCol={{offset: 9, span: 18}}>
                <Button
                  className="button-margin-right"
                  type="primary"
                  size="middle"
                  htmlType="submit"
                >
                  {t("button_save")}
                </Button>
                <Button onClick={handleCancel} size="middle">
                  {t("button_cancel")}
                </Button>
              </Form.Item>
            </Form>
          </>
        }
      />
    </>
  );
};

/* Exports */
export default DeviceMGDetails;
