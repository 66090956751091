import React, {useState, useContext, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Input, Button, Select, Divider} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {sortBy} from "lodash";
import {convertDatetimeString, convertDateString} from "../../utils/stringHelper";
import {
  xaMessage,
  errorHandler,
  XaConfirmTooltipButton,
  LoadingAnimationWholePage,
} from "../../common";
import "./index.css";
import {JobDispatch} from "./Jobs";

const {Option} = Select;

const UserDetail = (props) => {
  const {onCancel} = props;
  const [jobSq, setJobSq] = useState(null);
  const [mode, setMode] = useState("new");
  const [originalData, setOriginalData] = useState([]);
  const [rockInfoList, setRockInfoList] = useState([]);
  const [isShowLoad, setIsShowLoad] = useState(false);
  const [form] = Form.useForm();
  const axios = Axios.create();
  const {gState} = useContext(JobDispatch);

  /** i18next */
  const {t} = useTranslation();

  // 작업 상세 정보 로드
  const handleLoadJobData = async (job_sq) => {
    if (job_sq) {
      setIsShowLoad(true);
      const {data} = await axios.get(`/jobs/${job_sq}`, {job_sq: job_sq});
      setOriginalData(data);

      data.f_proj_nm = `(${data.project_mng_sq_t_project_mng}) ${data.f_proj_nm}`;
      data.f_input_date1 = convertDateString(data.f_input_date1);

      data.create_at = convertDatetimeString(data.create_at);
      data.update_at = convertDatetimeString(data.update_at);
      data.last_complete_at = convertDatetimeString(data.last_complete_at);

      form.setFieldsValue(data);

      setRockInfoList(
        sortBy(
          data.rock_info.filter(
            (item) => item.f_layer_nm !== null || item.subsurface_data_sq !== null
          ),
          "subsurface_data_sq"
        )
      );
      setMode("update");
      setIsShowLoad(false);
    }
  };

  const onClickBtnCancel = async () => {
    form.resetFields();
    onCancel();
  };

  // 프로젝트 정보(지형모델(Topo) 및 계획모델(Target) 이름 수정
  const handleSave = async () => {
    const {topo_layer_name, target_layer_name} = form.getFieldValue();

    if (
      originalData.topo_layer_name !== topo_layer_name ||
      originalData.target_layer_name !== target_layer_name
    ) {
      setIsShowLoad(true);
      await Axios.put(`/jobs/update/${jobSq}`, {
        params: {
          topoDataSq: originalData.topo_data_sq,
          newTopoLayerName: topo_layer_name,
          targetDataSq: originalData.target_data_sq,
          newTargetLayerName: target_layer_name,
        },
      })
        .then((data) => {
          onCancel();
          xaMessage("success", t("message_save_complete"));
        })
        .catch(function (e) {
          errorHandler("", e);
        });
      setIsShowLoad(false);
    } else {
      xaMessage("warn", "Please check for changes.");
    }
  };

  // 작업 재실행 (run_flag 수정)
  const handleRedo = async () => {
    const paramValue = form.getFieldValue();
    const isZeroFlag = paramValue.run_flag !== 0;
    const isEqualJobSq = jobSq === paramValue.job_sq;

    if (mode !== "new" && isZeroFlag && isEqualJobSq) {
      setIsShowLoad(true);
      await Axios.put(`/jobs/${jobSq}`, {params: paramValue})
        .then((data) => {
          onCancel();
          xaMessage("success", t("message_excute_reprocess"));
        })
        .catch(function (e) {
          errorHandler("", e);
        });
      setIsShowLoad(false);
    } else {
      errorHandler(t("message_error_not_change"), t("message_error_the_status"), null);
    }
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  /** Effect */
  useEffect(() => {
    if (jobSq !== props.jobSq) {
      setJobSq(props.jobSq);
      handleLoadJobData(props.jobSq);
    }
    if (!jobSq && props.jobSq === null) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  /* Renderer */
  return (
    <>
      <LoadingAnimationWholePage
        isLoading={isShowLoad}
        component={
          <>
            <Form
              labelCol={{span: 6.5}}
              autoComplete="off"
              form={form}
              onFinish={handleRedo}
              onFinishFailed={onFinishFailed}
              size="small"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="f_proj_nm"
                    label={t("general_project")}
                    rules={[{required: true}]}
                  >
                    <Input readOnly={true} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="f_input_date1" label={t("jobs_detail_input")}>
                    <Input readOnly={true} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="run_flag"
                    label={t("jobs_detail_status")}
                    rules={[{required: false}]}
                  >
                    <Select disabled>
                      {gState.runFlagList.map((item) => (
                        <Option key={item.key} value={item.key}>
                          {item.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="topo_data_sq" label={t("jobs_detail_topo_seq")}>
                    <Input readOnly={true} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="target_data_sq" label={t("jobs_detail_target_seq")}>
                    <Input readOnly={true} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="topo_layer_name" label={t("jobs_detail_layer_name")}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="target_layer_name" label={t("jobs_detail_target_name")}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={13}>
                  {/* Rock Layer Table */}
                  <Divider orientation="left">{t("jobs_detail_rock_layer")}</Divider>
                  <div className="layer-table">
                    <table>
                      <thead>
                        <tr>
                          <th>{t("jobs_detail_layer_table_sequence")}</th>
                          <th>{t("jobs_detail_layer_table_layer_name")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rockInfoList.length > 0 ? (
                          rockInfoList.map((item) => (
                            <tr>
                              <td>{item.subsurface_data_sq}</td>
                              <td>{item.f_layer_nm}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={2}>{t("table_empty_content")}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col span={11}>
                  {/* DateTimes */}
                  <Divider orientation="left">{t("jobs_detail_datetimes")}</Divider>
                  <>
                    <div className="datetime-info">
                      <table>
                        <tbody>
                          <tr>
                            <th>{t("jobs_detail_datetimes_table_create")}</th>
                            <td>{form.getFieldValue().create_at}</td>
                          </tr>
                          <tr>
                            <th>{t("jobs_detail_datetimes_table_update")}</th>
                            <td>{form.getFieldValue().update_at}</td>
                          </tr>
                          <tr>
                            <th>{t("jobs_detail_datetimes_table_last")}</th>
                            <td>{form.getFieldValue().last_complete_at}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                </Col>
              </Row>
              <Row className="bottom-button-area" gutter={16}>
                <Col
                  span={24}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button size="default" onClick={onClickBtnCancel} style={{marginRight: 8}}>
                    {t("button_cancel")}
                  </Button>
                  <XaConfirmTooltipButton
                    toolTipContent={t("jobs_detail_save_tooltip")}
                    toolTipPlacement="bottom"
                    btnName={t("button_save")}
                    btnStyle={{marginRight: 8}}
                    btnType="primary"
                    size="default"
                    popPlacement="top"
                    popContent={t("general_save_confirm")}
                    onConfirm={handleSave}
                  />
                  <XaConfirmTooltipButton
                    toolTipContent={t("jobs_detail_redo_tooltip")}
                    toolTipPlacement="bottom"
                    btnName={t("button_redo")}
                    btnType="primary"
                    size="default"
                    popPlacement="top"
                    popContent={t("general_redo_confirm")}
                    onConfirm={handleRedo}
                  />
                </Col>
              </Row>
            </Form>
          </>
        }
      />
    </>
  );
};

/* Exports */
export default UserDetail;
