import React, {useEffect, useState} from "react";
import {Row, Col, Button} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import Axios from "axios";
import {TableRender, xaMessage, errorHandler} from "../../common";

const AdminMng = (props) => {
  const {onCancel} = props;
  const [authList, setAuthList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  /** i18next */
  const {t} = useTranslation();

  const columns = [
    {
      title: t("user_admin_mng_table_column_seq"),
      align: "center",
      dataIndex: "auth_mng_sq",
      key: "tblAdminAuth01",
    },
    {
      title: t("user_admin_mng_table_column_code"),
      align: "center",
      dataIndex: "f_auth_nm",
      key: "tblAdminAuth02",
    },
    {
      title: t("user_admin_mng_table_column_type"),
      align: "center",
      key: "tblAdminAuth03",
      render: (_, record) => (record.code_txt ? record.code_txt : "-"),
    },
    {
      title: t("user_admin_mng_table_column_access"),
      align: "center",
      key: "tblAdminAuth04",
      render: (_, record) => (record.f_admin_auth_yn === "Y" ? <IconCheck /> : <IconClose />),
    },
  ];

  const onTableRowSelection = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onTableRowSelection,
    columnTitle: <></>,
  };

  const IconCheck = () => {
    return (
      <>
        <CheckOutlined size="small" style={{fontWeight: "1000"}} />
      </>
    );
  };

  const IconClose = () => {
    return (
      <>
        <CloseOutlined style={{fontWeight: "1000"}} />
      </>
    );
  };

  const initAdminAuth = async () => {
    const {data} = await Axios.get("/adminAuth");
    setAuthList(data);
    setSelectedRowKeys(data.filter((x) => x.f_admin_auth_yn === "Y").map((y) => y.auth_mng_sq));
  };

  const onClickSaveAdminMng = async () => {
    try {
      const {data} = await Axios.put("/adminAuth", {
        params: {
          changeAuthMngSqArray: selectedRowKeys,
        },
      });
      setAuthList(data);
      setSelectedRowKeys(data.filter((x) => x.f_admin_auth_yn === "Y").map((y) => y.auth_mng_sq));
      xaMessage("success", t("message_save_complete"));
    } catch (err) {
      errorHandler(t("message_error_beginning"), err, null);
    }
  };

  useEffect(() => {
    initAdminAuth();
  }, []);

  /* Renderer */
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          {/* <Table
            columns={columns}
            dataSource={authList}
            rowKey={"auth_mng_sq"}
            rowSelection={rowSelection}
            size="small"
            pagination={false}
          /> */}
          <TableRender
            columns={columns}
            dataSource={authList}
            rowKey={"auth_mng_sq"}
            rowSelection={rowSelection}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="col-margin-top col-align-right" span={24}>
          <Button className="button-margin-right" onClick={onCancel}>
            {t("button_close")}
          </Button>
          <Button type="primary" onClick={onClickSaveAdminMng}>
            {t("button_save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

/* Exports */
export default AdminMng;
