import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

/**
 * 전체 화면/요소에 로딩 애니메이션을 포함한 컴포넌트
 * @param {*} props
 *   - isLoading  로딩 애니메이션 표시 여부
 *   - component  해당 화면의 코드
 * @returns
 */
export const LoadingAnimationWholePage = (props) => {
  /** i18next */
  const {t} = useTranslation();

  const {isLoading, component} = props;
  return (
    <>
      <Spin
        indicator={<LoadingOutlined size="large" style={{fontSize: 36}} spin />}
        spinning={isLoading}
        size="large"
        tip={t("general_spin_tip")}
      >
        {component}
      </Spin>
    </>
  );
};
