import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Button} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import {convertDatetimeString} from "../../utils/stringHelper";
import {errorHandler} from "../../common";

/** Device Assign Info Main*/
const DeviceAssignInfo = (props) => {
  const {deviceKeyModal, onClickModalOk} = props;

  const [deviceKey, setDeviceKey] = useState(props.deviceKeyModal);
  const [assignInfo, setAssignInfo] = useState([]);

  /** i18next */
  const {t} = useTranslation();

  const axios = Axios.create();

  // Assign Info Table on Modal
  const columnsAssignInfo = [
    {
      title: t("device_details_assign_info_table_column_key"),
      dataIndex: "device_key",
    },
    {
      title: t("device_details_assign_info_table_column_subject"),
      dataIndex: "subject_id",
    },
    {
      title: t("device_details_assign_info_table_column_start"),
      dataIndex: "st_use_at",
      render: (time) => convertDatetimeString(time),
    },
    {
      title: t("device_details_assign_info_table_column_expire"),
      dataIndex: "en_use_at",
      render: (time) => convertDatetimeString(time),
    },
    {
      title: t("device_details_assign_info_table_column_map"),
      dataIndex: "map_info",
      render: (mapInfo) => {
        return (
          <span>
            {Object.keys(mapInfo)}: {JSON.stringify(mapInfo.keys)}{" "}
          </span>
        );
      },
    },
  ];

  // Assign Info 조회(Redis)
  const getAssignInfo = async (device_key) => {
    try {
      const {data} = await axios.get("/assignInfo", {
        params: {
          device_key: device_key,
        },
      });
      setAssignInfo(data ? data : []);
    } catch (e) {
      errorHandler(t("message_error_beginning"), e);
    }
  };

  useEffect(() => {
    getAssignInfo(props.deviceKeyModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deviceKeyModal]);

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <div className="assign-info">
            <table>
              <thead>
                <tr>
                  {columnsAssignInfo.map((columnItem) => (
                    <th>{columnItem.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {assignInfo.length > 0 ? (
                  assignInfo.map((item) => (
                    <tr>
                      <td>{item.device_key}</td>
                      <td>{item.subject_id}</td>
                      <td>{convertDatetimeString(item.st_use_at)}</td>
                      <td>{convertDatetimeString(item.en_use_at)}</td>
                      <td>
                        <span>
                          {Object.keys(item.map_info)}: {JSON.stringify(item.map_info.keys)}{" "}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="no-data-width" colSpan={5}>
                      {t("table_empty_content")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} className="assign-modal-button">
          <Button onClick={onClickModalOk}>{t("button_ok")}</Button>
        </Col>
      </Row>
    </>
  );
};

/* Exports */
export default DeviceAssignInfo;
