import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Button, Select, Modal, Space} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import {useTransition} from "react";
import "./index.css";
import {errorHandler, XaTooltip} from "../../common";
import {TableRender} from "../../common";
import {useTranslation} from "react-i18next";

const {Option} = Select;

// const TblDeviceList = (props) => {
//   const {columns, dataSource} = props;

//   return (
//     <Table
//       rowKey={"id"}
//       // rowSelection={rowSelection}
//       columns={columns}
//       dataSource={dataSource}
//       //  onRow={onRow}
//       // rowClassName={this.setRowClassName}
//       size="small"
//     />
//   );
// };

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FLT20":
      state = {...state, deviceKeyGbnList: action.payload.slice()};
      break;
    case "SET_FLT21":
      state = {...state, subStmList: action.payload.slice()};
      break;
    case "SET_FLT22":
      state = {...state, gbnWorkList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const DeviceDispatch = React.createContext(null);

/* Main Component */
const SocketManage = (props) => {
  const [socketList, setSocketList] = useState([]);
  const [nspList, setNspList] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  /** i18next */
  const {t} = useTranslation();

  const [form] = Form.useForm();

  /* user list table */
  const columns = [
    {
      title: t("socket_manage_table_column_name"),
      dataIndex: "namespace",
      key: "name",
      // render: (text, record, index) => {
      //   let rtnObj = subStmList.find((item) => `${item.code1}${item.code2}` === record.sub_stm);
      //   return rtnObj ? rtnObj.code_txt : "";
      // }
    },
    {
      title: t("socket_manage_table_column_id"),
      dataIndex: "id",
      key: "id",
      // render: (text, record, index) => {
      //   let rtnObj = gbnWorkList.find((item) =>  `${item.code1}${item.code2}` === record.gbn_work);
      //   return rtnObj ? rtnObj.code_txt : "";
      // }
    },
    {
      title: t("socket_manage_table_column_connect_at"),
      dataIndex: "connected_at",
      key: "connectAt",
      // render: (text, record, index) => {
      //   let rtnObj = `${record.device_name}[${record.device_key}]`;
      //   return rtnObj;
      // }
    },
    {
      title: t("socket_manage_table_column_disconnected"),
      dataIndex: "disconnected",
      key: "disconnect",
      width: 120,
      align: "center",
      render: (_, record) => (record.disconnected ? "O" : "X"),
    },
    {
      title: t("socket_manage_table_column_action"),
      key: "action",
      width: 120,
      render: (_, record) => {
        const handleDisconnect = (record) => {
          Modal.confirm({
            title: t("socket_manage_modal_title"),
            icon: <ExclamationCircleOutlined />,
            content: t("socket_manage_modal_content"),
            okText: t("button_ok"),
            cancelText: t("button_cancel"),
            onOk: () => onSetDisconnect(record),
          });
        };

        return (
          <>
            <Space size="middle">
              {/* <Button type="link" onClick={fnInClick} icon={<EditOutlined />} />*/}
              <Button type="link" onClick={() => handleDisconnect(record)}>
                {t("button_disconnect")}
              </Button>
              {/* <Button
                type="link"
                onClick={() => handleSendMessage(record)}>
                  {`SendMessage`}
              </Button> */}
            </Space>
          </>
        );
      },
    },
    {
      title: t("socket_manage_table_column_payload"),
      dataIndex: "data",
      key: "payload",
      width: 600,
      ellipsis: true,
      render: (_, record) => (
        <>
          <XaTooltip
            content={JSON.stringify(record.data)}
            mainComponent={JSON.stringify(record.data)}
          />
        </>
      ),
    },
  ];

  const initForm = async () => {
    try {
      // const { data } = await Axios.get("/code", { params: { code1: "FLT" } });
      // const flt20 = data.filter((v) => { return v.code2.substring(0,2) === '20'})
      // const flt21 = data.filter((v) => { return v.code2.substring(0,2) === '21'})
      // const flt22 = data.filter((v) => { return v.code2.substring(0,2) === '22'})
      // setGState({ type: "SET_FLT20", payload: flt20 });
      // setGState({ type: "SET_FLT21", payload: flt21 });
      // setGState({ type: "SET_FLT22", payload: flt22 });
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  // 검색
  const handleSearch = async (paramValue) => {
    setIsShowLoading(true);
    const _namespace = paramValue.namespace;

    try {
      const {data} = await Axios.get("/sockets", {params: paramValue});

      if (data && data.server_namespace) {
        setNspList(data.server_namespace.filter((x) => x !== ""));

        let tblData = [];
        data.sockets.map((item1) => {
          item1.sockets.map((item2) => {
            tblData.push({namespace: item1.namespace, ...item2});
          });
        });
        setSocketList(
          !_namespace ? tblData : tblData.filter((item) => item.namespace === _namespace)
        );
      }
    } catch (e) {
      errorHandler(t("message_error_beginning"), t("message_search_failed"), null);
    }
    setIsShowLoading(false);
  };

  const onFinish = async (paramValue) => {
    handleSearch(paramValue);
  };

  const onFinishFailed = (err) => {
    errorHandler(t("message_error_beginning"), err);
  };

  const onClientSendMessage = async (record, message) => {
    try {
      const {data} = await Axios.post("/client_sendmessage", {
        namespace: record.namespace,
        socket_id: record.id,
        message: message,
      });
    } catch (e) {}
  };

  const onSetDisconnect = async (record) => {
    try {
      const {data} = await Axios.post(`/client_disconnect`, {
        namespace: record.namespace,
        socket_id: record.id,
      });
    } catch (e) {}
  };

  const handleOk = () => {};

  const handleCancel = () => {
    // setDeviceModalVisible(false);
  };

  const handleSubjectCancel = () => {
    // setSubjectModalvisible(false);
  };

  const handleSendMessage = (record) => {
    var message = prompt(`Send message to ${record.id}`, "");
    onClientSendMessage(record, message);
  };

  useEffect(() => {
    Axios.defaults.headers.common["Authorization"] = JSON.parse(sessionStorage.userInfo).token;
    initForm();
    handleSearch({});
    return () => {};
  }, []);

  /* Renderer */
  return (
    <>
      <Form
        form={form}
        name="advanced_search"
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        className="common-search-area-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // initialValues={{ sub_stm: null, gbn_work: null, device_key : '', device_name : '', use_yn:null }}
      >
        <Row gutter={24}>
          <Col span={8} key={0}>
            <Form.Item
              name="namespace"
              label={t("socket_manage_name_space")}
              rules={[{required: false}]}
            >
              <Select>
                <Option>{t("selector_all")}</Option>
                {nspList.map((nsp) => (
                  <Option key={nsp} value={nsp}>
                    {nsp}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={24}>          
        <Col span={8} key={3}>
            <Form.Item
              name={`device_key`}
              label={`Key`}
              rules={[
                {
                  required: false,
                  message: "Choose optional",
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8} key={3}>
            <Form.Item
              name={`device_name`}
              label={`Name`}
              rules={[
                {
                  required: false,
                  message: "Choose optional",
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>         
        </Row> */}
        <Row>
          <Col span={24} style={{textAlign: "right"}}>
            {/* <Button type="dashed" onClick={fnOpenSubjectModal}>
              Subject Mng
            </Button> */}
            {/* <Button type="dashed" onClick={fnAddDevice}>
              Add Device
            </Button> */}
            <Button type="primary" htmlType="submit">
              {t("button_search")}
            </Button>
            <Button style={{margin: "0 8px"}} onClick={() => form.resetFields()}>
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </Form>

      <TableRender
        columns={columns}
        dataSource={socketList}
        isLoading={isShowLoading}
        rowKey="id"
      />
    </>
  );
};

export default SocketManage;
