import React, {useState, useReducer, useEffect, useRef} from "react";
import Axios from "axios";
import {Form, Row, Col, Button, Select, Modal, Table, Space, Tabs, message} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import SectionLineViewer from "./SectionLineViewer";
import SectionLineChartViewer from "./SectionLineChartViewer";
import SectionLineTableViewer from "./SectionLineTableViewer";
import SectionLineEditor from "./SectionLineEditor";

const {TabPane} = Tabs;
const {Option} = Select;

//Global State Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    case "SET_LAYERSOURCE":
      state = {...state, layerSourceList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

export const CrossectionDispatch = React.createContext(null);

/* Main Component */
const Crossection = (props) => {
  const [sectionList, setSectionList] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [stationChartList, setStationChartList] = useState([]);
  const frmInitVal = {f_project_mng_sq: null, f_create_date: null};

  const [gState, setGState] = useReducer(reducer, {projectList: [], layerSourceList: []});
  const {projectList, layerSourceList} = gState;

  const [form] = Form.useForm();
  const stationListOrg = useRef();

  const [selDataset, setSelDataset] = useState({
    project_mng_sq_t_project_mng: null,
    f_input_date1: null,
  });

  const [selTabIndex, setSelTabIndex] = useState(1);
  const [lineEditorModalvisible, setLineEditorModalvisible] = useState(false);

  /** i18next */
  const {t} = useTranslation();

  /* user list table */
  const sectionColumns = [
    {
      title: "Sec. Name",
      render: (record) => {
        return (
          <>
            <Space size="small">
              <Button
                type="link"
                onClick={() => fnGetStation(record)}
              >{`[${record.c_section_gbn}] ${record.c_section_name}`}</Button>
            </Space>
          </>
        );
      },
    },
    {
      title: "St Count",
      dataIndex: "station_cnt",
    },
  ];
  const stationColumns = [
    {
      title: "St. Name",
      render: (record) => {
        return (
          <>
            <Space size="small">
              <Button
                type="link"
                onClick={() => fnShowStationChart(record)}
              >{`${record.c_station_name}`}</Button>
            </Space>
          </>
        );
      },
    },
    {
      title: "Validate",
      dataIndex: "gbn_tr",
    },
  ];

  useEffect(() => {
    //초기 로딩 시 SearchForm 초기화
    fnInitForm();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fnInitForm = async () => {
    form.resetFields();

    try {
      const {data} = await Axios.get("/project");
      setGState({type: "SET_PROJECT", payload: data});
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleChangeProject = async (target) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({...fields, f_create_date: null});

    const {data} = await Axios.get("/layerSourceList", {params: {f_project_mng_sq: target}});
    // setLayerSourceList(data);
    setGState({type: "SET_LAYERSOURCE", payload: data});

    await setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: target,
      f_input_date1: null,
    });
    setSectionList([]);
    setStationList([]);
    setStationChartList([]);
  };

  const onFinish = async (paramValue) => {
    // console.log( paramValue )
    const {data} = await Axios.get("/sectionList", {params: paramValue});
    setSectionList(data);
    setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: paramValue.f_project_mng_sq,
      f_input_date1: paramValue.f_create_date,
    });
  };

  const onFinishFailed = (err) => {
    console.log(err);
  };

  const lineEditorHandleOk = () => {};

  const lineEditorHandleCancel = () => {
    setLineEditorModalvisible(false);
    // onFinish(selDataset);
    form.submit();
  };

  const fnOpenLineEditor = async () => {
    const frmValues = form.getFieldsValue();

    if (!frmValues.f_project_mng_sq || !frmValues.f_create_date) {
      message.error("Choose data~");
      return;
    }
    await setSelDataset({
      ...selDataset,
      project_mng_sq_t_project_mng: frmValues.f_project_mng_sq,
      f_input_date1: frmValues.f_create_date,
    });
    setLineEditorModalvisible(true);
  };

  const fnGetStation = async (record) => {
    try {
      const {f_create_date} = form.getFieldsValue();

      const f_target_input_date = layerSourceList.find(
        (item) => item.f_input_date === f_create_date
      ).f_target_input_date;

      let target_table_nm = `target_${record.project_mng_sq_t_project_mng}_${f_target_input_date}_original`;

      const {data} = await Axios.get("/stationList", {
        params: {
          f_project_mng_sq: record.project_mng_sq_t_project_mng,
          f_create_date: record.f_create_date,
          target_table_nm: target_table_nm,
          buffer_size: 0.01,
          f_section_id: record.f_section_id,
        },
      });
      stationListOrg.current = data;
      setStationList([...data.filter((item) => item.f_rock_num === 0)]);
    } catch (err) {
      console.log("error", err);
    }
  };

  const fnShowStationChart = (record) => {
    let chartArr = stationListOrg.current.filter(
      (item) => item.f_station_id === record.f_station_id
    );
    setStationChartList([...chartArr]);
  };

  const handleTabChange = (key) => {
    setSelTabIndex(key);
  };

  /* Renderer */
  return (
    <CrossectionDispatch.Provider value={{gState, setGState}}>
      {/* <Toggle ></Toggle> */}
      <Form
        form={form}
        name="advanced_search"
        labelCol={{span: 5}}
        wrapperCol={{span: 19}}
        className="ant-advanced-search-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={frmInitVal}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name={`f_project_mng_sq`}
              label={`Project`}
              rules={[
                {
                  required: true,
                  message: "선택",
                },
              ]}
            >
              <Select onChange={handleChangeProject} showSearch optionFilterProp="children">
                <Option>{t("selector_select")}</Option>
                {projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    ({project.project_mng_sq}) {project.f_proj_nm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="f_create_date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Choose optional",
                },
              ]}
            >
              <Select>
                <Option>{t("selector_select")}</Option>
                {layerSourceList.map((item) => (
                  <Option key={item.layer_source_sq} value={item.f_input_date}>
                    ({item.f_input_date}){item.f_title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
            }}
          >
            <Button type="dashed" onClick={fnOpenLineEditor}>
              Line CSV Loader
            </Button>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => form.resetFields()}
            >
              Clear
            </Button>
          </Col>
        </Row>
      </Form>

      <Row gutter={24}>
        <Col span={6}>
          <Table
            columns={sectionColumns}
            dataSource={sectionList}
            size="small"
            pagination={false}
            scroll={{y: 450}}
          />
        </Col>
        <Col span={6}>
          <Table
            columns={stationColumns}
            dataSource={stationList}
            size="small"
            pagination={false}
            scroll={{y: 450}}
          />
        </Col>
        <Col span={12}>
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab="Model" key="1">
              <SectionLineViewer
                style={{width: 600, height: 400}}
                selDataset={selDataset}
                selTabIndex={selTabIndex}
                stationList={stationList}
              ></SectionLineViewer>
            </TabPane>
            <TabPane tab="Graph" key="2">
              <SectionLineChartViewer stationChartList={stationChartList}></SectionLineChartViewer>
            </TabPane>
            <TabPane tab="Table" key="3">
              <SectionLineTableViewer stationChartList={stationChartList}></SectionLineTableViewer>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Modal
        open={lineEditorModalvisible}
        onOk={lineEditorHandleOk}
        onCancel={lineEditorHandleCancel}
        title="Line CSV Loader"
        footer={null}
        width={1000}
        getContainer={false}
        maskClosable={false}
      >
        {lineEditorModalvisible && (
          <SectionLineEditor
            selDataset={selDataset}
            lineEditorModalvisible={lineEditorModalvisible}
            lineEditorHandleCancel={lineEditorHandleCancel}
          ></SectionLineEditor>
        )}
      </Modal>
    </CrossectionDispatch.Provider>
  );
};

export default Crossection;
