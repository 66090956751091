import React, {useState, useEffect} from "react";
import {Row, Col, Select} from "antd";
import "./index.css";
import Axios from "axios";
import Logo from "../../../src/assets/logo/Logo190x34px-01.png";
import {HEALTH_CHECK_INTERVAL_SECONDS} from "../../config/miscellaneousInfo";
import {useInterval} from "../../utils/useInterval";
import {languagesList, changeLanguage, xcHealthCheck} from "../../utils/utils";
import {shortenString} from "../../utils/stringHelper";
import {XaTooltip, XaConfirmButton, errorHandler} from "../../common";
import {useTranslation} from "react-i18next";

const {Option} = Select;

/**
 * 서버 상태 컴포넌트
 * @param {*} props
 *    type: 서버 구분 (sol: XiteCloud Solution(Fleet, Analyst), admin: XiteAdmin)
 *    state: 서버 상태 (T/F)
 * @returns AntD Tooltip을 포함한 컴포넌트
 */
const StatusInfoComponent = (props) => {
  const {type, state} = props;

  // i18next
  const {t} = useTranslation();
  const typeContents = type === "sol" ? t("xitecloud_server") : t("xiteadmin_server");
  const indicatorContents = state ? t("general_ok") : t("general_check");
  const indicatorMain = state ? <span className="green-dot" /> : <span className="red-dot" />;
  const typeStringMain = type === "sol" ? "XC" : "XA";

  return (
    <>
      <XaTooltip
        content={`${typeContents}: ${indicatorContents}`}
        placement="bottom"
        mainComponent={
          <span>
            {indicatorMain}
            {typeStringMain}
          </span>
        }
        color={state ? "#29c93e" : "#ec281a"}
      />
    </>
  );
};

/** 한영 표시 전환 */
const SlctLanguge = (props) => {
  const {value, onChange, optionList} = props;
  return (
    <>
      <Select
        style={{color: "#ffffff"}}
        onChange={onChange}
        value={value}
        bordered={false}
        size="small"
      >
        {optionList.map((el) => (
          <Option key={el.label} value={el.value}>
            {el.label}
          </Option>
        ))}
      </Select>
    </>
  );
};

/**
 * 계정 정보 컴포넌트
 * @returns 계정 정보 테이블을 포함한 컴포넌트
 */
const AccountInfoComponent = () => {
  // i18next
  const {t} = useTranslation();
  const userInfoObject = JSON.parse(sessionStorage.userInfo);

  return (
    <div className="account-tooltip-content">
      <table>
        <tbody>
          <tr>
            <th>{t("user_seq")} </th>
            <td>{userInfoObject.user_mng_sq}</td>
          </tr>
          <tr>
            <th>{t("general_id")}</th>
            <td>{shortenString(13, userInfoObject.user_email_id)}</td>
          </tr>
          <tr>
            <th>{t("user_type")}</th>
            <td>{userInfoObject.t_user_type}</td>
          </tr>
          <tr>
            <th>{t("user_company")}</th>
            <td>{userInfoObject.company_info[0].f_comp_nm}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const HeaderMain = () => {
  // i18next
  const {t} = useTranslation();
  const {i18n} = useTranslation();

  const [initUserInfo, setInitUserInfo] = useState(
    sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : null
  );
  const [initUserCompanyInfo, setInitUserCompanyInfo] = useState(
    sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo).company_info[0] : null
  );

  const [currentMultilingualName, setCurrentMultilingualName] = useState(i18n.language);
  const [xcSolStatus, setXcSolStatus] = useState(null);
  const [xcAdminStatus, setAdminXcStatus] = useState(null);

  const getHealthInfo = async () => {
    const result = await xcHealthCheck();
    setAdminXcStatus(result.admin);
    setXcSolStatus(result.sol);
  };

  const handleLogout = () => {
    Axios.get("/authenticate")
      .then(() => {
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("languageScreen");
        window.location.href = "/";
      })
      .catch((err) => errorHandler(t("message_error_beginning"), err));
  };

  const handleClickMultilingul = (value) => {
    setCurrentMultilingualName(value);
    changeLanguage(value);
  };

  useInterval(() => {
    getHealthInfo();
  }, HEALTH_CHECK_INTERVAL_SECONDS);

  useEffect(() => {
    getHealthInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="header-main">
        <Col>
          <img
            className="logo-img"
            src={Logo}
            alt="XiteCloud Logo"
            onClick={() => window.open("https://xitecloud.io/")}
          />
        </Col>
        <Col className="user-info-space">
          <Col className="server-status-space">
            <span className="server-status-title">{t("general_status")}</span>
            <span className="server-status-each">
              <StatusInfoComponent type="sol" state={xcSolStatus} />
            </span>
            <span className="server-status-each">
              <StatusInfoComponent type="admin" state={xcAdminStatus} />
            </span>
          </Col>
          <Col>
            <XaTooltip
              content={AccountInfoComponent}
              placement="bottom"
              mainComponent={`${initUserInfo.f_user_nm} (${initUserCompanyInfo.f_comp_nm})`}
            />
          </Col>
          <Col className="lingual-swich">
            <SlctLanguge
              onChange={handleClickMultilingul}
              value={currentMultilingualName}
              optionList={languagesList}
            />
          </Col>
          <Col>
            <XaConfirmButton
              contents={t("logout_confirm")}
              onConfirm={handleLogout}
              btnName={t("button_logout")}
              buttonClassName="button-logout"
              placement="bottomRight"
            />
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default HeaderMain;
