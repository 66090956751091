import proj4 from "proj4";

const Cesium = window.Cesium;

export const addImageryProvider = (viewer, provider, index = undefined) => {
  if (!viewer || !provider) return;

  const idx = index && Number(index);

  return viewer.imageryLayers.addImageryProvider(provider, idx);
};

export const loadTileMap = (viewer, url, index = undefined) => {
  if (!url) return;
  const provider = new Cesium.TileMapServiceImageryProvider({url});
  return addImageryProvider(viewer, provider, index);
};

/**
 * WGS84(EPSG 4326) 좌표 변환
 * @param {*} coordSystem 저장된 ESPG 좌표계
 * @param {*} coordinates 좌표 배열 ([x, y] 또는 [x, y, z])
 * @returns WGS84(EPSG 4326) 좌표 배열 ([x, y] 또는 [x, y, z])
 */
export const converWGS84Arr = (coordSystem, coordinates) => {
  const wgs84 = proj4.defs("EPSG:4326");
  return proj4(coordSystem, wgs84, coordinates);
};

/**
 * 문자열 좌표의 JSON 배열 변환
 * @param {*} str 문자열 (x-y-z 구분은, 지점간 구분은 |)
 * @param {*} coordSystem ESPG 좌표계
 * @returns 배열 2개(입력 EPSG 좌표 배열, WGS84(EPSG 4326) 좌표 배열)가 포함된 JSON
 */
export const convertStringToJsonArray = (str, coordSystem) => {
  let locArr = [];
  let locWGS84Arr = [];
  let _geom = str.split("|");
  const rowJson = (_idx, _row) => {
    return {
      index: _idx,
      x: _row[0],
      y: _row[1],
      z: _row[2],
    };
  };

  for (let i = 0; i < _geom.length; i++) {
    if (!_geom[i] || _geom[i] === "") {
      continue;
    } else {
      const idx = i + 1;

      const _rowGeom = _geom[i].split(",").map((i) => parseFloat(i));
      const _originalRowJson = rowJson(idx, _rowGeom);

      const _rowGeomWgs84 = converWGS84Arr(coordSystem, _rowGeom);
      const _convertRowJson = rowJson(idx, _rowGeomWgs84);

      locArr.push(_originalRowJson);
      locWGS84Arr.push(_convertRowJson);
    }
  }
  return {
    original: locArr,
    wgs84: locWGS84Arr,
  };
};

// LINESTRING 문자열을 반점을 기준으로 분리하여 각 쌍의 좌표를 얻음
export const convertLineStringToArray = (str) => {
  const pairs = str.replace(/[LINESTRING()]/g, "").split(",");

  // 각 좌표 쌍을 공백을 기준으로 분리하여 x, y를 추출하고 배열에 추가
  return pairs.reduce((acc, pair) => {
    const [x, y] = pair.trim().split(" ");
    acc.push(parseFloat(x), parseFloat(y));
    return acc;
  }, []);
};

export const convertSimpleArray = (locArr) => {
  let returnArray = [];

  for (let i = 0; i < locArr.length; i++) {
    returnArray.push(locArr[i].x);
    returnArray.push(locArr[i].y);
  }

  return returnArray;
};

/**
 *  동서남북 좌표 (Polygon의 변환 배열에서 좌표 분리)
 *
 * @param {*} coordinatesArray 1차원 좌표 배열([x1, y1, x2, y2, ...])
 * @returns 동서남북 좌표 JSON
 */
export const getRectangleCoordinates = (coordinatesArray) => {
  const evenIndexArray = coordinatesArray.filter((v, i) => {
    return !(i % 2);
  });
  const oddIndexArray = coordinatesArray.filter((v, i) => {
    return i % 2;
  });

  const minY = Math.min.apply(Math, evenIndexArray);
  const maxY = Math.max.apply(Math, evenIndexArray);
  const minX = Math.min.apply(Math, oddIndexArray);
  const maxX = Math.max.apply(Math, oddIndexArray);

  return {
    minX: minX,
    maxX: maxX,
    minY: minY,
    maxY: maxY,
  };
};

/**
 *  중앙 좌표
 *
 * @param {*} coordinatesArray 1차원 좌표 배열([x1, y1, x2, y2, ...])
 * @returns 중앙 좌표(x, y) JSON
 */
export const getMiddlePoints = (coordinatesArray) => {
  const evenIndexArray = coordinatesArray.filter((v, i) => {
    return !(i % 2);
  });
  const oddIndexArray = coordinatesArray.filter((v, i) => {
    return i % 2;
  });

  const minY = Math.min.apply(Math, evenIndexArray);
  const maxY = Math.max.apply(Math, evenIndexArray);
  const minX = Math.min.apply(Math, oddIndexArray);
  const maxX = Math.max.apply(Math, oddIndexArray);

  return {
    x: (minY + maxY) / 2,
    y: (minX + maxX) / 2,
  };
};

/**
 * Cesium Point Entity 구성 *
 *
 * @param {*} _name Entity 명칭
 * @param {*} locArr  좌표 배열
 * @returns Entity JSON
 */
export const getEllipseEntity = (_name, locArr) => {
  return {
    id: _name,
    name: _name,
    position: fromDegrees(locArr[0], locArr[1], 0),
    ellipse: {
      semiMinorAxis: 10,
      semiMajorAxis: 10,
      material: Cesium.Color.BLUE.withAlpha(0.5),
      outline: false, // height must be set for outline to display
    },
  };
};

/**
 * Cesium 다각형(Polygon) 및 선분(Polyline) Entity 구성 *
 *
 * @param {*} _name Entity 명칭
 * @param {*} locArr  좌표 배열
 * @returns Entity JSON
 */
export const getPolygonEntity = (_name, locArr) => {
  return {
    id: _name,
    name: _name,
    polyline: {
      width: 3,
      positions: fromDegreesArray(locArr),
      clampToGround: true,
      material: Cesium.Color.BLUE.withAlpha(0.5),
      outline: false,
      outlineColor: Cesium.Color.BLUE,
      height: 0,
    },
    polygon: {
      hierarchy: fromDegreesArray(locArr),
      material: Cesium.Color.BLUE.withAlpha(0.3),
      height: 0,
    },
  };
};

/**
 * Cesium 선분(Polyline) Entity 구성 *
 *
 * @param {*} _name Entity 명칭
 * @param {*} locArr  좌표 배열
 * @returns Entity JSON
 */
export const getPolylineEntity = (_name, locArr) => {
  return {
    id: _name,
    name: _name,
    polyline: {
      width: 3,
      positions: fromDegreesArray(locArr),
      clampToGround: true,
      material: Cesium.Color.RED.withAlpha(0.5),
      outline: false,
      outlineColor: Cesium.Color.BLUE,
      height: 0,
    },
  };
};

export const toDegrees = (radian) => {
  return Cesium.Math.toDegrees(radian);
};

/** Cartesian3: 3차원 좌표 */
export const fromDegrees = (x, y, z) => {
  return Cesium.Cartesian3.fromDegrees(x, y, z);
};

/** Cartesian3: 좌표 배열 */
export const fromDegreesArray = (array) => {
  return Cesium.Cartesian3.fromDegreesArray(array);
};

/** Cartesian3: 배열(높이 포함) */
export const fromDegreesArrayHeights = (array) => {
  return Cesium.Cartesian3.fromDegreesArrayHeights(array);
};

/** Heading, Pitch, Roll 수치 설정 */
export const setHeadingPitRoll = (_heading = 0, _pitch = 0.0, _roll = 0.0) => {
  return new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(_heading), _pitch, _roll);
};

/** 시점 설정 */
export const setOrientation = (_position, _hpr) => {
  return Cesium.Transforms.headingPitchRollQuaternion(_position, _hpr);
};

/**
 * 사각형 조감을 위한 영역 변환
 *
 * North: X좌표 최대
 * South: X좌표 최소
 * West: Y좌표 최소
 * East: Y좌표 최대
 * */
export const getRectangleFromDegrees = (minX, maxX, minY, maxY) => {
  const south = minX;
  const north = maxX;
  const west = minY;
  const east = maxY;

  return Cesium.Rectangle.fromDegrees(west, south, east, north);
};

/**
 * Open Street Map 호출 및 로드
 *
 * @param {*} url 호출 URL
 * @returns Open Street Map 포함 레이어
 */
export const loadOpenStreetMap = (url) => {
  if (!url) return;

  const layer = new Cesium.OpenStreetMapImageryProvider({url});
  return layer;
};

/**
 * Entity 추가
 *
 * @param {*} entityCollection  Cesium Viewer 개체
 * @param {*} entityOptions  Entity 개체
 * @returns
 */
export const addEntity = (entityCollection, entityOptions) => {
  return entityCollection.add(entityOptions);
};

/**
 * 전체 Entity 제거
 *
 * @param {*} entityCollection Cesium Viewer 개체
 */
export const removeAllEntities = (entityCollection) => {
  entityCollection.removeAll();
};

/**
 * Cesium setView 구성
 *
 * @param {*} viewer Cesium Viewer 객체
 * @param {*} isPoint 점(Point) 여부
 * @param {*} destination 조감 위치 설정
 */
export const setView = (viewer, destination) => {
  viewer.camera.setView({
    destination: destination,
    // orientation: {
    //   heading: Cesium.Math.toRadians(-45),
    //   pitch: Cesium.Math.toRadians(-85),
    //   roll: 0,
    // },
  });
};

// zoomTo 설정
export const zoomTo = (viewer, entityCollection) => {
  viewer.zoomTo(
    entityCollection,
    new Cesium.HeadingPitchRange(Cesium.Math.toRadians(-45), Cesium.Math.toRadians(-45), 0)
  );
};

/**
 *  시점 조정 (Camera 설정)
 *
 * @param {*} viewer Cesium Viewer 객체
 * @param {*} middlePoints Annotation 중심 좌표(x, y)
 */
export const cameraTransform = (viewer, middlePoints) => {
  const transform = Cesium.Transforms.eastNorthUpToFixedFrame(
    fromDegrees(middlePoints.x, middlePoints.y)
  );
  const camera = viewer.camera;
  camera.constrainedAxis = Cesium.Cartesian3.UNIT_Z;
  camera.lookAtTransform(transform, new Cesium.Cartesian3(-300, -300, 300));
};

// Cesium Viewer 설정 (Open Street Map 적용)
export const makeViewerWithOpenMap = (
  cesiumContainer,
  isAnimation,
  isBaseLayerPicker,
  isFullscreenButton,
  isVrButton,
  isGeocoder,
  isHomeButton,
  isInfoBox,
  isSceneModePicker,
  isSelectionIndicator,
  isTimeline,
  isNavigationHelpButton,
  creditContainer,
  url
) =>
  new Cesium.Viewer(cesiumContainer.current, {
    animation: isAnimation,
    baseLayerPicker: isBaseLayerPicker,
    fullscreenButton: isFullscreenButton,
    vrButton: isVrButton,
    geocoder: isGeocoder,
    homeButton: isHomeButton,
    infoBox: isInfoBox,
    sceneModePicker: isSceneModePicker,
    selectionIndicator: isSelectionIndicator,
    timeline: isTimeline,
    navigationHelpButton: isNavigationHelpButton,
    creditContainer: creditContainer.current,
    imageryProvider: loadOpenStreetMap(url),
    contextOptions: {
      webgl: {
        alpha: false,
        antialias: true,
        preserveDrawingBuffer: true,
        failIfMajorPerformanceCaveat: false,
        depth: true,
        stencil: false,
        anialias: true,
      },
    },
    targetFrameRate: 60,
    resolutionScale: 0.1,
    // terrainShadows: Cesium.createWorldTerrain(),
  });

export const loadGltfModel = (viewer, id, urlGlft, points) => {
  try {
    const _position = fromDegrees(points.x, points.y, 1000);
    const _hpr = setHeadingPitRoll(135, 0.0, 0.0);
    const _orientation = setOrientation(_position, _hpr);

    viewer.entities.add({
      id: id,
      name: `Glb_${id}`,
      position: _position,
      orientation: _orientation,
      model: {
        uri: urlGlft,
        // modelMatrix: modelMatrix,
        minimumPixelSize: 128,
        maximumScale: 1000,
      },
    });
  } catch (error) {
    console.log(`Failed to load model. ${error}`);
  }
};
